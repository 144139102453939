import React from 'react';

import "./style.css"
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Cookies from 'js-cookie';
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../../../Comps/Modal';
import { apiUrl } from '../../../config';


const ItemTypes = {
  CARD: 'card',
};






class ImgR extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    sendImg:true,
    images: [],
    uploadedImages: [],
    items:[]
    }
    this.UplRef = React.createRef();
    this.BtnRef = React.createRef();
  }

  moveCard = (fromIndex, toIndex) => {
    const { items } = this.state;
    const newItems = [...items];
    const [removedItem] = newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, removedItem);
    this.setState({ items: newItems });
  };

  DraggableItem = ({ id,camp,img, index, moveCard }) => {
    const [, ref] = useDrag({
      type: ItemTypes.CARD,
      item: { id, index },
    });
  
    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: (item) => {
        if (item.index !== index) {
          moveCard(item.index, index);
          item.index = index;
        }
      },
    });
  
    return (
      <div ref={(node) => ref(drop(node))} class="area-drag">
      <div class="sc-6a0e7dd4-8 hlxybp">
     <div class="sc-6a0e7dd4-4 ejYSKg position"><span>{index + 1}</span></div>
     <div class="sc-6a0e7dd4-5 BXBkH">
        
  
        <button type="button" title="Remover" aria-label="Remover"
        onClick={()=>{
      
        
       

          Modal.create({titulo:"Remover imagem",html:<>
  <p style={{color:"#ccc"}}>Você tem certeza que deseja remover esta imagem? Esta ação não pode ser desfeita.</p>
  <div class="ajt-rm">
     <button type="button" class="sc-b1836745-0 bJxjoS"  onClick={(e)=>{
      e.target.classList.add("load");
      fetch(`${apiUrl}/raffles/images/${id}/${camp}/`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer '+Cookies.get('auth_token')
        },
        
      })
      .then(response => {
        e.target.classList.remove("load");
        if(response.status == 200){
  
response.json().then(data=>{


  const novaLista = this.state.items.filter(item => item.id !== data.r);
  this.setState({ items: novaLista });
  Modal.close()


})


  
        }
        
      })
     }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash ">
           <path d="M3 6h18"></path>
           <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
           <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
        </svg>
        Remover
     </button>
  </div>
  
          </>})
        }} class="sc-6a0e7dd4-6 lmyzAw">
           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash ">
              <path d="M3 6h18"></path>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
           </svg>
        </button>
  
     </div>
  </div>
  
    <img src={img} />
      
      </div>
    );
  };
 
  _Upload(e){
    var files = e.target.files;
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
     const file = files[i];
    
if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
toast.error("Formato de imagem inválido!", {
position: "top-center",
autoClose: 3000, // Fecha automaticamente após 3 segundos
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});
return;
}
if (file.size > 3 * 1024 * 1024) {
toast.error("Imagem muito grande!", {
position: "top-center",
autoClose: 3000, // Fecha automaticamente após 3 segundos
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});
return;
}

newImages.push(file);
    }


    const formData = new FormData();

    newImages.forEach((image, index) => {
      formData.append(`imagem`, image);
    });

    fetch(`${apiUrl}/raffles/${this.state.data.id}/images/`, {
     credentials: 'include',
     method: 'POST',
     headers: {
       'Authorization': 'Bearer '+Cookies.get('auth_token')
     },
     body:formData
   })
   .then(response => {
     if(response.status == 200){

response.json().then(data=>{
data.images.forEach((item,index)=>{



const newItem = {
id: item.id,
img: `${apiUrl}/static/${item.name}` 
};

this.setState(prevState => ({
items: [...prevState.items, newItem],
sendImg:false
}));



})
})


       this.setState({sendImg:false})
     }
   })
   
  }



  componentDidMount(){
document.title = "Imagens - EuRifei" 


var url = window.location.href;
const regex = /\/raffles\/(.*?)\//;
const match = url.match(regex);

    document.title  = "Carregando..."
    fetch(''+apiUrl+'/raffles/'+match[1], {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
         this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {

            document.title = `Imagens -  EuRifei`;
            this.setState({Loader:false,data:result,})

            if(result.images.length > 0){

              result.images.forEach((item,index)=>{
    

   
                const newItem = {
                  id: item.ref,
                  img: `${apiUrl}/static/${item.name}` 
                };
            
                this.setState(prevState => ({
                  items: [...prevState.items, newItem],
                  sendImg:false
                }));
            
            
            
              })



              this.setState({sendImg:false})
            }else{
              this.setState({sendImg:true})
            }
            
            })
            .catch(error => {
          console.log(error)   
          });

 




  }
   render() {
   
    
  
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <main class="sc-2ecf8ab5-0 dASEZo">
   <div class="sc-2ecf8ab5-1 lmEcAp">
      <div class="sc-2ecf8ab5-2 kVNZbz">
         <div class="sc-84a07fe4-0 jZNhWw">
            <a type="button" class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                  <polyline points="21 15 16 10 5 21"></polyline>
               </svg>
               <span>Imagens</span>
            </a>
            <a type="button" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
               </svg>
               <span>Opções</span>
            </a>
         </div>
         <div class="sc-2ecf8ab5-5 bBUHDK">
          {this.state.sendImg == true ? <>
          
            <div class="sc-2ecf8ab5-6 ixaFYK">
               <div class="sc-a9fd16ec-0 hvvAoc">
                  <h1 class="sc-2ecf8ab5-3 ctpgvf">Imagens</h1>
                  <div role="presentation" tabindex="0" class="sc-a9fd16ec-1 epGFUm dropzone">
                     <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 247 213" class="cloud-icon">
                        <rect fill="#0071ff" x="54" y="159" width="157" height="54"></rect>
                        <circle fill="#0071ff" cx="62" cy="151" r="62"></circle>
                        <circle fill="#0071ff" cx="133.313" cy="159.313" r="50.313"></circle>
                        <circle fill="#0071ff" cx="211" cy="176.625" r="36"></circle>
                        <circle fill="#bdc1c6" cx="24.5" cy="84.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="27.5" cy="68.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="33.5" cy="54.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="64.5" cy="17.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="100.5" cy="2.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="121.5" cy="1.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="141.5" cy="3.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="171.5" cy="17.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="187.5" cy="29.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="199.5" cy="46.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="207.5" cy="66.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="209.5" cy="114.5" r="1.5"></circle>
                        <circle fill="#bdc1c6" cx="205.5" cy="129.5" r="1.5"></circle>
                        <path fill="#fb8c00" fill-rule="evenodd" d="M203.651,75.514l28.563,6-1.648,4.129a2.878,2.878,0,0,0-3.5,1.307,2.8,2.8,0,0,0,1.487,3.721l-1.676,4.2a2.671,2.671,0,0,0-1.981,4.961l-1.529,3.828-28.524-6.1Z"></path>
                        <path fill="#ffffff" fill-rule="evenodd" d="M212.854,95.873l4.086,0.871-0.692-5.852a4.5,4.5,0,0,0,2.538-1.245,4.714,4.714,0,0,0,1.263-2.434,4,4,0,0,0,.051-1.383,2.747,2.747,0,0,0-.5-1.229,3.6,3.6,0,0,0-1.133-.99,6.209,6.209,0,0,0-1.834-.662l-6.222-1.326-4.737,12.719,3.866,0.824,1.753-4.751,1.086,0.231Zm-0.564-8.088,0.919-2.535,1.417,0.3a2.085,2.085,0,0,1,1.134.513,0.945,0.945,0,0,1,.182.852,1.514,1.514,0,0,1-.355.757,1.351,1.351,0,0,1-.6.376,2.222,2.222,0,0,1-.765.08,5.374,5.374,0,0,1-.823-0.11Z"></path>
                        <path fill="none" fill-rule="evenodd" stroke="#686b74" stroke-linecap="round" stroke-width="2px" d="M41.36,25.715l10.253-2.686A2.941,2.941,0,0,1,55.2,25.187l2.637,10.445a3.005,3.005,0,0,1-2.118,3.653L45.464,41.971a2.941,2.941,0,0,1-3.585-2.158L39.241,29.368A3,3,0,0,1,41.36,25.715Z"></path>
                        <path fill="none" fill-rule="evenodd" stroke="#686b74" stroke-linecap="round" stroke-width="2px" d="M44,41.488l7.317-11.449,6.018,3.622"></path>
                        <circle fill="none" stroke="#686b74" stroke-linecap="round" stroke-width="2px" cx="44.031" cy="29.781" r="1.406"></circle>
                        <path fill="#0071ff" fill-rule="evenodd" d="M160.5,11.055l-8.6-1.036,5.2-6.928Z"></path>
                        <circle fill="#ffc100" cx="81.219" cy="8" r="2.938"></circle>
                     </svg>
                     <h2 class="sc-a9fd16ec-2 hZenhZ">Arraste imagens aqui</h2>
                     <div class="sc-a9fd16ec-3 itaBiH">
                        <span>ou</span>
                        <button type="button"  class="sc-b1836745-3 kkeGrI" onClick={()=>{
                        this.UplRef.current.click()
                        }}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search ">
                              <circle cx="11" cy="11" r="8"></circle>
                              <path d="m21 21-4.3-4.3"></path>
                           </svg>
                           Procurar imagens
                        </button>
                     </div>
                     <input accept="image/*,.jpeg,.jpg,.png" multiple="true" onChange={(e)=>{
                      

this._Upload(e)

                     }} ref={this.UplRef} className='file' type="file"  tabindex="-1"/>
                  </div>
               </div>
               <hr/>
               <div class="sc-2ecf8ab5-7 cWaejX"><span><b>Imagens:</b> 0/15</span><span><b>Resolução recomendada:</b> 1280x960</span><span><b>Formatos aceitos:</b> JPG, JPEG ou PNG</span><span><b>Tamanho máximo:</b> 3MB p/imagem</span></div>
            </div>
          
          </> : <>
      







          <DndProvider backend={HTML5Backend}>
        <div className='area-upl'>
          {this.state.items.map((item, index) => (
            <this.DraggableItem key={item.id} camp={this.state.data.id} id={item.id} img={item.img} index={index} moveCard={this.moveCard} />
          ))}
        <button type="button" class="sc-6a0e7dd4-10 bYwRks" onClick={()=>{
          this.UplRef.current.click()
        }}>
   <div class="sc-6a0e7dd4-11 emGIUQ">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus ">
         <path d="M5 12h14"></path>
         <path d="M12 5v14"></path>
      </svg>
   </div>
   <span>Adicionar mais imagens</span>
</button>

        </div>
      
      </DndProvider>
     
      <input accept="image/*,.jpeg,.jpg,.png" multiple="true" onChange={(e)=>{
                      

                      this._Upload(e)
                      
                                           }} ref={this.UplRef} className='file' type="file"  tabindex="-1"/>
            </>}
           

         </div>
      </div>
   </div>
</main>


<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default ImgR;