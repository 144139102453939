import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import copy from 'clipboard-copy';
import { Dropdown, Drop, Item } from '../../Comps/Dropdown';
import Modal from '../../Comps/Modal';
class SlcRifa extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    data:[],
    plan:'',
    pdo:'',
    pg:"a",
    opc:false,
    n_luck:[],
    max_n:0
    }
    this.b1Ref = React.createRef();
    this.b2Ref = React.createRef();
    this.vRifa = React.createRef();
    this.info = React.createRef();
    this.tPremio = React.createRef();
  }

_Share = () =>{
Modal.create({titulo:"Compartilhar",html:<>
<div class="sc-6e1a1b9d-0 khpXNA">
   <div class="sc-2d577abe-0 jYSPyC"><span>Link</span></div>
   <div class="sc-7dbf86ee-0 gDhQnR">
      <input type="text" readonly="" class="sc-7dbf86ee-1 dzWCme" value={`eurifei.com/${this.state.data.slug}`}/>
      <div class="sc-7dbf86ee-2 hPtNIB"></div>
      <button type="button" aria-label="Copiar" class="sc-7dbf86ee-3 kpoUs" onClick={()=>{
          copy(`eurifei.com/${this.state.data.slug}`)
          .then(() => {
            toast.success("O texto foi copiado com sucesso!", {
               position: "top-center",
               autoClose: 3000, // Fecha automaticamente após 3 segundos
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
           
          })
          .catch(err => {
          
          });
      }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
         </svg>
         <span>Copiar</span>
      </button>
   </div>
</div>
<hr/>
<div className="sc-6e1a1b9d-1 fHvsly">
      <a href={`https://facebook.com/sharer/sharer.php?u=eurifei.com/${this.state.data.slug}`} target="_blank" rel="noopener noreferrer" className="sc-6e1a1b9d-2 ftnTBN">
        <div className="sc-6e1a1b9d-3 foauzJ">
          <div style={{ backgroundColor: 'rgb(24, 119, 242)', color: 'rgb(255, 255, 255)' }} className="sc-6e1a1b9d-4 CFbqk">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
          </div>
          <span className="sc-6e1a1b9d-5 bwHxMw">Compartilhar no Facebook</span>
        </div>
        <div className="sc-6e1a1b9d-6 JKSpw">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <a href={`https://wa.me/?text=${this.state.data.title}&eurifei.com/${this.state.data.slug}`} target="_blank" rel="noopener noreferrer" className="sc-6e1a1b9d-2 ftnTBN">
        <div className="sc-6e1a1b9d-3 foauzJ">
          <div style={{ backgroundColor: 'rgb(30, 195, 92)', color: 'rgb(255, 255, 255)' }} className="sc-6e1a1b9d-4 CFbqk">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
            </svg>
          </div>
          <span className="sc-6e1a1b9d-5 bwHxMw">Compartilhar no WhatsApp</span>
        </div>
        <div className="sc-6e1a1b9d-6 JKSpw">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <a href={`https://t.me/share/url?url=eurifei.com/${this.state.data.slug}&amp;text=${this.state.data.title}`} target="_blank" rel="noopener noreferrer" className="sc-6e1a1b9d-2 ftnTBN">
        <div className="sc-6e1a1b9d-3 foauzJ">
          <div style={{ backgroundColor: 'rgb(55, 174, 226)', color: 'rgb(255, 255, 255)' }} className="sc-6e1a1b9d-4 CFbqk">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
              <path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path>
            </svg>
          </div>
          <span className="sc-6e1a1b9d-5 bwHxMw">Compartilhar no Telegram</span>
        </div>
        <div className="sc-6e1a1b9d-6 JKSpw">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </a>
      <button type="button" className="sc-6e1a1b9d-2 ftnTBN">
        <div className="sc-6e1a1b9d-3 foauzJ">
          <div className="sc-6e1a1b9d-4 CFbqk">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" y1="2" x2="12" y2="15"></line>
            </svg>
          </div>
          <span className="sc-6e1a1b9d-5 bwHxMw">Mais opções</span>
        </div>
        <div className="sc-6e1a1b9d-6 JKSpw">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </button>
    </div>
</>})
  }

_ShowDt(){

   if(this.state.LoadDt == undefined){
      fetch(`${apiUrl}/raffles/${this.state.data.id}/analytics/sales-by-payment-method`, {
         method: 'GET',
         credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
         headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
         }
       })
       .then(response => {
         
         response.json().then(dataDt=>{
            this.setState({LoadDt:false,dataDt})
         })
        
       })
   }

  



   return(<>
   

{this.state.LoadDt == undefined ? <>
   <span class="react-loading-skeleton" style={{
  width: '116px',
  height: '16px',
  '--base-color': '#C0C4CF',
  '--highlight-color': '#EBEDF4',
}}>‌</span>
   <span class="react-loading-skeleton" style={{
  top:'10px',
  width: '100%',
  height: '60px',
  '--base-color': '#C0C4CF',
  '--highlight-color': '#EBEDF4',
}}>‌</span>
</> : <>


<div class="sc-5bb5aa0a-1 cFyaly">
   <div class="sc-5bb5aa0a-2 heOrIZ">
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#78b6a8" viewBox="0 0 24 24">
            <path d="M18.69 18.34C18.46 18.34 18.23 18.32 18.01 18.28C17.78 18.23 17.56 18.17 17.35 18.08C17.13 17.99 16.93 17.88 16.74 17.75C16.55 17.63 16.37 17.48 16.2 17.32L12.61 13.72C12.54 13.66 12.47 13.61 12.39 13.58C12.31 13.55 12.22 13.53 12.14 13.53C12.05 13.53 11.96 13.55 11.88 13.58C11.8 13.61 11.73 13.66 11.66 13.72L8.05 17.33C7.89 17.49 7.71 17.64 7.52 17.77C7.33 17.89 7.13 18 6.91 18.09C6.7 18.18 6.48 18.25 6.25 18.29C6.03 18.34 5.8 18.36 5.56 18.36L4.86 18.36L9.42 22.91C9.76 23.25 10.16 23.52 10.6 23.7C11.04 23.88 11.51 23.98 11.99 23.98C12.47 23.98 12.94 23.88 13.39 23.7C13.83 23.52 14.23 23.25 14.57 22.91L19.13 18.35L18.69 18.34Z"></path>
            <path d="M5.57 5.63C5.8 5.63 6.03 5.65 6.25 5.69C6.48 5.74 6.7 5.8 6.91 5.89C7.13 5.98 7.33 6.09 7.52 6.22C7.71 6.35 7.89 6.49 8.06 6.66L11.66 10.27C11.73 10.33 11.8 10.38 11.88 10.41C11.96 10.44 12.05 10.46 12.14 10.46C12.22 10.46 12.31 10.44 12.39 10.41C12.47 10.38 12.55 10.33 12.61 10.27L16.2 6.67C16.37 6.51 16.54 6.36 16.74 6.23C16.93 6.1 17.13 5.99 17.35 5.91C17.56 5.82 17.78 5.75 18.01 5.71C18.23 5.66 18.46 5.64 18.69 5.64L19.13 5.64L14.56 1.07C14.22 0.74 13.82 0.47 13.38 0.28C12.94 0.1 12.46 0.01 11.99 0.01C11.51 0.01 11.03 0.1 10.59 0.28C10.15 0.47 9.75 0.74 9.41 1.07L4.86 5.63L5.57 5.63Z"></path>
            <path d="M22.9 9.42L20.14 6.66C20.13 6.66 20.11 6.67 20.1 6.68C20.08 6.68 20.06 6.68 20.05 6.69C20.03 6.69 20.01 6.69 20 6.7C19.98 6.7 19.96 6.7 19.95 6.7L18.69 6.7C18.53 6.7 18.37 6.71 18.21 6.75C18.06 6.78 17.9 6.82 17.75 6.89C17.6 6.95 17.46 7.02 17.33 7.11C17.19 7.2 17.07 7.31 16.95 7.42L13.36 11.01C13.2 11.17 13.01 11.3 12.8 11.39C12.59 11.47 12.36 11.52 12.14 11.52C11.91 11.52 11.69 11.47 11.48 11.39C11.27 11.3 11.08 11.17 10.92 11.01L7.31 7.41C7.19 7.29 7.07 7.19 6.93 7.1C6.8 7.01 6.66 6.93 6.51 6.87C6.36 6.81 6.2 6.76 6.05 6.73C5.89 6.7 5.73 6.68 5.57 6.68L4.03 6.68C4.01 6.68 3.99 6.68 3.98 6.68C3.96 6.68 3.95 6.68 3.93 6.67C3.92 6.67 3.9 6.67 3.88 6.66C3.87 6.66 3.85 6.65 3.84 6.65L1.06 9.42C0.73 9.76 0.46 10.16 0.28 10.6C0.09 11.04 0 11.51 0 11.99C0 12.47 0.09 12.94 0.28 13.39C0.46 13.83 0.73 14.23 1.06 14.57L3.83 17.34C3.85 17.33 3.86 17.33 3.88 17.32C3.89 17.32 3.91 17.31 3.92 17.31C3.94 17.31 3.96 17.31 3.97 17.3C3.99 17.3 4 17.3 4.02 17.3L5.57 17.3C5.73 17.3 5.89 17.28 6.05 17.25C6.2 17.22 6.36 17.17 6.51 17.11C6.66 17.05 6.8 16.97 6.93 16.88C7.07 16.8 7.19 16.69 7.31 16.58L10.92 12.97C11.57 12.32 12.7 12.32 13.36 12.97L16.95 16.57C17.07 16.68 17.19 16.78 17.33 16.87C17.46 16.96 17.6 17.04 17.75 17.1C17.9 17.16 18.06 17.21 18.21 17.24C18.37 17.27 18.53 17.29 18.69 17.29L19.95 17.29C19.96 17.29 19.98 17.29 20 17.29C20.01 17.29 20.03 17.29 20.05 17.3C20.06 17.3 20.08 17.3 20.1 17.31C20.11 17.31 20.13 17.32 20.14 17.33L22.9 14.57C23.24 14.23 23.51 13.83 23.69 13.39C23.88 12.94 23.97 12.47 23.97 11.99C23.97 11.51 23.88 11.04 23.69 10.6C23.51 10.16 23.24 9.76 22.9 9.42"></path>
         </svg>
      </div>
      <h4 class="sc-5bb5aa0a-3 dEPGxy">Pix (Mercado Pago)</h4>
   </div>
   <div class="sc-5bb5aa0a-4 cfwJHe">
      <h4 class="sc-5bb5aa0a-5 iMsfis">Pedidos: <b>{this.state.dataDt[0].orders_count}</b></h4>
      <span class="point" aria-hidden="true"> · </span>
      <h4 class="sc-5bb5aa0a-5 iMsfis">Bilhetes vendidos: <b>{this.state.dataDt[0].numbers_count}</b></h4>
      <span class="point" aria-hidden="true"> · </span>
      <h4 class="sc-5bb5aa0a-5 iMsfis">Valor bruto arrecadado: <b>{this.state.dataDt[0].total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></h4>
   </div>
</div>




</>}



   </>)
}

_Detais = (data) =>{
   
   return(<>
   <div style={{marginTop:'45px'}} class="sc-26994133-3 bbWzbp">
   <div class="sc-26994133-4 bEqoKR">
      <h1 class="sc-26994133-5 csFrDE">Detalhes de vendas</h1>
   </div>
   <button type="button" aria-label="Vendas por forma de pagamento" className={this.state.click1 ? "sc-b9946f39-0 jGLsDr" : "sc-b9946f39-0 kzGWdA"} onClick={()=>{
      this.setState({click1:!this.state.click1})
   }}>
  
      <div>
         <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
               <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
               <line x1="1" y1="10" x2="23" y2="10"></line>
            </svg>
         </div>
         <span>Vendas por forma de pagamento</span>
      </div>
      <div class="chevron">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
         </svg>
      </div>
   </button>
   {this.state.click1 && <>
      <div class="sc-b9946f39-1 jiHENQ">
     {data != "pending" ? <>
   {this._ShowDt()}
     </> : <>
     <div class="sc-5bb5aa0a-6 gSEASE">
         
      <h2>:(</h2><span>Você ainda não possui nenhuma venda!</span>
      
      </div>
     </>}
      

      </div>
   </>}
  
</div>
   </>)
}

_LoadTp = (query) =>{
  
   fetch(`${apiUrl}/raffles/${this.state.data.id}/analytics/top-buyers${query}`, {
      method: 'GET',
      credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
      headers: {
        'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      }
    })
    .then(response => {
      
      response.json().then(data=>{
         this.setState({LoadTb:true,dataTp:data,query})
      })
     
    })
}


_ToBuyes = (id) =>{

   if(this.state.LoadTb == undefined){
     this._LoadTp('?startDate=all&endDate=today&allowTime=false')
   }

   if(this.state.LoadTb == undefined){
return<>
   <span class="react-loading-skeleton" style={{
  width: '100%',
  height: '60px',
  '--base-color': '#C0C4CF',
  '--highlight-color': '#EBEDF4',
}}>‌</span>
  <span class="react-loading-skeleton" style={{
   marginTop:'10px',
  width: '100%',
  height: '60px',
  '--base-color': '#C0C4CF',
  '--highlight-color': '#EBEDF4',
}}>‌</span>

<span class="react-loading-skeleton" style={{
   marginTop:'10px',
  width: '100%',
  height: '60px',
  '--base-color': '#C0C4CF',
  '--highlight-color': '#EBEDF4',
}}>‌</span>
</>
   }


return (<>

{this.state.dataTp.length > 0 ? <>
   {this.state.dataTp.map((item,index)=><>
<div class="sc-58116526-1 fuASIX">
            <div class="sc-58116526-6 fEihHu">
               <div>
                  <div class="sc-58116526-7 iVwBLN position-1">
                     <h2>{index+1}º</h2>
                  </div>
               </div>
               <div class="sc-58116526-8 jyXfvY">
                  <h4 class="sc-58116526-9 bYCMZz">{item.buyer_name}</h4>
                  <div class="sc-58116526-2 cyuDyL">
                     <h4 class="sc-58116526-3 jAVtFe">{item.buyer_email}</h4>
                     <span class="point" aria-hidden="true"> · </span>
                     <h4 class="sc-58116526-3 jAVtFe">{item.buyer_phone}</h4>
                  </div>
                  <div class="sc-58116526-2 cyuDyL">
                     <h4 class="sc-58116526-4 gBxekq">Pedidos: <b>{item.total_orders}</b></h4>
                     <span class="point" aria-hidden="true"> · </span>
                     <h4 class="sc-58116526-4 gBxekq">Bilhetes: <b>{item.total_numbers}</b></h4>
                     <span class="point" aria-hidden="true"> · </span>
                     <h4 class="sc-58116526-4 gBxekq">Total: <b>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></h4>
                  </div>
               </div>
            </div>
</div>
</>)}
</> : <>
<div class="sc-58116526-11 jYHNoH"><span class="sc-58116526-12 kCjyNR">:(</span><p>Você ainda não possui nenhum pedido pago!</p></div>
</>} 


</>)


}

_Render = (pg) =>{
  switch(pg){
    case "a":

    return(<>
   

{this.state.data.status == "pending" == true ? <>
<div class="sc-681b51f-9 fHCjfl">
   <div class="sc-26994133-4 bEqoKR">
      <h1 class="sc-26994133-5 csFrDE">Ative sua campanha</h1>
   </div>
   <p>Clique no botão abaixo para ativar a sua campanha e começar a faturar com ela!</p>
   <Link
      aria-label="Ativar campanha"
      style={{ width: 'fit-content', marginTop: '25px' }}
      to={`/checkout/active/${this.state.data.slug}`}
      className="sc-b1836745-0 bRwHpU"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
      Ativar campanha
    </Link>
</div>
<hr/>
</> : <div class="IBYtd" style={{width:'100%',height:'20px'}}>

</div>}
<div style={{ paddingTop: '32px' }} className="sc-26994133-3 bbWzbp">
      <div className="sc-26994133-4 bEqoKR">
        <h1 className="sc-26994133-5 csFrDE">Detalhes
    </h1>
    
    
    <div class="bts">
    {this.state.data.status == "paid" ? <>
    <a className='cpt' target="_blank" href={`https://eurifei.com/${this.state.data.slug}`}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
    </a>
    </> : <>
    <button class="inc" disabled="true">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
     </button>
    </>} 
   

    <button disabled={this.state.data.status == "paid" ? false : true} onClick={()=>{
      this._Share()
    }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
    </button>

<Dropdown>
<Drop>
<button>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
</button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 ethkPc">
<div class="sc-cf9fb95-1 iVXJmG2"></div>
<Link to={"/raffles/"+this.state.data.slug+"/edit"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
  </svg>
  <span>Editar</span>
</Link>
<Link to={"/raffles/"+this.state.data.slug+"/images"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
     <circle cx="8.5" cy="8.5" r="1.5"></circle>
     <polyline points="21 15 16 10 5 21"></polyline>
  </svg>
  <span>Imagens</span>
</Link>
<Link to={"/raffles/"+this.state.data.slug+"/payments"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
     <line x1="1" y1="10" x2="23" y2="10"></line>
  </svg>
  <span>Formas de pagamento</span>
</Link>
<Link to={"/raffles/"+this.state.data.slug+"/awards"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <circle cx="12" cy="8" r="7"></circle>
     <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
  </svg>
  <span>Prêmio(s)</span>
</Link>


<Link to={"/raffles/"+this.state.data.slug+"/finish"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
     <line x1="4" y1="22" x2="4" y2="15"></line>
  </svg>
  <span>Sortear</span>
</Link>

<button type="button" data-danger="true" aria-label="Remover">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <polyline points="3 6 5 6 21 6"></polyline>
     <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
  </svg>
  <span>Remover</span>
</button>
</div>

</Item>
</Dropdown>
 


  </div>
    
    
     

      </div>
      <div style={{ marginTop: '0px' }} className="sc-681b51f-12 kKxxON">
        <div className="sc-681b51f-0 fjzQkG">
          <div className="sc-681b51f-13 dFvRBx">
            <Link to={window.location.pathname+'/images'}  rel="noopener noreferrer" className="sc-681b51f-14 dZzpRz">
            {this.state.data.images.length > 0 ? <>
              <img src={`${apiUrl}/static/${this.state.data.images[0].name}`} alt="Imagem principal da campanha" width="200" height="125" style={{ objectFit: 'cover',borderRadius:'10px' }} />
            
            </> : <>
            <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=200&amp;h=125&amp;fit=crop&amp;auto=format,compress" alt="Imagem principal da campanha" width="200" height="125" style={{ objectFit: 'cover' }} />
            
            </>}
            
            </Link>
            <div className="sc-681b51f-15 dRLDJR">
              <div className="sc-681b51f-16 kanbMU">
                <small>Título:</small>
                <p className="title">{this.state.data.title}</p>
              </div>
              <div className="sc-681b51f-16 kanbMU">
                <small>Status:</small>
                <div className="sc-681b51f-17 kAXjZU">
                 
                 {this.state.data.status == "finish" ? <>
                 <div className="sc-681b51f-4 chgUIY">
                    <div>
                      <div className="sc-681b51f-5 fFBZPT"></div>
                    </div>
                    <span>Sorteada</span>
                  </div>
                  </> : <>
                 {this.state.data.status == "paid" ? <>
                 <div className="sc-681b51f-4 chgUIY" style={{cursor:'pointer'}} onClick={()=>{
                  this.setState({cpause:true})
                 }}>
                    <div>
                      <div className="sc-681b51f-5 fFBZPT"></div>
                    </div>
                    <span>Ativo</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down "><path d="m6 9 6 6 6-6"></path></svg>
                  </div>
                 </> : 
                 <>
                  <div className="sc-681b51f-4 chgUIY">
                    <div>
                      <div className="sc-681b51f-5 kBmpmy"></div>
                    </div>
                    <span>Aguardando pagamento</span>
                  </div>
                 </>}
                 </>}
                 
                 
                
                 

                </div>
              </div>
              <div className="sc-681b51f-16 kanbMU">
                <small>Plano:</small>
                <div className="sc-681b51f-17 kAXjZU">
                  <div className="sc-681b51f-4 chgUIY"><span>{this.state.plan}</span></div>
                
                
                {this.state.data.status == "pending" && <>
              
                {this.state.plan != "premium" && <>
                 <button style={{ backgroundColor: 'rgb(255, 255, 255)', borderRadius: '6px', fontWeight: '700' }} aria-label="Atualizar" className="sc-b1836745-3 ebftNg" onClick={()=>{
                if(this.state.data.plan == "economic"){
                  var el = this.state;
                  this.setState({tplan:undefined})
             
                  Modal.create({titulo:"Atualizar plano",html:<>
                  <span>Atualize seu plano para ter acesso a mais benefícios!</span>
                  <div class="sc-de53da76-6 blVcPk">
   <button  ref={this.b1Ref} className={this.state.tplan == "pdo" ? "sc-de53da76-7 dmBcxm selected" : "sc-de53da76-7 dmBcxm"} onClick={()=>{
this.setState({tplan:"default"})
this.b1Ref.current.classList.add("selected")
this.b2Ref.current.classList.remove("selected")
this.info.current.innerHTML = `
<div style="margin-top: 24px;" class="sc-2d577abe-0 jYSPyC"><span>O que você irá receber</span></div>
<ul class="sc-de53da76-9 lbyWPV list"><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign "><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div></div><p>Valor ilimitado por bilhete</p></li><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket "><path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path><path d="M13 5v2"></path><path d="M13 17v2"></path><path d="M13 11v2"></path></svg></div></div><p>Até 100.000 bilhetes</p></li></ul>
`
this.vRifa.current.innerHTML = 'R$ 199,90'   
    }}>
      <div>
         <div class="sc-de53da76-8 fmFdrm option"></div>
      </div>
      <h4>Padrão </h4>
   </button>

   <button class="sc-de53da76-7 dmBcxm" ref={this.b2Ref} onClick={()=>{
    this.setState({tplan:"premium"})
    this.b2Ref.current.classList.add("selected")
    this.b1Ref.current.classList.remove("selected")
    this.vRifa.current.innerHTML = 'R$ 697,00'
    this.info.current.innerHTML = `
    <div style="margin-top: 24px;" class="sc-2d577abe-0 jYSPyC"><span>O que você irá receber</span></div>
    <ul class="sc-de53da76-9 lbyWPV list"><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign "><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div></div><p>Valor ilimitado por bilhete</p></li><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket "><path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path><path d="M13 5v2"></path><path d="M13 17v2"></path><path d="M13 11v2"></path></svg></div></div><p>Até 999.999 bilhetes</p></li></ul>
    `
  
  }}>
      <div>
         <div class="sc-de53da76-8 fmFdrm option"></div>
      </div>
      <h4>Premium</h4>
   </button>
</div>

<div class="info-pmt" ref={this.info}></div>


                  </>,btn:<>
                  <div class="sc-955b9eec-6 iHWLZo">
                  <div class="sc-de53da76-0 iVWScT"><h3 ref={this.vRifa}></h3></div>
                  <button type="button" disabled="" class="sc-b1836745-0 lnOQKx" onClick={(e)=>{
                  if(this.state.tplan == undefined){
                    toast.error("Selecione um plano!", {
                      position: "top-center",
                      autoClose: 3000, // Fecha automaticamente após 3 segundos
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return false;
                  }

var btn = e.target;
  
               btn.classList.add("load")


 
               fetch(`${apiUrl}/raffles/${this.state.data.id}/update-plan`, {
                credentials: 'include',
               method:"PUT",
               headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
               
                body: JSON.stringify({
                plan:this.state.tplan
                })
              })
              .then(response =>{
                
                btn.classList.remove("load")

if(response.status == 200){


response.json().then(result=>{
  switch(result.plan){
              
    case 'default':
     var plan = "Padrão"
    break;

    case 'economic':
      var plan = "Econômico"
    break;

    case 'premium':
      var  plan = "premium"
    break;

  }
  this.setState({plan})


  this.setState(prevState => ({
    data: {
      ...prevState.data,
      plan:result.plan
    }
  }));



})



  



  Modal.close();
}



              })





                  }}>Atualizar plano  </button>
                  </div>
                  </>})
                }else if(this.state.data.plan == "default"){
                  Modal.create({titulo:"Atualizar plano",html:<>
                  <span>Atualize seu plano para ter acesso a mais benefícios!</span>
                  <div class="sc-de53da76-6 blVcPk">

   <button class="sc-de53da76-7 dmBcxm" ref={this.b2Ref} onClick={()=>{
    this.setState({tplan:"premium"})
    this.b2Ref.current.classList.add("selected")
   
    this.vRifa.current.innerHTML = 'R$ 697,00'
    this.info.current.innerHTML = `
    <div style="margin-top: 24px;" class="sc-2d577abe-0 jYSPyC"><span>O que você irá receber</span></div>
    <ul class="sc-de53da76-9 lbyWPV list"><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign "><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div></div><p>Valor ilimitado por bilhete</p></li><li><div><div class="sc-de53da76-10 jjifvk list-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket "><path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path><path d="M13 5v2"></path><path d="M13 17v2"></path><path d="M13 11v2"></path></svg></div></div><p>Até 999.999 bilhetes</p></li></ul>
    `
  
  }}>
      <div>
         <div class="sc-de53da76-8 fmFdrm option"></div>
      </div>
      <h4>Premium</h4>
   </button>
</div>

<div class="info-pmt" ref={this.info}></div>


                  </>,btn:<>
                  <div class="sc-955b9eec-6 iHWLZo">
                  <div class="sc-de53da76-0 iVWScT"><h3 ref={this.vRifa}></h3></div>
                  <button type="button" disabled="" class="sc-b1836745-0 lnOQKx" onClick={(e)=>{
                  if(this.state.tplan == undefined){
                    toast.error("Selecione um plano!", {
                      position: "top-center",
                      autoClose: 3000, // Fecha automaticamente após 3 segundos
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return false;
                  }

var btn = e.target;
  
               btn.classList.add("load")


 
               fetch(`${apiUrl}/raffles/${this.state.data.id}/update-plan`, {
                credentials: 'include',
               method:"PUT",
               headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
               
                body: JSON.stringify({
                plan:this.state.tplan
                })
              })
              .then(response =>{
                
                btn.classList.remove("load")

if(response.status == 200){


response.json().then(result=>{
  switch(result.plan){
              
    case 'default':
     var plan = "Padrão"
    break;

    case 'economic':
      var plan = "Econômico"
    break;

    case 'premium':
      var  plan = "premium"
    break;

  }
  this.setState({plan})


  this.setState(prevState => ({
    data: {
      ...prevState.data,
      plan:result.plan
    }
  }));



})



  



  Modal.close();
}



              })





                  }}>Atualizar plano  </button>
                  </div>
                  </>})
                }


                 }}>
                    Atualizar 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </button>
                 </>}
              
                </>}
                

                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="sc-681b51f-0 fjzQkG">

          <div className="sc-681b51f-18 knCEUx">
            <div className="sc-681b51f-16 kanbMU">
              <small>Bilhetes:</small>
              <p className="title">{this.state.data.numbers}</p>
            </div>
            <div className="sc-681b51f-16 kanbMU">
              <small>Bilhetes disponíveis:</small>
              <p className="title">{parseInt(this.state.data.numbers.replace(".", "")) - this.state.data.details.show.apv}</p>
            </div>
            <div className="sc-681b51f-16 kanbMU">
              <small>Bilhetes pagos:</small>
              <p className="title">{this.state.data.details.show.apv}</p>
            </div>
            <div className="sc-681b51f-16 kanbMU">
              <small>Bilhetes reservados:</small>
              <p className="title">{this.state.data.details.show.pcc}</p>
            </div>

          </div>
          <div className="sc-681b51f-16 kanbMU">
            <small>Progresso:</small>
            <div>
              <div className="sc-681b51f-19 hotkjq">
              <div class="sc-681b51f-20 JwFJD approved" style={{width:(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100)+'%'}}></div>
              </div>
              <div className="sc-681b51f-21 dCWZQc secondary" style={{ left: `calc(${(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100)}% - 16px)` }}>{(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100).toFixed(2)}%</div>
            </div>
          </div>
        
        
         
        </div>
        
      </div>
      
    </div>
    {this._Detais(this.state.data.status)}
    </>)
    case "b":
      return(<>

   <div style={{padding:'40px',marginBottom:'-60px'}} >
      <div class="sc-26994133-4 bEqoKR">
         <h1 class="sc-26994133-5 csFrDE">Maiores compradores</h1>
      </div>
   </div>
   
   <div style={{padding:'10px'}}>
      <button type="button"  className={this.state.click2 ? "sc-b9946f39-0 jGLsDr" : "sc-b9946f39-0 kzGWdA"} onClick={()=>{

     this.setState({click2:!this.state.click2})
   }}>
  
         <div>
            
            <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                  <line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line>
                  <line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line>
                  <line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line>
                  <line x1="17" y1="16" x2="23" y2="16"></line>
               </svg>
            </div>
            <span>Opções</span>
         </div>
         <div class="chevron">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
         </div>
      </button>
      {this.state.click2 && <>
      <div class="sc-b9946f39-1 jiHENQ">
   <div class="sc-58116526-14 igCBpO">
      <p class="text">Data dos dados</p>
      <div class="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
        <Dropdown>
         <Drop>
         <button type="button" class="sc-cf9fb95-0 fzuDCk">
            <div class="sc-5b5576f7-0 cBxLNV">
               <span>Todo o periodo</span>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="6 9 12 15 18 9"></polyline>
               </svg>
            </div>
         </button>
         </Drop>
         <Item>
      <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML">
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button class="" onClick={()=>{this._LoadTp('?startDate=today&endDate=today&allowTime=false')}}><span>Hoje</span></button>
   <button class="" onClick={()=>{this._LoadTp('?startDate=yesterday&endDate=yesterday&allowTime=false')}}><span>Ontem</span></button>
   <button class="" onClick={()=>{this._LoadTp('?startDate=7days&endDate=today&allowTime=false')}}><span>Últimos 7 dias</span></button>
   <button class="" onClick={()=>{this._LoadTp('?startDate=28days&endDate=today&allowTime=false')}}><span>Últimos 28 dias</span></button>


</div>

         </Item>
        </Dropdown>
         
      </div>
   </div>

   <div class="sc-58116526-14 igCBpO">
      <p class="text">Ordenar por</p>
      <div class="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
         <Dropdown>
            <Drop>
            <button type="button" class="sc-cf9fb95-0 fzuDCk">
            <div class="sc-5b5576f7-0 cBxLNV">
               <span>Maior valor gasto</span>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="6 9 12 15 18 9"></polyline>
               </svg>
            </div>
         </button>
            </Drop>
            <Item>
            <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML">
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" class="" aria-label="Mais bilhetes comprados" onClick={()=>{
      this._LoadTp(`${this.state.query}&orderBy=numbers`)
   }}>
   <span>Mais bilhetes comprados</span>
   </button>
   <button type="button" class="selected" disabled="" aria-label="Maior valor gasto" onClick={()=>{
        this._LoadTp(`${this.state.query}&orderBy=price`)
   }}>
      <span>Maior valor gasto</span></button>
</div>

            </Item>
         </Dropdown>
         
      </div>
   </div>
</div>

   </>}
   </div>
  <hr/>
   <div class="sc-26994133-3 bbWzbp">
      <div class="sc-58116526-0 OfdMt">
        

{this._ToBuyes(this.state.data.id)}


        
         
      </div>
   </div>


      </>)
    case 'd':
      return(<>
   <div class="sc-26994133-2 hCoRZw">
   <div class="sc-26994133-3 bbWzbp">
      <div  class="sc-26994133-4 bEqoKR">
         <h1 class="sc-26994133-5 csFrDE">Bilhetes premiados</h1>
      </div>
   </div>
   <div  class="sc-26994133-3 bbWzbp">
      <button type="button" className={this.state.opc ? 'sc-b9946f39-0 jGLsDr' : 'sc-b9946f39-0 kzGWdA'}  onClick={()=>{
        this.setState({opc:!this.state.opc})
      }}>
         <div>
            <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                  <line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line>
                  <line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line>
                  <line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line>
                  <line x1="17" y1="16" x2="23" y2="16"></line>
               </svg>
            </div>
            <span>Opções</span>
         </div>
         <div class="chevron">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
         </div>
      </button>
      {this.state.opc && <>
        <div class="sc-b9946f39-1 jiHENQ">
         <div class="sc-7c2ce114-0 kstbCp">
            <div  class="sc-2d577abe-0 jYSPyC"><span>Mostrar os bilhetes premiados na página da campanha</span></div>
            <div class="sc-7c2ce114-1 cqbzGO"><label class="sc-eb9b4b7f-1 jeCmla checked"></label></div>
         </div>
         <div class="sc-7c2ce114-0 kstbCp">
            <div  class="sc-2d577abe-0 jYSPyC"><span>Mostrar o nome dos compradores</span></div>
            <div class="sc-7c2ce114-1 cqbzGO"><label class="sc-eb9b4b7f-1 jeCmla checked"></label></div>
         </div>
      </div>
      </>}
     

   </div>
   <hr/>
   <div style={{paddingTop:32}} class="sc-26994133-3 bbWzbp">
      <div class="sc-7c2ce114-2 ibdvvW">
         <div class="sc-7c2ce114-3 bSINPb">
            <div class="sc-7c2ce114-4 cvpJSS">
               <input name="search" type="search" autocomplete="off" placeholder="Pesquisar..." class="sc-7c2ce114-5 jsdujc" value=""/>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
               </svg>
            </div>
            <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
               <button type="button" class="sc-cf9fb95-0 iIgArl">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path>
                  </svg>
               </button>
            </div>
         </div>
         <div class="sc-7c2ce114-12 embLKa">
            <button type="button" aria-label="Adicionar bilhete" class="sc-b1836745-0 efnqmZ" onClick={()=>{
             this.setState({addB:true})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
               </svg>
               Adicionar bilhete
            </button>
         </div>
      </div>
   </div>
   <div class="sc-7c2ce114-9 luuDI">
  
{this.state.n_luck.length > 0 ? <>
  <div class="sc-56b9959f-0 jImkcc">
         <div class="sc-56b9959f-1 dhBAhx">
  <table class="sc-56b9959f-2 kncOte">
               <thead class="sc-56b9959f-3 bSehiw">
                  <tr>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf">
                           <label>
                              <input type="checkbox" class="sc-7d17252a-2 dsIyxA"/>
                              <div class="sc-7d17252a-1 iJmEIM">
                                 <div class="sc-7d17252a-0 iLDTdu">
                                    <svg width="12px" height="10px">
                                       <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                 </div>
                              </div>
                           </label>
                        </div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Bilhete</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Prêmio</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Status</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Data de compra</span></div>
                     </th>
                     <th colspan="1" class="fixed last">
                        <div class="sc-56b9959f-5 cWVjpf"><span></span></div>
                     </th>
                  </tr>
               </thead>
               {this.state.n_luck.map((item,index)=><>
            
                <tbody class="sc-56b9959f-4 kKLelq">
                  <tr>
                     <td data-label="">
                        <label>
                           <input type="checkbox" class="sc-7d17252a-2 dsIyxA"/>
                           <div class="sc-7d17252a-1 iJmEIM">
                              <div class="sc-7d17252a-0 iLDTdu">
                                 <svg width="12px" height="10px">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                 </svg>
                              </div>
                           </div>
                        </label>
                     </td>

                     <td data-label="Bilhete"><span class="sc-b7475dd8-0 hFmiRF">{item.number}</span></td>
                     <td data-label="Prêmio">{item.award}</td>
                     <td data-label="Status">
                        <span class="sc-b7475dd8-0 hQPpTS status" aria-label="Disponível">
                           <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <circle cx="12" cy="12" r="10"></circle>
                           </svg>
                           Disponível
                        </span>
                     </td>
                     <td data-label="Data de compra">—</td>
                     <th class="fixed last" data-label="">
                        <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                      
                           

<Dropdown>
  <Drop>
  <button type="button" class="sc-cf9fb95-0 cKVYkL">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <circle cx="12" cy="12" r="1"></circle>
                                 <circle cx="12" cy="5" r="1"></circle>
                                 <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                           </button>
  </Drop>
  <Item>
  <div aria-hidden="false" role="menu" class="sc-e82feeba-0 sOJTp">
   <div class="sc-cf9fb95-1 gigqVx"></div>


   <button type="button" aria-label="Editar prêmio" onClick={()=>{
     Modal.create({titulo:"Editar prêmio",html:<>
     
     <fieldset class="sc-56eec735-1 bSrfIZ">
        <div class="sc-2d577abe-0 jYSPyC"><span>Prêmio</span></div>
        <div class="sc-56eec735-2 bBSNUk">
       <input name="award" id="award" maxlength="120" ref={this.tPremio} class="sc-56eec735-0 jDba-dm2"/></div>
     </fieldset>
     
     
        
         </>,btn:<>
         <div class="sc-955b9eec-6 iHWLZo2">
         <button type="submit" class="sc-cf9fb95-0 iIgArl" onClick={(e)=>{
     
     
     if(this.tPremio.current.value.trim().length == 0){
       toast.error('Informe o titulo do prêmio!', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
       return false;
     }
     
     e.target.classList.add("load")
     
     fetch(`${apiUrl}/raffles/${this.state.data.id}/lucky-numbers/${item.id}`, {
       method: 'PUT',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer '+Cookies.get('auth_token')
       },
       body: JSON.stringify({
         award:this.tPremio.current.value,
    
       })
     }).then(response => {
       e.target.classList.add("remove")
     if(response.status == 200){
     
     
       const updatedAwards = this.state.n_luck.map(award =>
         award.id === item.id ? { ...award, award: this.tPremio.current.value } : award
       );
     

       this.setState({ n_luck: updatedAwards});
      
       toast.success("Prêmio editado com sucesso!", {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
       Modal.close()
     }
     })
     
     
     
         }}>Salvar alterações</button>
         </div>
         </>})
         this.tPremio.current.value = item.award;
  }}>
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
   </svg>
   <span>Editar prêmio</span>
</button>




   <button type="button" data-danger="true" aria-label="Remover" onClick={()=>{
    Modal.create({titulo:"Remover bilhete premiado",html:<>
    <p>Você tem certeza que deseja excluir este bilhete premiado? Esta ação não pode ser desfeita.</p>
    </>,btn:<>
    <div class="sc-955b9eec-6 iHWLZo2">
    <button type="button" aria-label="Remover" class="sc-b1836745-0 bJxjoS" onClick={(e)=>{
     e.target.classList.add("load")

     fetch(`${apiUrl}/raffles/${this.state.data.id}/lucky-numbers/${item.id}`, {
      method: 'DELETE',
      credentials: 'include', 
      headers: {
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+Cookies.get('auth_token')
      }
    })
   .then(response => {
    e.target.classList.remove("load")
    if(response.status == 200){


     var  novaLista = this.state.n_luck.filter(e => e.id !== item.id);
     this.setState({n_luck:novaLista});

    toast.success("prêmio removido com sucesso!", {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
      Modal.close()
    }
   })




    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>Remover</button></div>
    </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>

  </Item>
</Dropdown>


                        </div>
                     </th>
                  </tr>
               </tbody>
               </>)}
            

            </table>
            </div>
            </div>
</> : <>
<div class="sc-26994133-3 bbWzbp"><div class="sc-58116526-0 OfdMt"><div class="sc-58116526-11 jYHNoH"><span class="sc-58116526-12 kCjyNR">:(</span><p>Você ainda não adicionou nenhum número!</p></div></div></div>
</>}
        

         </div>
   
   <div class="sc-7c2ce114-13 hErkGe"></div>
</div>

      </>)
  }
}


  componentDidMount(){
    document.title  = "Carregando..."
    fetch(''+apiUrl+'/raffles/'+window.location.pathname.split("/raffles/")[1]+'?details=true', {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
         this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `${result.title} | EuRifei`;
            
            switch(result.plan){
              
              case 'default':
               var plan = "Padrão"
           this.setState({max_n:999})
              break;

              case 'economic':
                var plan = "Econômico"
                this.setState({max_n:100000})
              break;

              case 'premium':
                var  plan = "premium"
                this.setState({max_n:999999})
              break;

            }
            this.setState({data:result,Loader:false,plan})




            })
            .catch(error => {
           
          });

  }
  render() {

    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }

    return (
 <>
 
 <main class="sc-26994133-0 cbBLwb">
 <div class="sc-26994133-1 ebNXoB">
 <div class="sc-84a07fe4-0 menu-cp">
   <a type="button" onClick={()=>{
    this.setState({
      pg:"a"
    })
   }} className={this.state.pg == "a" ? "sc-84a07fe4-1 xZztE-cp active" : "sc-84a07fe4-1 xZztE-cp"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="m12 14 4-4"></path>
         <path d="M3.34 19a10 10 0 1 1 17.32 0"></path>
      </svg>
      <span>Detalhes</span>
   </a>
   <a type="button" onClick={()=>{
    this.setState({
      pg:"b"
    })
   }} className={this.state.pg == "b" ? "sc-84a07fe4-1 xZztE-cp active" : "sc-84a07fe4-1 xZztE-cp"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M3 3v18h18"></path>
         <path d="m19 9-5 5-4-4-3 3"></path>
      </svg>
      <span>Maiores compradores</span>
   </a>
   <a type="button" onClick={()=>{
    this.setState({
      pg:"c"
    })
   }} className={this.state.pg == "c" ? "sc-84a07fe4-1 xZztE-cp active" : "sc-84a07fe4-1 xZztE-cp"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
         <circle cx="9" cy="7" r="4"></circle>
         <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
         <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
      <span>Vendas dos afiliados</span>
   </a>
   <a type="button" onClick={()=>{
    
    
    fetch(`${apiUrl}/raffles/${this.state.data.id}/analytics/lucky-numbers`, {
      method: 'GET',
      credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
      headers: {
        'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      }
    })
    .then(response => {
    if(response.status == 200){
     response.json().then(n_luck=>{
      this.setState({
        pg:"d",
        n_luck
      })
     })
    }
    })
    
    
    

   }} className={this.state.pg == "d" ? "sc-84a07fe4-1 xZztE-cp active" : "sc-84a07fe4-1 xZztE-cp"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
      </svg>
      <span>Bilhetes premiados</span>
   </a>
</div>
<div class="sc-26994133-2 hCoRZw">
{this._Render(this.state.pg)}

</div>






</div>
 </main>


{this.state.cpause && <>
  <div id="modal-root">
  <div class="sc-912e3955-1 guWPuq" onClick={()=>{
    this.setState({cpause:false})
  }}></div>
   
         <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar status</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA" onClick={()=>{
    this.setState({cpause:false})
  }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-262cfb40-0 sUMyx">
                     <label>
                        <input name="status" type="radio" id="status" class="sc-262cfb40-8 iDmPEq" value="active" onClick={()=>{
                           this.setState(prevState => ({
                            data: {
                              ...prevState.data,
                              paused:0
                            }
                          }));
                        }} checked={this.state.data.paused == 0 ? true : false}/>
                        <div class="sc-262cfb40-1 kPSVld">
                           <div class="sc-262cfb40-2 jhenhz">
                              <div class="sc-262cfb40-4 blqqI icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                 </svg>
                              </div>
                              <h3 class="sc-262cfb40-3 OJeJK">Ativo</h3>
                              <div class="sc-262cfb40-6 gPuqcB">
                                 <div class="sc-262cfb40-7 gLgCDf option"></div>
                              </div>
                           </div>
                           <div class="sc-262cfb40-5 cwUvaj"><span>Todos podem comprar bilhetes</span></div>
                        </div>
                     </label>
                     <label>
                        <input name="status" type="radio" id="status" class="sc-262cfb40-8 iDmPEq" onClick={()=>{
                           this.setState(prevState => ({
                            data: {
                              ...prevState.data,
                              paused:1
                            }
                          }));
                        }} checked={this.state.data.paused == 1 ? true : false} value="paused"/>
                        <div class="sc-262cfb40-1 kPSVld">
                           <div class="sc-262cfb40-2 jhenhz">
                              <div class="sc-262cfb40-4 blqqI icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <rect x="6" y="4" width="4" height="16"></rect>
                                    <rect x="14" y="4" width="4" height="16"></rect>
                                 </svg>
                              </div>
                              <h3 class="sc-262cfb40-3 OJeJK">Pausado</h3>
                              <div class="sc-262cfb40-6 gPuqcB">
                                 <div class="sc-262cfb40-7 gLgCDf option"></div>
                              </div>
                           </div>
                           <div class="sc-262cfb40-5 cwUvaj"><span>Ninguém poderá comprar bilhetes</span></div>
                        </div>
                     </label>
                  </fieldset>
               </div>
            </div>
         </div>
    
</div>

</>}


<div id="modal-root"></div>

{this.state.addB && <>

  <div id="modal-root">
  
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({addB:false})
      }}></div>
  
   
         <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Adicionar bilhete premiado</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA"  onClick={()=>{

        this.setState({addB:false})
      }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Bilhete</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
                    <input maxlength="120" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                      var v = e.target.value;
                      if(`${v}`.length < (`${this.state.max_n}`.length + 1)){
                        this.setState({vbht:v.replace(/\D/g, "")})
                      }
                       
                      
                    }} value={this.state.vbht} /></div>
                  </fieldset>
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Prêmio</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
                    <input maxlength="120" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                 this.setState({award:e.target.value})
                    }} /></div>
                  </fieldset>
                  <div class="sc-ba975a8b-22 WQtHM">
                     <button type="submit" class="sc-b1836745-0 gKYPUX" aria-label="Adicionar" onClick={(e)=>{
            
                   
if(this.state.vbht == undefined){
  toast.error("Informe o número do bilhete!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
return false;
}

if(`${this.state.vbht}`.length < `${this.state.max_n}`.length){
  toast.error(`Insira o bilhete com ${`${this.state.max_n}`.length} dígitos!`, {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
return false;
}
if(this.state.award == undefined){
  toast.error("Informe titulo do prêmio!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
return false;
}
if(this.state.award.trim().length == 0){
  toast.error("Informe titulo do prêmio!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
return false;
}
e.target.classList.add('load')
fetch(`${apiUrl}/raffles/${this.state.data.id}/lucky-numbers`, {
  credentials: 'include',
 method:"POST",
 headers: {
'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
 },
 
  body: JSON.stringify({
  award:this.state.award,
  number:new Number(this.state.vbht)
  })
})
.then(response =>{
e.target.classList.remove('load')


if(response.status == 422){
  response.json().then(data=>{
        
    toast.error(data.errors[0].message, {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  })
}

if(response.status == 400){
  response.json().then(data=>{
   
    toast.error(data[0].errors[0].message, {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  })
}


if(response.status == 200){
  response.json().then(data=>{
    toast.success('Bilhete premiado adicionado!', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const novoItem = { 
      id:data.id,
      award:data.award,
      number:data.number
  }; 
  var novaLista = [...this.state.n_luck, novoItem];

  this.setState({ n_luck: novaLista,addB:false }); 


  })

  



 
}




})                   
          






                     }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <line x1="12" y1="5" x2="12" y2="19"></line>
                           <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Adicionar
                     </button>
                  </div>
               </div>
            </div>
         </div>
  
</div>


</>}



<ToastContainer/>
 </>
    );
  }
}

export default SlcRifa;