import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { Drop, Dropdown, Item } from '../../../Comps/Dropdown';
class ROrderEx extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    psq:false,
    stt:false,
    afl:false,
    drsv:false
    }
  }
  componentDidMount(){
document.title = "Pedidos expirados - EuRifei"   
  }

  
  





   render() {
   
    return (
 <>
 
 
 <div class="sc-cf11fef-0 EpdDr">
   <div class="sc-cf11fef-1 fLXWdH">
      <div class="sc-cf11fef-2 kPEJir">
         <div class="sc-f27074da-7 caYqoL expanded">
            <button type="button" class="sc-f27074da-5 gkzmwh expand" aria-label="Expandir filtros" aria-expanded="true" >
               <div class="sc-f27074da-6 dtfhmr">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <line x1="20" y1="8" x2="4" y2="8"></line>
                     <line x1="17" y1="12" x2="7" y2="12"></line>
                     <line x1="14" y1="16" x2="10" y2="16"></line>
                  </svg>
                  <h3>Filtrar</h3>
               </div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
               </svg>
            </button>
           
              <div class="sc-f27074da-0 bweBTO expanded">
               <button type="button" class="sc-f27074da-5 gkzmwh action" onClick={()=>{
              this.setState({psq:!this.state.psq})
            }}>
                  <div class="sc-f27074da-6 dtfhmr">
                     <h3>Pesquisar</h3>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle " id="filter-help" >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  {this.state.psq == false ? <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up ">
                     <path d="m18 15-6-6-6 6"></path>
                  </svg>
                  </> : <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down "><path d="m6 9 6 6 6-6"></path></svg>
                  </> }
                  

               </button>
               {this.state.psq == false && <>
               <div class="sc-f27074da-8 kCMVDu active">
                  <div class="sc-3c5cdb17-0 fNLSPv">
                     <div class="sc-3c5cdb17-1 hkAfjn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user ">
                           <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                           <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                     </div>
                     <input name="name" id="name" placeholder="Nome" role="searchbox" class="sc-3c5cdb17-2 gcMXaV" value=""/>
                  </div>
                  <div class="sc-3c5cdb17-0 fNLSPv">
                     <div class="sc-3c5cdb17-1 hkAfjn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail ">
                           <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                           <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                        </svg>
                     </div>
                     <input name="email" id="email" placeholder="E-mail" role="searchbox" class="sc-3c5cdb17-2 gcMXaV" value=""/>
                  </div>
                  <div class="sc-3c5cdb17-0 fNLSPv">
                     <div class="sc-3c5cdb17-1 hkAfjn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ticket ">
                           <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                           <path d="M13 5v2"></path>
                           <path d="M13 17v2"></path>
                           <path d="M13 11v2"></path>
                        </svg>
                     </div>
                     <input name="number" id="number" placeholder="Bilhete" role="searchbox" class="sc-3c5cdb17-2 gcMXaV" value=""/>
                  </div>
                  <div class="sc-20bee3b1-0 fwtfhZ">
                  <div class="sc-3c5cdb17-0 fNLSPv">
                     <div class="sc-3c5cdb17-1 hkAfjn lgt">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"  stroke-linejoin="round" class="lucide lucide-mail ">
<path d="M5.13641 12.764L8.15456 9.08664C8.46255 8.69065 8.61655 8.49264 8.69726 8.27058C8.76867 8.07409 8.79821 7.86484 8.784 7.65625C8.76793 7.42053 8.67477 7.18763 8.48846 6.72184L7.77776 4.9451C7.50204 4.25579 7.36417 3.91113 7.12635 3.68522C6.91678 3.48615 6.65417 3.35188 6.37009 3.29854C6.0477 3.238 5.68758 3.32804 4.96733 3.5081L3 4C3 14 9.99969 21 20 21L20.4916 19.0324C20.6717 18.3121 20.7617 17.952 20.7012 17.6296C20.6478 17.3456 20.5136 17.0829 20.3145 16.8734C20.0886 16.6355 19.7439 16.4977 19.0546 16.222L17.4691 15.5877C16.9377 15.3752 16.672 15.2689 16.4071 15.2608C16.1729 15.2536 15.9404 15.3013 15.728 15.4001C15.4877 15.512 15.2854 15.7143 14.8807 16.119L11.8274 19.1733M12.9997 7C13.9765 7.19057 14.8741 7.66826 15.5778 8.37194C16.2815 9.07561 16.7592 9.97326 16.9497 10.95M12.9997 3C15.029 3.22544 16.9213 4.13417 18.366 5.57701C19.8106 7.01984 20.7217 8.91101 20.9497 10.94" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                     </div>
                     <input name="email" id="email" placeholder="Telefone" role="searchbox" class="sc-3c5cdb17-2 gcMXaV" value=""/>
                  </div>
                  </div>
               </div> </>
               }
               <button type="button" class="sc-f27074da-5 gkzmwh action" onClick={()=>{
                this.setState({stt:!this.state.stt})
               }}>
                  <div class="sc-f27074da-6 dtfhmr">
                     <h3>Status</h3>
                  </div>
                  {this.state.stt == false ? <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up ">
                     <path d="m18 15-6-6-6 6"></path>
                  </svg>
                  </> : <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down "><path d="m6 9 6 6 6-6"></path></svg>
                  </> }
               </button>
               {this.state.stt == false && <>
                <div class="sc-f27074da-8 kCMVDu active">
                  <div class="sc-ccfd6b52-3 ePeyJK">
                     <label>
                        <input type="checkbox" class="sc-7d17252a-2 dsIyxA"/>
                        <div class="sc-7d17252a-1 iJmEIM">
                           <div>
                              <div size="20" class="sc-7d17252a-0 iLDTdu">
                                
                              </div>
                           </div>
                           <span>Pago</span>
                        </div>
                     </label>
                  </div>
                  <div class="sc-ccfd6b52-3 ePeyJK">
                     <label>
                        <input type="checkbox" class="sc-7d17252a-2 dsIyxA"/>
                        <div class="sc-7d17252a-1 iJmEIM">
                           <div>
                              <div size="20" class="sc-7d17252a-0 iLDTdu">
                                 
                              </div>
                           </div>
                           <span>Reservado</span>
                        </div>
                     </label>
                  </div>
               </div>
               </>}
              

               <button type="button" class="sc-f27074da-5 gkzmwh action" onClick={()=>{
                this.setState({afl:!this.state.afl})
               }}>
                  <div class="sc-f27074da-6 dtfhmr">
                     <h3>Afiliados</h3>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle " id="affiliate-filter-help" >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  {this.state.afl == true ? <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up ">
                     <path d="m18 15-6-6-6 6"></path>
                  </svg>
                  </> : <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down "><path d="m6 9 6 6 6-6"></path></svg>
                  </> }
               </button>
               {this.state.afl && <>
                <div class="sc-f27074da-8 kCMVDu active">
                  <div class="sc-f27074da-11 bWJaTF">
                     <h2>:(</h2>
                     <span>Você não possui nenhum afiliado</span>
                  </div>
               </div>
               </>}
               <button type="button" class="sc-f27074da-5 gkzmwh action" onClick={()=>{
                this.setState({drsv:!this.state.drsv})
               }}>
                  <h3>Data de reserva</h3>
                  {this.state.drsv == false ? <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up ">
                     <path d="m18 15-6-6-6 6"></path>
                  </svg>
                  </> : <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down "><path d="m6 9 6 6 6-6"></path></svg>
                  </> }
               </button>
              {this.state.drsv && <>
                <div class="sc-f27074da-8 kCMVDu active">
                  <div class="sc-f27074da-9 kWPHKv">
                     <div class="sc-f27074da-10 lkwyqn">
                        <span class="label">A partir de</span>
                        <fieldset class="sc-e55ae362-2 ByHbC">
                           <div class="sc-e55ae362-3 hkabXH">
                              <div class="react-datepicker-wrapper">
                                 <div class="react-datepicker__input-container">
                                 <DatePicker 
   className='sc-e55ae362-1 ieCoXO'
   onChange={(e)=>{
   this.setState({dataSlc:e})
   }}
   selected={this.state.dataSlc}
      
     
      dateFormat="dd/MM/yyyy"
      timeCaption="Hora"
      locale="BR"
      />
            
                                 </div>
                              </div>
                              <div class="sc-e55ae362-0 jXZIum">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                 </svg>
                              </div>
                           </div>
                        </fieldset>
                     </div>
                     <div class="sc-f27074da-10 lkwyqn">
                        <span class="label">Até</span>
                        <fieldset class="sc-e55ae362-2 ByHbC">
                           <div class="sc-e55ae362-3 hkabXH">
                              <div class="react-datepicker-wrapper">
                              <DatePicker 
   className='sc-e55ae362-1 ieCoXO'
   onChange={(e)=>{
   this.setState({dataSlc:e})
   }}
   selected={this.state.dataSlc}
      
     
      dateFormat="dd/MM/yyyy"
      timeCaption="Hora"
      locale="BR"
      />
                              </div>
                              <div class="sc-e55ae362-0 jXZIum">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                 </svg>
                              </div>
                           </div>
                        </fieldset>
                     </div>
                  </div>
               </div>
              </>}
            </div>
          
          

         </div>
     
         <div class="sc-89a4ca15-0 dQVvDe">
            <div class="sc-89a4ca15-2 yvBrN">
               <div class="sc-8711f07d-0 eLIXJf">
            <Link class="sc-8711f07d-1 fVFdpm" to={window.location.pathname.replace("expired-orders","orders")}>Pedidos</Link>
               <Link class="sc-8711f07d-1 fVFdpm active">Expirados</Link></div>
               <div class="sc-89a4ca15-1 bHhgjA">
                  <div class="sc-2ef26041-0 cHmuv">
                     <button type="button" class="sc-2ef26041-1 gTTcij">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <polyline points="23 4 23 10 17 10"></polyline>
                           <polyline points="1 20 1 14 7 14"></polyline>
                           <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                        </svg>
                     </button>
                     <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                       

<Dropdown>
  <Drop>

  </Drop>
  <Item>
  <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML" >
   <div class="sc-cf9fb95-1 grOBkt"></div>
   <button type="button" aria-label="Exportar para .CSV">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
         <polyline points="7 10 12 15 17 10"></polyline>
         <line x1="12" y1="15" x2="12" y2="3"></line>
      </svg>
      <span>Exportar para .CSV</span>
   </button>
   <button type="button" aria-label="Buscar por ID">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="4" y1="21" x2="4" y2="14"></line>
         <line x1="4" y1="10" x2="4" y2="3"></line>
         <line x1="12" y1="21" x2="12" y2="12"></line>
         <line x1="12" y1="8" x2="12" y2="3"></line>
         <line x1="20" y1="21" x2="20" y2="16"></line>
         <line x1="20" y1="12" x2="20" y2="3"></line>
         <line x1="1" y1="14" x2="7" y2="14"></line>
         <line x1="9" y1="8" x2="15" y2="8"></line>
         <line x1="17" y1="16" x2="23" y2="16"></line>
      </svg>
      <span>Buscar por ID</span>
   </button>
   <hr/>
   <button type="button" aria-label="Como funciona">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <circle cx="12" cy="12" r="10"></circle>
         <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
         <line x1="12" y1="17" x2="12.01" y2="17"></line>
      </svg>
      <span>Como funciona</span>
   </button>
</div>

  </Item>
</Dropdown>



                     </div>
                  </div>
                  <div class="sc-8c87747f-0 uqleY">
                     <div class="sc-cf9fb95-2 emNxsV dropdown-wrapper">
                      
                     
                     </div>
                  </div>
                  <div class="sc-8c87747f-18 jONogI">
                     <h2>:(</h2>
                     <p>Parece que ainda não há nada aqui</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>




 </>
    );
  }
}

export default ROrderEx;