import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class DstR extends React.Component {
  componentDidMount(){
document.title = "Página não encontrada! - EuRifei"   
  }
   render() {
   
    return (
 <>
 
 aaaaa

 </>
    );
  }
}

export default DstR;