import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./ord.css"
import Cookies from 'js-cookie';
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Header from '../../Comps/Header';
import { apiUrl } from '../../config';
import {AreaMods, Modal} from "../../Comps/Mods"
class Orders extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
MenOpc:false,
Loader:true,
Data:[],
filtered:[],
tp:"a"
        }
    this.dropdownRef = React.createRef();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    LerCookie(nome) {
        // Separe todos os cookies em uma matriz
        var cookies = document.cookie.split(';');
        
        // Percorra a matriz de cookies
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          
          // Remova espaços em branco no início e no final do cookie
          while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
          }
          
          // Se o cookie começar com o nome desejado, retorne o valor do cookie
          if (cookie.indexOf(nome + '=') == 0) {
            return cookie.substring(nome.length + 1, cookie.length);
          }
        }
        
        // Se o cookie não for encontrado, retorne null
        return null;
      }

    componentDidMount() {
      document.title = "Selecionar pedidos | EuRifei"
        document.addEventListener('mousedown', this.handleClickOutside);
     
    
        fetch(`${apiUrl}/raffles`, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+this.LerCookie("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {
            if (response.status == 401) {
          
          document.cookie = "auth_token" + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          window.location = window.location.origin;
          return;
            }
          
 
            return response.json();
         
          }).then(result => {
            this.setState({Data:result,Loader:false})
            })
            .catch(error => {
          console.log(error)   
          });



    }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    
      handleClickOutside(event) {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
          this.setState({ MenOpc: false });
        }
      }
    
      toggleDropdown() {
        this.setState(prevState => ({
          MenOpc: !prevState.isOpen
        }));
      }



  render() {

    if(this.state.Loader){
      return(<>
     <div class="sc-fee5f128-1 eQsWAM"></div>
     <main className="sc-a165da0e-0 jKohuz">
      <div className="sc-a165da0e-1 hTmKcS">
        <div className="sc-a165da0e-2 juUKmh">
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ width: '102px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
          </span>
          <div className="sc-a165da0e-3 yVVzT">
            <div className="sc-a165da0e-4 kwtAAT">
              <div style={{ width: '100%' }}>
                <span aria-live="polite" aria-busy="true">
                  <span className="react-loading-skeleton" style={{ width: '100%', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                </span>
              </div>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '100px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '48px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
            </div>
            <div className="sc-a165da0e-9 jizcMN">
              <div style={{ width: '100%', minWidth: '184px' }}>
                <span aria-live="polite" aria-busy="true">
                  <span className="react-loading-skeleton" style={{ width: '100%', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="sc-a165da0e-10 cVeWIT">
         
          
          <div className="sc-712c86e4-0 fSIXCZ">
            <div className="sc-a165da0e-15 fJmUQa">
              <header>
                <span aria-live="polite" aria-busy="true">
                  <span className="react-loading-skeleton" style={{ width: '48px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                </span>
                <div className="sc-a165da0e-17 jrjAYu">
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '100px', height: '12px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                </div>
              </header>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '125px', height: '16px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
            </div>
          </div>
          <div className="sc-712c86e4-0 fSIXCZ">
            <div className="sc-a165da0e-15 fJmUQa">
              <header>
                <span aria-live="polite" aria-busy="true">
                  <span className="react-loading-skeleton" style={{ width: '48px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                </span>
                <div className="sc-a165da0e-17 jrjAYu">
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '100px', height: '12px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                </div>
              </header>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '125px', height: '16px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
            </div>
          </div>


          <div className="sc-712c86e4-0 fSIXCZ">
            <div className="sc-a165da0e-15 fJmUQa">
              <header>
                <span aria-live="polite" aria-busy="true">
                  <span className="react-loading-skeleton" style={{ width: '48px', height: '48px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                </span>
                <div className="sc-a165da0e-17 jrjAYu">
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '100px', height: '12px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                  <span aria-live="polite" aria-busy="true">
                    <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
                  </span>
                </div>
              </header>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '60px', height: '8px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
              <span aria-live="polite" aria-busy="true">
                <span className="react-loading-skeleton" style={{ width: '125px', height: '16px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span><br />
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
      </>)
    }


    return (
 <>
 <div class="sc-fee5f128-1 eQsWAM"></div>

 <main class="sc-a165da0e-0 jKohuz">
   <div class="sc-a165da0e-1 hTmKcS">
      <div class="sc-a165da0e-2 juUKmh">
         <h1>Pedidos</h1>
         <h4>Selecione uma campanha abaixo para ver todos os pedidos</h4>
         <div class="sc-a165da0e-3 yVVzT">
            <div class="sc-a165da0e-4 kwtAAT">
               <div class="sc-a165da0e-5 jzJizg">
                  <input name="search" type="search" autocomplete="off" placeholder="Pesquisar..." class="sc-a165da0e-6 iupdJD" onChange={(e)=>{
                   var val  = e.target.value;
                   this.setState({bsc:val})
                   const filtered = this.state.Data.filter(item =>
                    item.title.toLowerCase().includes(val.toLowerCase())
                  );
                  this.setState({filtered,errB:filtered.length == 0 ? true : false})
                  }} value={this.state.bsc}/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <circle cx="11" cy="11" r="8"></circle>
                     <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
               </div>
               <div class="sc-a165da0e-7 kZGwPq">
                  <button type="button" aria-label="Ver como grade" class="sc-a165da0e-8 kyZbBm" onClick={()=>{
                    this.setState({tp:"a"})
                  }} disabled={this.state.tp == "a" ? true : false}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                     </svg>
                  </button>
                  <button type="button" aria-label="Ver como lista" class="sc-a165da0e-8 kyZbBm" onClick={()=>{
                    this.setState({tp:"b"})
                  }} disabled={this.state.tp == "b" ? true : false}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        <line x1="8" y1="6" x2="21" y2="6"></line>
                        <line x1="8" y1="12" x2="21" y2="12"></line>
                        <line x1="8" y1="18" x2="21" y2="18"></line>
                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                     </svg>
                  </button>
               </div>
               <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                  <button type="button" aria-label="Filtrar" class="sc-cf9fb95-0 hIfFte" onClick={()=>{
                   
                  }}>
                     <div class="sc-a165da0e-23 iPkZWk">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <line x1="20" y1="8" x2="4" y2="8"></line>
                           <line x1="17" y1="12" x2="7" y2="12"></line>
                           <line x1="14" y1="16" x2="10" y2="16"></line>
                        </svg>
                     </div>
                  </button>
               </div>
            </div>
           
         </div>
      </div>
      <div className={this.state.tp == "a" ? "sc-a165da0e-10 cVeWIT" : "sc-a165da0e-10 cVeWIT list"}>

       {this.state.Data.length > 0 ? <>
     
{this.state.errB ? <>
  <div class="sc-a165da0e-11 jCJxZP">
   <div class="sc-a165da0e-12 cMAmTc">
      <svg width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5">
         <path d="M19.5 12.5C19.5 11.12 20.62 10 22 10V9C22 5 21 4 17 4H7C3 4 2 5 2 9V9.5C3.38 9.5 4.5 10.62 4.5 12C4.5 13.38 3.38 14.5 2 14.5V15C2 19 3 20 7 20H17C21 20 22 19 22 15C20.62 15 19.5 13.88 19.5 12.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
         <path d="M10 4L10 20" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5 5"></path>
      </svg>
   </div>
   <p>As suas campanhas irão aparecer aqui</p>
</div>
</> : <>
{this.state.filtered.length > 0 ? <>
  {this.state.filtered.map((item,index)=><>
        <div class="sc-a165da0e-13 bRTjry">
            <Link class="sc-a165da0e-14 bMSYps card" to={`/raffles/${item.slug}/orders`}>
               <div class="sc-a165da0e-15 fJmUQa card-padding">
                  <header>
                     <div class="sc-a165da0e-16 fzxWkv">
                    
                     <div class="sc-a165da0e-16 fzxWkv">
                      {item.imagem.length > 0 ? <>

                        <img src={`${apiUrl}/static/${item.imagem[0].name}`} alt="Imagem principal de meu gato" width="48" height="48" />
                    
                      </> : <>
                      <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=48&amp;h=48&amp;fit=crop&amp;auto=format,compress" alt="Imagem principal de meu gato" width="48" height="48" />
                    
                      </>}
                      
                    </div>
                    
                    </div>
                     
                     <div  class="sc-a165da0e-17 jrjAYu">
                        <h3 class="sc-a165da0e-18 KkFsu">{item.title}</h3>
                        <span>/{item.slug}</span>
                     </div>
                  </header>
                  <ul>
                     <li>
                        <p>Status:</p>
                      {item.status == "finish" ? <>
                      <div class="sc-a165da0e-19 zrxBH">
                        <div class="sc-a165da0e-20 fiWEho">
                        </div>
                        <span>Sorteada</span>
                        </div>
                      </> : <>
                      {item.status == "pending" ? <>
                        <div class="sc-a165da0e-19 zrxBH">
                           <div>
                              <div class="sc-a165da0e-20 hxNBlN"></div>
                           </div>
                           <span>Aguardando pagamento</span>
                        </div>
                        </> : <>
                        <div class="sc-a165da0e-19 zrxBH">
                        <div class="sc-a165da0e-20 fiWEho">
                        </div>
                        <span>Ativo</span>
                        </div>
                        
                        </>}
                      </>}
                     </li>
                  </ul>
               </div>
            </Link>
            <Link class="sc-a165da0e-21 jdPnWo open-raffle-button" role="button" type="submit" aria-hidden="true" to="https://rifei.com.br/demo/meu-gato" target="_blank" rel="noopener noreferrer">
              {item.imagem != null ? <>
              
              </> : <>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
               </svg>
              </>}
               

            </Link>
         </div>
      </>)}
</> : <>

{this.state.Data.map((item,index)=><>
        <div class="sc-a165da0e-13 bRTjry">
            <Link class="sc-a165da0e-14 bMSYps card" to={`/raffles/${item.slug}/orders`}>
               <div class="sc-a165da0e-15 fJmUQa card-padding">
                  <header>
                     <div class="sc-a165da0e-16 fzxWkv">
                      {item.imagem.length > 0 ? <>

                        <img src={`${apiUrl}/static/${item.imagem[0].name}`} alt="Imagem principal de meu gato" width="48" height="48" />
                    
                      </> : <>
                      <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=48&amp;h=48&amp;fit=crop&amp;auto=format,compress" alt="Imagem principal de meu gato" width="48" height="48" />
                    
                      </>}
                      
                    </div>
                     <div  class="sc-a165da0e-17 jrjAYu">
                        <h3 class="sc-a165da0e-18 KkFsu">{item.title}</h3>
                        <span>/{item.slug}</span>
                     </div>
                  </header>
                  <ul>
                     <li>
                        <p>Status:</p>
                    
                        {item.status == "finish" ? <>
                      <div class="sc-a165da0e-19 zrxBH">
                        <div class="sc-a165da0e-20 fiWEho">
                        </div>
                        <span>Sorteada</span>
                        </div>
                      </> : <>
                      {item.status == "pending" ? <>
                        <div class="sc-a165da0e-19 zrxBH">
                           <div>
                              <div class="sc-a165da0e-20 hxNBlN"></div>
                           </div>
                           <span>Aguardando pagamento</span>
                        </div>
                        </> : <>
                        <div class="sc-a165da0e-19 zrxBH">
                        <div class="sc-a165da0e-20 fiWEho">
                        </div>
                        <span>Ativo</span>
                        </div>
                        
                        </>}
                      </>}
                       


                     </li>
                  </ul>
               </div>
            </Link>
            <Link class="sc-a165da0e-21 jdPnWo open-raffle-button" role="button" type="submit" aria-hidden="true" to="https://rifei.com.br/demo/meu-gato" target="_blank" rel="noopener noreferrer">
              {item.imagem != null ? <>
              
              </> : <>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
               </svg>
              </>}
               

            </Link>
         </div>
      </>)}

</>}
</>}

       </> : <>
       <div class="sc-a165da0e-11 jCJxZP">
   <div class="sc-a165da0e-12 cMAmTc">
      <svg width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5">
         <path d="M19.5 12.5C19.5 11.12 20.62 10 22 10V9C22 5 21 4 17 4H7C3 4 2 5 2 9V9.5C3.38 9.5 4.5 10.62 4.5 12C4.5 13.38 3.38 14.5 2 14.5V15C2 19 3 20 7 20H17C21 20 22 19 22 15C20.62 15 19.5 13.88 19.5 12.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
         <path d="M10 4L10 20" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5 5"></path>
      </svg>
   </div>
   <p>As suas campanhas irão aparecer aqui</p>
</div>

       </>} 
     
        
        
        
       
         
      </div>
   </div>
</main>


 </>
    );
  }
}

export default Orders;