import React from 'react';
import "./style.css"
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import mylogo from "../../images/logo.png"
import { Link } from 'react-router-dom';
import { Dropdown, Drop, Item } from '../../Comps/Dropdown'; // Importe os componentes Dropdown
import InputMask from 'react-input-mask'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class CheckActive extends React.Component {
    constructor(props) {
    super(props);
       
    this.state = {
    Loader:true,
    tpDc:'CPF',
    page:"a",
    dcDoc:'',

  
    }
}


CreditCardButton = (ref) =>{
   
   let state = this.state;
   this.onClick = ref(state);
return(<>
 <button type="button" class="sc-2352e99a-0 dYTXSU" onClick={()=>{
          this.setState({pgmt:"credit_card",page:"d"})
         }}>
            <div class="sc-2352e99a-1 ltyVJ">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card ">
                  <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                  <line x1="2" x2="22" y1="10" y2="10"></line>
               </svg>
            </div>
            <div class="sc-2352e99a-2 laYHHA">
               <h3>Cartão de crédito</h3>
               <span>Parcele em até 12x</span>
            </div>
         </button>
</>)
}

validateCnpj = (cnpj) => {
  // Remove caracteres não numéricos
  const cleanedCnpj = cnpj.replace(/\D/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cleanedCnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1{13}$/.test(cleanedCnpj)) {
    return false;
  }

  // Calcula os dígitos verificadores
  let sum = 0;
  let position = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cleanedCnpj.charAt(i)) * position;
    position--;
    if (position < 2) {
      position = 9;
    }
  }
  let remainder = sum % 11;
  if (remainder < 2) {
    remainder = 0;
  } else {
    remainder = 11 - remainder;
  }
  if (remainder !== parseInt(cleanedCnpj.charAt(12))) {
    return false;
  }

  sum = 0;
  position = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cleanedCnpj.charAt(i)) * position;
    position--;
    if (position < 2) {
      position = 9;
    }
  }
  remainder = sum % 11;
  if (remainder < 2) {
    remainder = 0;
  } else {
    remainder = 11 - remainder;
  }
  if (remainder !== parseInt(cleanedCnpj.charAt(13))) {
    return false;
  }

  return true;
};
validateCpf = (cpf) => {
  // Remove caracteres não numéricos
  const cleanedCpf = cpf.replace(/\D/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cleanedCpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1{10}$/.test(cleanedCpf)) {
    return false;
  }

  // Calcula os dígitos verificadores
  let sum = 0;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cleanedCpf[i - 1]) * (11 - i);
  }
  let remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cleanedCpf[9])) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cleanedCpf[i - 1]) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cleanedCpf[10])) {
    return false;
  }

  return true;
};
  componentDidMount(){

document.title = "Ativar campanha | EuRifei"  
var url = window.location.href;
var pastaAtual = url.substring(url.lastIndexOf("/") + 1);
fetch(`${apiUrl}/raffles/checkout/${pastaAtual}`, {
    method: 'GET',
    credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
    headers: {
      'Authorization': 'Bearer '+Cookies.get('auth_token'), // Se necessário, adicione um cabeçalho de autorização
      'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
    }
  })
  .then(response => {
   
    if (response.status == 422) {
  response.json().then(data=>{
    this.props.navigate("/checkout/active/pay/"+data.action)
  })
      
         
    }
       

    if (response.status == 404) {
  
 this.props.navigate("/raffles/")
    }
  

    return response.json();
 
  }).then(result => {
    this.setState({data:result,Loader:false}) 
    })
    .catch(error => {
  console.log(error)   
  });


  }


_RenderP = (page) =>{
switch(page){
  case "a":

return(<>
<div className="sc-57a20e2c-2 fpTtmm">
      <div className="sc-57a20e2c-3 bJrJgu">
    
          <div className="sc-57a20e2c-4 bXvgEY">
            <div className="sc-57a20e2c-5 iYJSwo">
              <div className="sc-57a20e2c-6 fkrWSV">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="8" r="5"></circle>
                  <path d="M20 21a8 8 0 1 0-16 0"></path>
                </svg>
                <h2>Dados pessoais</h2>
              </div>
              <div className="sc-fcd11139-0 dIDckl">
                <fieldset className="sc-56eec735-1 bSrfIZ">
                  <div className="sc-2d577abe-0 jYSPyC">
                    <span>Nome completo</span>
                  </div>
                  <div className="sc-56eec735-2 bBSNUk">
                   
                  <input name="name" placeholder="Digite seu nome e sobrenome" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                var nome  = e.target.value;
         
                const newData = { ...this.state.data, user: { ...this.state.data.user, name: e.target.value } };
                this.setState({ data: newData });

                if (!/^[a-zA-Z\s]+$/.test(nome)) {
                  this.setState({checkName:"Insira seu nome completo utilizando somente letras e espaços."})
                return false;
               }

               const parts = nome.split(' ');
               if((parts.length >= 2 && parts.every(part => part.trim() !== '')) == false){
                  this.setState({checkName:"Insira seu nome completo utilizando somente letras e espaços."})
               }else{
             
                  this.setState({checkName:""})
               }
               }} onBlur={(e)=>{
              if(this.state.checkName == undefined){
              this.setState({checkName:"Este é um campo obrigatório!"})
              }
               }}  value={this.state.data.user.name}/>
                   
                   
                  
                  </div>
                  
                </fieldset>
                <fieldset className="sc-56eec735-1 bSrfIZ">
                  <div className="sc-2d577abe-0 jYSPyC">
                    <span>Endereço de e-mail</span>
                  </div>
                  <div className="sc-56eec735-2 bBSNUk">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      disabled
                      className="sc-56eec735-0 jDba-dm2"
                      value={this.state.data.user.email}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="sc-fcd11139-0 dIDckl">
            
              <fieldset className="sc-1af9f2aa-1 iieSta">
                  <div className="sc-2d577abe-0 jYSPyC">
                    <span>Telefone</span>
                  </div>
                  <div className="sc-1af9f2aa-3 pwYdD">
                    <div className="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
                    
                    
                     

                    </div>
                    <InputMask mask={this.state.mask} class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                 var phone = e.target.value;
                 if (/^\d{2} 9\d/.test(phone)) {
                    this.setState({mask:'99 99999-9999'})
                  } else {
                    this.setState({mask:'99 9999-9999'})
                  }
                
                
                  this.setState({phone})

              }} value={this.state.phone}  placeholder="Telefone" />
                  </div>
                </fieldset>



                <fieldset className="sc-1af9f2aa-1 iieSta">
                  <div className="sc-2d577abe-0 jYSPyC">
                    <span>CPF/CNPJ</span>
                  </div>
                  <div className="sc-1af9f2aa-3 pwYdD">
                    <div className="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
                    
                     <Dropdown>
                        <Drop>
                        <button type="button" className="sc-cf9fb95-0 cheeA">
                        <span>CPF</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </button>
                        </Drop>
                        <Item>
                      <div class="slc-cc">
<button CloseMe="true"  onClick={()=>{
         this.setState({tpDc:'CPF',dcDoc:"",erroDc:"",checkDc:undefined})
      }}>CPF</button>
<button CloseMe="true" onClick={()=>{
         this.setState({tpDc:'CNPJ',dcDoc:"",erroDc:"",checkDc:undefined})
      }}>CNPJ</button>
                      </div>
                        </Item>
                     </Dropdown>
                     

                    </div>
                  
                    <InputMask class="sc-1af9f2aa-0 dgcJog"  mask={this.state.tpDc == "CPF" ? "999.999.999-99" : " 99.999.999/9999-99"} onChange={(e)=>{
  this.setState({dcDoc:e.target.value})
  
}} value={this.state.dcDoc} autoComplete='off'/>
                  </div>
                </fieldset>
              </div>
             
            </div>
          </div>

          
          <div className="sc-57a20e2c-8 BCAOd">
            <div className="sc-57a20e2c-10 leBUNg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-shield-check "
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              <span>Ambiente seguro</span>
            </div>
            <button type="submit" className="sc-b1836745-0 dYOSJl" aria-label="Continuar" onClick={()=>{
            const parts = this.state.data.user.name.split(' ');
            if (!/^[a-zA-Z\s]+$/.test(this.state.data.user.name)) {
              toast.error('Insira seu nome completo utilizando somente letras e espaços.', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return false;
           }
            if((parts.length >= 2 && parts.every(part => part.trim() !== '')) == false){
              toast.error('Insira seu nome completo utilizando somente letras e espaços.', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return false;
            }
 const regex = /^(\d{2} )?(\d{4,5}-\d{4})$/;
          
if(regex.test(this.state.phone) == false){
  toast.error('Número de telefone inválido!.', {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return false;
}


if(this.state.tpDc == "CPF"){
if(this.validateCpf(this.state.dcDoc) == false || this.state.dcDoc == undefined){
  toast.error('Documento inválido!.', {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return false;
}
}else{
  if(this.validateCnpj(this.state.dcDoc) == false || this.state.dcDoc == undefined){
    toast.error('Documento inválido!.', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
}

this.setState({page:"b"})

            }}>
              Continuar
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-right "
              >
                <path d="M5 12h14"></path>
                <path d="m12 5 7 7-7 7"></path>
              </svg>
            </button>
          </div>
   
      </div>
</div>
</>)

  break;

case "b":

return(<>

<div class="sc-57a20e2c-2 fpTtmm">
   <div class="sc-57a20e2c-3 bJrJgu">
     
         <div class="sc-57a20e2c-4 bXvgEY">
            <div class="sc-57a20e2c-5 iYJSwo-a">
               <div class="sc-57a20e2c-6 fkrWSV-a">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map ">
                     <polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon>
                     <line x1="9" x2="9" y1="3" y2="18"></line>
                     <line x1="15" x2="15" y1="6" y2="21"></line>
                  </svg>
               </div>
               <h2>Endereço de cobrança</h2>
            </div>
            <div class="sc-c92c4540-0 ciTcDV">
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>CEP</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                 
                    <InputMask mask="99.999-999" onChange={(e)=>{
                 function siglaParaNome(sigla) {
                  const estados = {
                      'AC': 'Acre',
                      'AL': 'Alagoas',
                      'AP': 'Amapá',
                      'AM': 'Amazonas',
                      'BA': 'Bahia',
                      'CE': 'Ceará',
                      'DF': 'Distrito Federal',
                      'ES': 'Espírito Santo',
                      'GO': 'Goiás',
                      'MA': 'Maranhão',
                      'MT': 'Mato Grosso',
                      'MS': 'Mato Grosso do Sul',
                      'MG': 'Minas Gerais',
                      'PA': 'Pará',
                      'PB': 'Paraíba',
                      'PR': 'Paraná',
                      'PE': 'Pernambuco',
                      'PI': 'Piauí',
                      'RJ': 'Rio de Janeiro',
                      'RN': 'Rio Grande do Norte',
                      'RS': 'Rio Grande do Sul',
                      'RO': 'Rondônia',
                      'RR': 'Roraima',
                      'SC': 'Santa Catarina',
                      'SP': 'São Paulo',
                      'SE': 'Sergipe',
                      'TO': 'Tocantins'
                  };
              
                  return estados[sigla] || sigla; // Retorna o nome completo ou a sigla se não encontrada
              }
                this.setState({cep:e.target.value})
                     var regexCEP = /^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/;
                     if(regexCEP.test(e.target.value)){

                      fetch(`https://viacep.com.br/ws/${e.target.value.replace(/\D/g, '')}/json/`, {
                        method: 'GET',
                        headers: {
                        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                        }
                      })
                      .then(response => {
                       if(response.status == 200){
                      response.json().then(data=>{
                        if(data.erro == true){
                          toast.error('Cep inválido!', {
                            position: "top-center",
                            autoClose: 3000, // Fecha automaticamente após 3 segundos
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }else{
                          this.setState({
                            rua:data.logradouro,
                            bairro:data.bairro,
                            cidade:data.localidade,
                            estado:siglaParaNome(data.uf)
                          })
                        }
                      })
                       }
                      })

                     }


                    }} value={this.state.cep} class="sc-56eec735-0 jDba-dm2"/>
                  </div>
               </fieldset>
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Endereço</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="address" id="address" class="sc-56eec735-0 jDba-dm2"
                  onChange={(e)=>{
                    this.setState({rua:e.target.value})
                  }}
                  value={this.state.rua} autoComplete='off'/></div>
               </fieldset>
            </div>
            <div class="sc-c92c4540-0 ciTcDV">
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Número</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="number" id="number" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                    this.setState({NEdrc:e.target.value.replace(/\D/g, '')})
                  }} value={this.state.NEdrc} autoComplete='off'/>
                  </div>
               </fieldset>
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Bairro</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="neighborhood" id="neighborhood" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                    this.setState({
                      bairro:e.target.value
                    })
                  }} value={this.state.bairro}/>
                  </div>
               </fieldset>
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Complemento<small> • Opcional</small></span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="complement" id="complement" class="sc-56eec735-0 jDba-dm2" autoComplete='off' onChange={(e)=>{
                    this.setState({cpmt:e.target.value})
                  }} value={this.state.cpmt}/>
                  </div>
               </fieldset>
            </div>
            <div class="sc-c92c4540-0 ciTcDV">
            <fieldset class="sc-56eec735-1 hDxYJw">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Estado</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="city" id="city" disabled="true" class="sc-56eec735-0 jDba-dm2" autoComplete='off' value={this.state.estado}/></div>
               </fieldset>
               <fieldset class="sc-56eec735-1 hDxYJw">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Cidade</span></div>
                  <div class="sc-56eec735-2 bBSNUk">
                  <input name="city" id="city" disabled="true" class="sc-56eec735-0 jDba-dm2"  autoComplete='off' value={this.state.cidade}/>
                  </div>
               </fieldset>
            </div>
         </div>
         <div class="sc-57a20e2c-8 BCAOd">
            <div class="sc-57a20e2c-10 leBUNg">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shield-check ">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"></path>
                  <path d="m9 12 2 2 4-4"></path>
               </svg>
               <span>Ambiente seguro</span>
            </div>
            <button type="submit" class="sc-b1836745-0 dYOSJl" aria-label="Continuar" onClick={()=>{
           console.log(this.state)
           var regexCEP = /^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/;
            if(regexCEP.test(this.state.cep) == false){
              toast.error('Cep inválido!', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
return false;
            }


            if(this.state.rua.trim().length == 0 || this.state.rua == undefined){
              toast.error('O campo "Rua" é obrigatório!', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
return false;
            }

            if(isNaN(this.state.NEdrc)){
              toast.error('O campo "Número" é obrigatório!', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
return false;
            }

            if(this.state.bairro.trim().length == 0 || this.state.bairro == undefined){
              toast.error('O campo "Bairro" é obrigatório!', {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
return false;
            }
            this.setState({page:"c"})
            }}>
               Continuar
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right ">
                  <path d="M5 12h14"></path>
                  <path d="m12 5 7 7-7 7"></path>
               </svg>
            </button>
         </div>
      
   </div>
</div>


</>)

case "c":

return(<>
<div class="sc-57a20e2c-2 fpTtmm">
   <div class="sc-57a20e2c-3 bJrJgu">
      <div class="sc-57a20e2c-4 bXvgEY">
         <div class="sc-57a20e2c-5 iYJSwo-a">
            <div class="sc-57a20e2c-6 fkrWSV-a">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke="currentColor" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect width="20" height="12" x="2" y="6" rx="2"></rect>
                  <circle cx="12" cy="12" r="2"></circle>
                  <path d="M6 12h.01M18 12h.01"></path>
               </svg>
            </div>
            <h2>Forma de pagamento</h2>
         </div>
         <button type="button" class="sc-2352e99a-0 dYTXSU" onClick={()=>{
          this.setState({pgmt:"pix",page:"d"})
         }}>
            <div class="sc-2352e99a-1 ltyVJ">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="24" height="24" fill="currentColor">
                  <path d="m18.5 7.2c1.5 1.5 1.5 4.1 0 5.6l-5.7 5.7c-1.5 1.5-4.1 1.5-5.6 0l-5.7-5.7c-1.5-1.5-1.5-4.1 0-5.6l5.7-5.7c1.5-1.5 4.1-1.5 5.6 0zm-1.4 1.4l-5.6-5.6c-0.8-0.8-2.1-0.8-2.9 0l-5.6 5.6c-0.8 0.8-0.8 2.1 0 2.9l5.6 5.6c0.8 0.8 2.1 0.8 2.9 0l5.6-5.6c0.8-0.8 0.8-2.1 0-2.9z"></path>
                  <path d="m16.4 7.9c-1-0.9-2.4-1.1-3.5-0.5l-2.9 1.8-2.9-1.8c-1.1-0.6-2.5-0.4-3.5 0.5l-1.4-1.4c1.6-1.6 4-1.9 5.9-0.8l1.9 1.1 1.9-1.1c1.9-1.1 4.3-0.8 5.9 0.8z"></path>
                  <path d="m3.6 12.1c1 0.9 2.4 1.1 3.5 0.5l2.9-1.8 2.9 1.8c1.1 0.6 2.5 0.4 3.5-0.5l1.4 1.4c-1.6 1.6-4 1.9-5.9 0.8l-1.9-1.1-1.9 1.1c-1.9 1.1-4.3 0.8-5.9-0.8z"></path>
               </svg>
            </div>
            <div class="sc-2352e99a-2 laYHHA">
               <h3>Pix</h3>
               <span>Aprovação imediata</span>
            </div>
         </button>
        
      </div>
   </div>
</div>

</>)

case "d":
return(<>
<div class="sc-57a20e2c-2 fpTtmm">
   <div class="sc-57a20e2c-3 bJrJgu">
      <div class="sc-57a20e2c-4 bXvgEY">
         <div class="sc-57a20e2c-5 iYJSwo-a">
            <div class="sc-57a20e2c-6 fkrWSV-a">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text ">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" x2="8" y1="13" y2="13"></line>
                  <line x1="16" x2="8" y1="17" y2="17"></line>
                  <line x1="10" x2="8" y1="9" y2="9"></line>
               </svg>
            </div>
            <h2>Revisar</h2>
         </div>
         <div class="sc-f2eb517a-7 jsMsXc">
            <span class="sc-f2eb517a-8 leczAK">Dados pessoais</span>
            <button type="button" aria-label="Editar" class="sc-f2eb517a-9 loXwEp" onClick={()=>{
              this.setState({page:"a"})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pen ">
                  <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
               </svg>
               <span>Editar</span>
            </button>
         </div>
         <div class="sc-f2eb517a-0 bzekNH">
            <div class="sc-f2eb517a-1 kzvmBI">
               <p>Nome:</p>
               <span>{this.state.data.user.name}</span>
               <p>Telefone:</p>
               <span>{this.state.phone}</span>
               <p>E-mail:</p>
               <span>{this.state.data.user.email}</span>
               <p>CPF/CNPJ:</p>
               <span>{this.state.dcDoc}</span>
             
            </div>
         </div>
         <div class="sc-f2eb517a-7 jsMsXc">
            <span class="sc-f2eb517a-8 leczAK">Endereço de cobrança</span>
            <button type="button" aria-label="Editar" class="sc-f2eb517a-9 loXwEp" onClick={()=>{
              this.setState({page:"b"})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pen ">
                  <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
               </svg>
               <span>Editar</span>
            </button>
         </div>
         <div class="sc-f2eb517a-0 bzekNH">
            <div class="sc-f2eb517a-1 kzvmBI">
               <div class="sc-f2eb517a-6 gqdnGH">
                  <p>{this.state.rua}, 100</p>
                  <p>{this.state.bairro} - {this.state.cidade} - {this.state.estado}</p>
                  <p>CEP: {this.state.cep}</p>
               </div>
            </div>
         </div>
         <div class="sc-f2eb517a-7 jsMsXc">
            <span class="sc-f2eb517a-8 leczAK">Forma de pagamento</span>
            <button type="button" aria-label="Alterar" class="sc-f2eb517a-9 loXwEp" onClick={()=>{
              this.setState({page:"c"})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pen ">
                  <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
               </svg>
               <span>Alterar</span>
            </button>
         </div>
         <div class="sc-f2eb517a-0 bzekNH no-mb">
          {this.state.pgmt == "pix" ? <>
          <div class="sc-f2eb517a-2 enCtsM">
               <div class="sc-f2eb517a-3 cfIeVQ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#32bcad" viewBox="0 0 24 24">
                     <path d="M18.69 18.34C18.46 18.34 18.23 18.32 18.01 18.28C17.78 18.23 17.56 18.17 17.35 18.08C17.13 17.99 16.93 17.88 16.74 17.75C16.55 17.63 16.37 17.48 16.2 17.32L12.61 13.72C12.54 13.66 12.47 13.61 12.39 13.58C12.31 13.55 12.22 13.53 12.14 13.53C12.05 13.53 11.96 13.55 11.88 13.58C11.8 13.61 11.73 13.66 11.66 13.72L8.05 17.33C7.89 17.49 7.71 17.64 7.52 17.77C7.33 17.89 7.13 18 6.91 18.09C6.7 18.18 6.48 18.25 6.25 18.29C6.03 18.34 5.8 18.36 5.56 18.36L4.86 18.36L9.42 22.91C9.76 23.25 10.16 23.52 10.6 23.7C11.04 23.88 11.51 23.98 11.99 23.98C12.47 23.98 12.94 23.88 13.39 23.7C13.83 23.52 14.23 23.25 14.57 22.91L19.13 18.35L18.69 18.34Z"></path>
                     <path d="M5.57 5.63C5.8 5.63 6.03 5.65 6.25 5.69C6.48 5.74 6.7 5.8 6.91 5.89C7.13 5.98 7.33 6.09 7.52 6.22C7.71 6.35 7.89 6.49 8.06 6.66L11.66 10.27C11.73 10.33 11.8 10.38 11.88 10.41C11.96 10.44 12.05 10.46 12.14 10.46C12.22 10.46 12.31 10.44 12.39 10.41C12.47 10.38 12.55 10.33 12.61 10.27L16.2 6.67C16.37 6.51 16.54 6.36 16.74 6.23C16.93 6.1 17.13 5.99 17.35 5.91C17.56 5.82 17.78 5.75 18.01 5.71C18.23 5.66 18.46 5.64 18.69 5.64L19.13 5.64L14.56 1.07C14.22 0.74 13.82 0.47 13.38 0.28C12.94 0.1 12.46 0.01 11.99 0.01C11.51 0.01 11.03 0.1 10.59 0.28C10.15 0.47 9.75 0.74 9.41 1.07L4.86 5.63L5.57 5.63Z"></path>
                     <path d="M22.9 9.42L20.14 6.66C20.13 6.66 20.11 6.67 20.1 6.68C20.08 6.68 20.06 6.68 20.05 6.69C20.03 6.69 20.01 6.69 20 6.7C19.98 6.7 19.96 6.7 19.95 6.7L18.69 6.7C18.53 6.7 18.37 6.71 18.21 6.75C18.06 6.78 17.9 6.82 17.75 6.89C17.6 6.95 17.46 7.02 17.33 7.11C17.19 7.2 17.07 7.31 16.95 7.42L13.36 11.01C13.2 11.17 13.01 11.3 12.8 11.39C12.59 11.47 12.36 11.52 12.14 11.52C11.91 11.52 11.69 11.47 11.48 11.39C11.27 11.3 11.08 11.17 10.92 11.01L7.31 7.41C7.19 7.29 7.07 7.19 6.93 7.1C6.8 7.01 6.66 6.93 6.51 6.87C6.36 6.81 6.2 6.76 6.05 6.73C5.89 6.7 5.73 6.68 5.57 6.68L4.03 6.68C4.01 6.68 3.99 6.68 3.98 6.68C3.96 6.68 3.95 6.68 3.93 6.67C3.92 6.67 3.9 6.67 3.88 6.66C3.87 6.66 3.85 6.65 3.84 6.65L1.06 9.42C0.73 9.76 0.46 10.16 0.28 10.6C0.09 11.04 0 11.51 0 11.99C0 12.47 0.09 12.94 0.28 13.39C0.46 13.83 0.73 14.23 1.06 14.57L3.83 17.34C3.85 17.33 3.86 17.33 3.88 17.32C3.89 17.32 3.91 17.31 3.92 17.31C3.94 17.31 3.96 17.31 3.97 17.3C3.99 17.3 4 17.3 4.02 17.3L5.57 17.3C5.73 17.3 5.89 17.28 6.05 17.25C6.2 17.22 6.36 17.17 6.51 17.11C6.66 17.05 6.8 16.97 6.93 16.88C7.07 16.8 7.19 16.69 7.31 16.58L10.92 12.97C11.57 12.32 12.7 12.32 13.36 12.97L16.95 16.57C17.07 16.68 17.19 16.78 17.33 16.87C17.46 16.96 17.6 17.04 17.75 17.1C17.9 17.16 18.06 17.21 18.21 17.24C18.37 17.27 18.53 17.29 18.69 17.29L19.95 17.29C19.96 17.29 19.98 17.29 20 17.29C20.01 17.29 20.03 17.29 20.05 17.3C20.06 17.3 20.08 17.3 20.1 17.31C20.11 17.31 20.13 17.32 20.14 17.33L22.9 14.57C23.24 14.23 23.51 13.83 23.69 13.39C23.88 12.94 23.97 12.47 23.97 11.99C23.97 11.51 23.88 11.04 23.69 10.6C23.51 10.16 23.24 9.76 22.9 9.42"></path>
                  </svg>
               </div>
               <span>Pix</span>
            </div>
          </> : <>
          <div class="sc-f2eb517a-2 enCtsM">
   <div class="sc-f2eb517a-3 cfIeVQ">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fb8c00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card ">
         <rect width="20" height="14" x="2" y="5" rx="2"></rect>
         <line x1="2" x2="22" y1="10" y2="10"></line>
      </svg>
   </div>
   <span>Cartão de crédito</span>
</div>

          </>}
           

         </div>
      </div>
      {this.state.pgmt == "pix" ? <>
      <div class="sc-57a20e2c-8 hFZwZo">
         <div class="sc-f2eb517a-10 iDJTsr"><span>Ao continuar declaro ter lido e concordado com os&nbsp;<a href="https://rifei.com.br/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer">termos de uso</a>&nbsp;e a&nbsp;<a href="https://rifei.com.br/ajuda/outros/politica-de-privacidade" target="_blank" rel="noopener noreferrer">política de privacidade</a>&nbsp;do Rifei.</span></div>
         <button type="button" class="sc-b1836745-0 kICcdG" aria-label="Finalizar" onClick={(e)=>{

e.target.classList.add("load");

fetch(`${apiUrl}/purchases/pix`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get('auth_token')
  },
  body: JSON.stringify({
  campanha:this.state.data.campanha.code,
  user:{
  nome:this.state.data.user.name,
  email:this.state.data.user.email,
  document:this.state.dcDoc,
  dcmt:this.state.tpDc
  }
  })
})
  .then(response => {
    e.target.classList.remove("load");
console.log(response)
if(response.status == "200"){
  response.json().then(data=>{
    this.props.navigate("/checkout/active/pay/"+data.token)
  })
}

  })




         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock ">
               <rect width="18" height="11" x="3" y="11" rx="2" ry="2"></rect>
               <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            Finalizar
         </button>
      </div>
      </> : <>
  
   <div style={{marginTop:"30px"}} class="sc-57a20e2c-4 bXvgEY">
      <div class="sc-57a20e2c-5 iYJSwo-c">
         <div class="sc-57a20e2c-6 fkrWSV">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
               <line x1="1" y1="10" x2="23" y2="10"></line>
            </svg>
         </div>
         <h2>Cartão de crédito</h2>
      </div>
      <fieldset class="sc-56eec735-1 bSrfIZ">
         <div class="sc-2d577abe-0 jYSPyC">
            <span>Titular do cartão</span>
            <div style={{height:"16px"}}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="cardholderName-help-tt">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
               </svg>
            </div>
         </div>
         <div class="sc-56eec735-2 bBSNUk"><input name="cardholderName" id="cardholderName" placeholder="Digite o titular" type="text" class="sc-56eec735-0 jDba-dm" value=""/></div>
      </fieldset>
      <fieldset class="sc-230ae67d-1 dnwFYI">
         <fieldset class="sc-56eec735-1 hDxYJw">
            <div class="sc-2d577abe-0 jYSPyC"><span>Número do cartão</span></div>
            <div class="sc-56eec735-2 bBSNUk"><input name="cardNumber" id="cardNumber" type="text" class="sc-56eec735-0 jDba-dm" value="**** **** **** ****"/></div>
         </fieldset>
      </fieldset>
      <div class="sc-57a20e2c-7 johztJ">
         <fieldset class="sc-56eec735-1 bSrfIZ">
            <div class="sc-2d577abe-0 jYSPyC"><span>Data de validade</span></div>
            <div class="sc-56eec735-2 bBSNUk"><input name="cardExpiration" id="cardExpiration" type="text" class="sc-56eec735-0 jDba-dm" value="__/__"/></div>
         </fieldset>
         <fieldset class="sc-56eec735-1 bSrfIZ">
            <div class="sc-2d577abe-0 jYSPyC">
               <span>Código de segurança</span>
               <div style={{height:"16px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="securityCode-help-tt">
                     <circle cx="12" cy="12" r="10"></circle>
                     <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                     <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
               </div>
            </div>
            <div class="sc-56eec735-2 bBSNUk"><input name="securityCode" id="securityCode" placeholder="Digite o código" type="text" maxlength="4" class="sc-56eec735-0 jDba-dm" value=""/></div>
         </fieldset>
      </div>
    
      <div class="sc-fcd11139-3 gnFusz"></div>
   </div>
   <div class="sc-57a20e2c-8 hFZwZo">
      <div class="sc-f2eb517a-10 iDJTsr"><span>Eu li e concordo com os&nbsp;<a href="https://rifei.com.br/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer">termos de uso</a>&nbsp;e a&nbsp;<a href="https://rifei.com.br/ajuda/outros/politica-de-privacidade" target="_blank" rel="noopener noreferrer">política de privacidade</a>&nbsp;e estou ciente que o Rifei não reterá nenhum dado do cartão.</span></div>
      <button type="submit" disabled="" class="sc-b1836745-0 kICcdG" aria-label="Pagar">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
         </svg>
         Pagar
      </button>
   </div>


      </>}
    

   </div>
</div>

</>)


}
}

   render() {
   
    if(this.state.Loader){
    return (
        <>
        
       
        <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
       
       
        </>
           );
   }

return(<>
  





<div class="sc-48e0541d-0 cPwTvc">
   <div class="sc-48e0541d-1 fweOiT">
      <Link to="/raffles" class="sc-48e0541d-2 cPuNND">
         <div class="full-logo">
          <img src={mylogo} style={{width:'100px'}} />
         </div>
         <div class="small-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#fb8c00" viewBox="0 0 24 24">
               <path fill="#ffffff" d="m3.3 5.8l14.3-1.5 1.4 14.3-14.2 1.4z"></path>
               <path d="M24 0v3.71l-.22.01c-.96.05-1.78.73-2.03 1.67-.07.23-.09.67-.05.9.07.48.28.89.61 1.23.4.42.95.66 1.52.68l.17.01v3.78l-.05.01c-.15.03-.48.14-.59.2-.57.29-1 .82-1.17 1.43-.05.19-.06.24-.06.58 0 .35.01.39.06.58.06.24.24.59.37.77.33.43.82.75 1.33.85l.11.02v3.44l-11.97 2.05C5.45 23.05.05 23.97.04 23.98 0 23.98 0 23.5 0 14.06 0 8.6.01 4.14.02 4.14c0 0 5.4-.93 11.99-2.07C18.6.93 23.99 0 24 0zM12.48 6.4c-.49.04-.66.06-4.44.71l-1.46.25v5.63c0 4.49.01 5.62.03 5.62.01-.01.71-.13 1.55-.27l1.53-.26v-2.13c0-2 0-2.12.04-2.13.1-.03.96-.16 1.13-.17.71-.05 1.13.15 1.55.76.08.1.48.79.91 1.53.42.74.78 1.35.79 1.36.01.02 3.28-.53 3.3-.55 0 0-.47-.84-1.55-2.69-.51-.88-.86-1.31-1.3-1.6-.26-.17-.65-.31-.88-.31-.06 0-.03-.02.17-.12 1.11-.59 1.75-1.37 1.97-2.42.05-.23.05-.29.05-.74 0-.53-.02-.71-.14-1.06-.29-.86-.99-1.31-2.18-1.41-.27-.02-.74-.02-1.07 0z"></path>
               <path d="M11.27 8.68c-.07.01-.46.07-.85.14-.39.07-.71.12-.72.12-.01 0-.01.65-.01 1.44v1.43l.06-.01c.03-.01.34-.06.71-.12.95-.17 1.21-.24 1.55-.42.4-.22.64-.53.73-.98.06-.29.05-.75-.03-.98-.11-.33-.28-.5-.61-.59-.14-.04-.62-.06-.83-.03z"></path>
            </svg>
         </div>
      </Link>
      <div class="sc-48e0541d-3 ebWweX">
         <div class="sc-48e0541d-4 cINijs">
            <div class="sc-48e0541d-5 clQKTA">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="8" r="5"></circle>
                  <path d="M20 21a8 8 0 1 0-16 0"></path>
               </svg>
            </div>
            <p>Dados pessoais</p>
         </div>
         <div class="sc-48e0541d-6 itlzSV"></div>
         <div class="sc-48e0541d-4 fJkmDh">
            <div class="sc-48e0541d-5 clQKTA">
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map ">
                  <polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon>
                  <line x1="9" x2="9" y1="3" y2="18"></line>
                  <line x1="15" x2="15" y1="6" y2="21"></line>
               </svg>
            </div>
            <p>Endereço</p>
         </div>
         <div class="sc-48e0541d-6 itlzSV"></div>
         <div class="sc-48e0541d-4 fJkmDh">
            <div class="sc-48e0541d-5 clQKTA">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text ">
                  <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" x2="8" y1="13" y2="13"></line>
                  <line x1="16" x2="8" y1="17" y2="17"></line>
                  <line x1="10" x2="8" y1="9" y2="9"></line>
               </svg>
            </div>
            <p>Revisar</p>
         </div>
      </div>
   </div>
</div>

<main>

<div class="sc-57a20e2c-0 jlBcBu">

{this._RenderP(this.state.page)}

<div class="sc-de7e3f23-0 kOZbAK">
   <div class="sc-de7e3f23-1 eAnCNa">
      <div class="sc-de7e3f23-2 ihriHv">
         <div>
            <div class="sc-de7e3f23-4 iTOWQA">
            {this.state.data.campanha.imagem.length > 0 ? <>
              <img src={`${apiUrl}/static/${this.state.data.campanha.imagem[0].name}`} alt="pc gamer" width="60" height="60"/>
            
            </> : <>
            <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=60&amp;h=60&amp;fit=crop&amp;auto=format,compress&amp;q=75" alt="pc gamer" width="60" height="60"/>
            
            </>}
           
            </div>
         </div>
         <div class="sc-de7e3f23-3 hEhhfg">
            <p>Ativação da campanha "{this.state.data.campanha.titulo}"</p>
         </div>
      </div>
      <div class="sc-de7e3f23-5 huMDdy">
         <div class="sc-de7e3f23-6 kCWiXe">
            <div class="sc-de7e3f23-7 zIqtI">
               <div class="sc-de7e3f23-8 kcOxfx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <circle cx="12" cy="12" r="10"></circle>
                  </svg>
               </div>
               <span>Subtotal</span>
            </div>
            <div class="sc-de7e3f23-9 gbDQxZ"><span>{this.state.data.campanha.valor}</span></div>
         </div>
         <div class="sc-de7e3f23-6 kCWiXe">
            <div class="sc-de7e3f23-7 zIqtI total">
               <div class="sc-de7e3f23-8 kcOxfx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <line x1="12" y1="1" x2="12" y2="23"></line>
                     <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
               </div>
               <span>Total</span>
            </div>
            <div class="sc-de7e3f23-9 gbDQxZ">
               <h4>{this.state.data.campanha.valor}</h4>
            </div>
         </div>
      </div>
    
   </div>
</div>


</div>


</main>


<ToastContainer/>
  </>)
   
  }
}

export default CheckActive;