import React from 'react';
import "../afiliado.css"
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Drop, Dropdown, Item } from '../../../Comps/Dropdown';
import Modal from '../../../Comps/Modal';
class Affiliate extends React.Component {
    constructor(props) {
    super(props);
       
    this.state = {
    Loader:true,
    data:[]
    }
    this.CodeRef = React.createRef();
}

componentDidMount(){
    fetch(`${apiUrl}/affiliates`, {
        method: 'GET',
        credentials: 'include', 
        headers: {
              'Content-Type': 'application/json' ,
              'Authorization': 'Bearer '+Cookies.get('auth_token')
        }
      })
     .then(response => {
     
     
     
     response.json().then(data=>{
        this.setState({Loader:false,data})
     })
    })
}

   render() {
    const todosAtivos = this.state.data.every(item => item.accepted_invite == "true");
   
   
    if(this.state.Loader){
        return (
            <>
            
           
            <div class="sc-179422ed-0 keEfHG">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                 <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                 <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                 <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                    <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                    <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                 </circle>
              </svg>
           </div>
           
           
            </>
               );
       }




return(<>

<main class="sc-53c286d8-0 hVjYGT">
   <div class="sc-53c286d8-1 kwcquX">
      <div class="sc-84a07fe4-0 jZNhWw">
        
         <a type="button" className="sc-84a07fe4-1 xZztE active" onClick={()=>{
            this.setState({active:"b"})
         }}>
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail "><rect width="20" height="16" x="2" y="4" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>
            <span>Solicitações</span>
         </a>
      </div>
      <div class="sc-53c286d8-2 mRYWg">

      <div class="sc-16a1e35d-4 eRmWWQ">
   <div class="sc-16a1e35d-7 kcRDMr">
      <div class="sc-16a1e35d-8 dXqRbT">
         <div class="sc-16a1e35d-9 dbMCVd">
            <h1>Área do afiliado</h1>
         </div>
         <span>Gerencie seu link de afiliado, aceite convites e veja suas comissões.</span>
      </div>
   </div>
   <hr/>
   <div name="scroll_0" class="sc-16a1e35d-6 cwPGEx">
   {this.state.data.map((item,index)=>{
    if(item.accepted_invite == "true"){
return(<>

   <div class="sc-16a1e35d-7 kcRDMr">
      <div class="sc-16a1e35d-13 enKQqn">
         <div class="sc-16a1e35d-15 ldsPpM2">
            <div class="sc-16a1e35d-16 jUURzU">
               <div class="sc-16a1e35d-17 bovJwT">{item.name.split(' ')
  .map(word => word.charAt(0).toUpperCase())
  .join('')}</div>
               <div class="sc-16a1e35d-18 tDKCV">
                  <p>{item.name}</p>
                  <span>{item.commission_type == "percentage" ? `
                         ${item.commission_value}%
                         ` : `
                         R$${item.commission_value}
                         `} de comissão por bilhete vendido</span>
               </div>
            </div>
            <div class="sc-16a1e35d-21 gCBPHB">
               <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                <Dropdown>
                    <Drop>
                    <button type="button" class="sc-cf9fb95-0 cViQTZ">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings ">
                        <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                     </svg>
                  </button>
                    </Drop>
                    <Item>
                    <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML">
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" aria-label="Alterar código" onClick={()=>{
    Modal.create({titulo:"Alterar código",html:<>
    <p>Ao alterar o código, o link de afiliado que você compartilhava antes não irá mais funcionar!</p>
    <fieldset class="sc-56eec735-1 bSrfIZ">
   <div class="sc-2d577abe-0 jYSPyC">
      <span>Código</span>
      <div style={{height:'16px'}}>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="code-help-tt">
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
         </svg>
      </div>
   </div>
   <div class="sc-56eec735-2 bBSNUk"><input ref={this.CodeRef} name="code" id="code" class="sc-56eec735-0 jDba-dm" onChange={(e)=>{
this.CodeRef.current.value = e.target.value;
   }} /></div>
</fieldset>

    </>,btn:<>
    <div class="sc-955b9eec-7 eqSpHU">
    <button type="submit" disabled="" class="sc-b1836745-0 fzfuwn" aria-label="Salvar alterações" onClick={(e)=>{
         e.target.classList.add("load")
         fetch(`${apiUrl}/affiliates/${item.id}/code`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+Cookies.get('auth_token')
            },
            body:JSON.stringify({
                code:this.CodeRef.current.value
            })
          }).then(response => {
            e.target.classList.remove("load")
if(response.status == 200){
    response.json().then(data=>{
        toast.success('Código alterado com sucesso!', {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Modal.close()
    })
}
          })
    }}>Salvar alterações</button>
    </div>
    </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link ">
         <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
         <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
      </svg>
      <span>Alterar código</span>
   </button>
   <hr/>
   <button data-danger="true" type="button" aria-label="Desafiliar" onClick={()=>{
    Modal.create({titulo:"Desafiliar",html:<>
    <div class="sc-955b9eec-5 dpOCjN"><p>Você tem certeza que deseja se desafiliar deste usuário?</p><br/><p>Esta ação não pode ser desfeita e todas as suas vendas e comissões serão descontabilizadas.</p></div>
    </>,btn:<>
    <div class="sc-955b9eec-6 iHWLZo">
   <button type="button" aria-label="Cancelar" class="sc-b1836745-0 dmLiMY" onClick={()=>{
    Modal.close()
   }}>Cancelar</button>
   <button type="submit" class="sc-b1836745-0 bJxjoS" aria-label="Desafiliar" onClick={(e)=>{
    e.target.classList.add("load")
    fetch(`${apiUrl}/affiliates/${item.id}/disaffiliate`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get('auth_token')
        }
      }).then(response => {

       Modal.close()

        this.setState(prevState => ({
            data: prevState.data.filter(i => i.id !== item.id)
          }));



      })
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out ">
         <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
         <polyline points="16 17 21 12 16 7"></polyline>
         <line x1="21" x2="9" y1="12" y2="12"></line>
      </svg>
      Desafiliar
   </button>
</div>

    </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out ">
         <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
         <polyline points="16 17 21 12 16 7"></polyline>
         <line x1="21" x2="9" y1="12" y2="12"></line>
      </svg>
      <span>Desafiliar</span>
   </button>
</div>

                    </Item>
                </Dropdown>
                 
               </div>
            </div>
         </div>
         <div class="sc-16a1e35d-12 hUWlsN">
            <h3>Este usuário ainda não possui nenhuma campanha.</h3>
         </div>
      </div>
   </div>


</>)
    }
   })}

   </div>
   <div name="invites" class="sc-16a1e35d-6 cwPGEx">
      <div class="sc-16a1e35d-7 kcRDMr">
         <h1 class="sc-16a1e35d-10 jEQSCu">Solicitações</h1>
         <div id="affiliates_invitees" class="sc-16a1e35d-12 hUWlsN">

{this.state.data.length > 0 ? <>
{this.state.data.map((item,index)=>{
    
    if(item.accepted_invite == "false"){
        return(<>
            <div id="87900" data-id="87900" class="sc-16a1e35d-43 fvifFv">
                      <div class="sc-16a1e35d-24 iVBXLl">
                         <div class="sc-16a1e35d-15 ldsPpM">
                            <div class="sc-16a1e35d-16 jUURzU">
                               <div class="sc-16a1e35d-17 bovJwT">{item.name.split(' ')
  .map(word => word.charAt(0).toUpperCase())
  .join('')}</div>
                               <div class="sc-16a1e35d-18 tDKCV">
                                  <h4>{item.name}</h4>
                                  <span>Convite enviado há 4 minutos</span>
                               </div>
                            </div>
                         </div>
                         <div class="sc-16a1e35d-20 kYJEXY mt">{item.commission_type == "percentage" ? `
                         ${item.commission_value}%
                         ` : `
                         R$${item.commission_value}
                         `} de comissão por pedido vendido</div>
                      </div>
                      <hr/>
                      <div class="sc-16a1e35d-24 iVBXLl">
                         <div class="sc-16a1e35d-44 cIjeRH"><button type="button" aria-label="Recusar" class="sc-b1836745-0 fWNWHF">Recusar</button>
                        <button type="button" aria-label="Aceitar" class="sc-b1836745-0 kedJPy" onClick={(e)=>{
                         e.target.classList.add("load")
                         fetch(`${apiUrl}/affiliates/${item.id}/accept`, {
                            method: 'PUT',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get('auth_token')
                            }
                          }).then(response => {
                          
                           
                            response.json().then(re=>{
                                e.target.classList.remove("load")


    const itemIndex = this.state.data.findIndex(i => i.id === item.id);
    if (itemIndex === -1) {
      console.error('Item não encontrado');
      return;
    }
    const newItems = [...this.state.data];
    newItems[itemIndex].accepted_invite = 'true';
    this.setState({ data: newItems });         

})

                        })
                        }}>Aceitar</button>
                        </div>
                      </div>
                  
                  
                   </div>
           </>)
    }
    
})}
</> : <>


</>}

           

         </div>
      </div>
   </div>
</div>



      </div>
     
   
   

   </div>
</main>
<ToastContainer/>
<div id="modal-root"></div>
</>)

  }
}

export default Affiliate;