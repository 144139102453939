import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import copy from 'clipboard-copy';
import logo from "../../../src/images/logo.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../../config';

class CheckPay extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
           menu:false,
           Loader:true,
           data:[]
        }
     }

_Data = (dataOriginal) => {

let data = new Date(dataOriginal);

let dia = data.getDate().toString().padStart(2, "0");
let mes = (data.getMonth() + 1).toString().padStart(2, "0"); // Os meses começam em zero, por isso adicionamos 1
let ano = data.getFullYear();

let hora = data.getHours().toString().padStart(2, "0");
let minuto = data.getMinutes().toString().padStart(2, "0");
let segundo = data.getSeconds().toString().padStart(2, "0");

return  `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`
}


    setCookie(name, value, days) {
        var expires = '';  
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }
 
 
 
 
    componentDidMount(){
document.title = "Pagar pedido - EuRifei"  



let str =  window.location.pathname;
let lastIndex = str.lastIndexOf("/");
let result = str.substring(lastIndex + 1);



fetch(`${apiUrl}/raffles/check-purchase/${result}`, {
   method: 'GET',
   credentials: 'include', 
   headers: {
          'Content-Type': 'application/json' 
   }
 })
.then(response => {
   
   
response.json().then(data=>{
  this.setState({data})
  this.setState({Loader:false})
   })


})


  }
   render() {
   if(this.state.Loader == true){

    return (
 <>
     <main>
 <div class="sc-179422ed-0 keEfHG ckmail">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       
       </div>
</main>
<ToastContainer/>
 </>
    );

}

return(<>

    <div class="sc-48e0541d-0 cPwTvc">
       <div class="sc-48e0541d-1 fweOiT">
          <div class="sc-de9764ff-2 cvwfOn">
            <Link to="/raffles">      <img src={logo} style={{width:'100px'}} /></Link>
    
          </div>
          <span class="sc-20b9c76f-0 dgQNDx">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-timer ">
                <line x1="10" x2="14" y1="2" y2="2"></line>
                <line x1="12" x2="15" y1="14" y2="11"></line>
                <circle cx="12" cy="14" r="8"></circle>
             </svg>
             <span>Pague e será creditado na hora</span>
          </span>
       </div>
    </div>
    <main>
      <div className='sc-c747ac7d-3 MVTKG'>

{this.state.data.status == "approved" ? <>
<div class="sc-51b58f3d-2 dCJSzp">
   <div class="sc-51b58f3d-6 jTPUdU">
      <div class="sc-51b58f3d-4 ivVfnx">
         <div class="sc-51b58f3d-5 jJhefP">
            <div class="sc-51b58f3d-7 kdXYfB">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check ">
                  <path d="M20 6 9 17l-5-5"></path>
               </svg>
            </div>
            <h3>Pagamento aprovado</h3>
            <p>Você já está participando, agora é só torcer!</p>
         </div>
      </div>
   </div>
</div>

</> : <>
<div class="sc-c747ac7d-5 bcreay">
   <div class="sc-c747ac7d-6 cZOpPg">
      <div class="sc-c747ac7d-7 biTPaW">
         <div class="sc-c747ac7d-8 gEBMun">
            <div class="sc-c747ac7d-9 dqruIr">
               <div>
                  <div class="sc-c747ac7d-10 kqnlNF">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                     </svg>
                  </div>
               </div>
               <h2>Copie o código para pagar</h2>
            </div>
            <div class="sc-c747ac7d-0 fxxOyW">
               <div class="sc-c747ac7d-1 ceIiZv">
                  <div>
                     <div class="sc-c747ac7d-2 hzmHON"><span>1</span></div>
                  </div>
                  <p>Abra o aplicativo do seu banco.</p>
               </div>
               <div class="sc-c747ac7d-1 ceIiZv">
                  <div>
                     <div class="sc-c747ac7d-2 hzmHON"><span>2</span></div>
                  </div>
                  <p>Escolha pagar via Pix, e clique em código copia e cola.</p>
               </div>
               <div class="sc-c747ac7d-1 ceIiZv">
                  <div>
                     <div class="sc-c747ac7d-2 hzmHON"><span>3</span></div>
                  </div>
                  <p>Cole o código abaixo:</p>
               </div>
            </div>
            <div class="sc-c747ac7d-12 bHINxE">
               <div class="sc-7dbf86ee-0 gDhQnR">
                  <input type="text" readonly="" class="sc-7dbf86ee-1 dzWCme" value={this.state.data.pixCode}/>
                  <div class="sc-7dbf86ee-2 hPtNIB"></div>
                  <button type="button" aria-label="Copiar" class="sc-7dbf86ee-3 kpoUs" onClick={()=>{
                      copy(this.state.data.pixCode)
                      .then(() => {
                         toast.success('Texto copiado para área de transferência', {
                            position: "top-center",
                            autoClose: 3000, // Fecha automaticamente após 3 segundos
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                      })
                      .catch(err => {
                        console.error('Falha ao copiar: ', err);
                      });
                  }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                     </svg>
                     <span>Copiar</span>
                  </button>
               </div>
            </div>
         </div>
         <hr/>
         <div class="sc-c747ac7d-8 gEBMun">
            <div class="sc-c747ac7d-9 dqruIr">
               <div>
                  <div class="sc-c747ac7d-10 kqnlNF">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 109 109">
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="7" fill-rule="evenodd" d="M19,24H9s-5,0-5,5V79s0.125,5,5,5H19"></path>
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="7" fill-rule="evenodd" d="M90,24h10s5,0,5,5V79s-0.125,5-5,5H90"></path>
                        <rect x="16" y="40" width="5" height="28" rx="2.5" ry="2.5"></rect>
                        <rect x="30" y="32" width="5" height="44" rx="2.5" ry="2.5"></rect>
                        <rect x="44" y="39" width="5" height="31" rx="2.5" ry="2.5"></rect>
                        <rect x="58" y="32" width="9" height="45" rx="4.5" ry="4.5"></rect>
                        <rect x="76" y="39" width="4" height="31" rx="1.5" ry="1.5"></rect>
                        <rect x="89" y="46" width="4" height="17" rx="1.5" ry="1.5"></rect>
                     </svg>
                  </div>
               </div>
               <h2>Ou escaneie o QR Code</h2>
            </div>
            <div class="sc-c747ac7d-0 fxxOyW">
               <div class="sc-c747ac7d-1 ceIiZv">
                  <div>
                     <div class="sc-c747ac7d-2 hzmHON"><span>1</span></div>
                  </div>
                  <p>Abra o aplicativo do seu banco.</p>
               </div>
               <div class="sc-c747ac7d-1 ceIiZv">
                  <div>
                     <div class="sc-c747ac7d-2 hzmHON"><span>2</span></div>
                  </div>
                  <p>Escolha pagar via Pix, e escaneie o código abaixo:</p>
               </div>
               <div class="sc-c747ac7d-11 bcLNRJ"><img alt="QR Code do PicPay" width="170" height="170" src={this.state.data.pixImage}/></div>
            </div>
             </div>
      </div>
   </div>
</div>
</>}


  

   <div class="sc-de7e3f23-0 kOZbAK">
   <div class="sc-de7e3f23-1 eAnCNa">
      <div class="sc-de7e3f23-2 ihriHv">
         <div>
            <div class="sc-de7e3f23-4 iTOWQA">
            {this.state.data.image.length > 0 ? <>
              <img src={`${apiUrl}/static/${this.state.data.image[0].name}`} alt="pc gamer" width="60" height="60"/>
            
            </> : <>
            <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=60&amp;h=60&amp;fit=crop&amp;auto=format,compress&amp;q=75" alt="pc gamer" width="60" height="60"/>
            
            </>}
           
            </div>
         </div>
         <div class="sc-de7e3f23-3 hEhhfg">
            <p>Ativação da campanha "{this.state.data.title}"</p>
         </div>
      </div>
      <div class="sc-de7e3f23-5 huMDdy">
         <div class="sc-de7e3f23-6 kCWiXe">
            <div class="sc-de7e3f23-7 zIqtI">
               <div class="sc-de7e3f23-8 kcOxfx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <circle cx="12" cy="12" r="10"></circle>
                  </svg>
               </div>
               <span>Subtotal</span>
            </div>
            <div class="sc-de7e3f23-9 gbDQxZ"><span>{this.state.data.transaction_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></div>
         </div>
         <div class="sc-de7e3f23-6 kCWiXe">
            <div class="sc-de7e3f23-7 zIqtI total">
               <div class="sc-de7e3f23-8 kcOxfx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                     <line x1="12" y1="1" x2="12" y2="23"></line>
                     <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
               </div>
               <span>Total</span>
            </div>
            <div class="sc-de7e3f23-9 gbDQxZ">
               <h4>{this.state.data.transaction_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
            </div>
         </div>
      </div>
    
   </div>



</div>
</div>
    </main>
    <ToastContainer/>
    </>)




  }
}

export default CheckPay;