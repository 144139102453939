import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Footer from '../Inicio/Footer';
class Ajuda extends React.Component {
   componentDidMount(){
      document.title = "Ajuda"
   }
   
  render() {
    return (
 <>
 
 <header class="sc-99a72b0d-0 eQystT">
   <nav class="sc-99a72b0d-1 lfTGCM">
      <Link to="/" class="sc-99a72b0d-2 jxbNpo">
         <div class="logo full-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="#fb8c00" viewBox="0 0 100 45">
               <path fill="#ffffff" d="m6.5 19.1l83.8-13.9 3.3 19.9-83.8 13.9z"></path>
               <path d="M0,17.391L100,0V5.171a3.321,3.321,0,0,0-3.179,2.919A3.231,3.231,0,0,0,100,11.469v5.255a3.107,3.107,0,0,0,0,6.214v4.8L0,45V17.391ZM30.43,33.83l4.742-.822-3.24-6.024a5.217,5.217,0,0,0,2.183-2.44,5.543,5.543,0,0,0,.311-3.174,4.727,4.727,0,0,0-.536-1.516,3.183,3.183,0,0,0-1.059-1.112,4.125,4.125,0,0,0-1.635-.58,7.069,7.069,0,0,0-2.244.076l-7.22,1.252L22.1,35.274l4.486-.778-0.154-5.889,1.26-.218Zm-4.058-8.495-0.1-3.135,1.645-.285a2.378,2.378,0,0,1,1.432.065,1.1,1.1,0,0,1,.559.842,1.782,1.782,0,0,1-.057.971,1.568,1.568,0,0,1-.486.667,2.55,2.55,0,0,1-.784.417,6.136,6.136,0,0,1-.927.236Zm15.782-6.687,1.624-.281-0.043-2.691L35.98,17.019l0.064,2.688,1.6-.278,0.239,10.409-1.623.281L36.347,32.8l7.733-1.34-0.064-2.688-1.6.278Zm13.5,1.569-5.276.915-0.088-2.943,6.067-1.052L56.273,13.5,45.7,15.334l0.367,15.785,4.529-.785-0.138-5.676,5.276-.915ZM67.892,17.49l-5.362.929-0.068-2.342,6.045-1.048-0.058-3.639L57.875,13.224l0.367,15.785,10.83-1.877-0.084-3.656-6.3,1.092-0.07-2.6,5.362-.929Zm8.46-4.77,1.623-.281L77.933,9.747l-7.754,1.344,0.064,2.688,1.6-.278L72.084,23.91l-1.623.281,0.085,2.684,7.733-1.34-0.064-2.688-1.6.278Z"></path>
            </svg>
         </div>
         <div class="logo small-logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fb8c00" viewBox="0 0 24 24">
               <path fill="#ffffff" d="m3.3 5.8l14.3-1.5 1.4 14.3-14.2 1.4z"></path>
               <path d="M24 0v3.71l-.22.01c-.96.05-1.78.73-2.03 1.67-.07.23-.09.67-.05.9.07.48.28.89.61 1.23.4.42.95.66 1.52.68l.17.01v3.78l-.05.01c-.15.03-.48.14-.59.2-.57.29-1 .82-1.17 1.43-.05.19-.06.24-.06.58 0 .35.01.39.06.58.06.24.24.59.37.77.33.43.82.75 1.33.85l.11.02v3.44l-11.97 2.05C5.45 23.05.05 23.97.04 23.98 0 23.98 0 23.5 0 14.06 0 8.6.01 4.14.02 4.14c0 0 5.4-.93 11.99-2.07C18.6.93 23.99 0 24 0zM12.48 6.4c-.49.04-.66.06-4.44.71l-1.46.25v5.63c0 4.49.01 5.62.03 5.62.01-.01.71-.13 1.55-.27l1.53-.26v-2.13c0-2 0-2.12.04-2.13.1-.03.96-.16 1.13-.17.71-.05 1.13.15 1.55.76.08.1.48.79.91 1.53.42.74.78 1.35.79 1.36.01.02 3.28-.53 3.3-.55 0 0-.47-.84-1.55-2.69-.51-.88-.86-1.31-1.3-1.6-.26-.17-.65-.31-.88-.31-.06 0-.03-.02.17-.12 1.11-.59 1.75-1.37 1.97-2.42.05-.23.05-.29.05-.74 0-.53-.02-.71-.14-1.06-.29-.86-.99-1.31-2.18-1.41-.27-.02-.74-.02-1.07 0z"></path>
               <path d="M11.27 8.68c-.07.01-.46.07-.85.14-.39.07-.71.12-.72.12-.01 0-.01.65-.01 1.44v1.43l.06-.01c.03-.01.34-.06.71-.12.95-.17 1.21-.24 1.55-.42.4-.22.64-.53.73-.98.06-.29.05-.75-.03-.98-.11-.33-.28-.5-.61-.59-.14-.04-.62-.06-.83-.03z"></path>
            </svg>
         </div>
         <span>Central de ajuda</span>
      </Link>
      <div class="sc-99a72b0d-3 bvXEgf">
         <Link class="sc-99a72b0d-4 erGKYR only-desktop" to="https://wa.me/554892100283" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
               <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
            </svg>
            WhatsApp
         </Link>
         <Link class="sc-99a72b0d-4 erGKYR only-desktop" aria-label="Acessar o Rifei" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link ">
               <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
               <polyline points="15 3 21 3 21 9"></polyline>
               <line x1="10" x2="21" y1="14" y2="3"></line>
            </svg>
            Acessar o Rifei
         </Link>
         <button type="button" aria-expanded="false" aria-haspopup="true" aria-label="Abrir menu" class="sc-99a72b0d-5 kgOVzc">
            <div class="sc-99a72b0d-6 hbNvUW">
               <div class="sc-99a72b0d-7 ktfgVq header-menu-line"></div>
               <div class="sc-99a72b0d-7 ktfgVq header-menu-line"></div>
            </div>
         </button>
      </div>
   </nav>
   <div id="mobile-header" aria-hidden="true" class="rah-static rah-static--height-zero " >
      <div >
         <nav class="sc-99a72b0d-8 clALcd">
            <Link to="https://wa.me/554892100283" target="_blank" rel="noopener noreferrer" class="sc-99a72b0d-4 erGKYR">
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
               </svg>
               WhatsApp
            </Link>
            <div class="sc-99a72b0d-9 gKCmCW"></div>
            <Link to="/" class="sc-99a72b0d-4 erGKYR">
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link ">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" x2="21" y1="14" y2="3"></line>
               </svg>
               Acessar o Rifei
            </Link>
         </nav>
      </div>
   </div>
</header>
<main class="sc-3e52f5eb-0 goksQR">
<section class="sc-3e52f5eb-1 eUyTFy">
   <div class="sc-3e52f5eb-2 dXuzjv">
      <svg xmlns="http://www.w3.org/2000/svg" width="1440" height="500" viewBox="0 0 1440 500">
         <path fill="#88fc73" fill-rule="evenodd" d="M156.98,317s11.869-30.418,62.556-20c27.776,5.709,14.662,27,14.662,27s-15.334,21.759-19.549,31c-4.562,10-14.988,18.667-30.3,19S146.472,336.083,156.98,317Z"></path>
         <path fill="none" fill-rule="evenodd" stroke-linecap="round" stroke="#dda2ff" stroke-width="25" d="M1391.02,394.036s56.68,15.2,47.02,101.964c-5.19,42.939-57.6,29-57.6,29s-28.23-21.292-46.08-31.455c-17-9.677-38.06-27.6-33.64-55.086C1306.09,405.121,1357.23,374.933,1391.02,394.036Z"></path>
         <path fill="none" fill-rule="evenodd" stroke-linecap="round" stroke="#00b4ff" stroke-width="10" stroke-linejoin="bevel" d="M867.291,480.2L852.7,508.909l-31.941-4.876-5.149-31.723,28.759-14.729Z"></path>
         <path fill="none" fill-rule="evenodd" stroke="#ff9272" stroke-width="25" stroke-linejoin="bevel" d="M-93,325s91.971-132.15,201.985,24c55.954,79.419,84.516,44.958,144.989,119C353.175,585.436,25.074,569.7-105,492-196.146,437.553-93,325-93,325Z"></path>
         <g>
            <path fill-rule="evenodd" fill="none" stroke="#ffc000" stroke-width="25" stroke-linecap="round" stroke-linejoin="bevel" d="M1583.24-9.581a174.248,174.248,0,0,1-334.53,97.666"></path>
            <circle fill-rule="evenodd" fill="#ffc000" cx="1243.78" cy="43.344" r="12.5"></circle>
            <path fill-rule="evenodd" fill="none" stroke-linecap="round" stroke="#00b4ff" stroke-width="25" stroke-linejoin="bevel" d="M1478.01,148.373A124.429,124.429,0,0,1,1353.58-67.144"></path>
            <path fill-rule="evenodd" fill="none" stroke-linecap="round" stroke="#ff3c00" stroke-width="25" stroke-linejoin="bevel" d="M1362.86,97.831A78.2,78.2,0,0,1,1470.83-15.324"></path>
         </g>
         <path fill="#ffc000" fill-rule="evenodd" d="M60.88,65.832l31.1,15.627L63.827,100.516Z"></path>
      </svg>
   </div>
   <div class="sc-3e52f5eb-4 jEqinU">
      <div class="sc-3e52f5eb-5 kPByYt">
         <h1 class="sc-3e52f5eb-9 cdlaPM">Como podemos te ajudar?</h1>
         <form action="#" class="sc-3e52f5eb-10 eZgCoJ">
            <div class="sc-3e52f5eb-11 kTXRvu">
               <input name="search" type="search" autocomplete="off" placeholder="Busque por um artigo" class="sc-3e52f5eb-12 kuNDcc" value=""/>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search search-icon">
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
               </svg>
            </div>
         </form>
      </div>
   </div>
</section>
<svg xmlns="http://www.w3.org/2000/svg" id="visual" viewBox="0 0 960 67" width="960" version="1.1" class="sc-3e52f5eb-3 dtxWWZ"><path d="m0 0l22.8 3.7c22.9 3.6 68.5 11 114.2 14.8 45.7 3.8 91.3 4.2 137 6.8 45.7 2.7 91.3 7.7 137.2 7.7 45.8 0 91.8-5 137.6-5.8 45.9-0.9 91.5 2.5 137.2-2 45.7-4.5 91.3-16.9 137-21.5 45.7-4.7 91.3-1.7 114.2-0.2l22.8 1.5v63h-22.8c-22.9 0-68.5 0-114.2 0-45.7 0-91.3 0-137 0-45.7 0-91.3 0-137.2 0-45.8 0-91.8 0-137.6 0-45.9 0-91.5 0-137.2 0-45.7 0-91.3 0-137 0-45.7 0-91.3 0-114.2 0h-22.8z" fill="#ffffff" stroke-linecap="round" stroke-linejoin="miter"></path></svg>


<section class="sc-3e52f5eb-13 jyjOba first">
   <div class="sc-3e52f5eb-6 caiqwy">
      <div class="sc-3e52f5eb-7 izCGSC">
         <div class="sc-3e52f5eb-17 gezrwE">
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(147, 255, 79)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3a681e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <rect width="7" height="7" x="14" y="3" rx="1"></rect>
                        <path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3"></path>
                     </svg>
                  </div>
                  <h2>Integrações</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Como configurar o Google Ads" to="/ajuda/integracoes/como-configurar-o-google-ads" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como configurar o Google Ads</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Como adicionar o Pixel da Meta (API de conversões)" to="/ajuda/integracoes/como-adicionar-o-meta-pixel-api-de-conversoes" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como adicionar o Pixel da Meta (API de conversões)</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Como adicionar o TikTok Pixel" to="/ajuda/integracoes/como-adicionar-o-tiktok-pixel-navegador" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como adicionar o TikTok Pixel</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Como adicionar o Pixel da Meta (Navegador)" to="/ajuda/integracoes/como-adicionar-o-meta-pixel-navegador" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como adicionar o Pixel da Meta (Navegador)</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Integrações" to="/ajuda/integracoes" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(251, 140, 0)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 12.5C19.5 11.12 20.62 10 22 10V9C22 5 21 4 17 4H7C3 4 2 5 2 9V9.5C3.38 9.5 4.5 10.62 4.5 12C4.5 13.38 3.38 14.5 2 14.5V15C2 19 3 20 7 20H17C21 20 22 19 22 15C20.62 15 19.5 13.88 19.5 12.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M10 4L10 20" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5 5"></path>
                     </svg>
                  </div>
                  <h2>Campanha</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Como adicionar uma promoção" to="/ajuda/campanha/como-adicionar-uma-promocao" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como adicionar uma promoção</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Campanha" to="/ajuda/campanha" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(76, 76, 76)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path>
                        <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path>
                        <path d="M4 22h16"></path>
                        <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path>
                        <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path>
                        <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path>
                     </svg>
                  </div>
                  <h2>Sorteio</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Como funciona o sorteio com base na Loteria Federal?" to="/ajuda/sorteio/loteria-federal" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como funciona o sorteio com base na Loteria Federal?</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Sorteio" to="/ajuda/sorteio" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(0, 214, 50)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <rect width="20" height="12" x="2" y="6" rx="2"></rect>
                        <circle cx="12" cy="12" r="2"></circle>
                        <path d="M6 12h.01M18 12h.01"></path>
                     </svg>
                  </div>
                  <h2>Pagamentos</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Como posso saber se um PIX manual foi pago?" to="/ajuda/pagamentos/como-saber-se-um-pix-manual-foi-pago" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como posso saber se um PIX manual foi pago?</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Como adicionar o Mercado Pago como forma de pagamento" to="/ajuda/pagamentos/como-configurar-o-mercado-pago" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como adicionar o Mercado Pago como forma de pagamento</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Pagamentos" to="/ajuda/pagamentos" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(0, 157, 255)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M18 20a6 6 0 0 0-12 0"></path>
                        <circle cx="12" cy="10" r="4"></circle>
                        <circle cx="12" cy="12" r="10"></circle>
                     </svg>
                  </div>
                  <h2>Conta</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Como identificar se um e-mail é do Rifei?" to="/ajuda/conta/email-confiavel-do-rifei" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como identificar se um e-mail é do Rifei?</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Como excluir a minha conta" to="/ajuda/conta/como-excluir-minha-conta" class="sc-3e52f5eb-21 csrQbF">
                        <span>Como excluir a minha conta</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Conta" to="/ajuda/conta" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
            <div class="sc-3e52f5eb-18 ciTwVe">
               <div>
                  <div style={{backgroundColor: 'rgb(192, 205, 206)'}} class="sc-3e52f5eb-19 bxQUux">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#02363d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                     </svg>
                  </div>
                  <h2>Outros</h2>
                  <div class="sc-3e52f5eb-20 cjIItX">
                     <Link aria-label="Política de privacidade" to="/ajuda/outros/politica-de-privacidade" class="sc-3e52f5eb-21 csrQbF">
                        <span>Política de privacidade</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                     <Link aria-label="Termos de uso" to="/ajuda/outros/termos-de-uso" class="sc-3e52f5eb-21 csrQbF">
                        <span>Termos de uso</span>
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right ">
                              <path d="m9 18 6-6-6-6"></path>
                           </svg>
                        </div>
                     </Link>
                  </div>
               </div>
               <Link aria-label="Ir para a categoria Outros" to="/ajuda/outros" class="sc-3e52f5eb-28 kLzaZx">Ver todos</Link>
            </div>
         </div>
      </div>
   </div>
</section>


</main>
<Footer/>
 </>
    );
  }
}

export default Ajuda;