import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./p.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import copy from 'clipboard-copy';
import Modal from '../../Comps/Modal';
import { Drop, Dropdown, Item } from '../../Comps/Dropdown';
class Personalise extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
        Loader:true,
        data:[],
        cLogo:true,
        page:"a"
        }
        this.iptUpl = React.createRef();
        this.faviUpl = React.createRef()
        this.iptF = React.createRef()
        this.iptS = React.createRef()
    }

_LoadPage = (page) => {
   switch(page){
      case 'a':
         return(<>
           <div class="sc-bb1591e2-7 khSMF">
               <h1 class="sc-bb1591e2-8 hEqGkD">Domínio</h1>
               <div class="sc-bb1591e2-3 iUBqHp">
                {this.state.data.domain_verified == "true" ? <>

{this.state.EditD == true ? <>
    <div class="sc-bb1591e2-4 bpBBvu">
  
      <fieldset class="sc-56eec735-1 bSrfIZ">
         <div class="sc-2d577abe-0 jYSPyC"><span>Domínio</span></div>
         <div class="sc-56eec735-2 bBSNUk">
    <input name="domain" id="domain" placeholder="example.com" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
let newData = { ...this.state.data };
newData.domain_verified = 'true';
newData.domain = e.target.value;
this.setState({ data: newData });
    }} value={this.state.data.domain}/></div>
      </fieldset>
      <div class="sc-e3007680-6 fcYheY">
         <button type="button" aria-label="Remover" class="sc-b1836745-0 jtUeXm" onClick={()=>{
            Modal.create({titulo:"Remover domínio",html:<>
            <p>Você tem certeza que deseja remover este domínio?<br/><br/>Esta ação não pode ser desfeita.</p>
            </>,btn:<>
            <div class="sc-955b9eec-6 iHWLZo"><button type="button" class="sc-b1836745-0 eXFuRH" onClick={(e)=>{
              e.target.classList.add("load")
              fetch(`${apiUrl}/account/personalizations/domain`, {
                    credentials: 'include',
                    method:"DELETE",
                    headers: {
                    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                    }
                    })
                    .then(response =>{{
                        let newData = { ...this.state.data };
                        newData.domain_verified = 'false';
                        newData.domain = '';
                        e.target.classList.remove("load")
                        this.setState({ data: newData });    
                        toast.success("Domínio removido com sucesso!", {
                            position: "top-center",
                            autoClose: 3000, // Fecha automaticamente após 3 segundos
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });             
Modal.close()
                    }
                })
            }}>Remover</button></div>
            </>})
         }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="3 6 5 6 21 6"></polyline>
               <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
               <line x1="10" y1="11" x2="10" y2="17"></line>
               <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
            Remover
         </button>
         <div class="sc-e3007680-7 DOuaU"><button type="button" class="sc-b1836745-3 cMVFoJ" onClick={()=>{
            this.setState({EditD:false})
         }}>Cancelar</button>
         <button type="submit" disabled="" class="sc-b1836745-0 gCHqgz" onClick={(e)=>{


const regex = /^((?!www\.|https?:\/\/).)*\.[a-zA-Z]{2,}$/;
if(regex.test(this.state.data.domain) == false){
toast.error("Insira somente letras, números, pontos e traços!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return false;
}

e.target.classList.add("load")

fetch(`${apiUrl}/account/personalizations/domain`, {
credentials: 'include',
method:"PUT",
headers: {
'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
},

body: JSON.stringify({
domain:this.state.data.domain
})
})
.then(response =>{
response.json().then(r=>{
e.target.classList.remove("load")
toast.success("Domínio alterado com sucesso!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
this.setState({EditD:false})
})

})

         }}>Salvar</button></div>
      </div>

</div>

</> : <>
<div class="sc-bb1591e2-4 bpBBvu">
   <div class="sc-e3007680-0 bzplPO">
      <div class="sc-e3007680-1 gtmcSG">
         <a href={`https://${this.state.data.domain}`} target="_blank" rel="noopener noreferrer" class="sc-e3007680-2 gJcckJ">
            <h4>{this.state.data.domain}</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
               <polyline points="15 3 21 3 21 9"></polyline>
               <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
         </a>
         <div class="sc-e3007680-3 likpRe">
            <button type="button" class="sc-b1836745-0 FkBgt" aria-label="Atualizar" title="Atualizar">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <polyline points="1 20 1 14 7 14"></polyline>
                  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
               </svg>
            </button>
            <button type="button" aria-label="Editar" class="sc-b1836745-0 jicnTG" onClick={()=>{
                this.setState({EditD:true})
            }}>Editar</button>
         </div>
      </div>
      <div class="sc-e3007680-8 dLKMBq"></div>
      <p>Insira os registros abaixo no DNS do seu domínio para continuar.</p>
      <table class="sc-e3007680-9 hyNOsI">
         <thead>
            <tr>
               <th>Tipo</th>
               <th>Nome</th>
               <th>Valor</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td><span class="label">Tipo:</span><span>A</span></td>
               <td><span class="label">Nome:</span><span>@</span></td>
               <td>
                  <span class="label">Valor:</span>
                  <div class="sc-e3007680-10 iiBHXR">
                     <span>217.196.61.56</span>
                     <button type="button" class="sc-e3007680-11 csuvxJ" onClick={()=>{
                         copy('217.196.61.56')
                         .then(() => {
                            toast.info('Texto copiado para área de transferência', {
                               position: "top-center",
                               autoClose: 3000, // Fecha automaticamente após 3 segundos
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                             });
                         })
                         .catch(err => {
                           console.error('Falha ao copiar: ', err);
                         });
                     }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                           <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                        </svg>
                        <span>Copiar</span>
                     </button>
                  </div>
                 
               </td>
            </tr>
            <tr>
               <td><span class="label">Tipo:</span><span>A</span></td>
               <td><span class="label">Nome:</span><span>www</span></td>
               <td>
                  <span class="label">Valor:</span>
                  <div class="sc-e3007680-10 iiBHXR">
                     <span>217.196.61.56</span>
                     <button type="button" class="sc-e3007680-11 csuvxJ" onClick={()=>{
                         copy('217.196.61.56')
                         .then(() => {
                            toast.info('Texto copiado para área de transferência', {
                               position: "top-center",
                               autoClose: 3000, // Fecha automaticamente após 3 segundos
                               hideProgressBar: false,
                               closeOnClick: true,
                               pauseOnHover: true,
                               draggable: true,
                               progress: undefined,
                             });
                         })
                         .catch(err => {
                           console.error('Falha ao copiar: ', err);
                         });
                     }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                           <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                        </svg>
                        <span>Copiar</span>
                     </button>
                  </div>
                 
               </td>
            </tr>
         </tbody>
      </table>
      <div class="sc-e3007680-12 ixsObR">
         <p>Dependendo do seu provedor pode demorar algum tempo para as mudanças serem aplicadas.</p>
      </div>
   </div>
</div>

</>}



                </> : <>
                <div class="sc-bb1591e2-4 bpBBvu">
                    
                    <fieldset class="sc-56eec735-1 bSrfIZ">
                       <div class="sc-2d577abe-0 jYSPyC"><span>Domínio<small> • Opcional</small></span></div>
                       <div class="sc-56eec735-2 bBSNUk"><input name="domain" id="domain" placeholder="example.com" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                        this.setState({dominio:e.target.value})
                       }} value={this.state.dominio}/></div>
                    </fieldset>
                    <div class="sc-bb1591e2-11 hzcFfQ">
                    <button type="submit" disabled="" class="sc-b1836745-0 gvxWYo" aria-label="Adicionar" onClick={(e)=>{

const regex = /^((?!www\.|https?:\/\/).)*\.[a-zA-Z]{2,}$/;
if(regex.test(this.state.dominio) == false){
toast.error("Insira somente letras, números, pontos e traços!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return false;
}

e.target.classList.add("load")

fetch(`${apiUrl}/account/personalizations/domain`, {
credentials: 'include',
method:"POST",
headers: {
'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
},

body: JSON.stringify({
domain:this.state.dominio
})
})
.then(response =>{
response.json().then(r=>{
e.target.classList.remove("load")


let newData = { ...this.state.data };
newData.domain_verified = 'true';
newData.domain = r.domain;
this.setState({ data: newData });
toast.success("Domínio adicionado com sucesso!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });




})
})






                    }}>Adicionar</button></div>
               
              </div>
                </>}
                 

                <br/>
               
               </div>
            </div>
            <hr/>
            <div class="sc-bb1591e2-7 khSMF">
               <h1 class="sc-bb1591e2-8 hEqGkD">Logotipo</h1>
               <button type="button" className={!this.state.cLogo ? "sc-b9946f39-0 kzGWdA" : "sc-b9946f39-0 jGLsDr"} onClick={()=>{
                this.setState({cLogo:!this.state.cLogo})
               }}>
                  <div><span>Logotipo</span></div>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="6 9 12 15 18 9"></polyline>
                     </svg>
                  </div>
               </button>
{this.state.cLogo && <>
    <div class="sc-b9946f39-1 jiHENQ">
                {this.state.data.logo != null && this.state.data.logo != "" ? <>
                    <div class="sc-c97ec1e1-5 deIjZN">
   <div>
      <div class="sc-c97ec1e1-6 dfjZlh"><img src={`${apiUrl}/static/${this.state.data.logo}`} alt="Logo" width="90" height="90" class=""/></div>
   </div>
   <div class="sc-c97ec1e1-7 gyGRxw">
      <input accept="image/*,.jpeg,.jpg,.png" multiple="" type="file" style={{display:'none'}} tabindex="-1"/>
      <button type="button" aria-label="Alterar" class="sc-b1836745-0 chwymp" onClick={()=>{
        this.iptUpl.current.click()
      }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
         </svg>
         Alterar
      </button>
      <button type="button" aria-label="Remover"  class="sc-b1836745-3 kVgHKd" onClick={()=>{
         Modal.create({titulo:"Remover logo",html:<>
         <p>Você tem certeza que deseja remover a logo atual?<br/><br/>Esta ação não pode ser desfeita.</p>
         </>,btn:<>
         <div class="sc-955b9eec-6 iHWLZo"><button type="button" class="sc-b1836745-0 eXFuRH" onClick={(e)=>{
           e.target.classList.add("load")
           fetch(`${apiUrl}/account/personalizations/logo`, {
                 credentials: 'include',
                 method:"DELETE",
                 headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                 }
                 })
                 .then(response =>{{
                     let newData = { ...this.state.data };
                     newData.logo = null;
                     e.target.classList.remove("load")
                     this.setState({ data: newData });    
                     toast.success("Logo removido com sucesso!", {
                         position: "top-center",
                         autoClose: 3000, // Fecha automaticamente após 3 segundos
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                       });             
Modal.close()
                 }
             })
         }}>Remover</button></div>
         </>})
      }}>Remover</button>
   </div>
   
</div>
<div class="sc-c97ec1e1-9 jrMuSm"></div>
<div><span class="sc-c97ec1e1-8 cXYVwq"><b>Tipo:</b> JPG, JPEG ou PNG</span><span class="sc-c97ec1e1-8 cXYVwq"><b>Tamanho máximo:</b> 3MB</span></div>
                </> : <>
                <div role="presentation" tabindex="0" class="sc-c97ec1e1-0 jqyYjF dropzone"  onClick={()=>{
                    this.iptUpl.current.click()
                  }}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 161 161" width="75" height="75">
                        <defs>
                           <linearGradient id="P" gradientUnits="userSpaceOnUse"></linearGradient>
                           <linearGradient id="g1" x1="80.5" y1="40.3" x2="80.5" y2="120.8" href="#P">
                              <stop stop-color="#fffbf9"></stop>
                              <stop offset="1" stop-color="#ffd5a9"></stop>
                           </linearGradient>
                           <linearGradient id="g2" x1="49.5" y1="60.2" x2="49.5" y2="44.7" href="#P">
                              <stop stop-color="#fb8b00"></stop>
                              <stop offset="1" stop-color="#ffa029"></stop>
                           </linearGradient>
                           <linearGradient id="g3" x1="45" y1="145.3" x2="45" y2="113.2" href="#P">
                              <stop stop-color="#ffd5a9"></stop>
                              <stop offset="1" stop-color="#ffc890"></stop>
                           </linearGradient>
                           <linearGradient id="g4" x1="83" y1="143" x2="83" y2="107" href="#P">
                              <stop stop-color="#fb8b00"></stop>
                              <stop offset="1" stop-color="#ffa029"></stop>
                           </linearGradient>
                        </defs>
                        <path fill="url(#g1)" d="m34 0h93c8.3 0 15 6.7 15 15v131c0 8.3-6.7 15-15 15h-93c-8.3 0-15-6.7-15-15v-131c0-8.3 6.7-15 15-15z"></path>
                        <path fill="url(#g2)" d="m49.5 37c8.6 0 15.5 6.9 15.5 15.5 0 8.6-6.9 15.5-15.5 15.5-8.6 0-15.5-6.9-15.5-15.5 0-8.6 6.9-15.5 15.5-15.5z"></path>
                        <path fill="url(#g3)" d="m19 147c0-0.3 0-40.7 0-41 15.3-12.1 21.2-13.6 38.8 7.1 10 12 13.2 40.9 13.2 40.9 0 0-52 20.5-52-7z"></path>
                        <path fill="url(#g4)" d="m24 158c0 0 11.2-20.2 27.8-41 34.2-42.5 42-31.7 79.5 0 3.9 3.9 7.3 7.3 10.7 11 0 0.5 0 18.5 0 19 0 6-5 14-14 14-10.8 0-91.8 0-95 0-4.8 0-9-3-9-3z"></path>
                     </svg>
                     <h4 class="sc-c97ec1e1-1 haieRD">Arraste a imagem aqui, ou clique para procurar</h4>
                     <span class="sc-c97ec1e1-2 ftAmmv">Imagem JPG, JPEG ou PNG - Tamanho máximo de 3MB</span>
                  </div>
                </>}
                

               </div>
</>}
              

               <button type="button" className={!this.state.cFavi ? "sc-b9946f39-0 kzGWdA" : "sc-b9946f39-0 jGLsDr"} onClick={()=>{
                this.setState({cFavi:!this.state.cFavi})
               }}>
                  <div><span>Favicon</span></div>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="6 9 12 15 18 9"></polyline>
                     </svg>
                  </div>
               </button>
               {this.state.cFavi && <>
                <div class="sc-b9946f39-1 jiHENQ">
   
   
   <div class="sc-c97ec1e1-11 jwhKPU">
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
         </svg>
      </div>
      <p class="sc-c97ec1e1-12 iDHyIo">Favicon é o ícone que aparece na aba do navegador, ao lado do título da página.</p>
   </div>

   <div style={{marginBottom:'32px'}} class="sc-c97ec1e1-11 jwhKPU">
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 3 9 15"></path>
            <path d="M12 3H3v18h18v-9"></path>
            <path d="M16 3h5v5"></path>
            <path d="M14 15H9v-5"></path>
         </svg>
      </div>
      <p class="sc-c97ec1e1-12 iDHyIo">Tamanho recomendado de 32x32px.</p>
   </div>
   {this.state.data.favicon != null && this.state.data.favicon != "" ? <>
<div class="sc-c97ec1e1-5 deIjZN">
   <div>
      <div class="sc-c97ec1e1-6 dfjZlh"><img src={`${apiUrl}/static/${this.state.data.favicon}`} alt="Logo" width="90" height="90" class=""/></div>
   </div>
   <div class="sc-c97ec1e1-7 gyGRxw">
     
      <button type="button" aria-label="Alterar" class="sc-b1836745-0 chwymp" onClick={()=>{
        this.faviUpl.current.click()
      }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
         </svg>
         Alterar
      </button>
      <button type="button" aria-label="Remover"  class="sc-b1836745-3 kVgHKd" onClick={()=>{
         Modal.create({titulo:"Remover logo",html:<>
         <p>Você tem certeza que deseja remover a logo atual?<br/><br/>Esta ação não pode ser desfeita.</p>
         </>,btn:<>
         <div class="sc-955b9eec-6 iHWLZo"><button type="button" class="sc-b1836745-0 eXFuRH" onClick={(e)=>{
           e.target.classList.add("load")
           fetch(`${apiUrl}/account/personalizations/favicon`, {
                 credentials: 'include',
                 method:"DELETE",
                 headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                 }
                 })
                 .then(response =>{{
                     let newData = { ...this.state.data };
                     newData.favicon = null;
                     e.target.classList.remove("load")
                     this.setState({ data: newData });    
                     toast.success("favicon removido com sucesso!", {
                         position: "top-center",
                         autoClose: 3000, // Fecha automaticamente após 3 segundos
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                       });             
Modal.close()
                 }
             })
         }}>Remover</button></div>
         </>})
      }}>Remover</button>
   </div>
   
</div>
   </> : <>
   <div role="presentation" tabindex="0" class="sc-c97ec1e1-0 jqyYjF dropzone"  onClick={()=>{
                       this.faviUpl.current.click()
                     }}>
           <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 161 161" width="75" height="75">
            <defs>
               <linearGradient id="g1" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0,80.5,-80.5,0,80.5,40.3)">
                  <stop offset="0" stop-color="#fffbf9"></stop>
                  <stop offset="1" stop-color="#ffd5a9"></stop>
               </linearGradient>
               <linearGradient id="g2" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0,-15.5,15.5,0,49.5,60.2)">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffa029"></stop>
               </linearGradient>
               <linearGradient id="g3" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0,-36.055,48.013,0,96.207,143.083)">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffa029"></stop>
               </linearGradient>
               <linearGradient id="g4" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0,-30.453,37.312,0,43.9,145.801)">
                  <stop offset="0" stop-color="#ffd5a9"></stop>
                  <stop offset="1" stop-color="#ffc890"></stop>
               </linearGradient>
            </defs>
            <path id="Layer" fill="url(#g1)" d="m0 80.5c0-44.5 36-80.5 80.5-80.5 44.5 0 80.5 36 80.5 80.5 0 44.5-36 80.5-80.5 80.5-44.5 0-80.5-36-80.5-80.5z"></path>
            <path id="Layer" fill="url(#g2)" d="m49.5 37c8.6 0 15.5 6.9 15.5 15.5 0 8.6-6.9 15.5-15.5 15.5-8.6 0-15.5-6.9-15.5-15.5 0-8.6 6.9-15.5 15.5-15.5z"></path>
            <path id="Layer" fill="url(#g3)" d="m81.6 161c0 0-12.1-6.1-21.8-14.8-9.2-8.3-16.1-19-8-29.2 34.2-42.5 42-31.7 79.5 0 3.9 3.9 9.5 9.3 12.9 13 0 0.2-7.6 12.6-28.8 23.8-14.6 8.8-33.8 7.2-33.8 7.2z"></path>
            <path id="Layer" fill="url(#g4)" d="m31.2 145.4c-21.4-18.9-24.6-33.1-24.6-33.4 15.3-12.1 33.6-19.6 51.2 1.1 10 12 23.4 47.9 23.4 47.9 0 0-31 1.2-50-15.6z"></path>
         </svg>
         <h4 class="sc-c97ec1e1-1 haieRD">Arraste a imagem aqui, ou clique para procurar</h4>
         <span class="sc-c97ec1e1-2 ftAmmv">Imagem JPG, JPEG ou PNG - Tamanho máximo de 3MB</span>
      </div>
   </> }
 

</div>

               </>}
            </div>
            <hr/>
            <div class="sc-bb1591e2-7 khSMF">
               <h1 class="sc-bb1591e2-8 hEqGkD">Nome fantasia</h1>
             
                  <div class="sc-bb1591e2-3 iUBqHp margin-top">
                     <div class="sc-bb1591e2-4 bpBBvu">
                        <fieldset class="sc-56eec735-1 hDxYJw">
                           <div class="sc-2d577abe-0 jYSPyC"><span>Nome<small> • Opcional</small></span></div>
                           <div class="sc-56eec735-2 bBSNUk">
                        <input name="fantasy_name" id="fantasy_name" placeholder="Digite o nome do seu negócio" class="sc-56eec735-0 jDba-dm" onChange={(e)=>{



                        }} ref={this.iptF}/>
                        </div>
                        </fieldset>
                     </div>
                  </div>
                <div class="sc-bb1591e2-11 hzcFfQ">
                    {this.state.data.fantasy_name == "" ? <>
                    <button type="submit"  class="sc-b1836745-0 eANjXa" onClick={(e)=>{

if(this.iptF.current.value.length == 0){
    toast.error("Insira no mínimo 3 caracteres!", {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return false;
}



if(this.iptF.current.value.trim().length <= 2){
    toast.error("Insira no mínimo 3 caracteres!", {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return false;
}

e.target.classList.add("load")
fetch(`${apiUrl}/account/personalizations`, {
    credentials: 'include',
    method:"PUT",
    headers: {
    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
    },
    
    body: JSON.stringify({
    fantasy_name:this.iptF.current.value
    })
    })
    .then(response =>{
        e.target.classList.remove("load")
        let newData = { ...this.state.data };


        toast.success("Nome fantasia salvo com sucesso!", {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        
newData.domain_verified = 'true';
newData.fantasy_name = this.iptF.current.value;
this.setState({ data: newData });
    })


                    }}>Adicionar</button>
                    </> : <>
                    <button type="submit"  class="sc-b1836745-0 eANjXa" onClick={(e)=>{

if(this.iptF.current.value.length == 0){
    toast.error("Insira no mínimo 3 caracteres!", {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return false;
}



if(this.iptF.current.value.trim().length <= 2){
    toast.error("Insira no mínimo 3 caracteres!", {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return false;
}

e.target.classList.add("load")
fetch(`${apiUrl}/account/personalizations`, {
    credentials: 'include',
    method:"PUT",
    headers: {
    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
    },
    
    body: JSON.stringify({
    fantasy_name:this.iptF.current.value
    })
    })
    .then(response =>{
        e.target.classList.remove("load")
        let newData = { ...this.state.data };


        toast.success("Nome fantasia alterado com sucesso!", {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        
newData.domain_verified = 'true';
newData.fantasy_name = this.iptF.current.value;
this.setState({ data: newData });
    })


                    }}>Salvar alterações</button>
                    <button type="button" class="sc-b1836745-3 cMVFoJ" onClick={()=>{
            Modal.create({titulo:"Remover nome",html:<>
            <p>Você tem certeza que deseja remover o nome fantasia?</p>
            </>,btn:<>
            <div class="sc-955b9eec-6 iHWLZo"><button type="button" class="sc-b1836745-0 eXFuRH" onClick={(e)=>{
              e.target.classList.add("load")
              fetch(`${apiUrl}/account/personalizations`, {
                    credentials: 'include',
                    method:"PUT",
                    headers: {
                    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                    },
                    body:JSON.stringify({
                        fantasy_name:'',
                    })
                    })
                    .then(response =>{{
                        let newData = { ...this.state.data };
                      
                        newData.fantasy_name = '';
                        e.target.classList.remove("load")
                        this.setState({ data: newData }); 
                        this.iptF.current.value = ''   
                        toast.success("Nome removido com sucesso!", {
                            position: "top-center",
                            autoClose: 3000, // Fecha automaticamente após 3 segundos
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });             
Modal.close()
                    }
                })
            }}>Remover</button></div>
            </>})
         }}>Remover</button>
                    </> }
                
                </div>
            
            </div>
         </>)
      case 'b':
         return(<>
         
   <div class="sc-bb1591e2-7 khSMF">
      <h1 class="sc-bb1591e2-8 hEqGkD">Aparência</h1>
 
         <div class="sc-bb1591e2-3 iUBqHp">
            <div class="sc-bb1591e2-4 bpBBvu">
               <fieldset class="sc-6080f731-0 gXqUOM">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Slide</span>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="slide_style-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>

                  <div class="sc-6080f731-1 hTQEjb">
                     <label class="sc-6080f731-2 iCxOwm">
                        <input name="slide_style" type="radio" id="slide_style" class="sc-6080f731-6 hYweKm" 
                        onClick={()=>{
                       
                           let newData = { ...this.state.data };
                     
                           newData.slide_style = 0;
                           this.setState({ data: newData });
    
                         }} 
                        checked={this.state.data.slide_style == 0 ? true : false} value="default"/>
                        <div class="sc-6080f731-5 dAuHDp icon-container">
                           <div class="sc-6080f731-7 kHCtKr">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 737 362" width="340" height="167">
                                 <path fill="#e3e6eb" d="m15 0h406c8.3 0 15 6.7 15 15v270c0 8.3-6.7 15-15 15h-406c-8.3 0-15-6.7-15-15v-270c0-8.3 6.7-15 15-15z"></path>
                                 <g>
                                    <path fill="none" stroke="#c3c8d1" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m195.4 119.4h44.2c4.4 0 8 3.6 8 8v44.2c0 4.4-3.6 8-8 8h-44.2c-4.4 0-8-3.6-8-8v-44.2c0-4.4 3.6-8 8-8z"></path>
                                    <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m514.5 172.1"></path>
                                    <path fill="none" stroke="#c3c8d1" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m205.8 142.8c-2.8 0-5-2.2-5-5 0-2.8 2.2-5 5-5 2.8 0 5 2.2 5 5 0 2.8-2.2 5-5 5z"></path>
                                    <path fill="none" stroke="#c3c8d1" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m247.6 159.5l-16.7-16.7-36.8 36.8"></path>
                                 </g>
                                 <g>
                                    <path fill="#e3e6eb" d="m483 0h239c8.3 0 15 6.7 15 15v332c0 8.3-6.7 15-15 15h-239c-8.3 0-15-6.7-15-15v-332c0-8.3 6.7-15 15-15z"></path>
                                    <g>
                                       <path fill="#c3c8d1" d="m509 31h151c5.5 0 10 4.5 10 10v9c0 5.5-4.5 10-10 10h-151c-5.5 0-10-4.5-10-10v-9c0-5.5 4.5-10 10-10z"></path>
                                       <path fill="#c3c8d1" d="m504 70h76c2.8 0 5 2.2 5 5v3c0 2.8-2.2 5-5 5h-76c-2.8 0-5-2.2-5-5v-3c0-2.8 2.2-5 5-5z"></path>
                                    </g>
                                    <g>
                                       <path fill="#c3c8d1" d="m504 116h128c2.8 0 5 2.2 5 5v4c0 2.8-2.2 5-5 5h-128c-2.8 0-5-2.2-5-5v-4c0-2.8 2.2-5 5-5z"></path>
                                       <path fill="#c3c8d1" d="m506 141h193c3.9 0 7 3.1 7 7v53c0 3.9-3.1 7-7 7h-193c-3.9 0-7-3.1-7-7v-53c0-3.9 3.1-7 7-7z"></path>
                                    </g>
                                    <g>
                                       <path fill="#c3c8d1" d="m504 251h99c2.8 0 5 2.2 5 5v4c0 2.8-2.2 5-5 5h-99c-2.8 0-5-2.2-5-5v-4c0-2.8 2.2-5 5-5z"></path>
                                       <path fill="#c3c8d1" d="m506 276h84c3.9 0 7 3.1 7 7v10c0 3.9-3.1 7-7 7h-84c-3.9 0-7-3.1-7-7v-10c0-3.9 3.1-7 7-7z"></path>
                                       <path fill="#c3c8d1" d="m506 307h95c3.9 0 7 3.1 7 7v10c0 3.9-3.1 7-7 7h-95c-3.9 0-7-3.1-7-7v-10c0-3.9 3.1-7 7-7z"></path>
                                       <path fill="#c3c8d1" d="m615 276h84c3.9 0 7 3.1 7 7v10c0 3.9-3.1 7-7 7h-84c-3.9 0-7-3.1-7-7v-10c0-3.9 3.1-7 7-7z"></path>
                                    </g>
                                 </g>
                              </svg>
                           </div>
                        </div>
                        <div class="sc-6080f731-3 hexPUj radio-content">
                           <div class="sc-6080f731-4 ihregp">
                              <h3>Padrão</h3>
                              <p>Slide na esquerda, com informações na direita</p>
                           </div>
                        </div>
                     </label>

                     <label class="sc-6080f731-2 iCxOwm">
                        <input name="slide_style" type="radio" id="slide_style" class="sc-6080f731-6 hYweKm" 
                                     onClick={()=>{
                       
                                       let newData = { ...this.state.data };
                                 
                                       newData.slide_style = 1;
                                       this.setState({ data: newData });
                
                                     }} 
                        checked={this.state.data.slide_style == 1 ? true : false} value="large"/>
                        <div class="sc-6080f731-5 dAuHDp icon-container">
                           <div class="sc-6080f731-7 kHCtKr">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 737 362" width="340" height="167">
                                 <g>
                                    <path fill="#c3c8d1" d="m59.3 0h202.9v139h-202.9z"></path>
                                    <g>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m144.7 58.4c0-2.7 2.2-5 5-5h22.7c2.7 0 5 2.3 5 5v22.7c0 2.7-2.3 5-5 5h-22.7c-2.8 0-5-2.3-5-5z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m154.7 66.1c-1.5 0-2.8-1.2-2.8-2.7 0-1.5 1.3-2.7 2.8-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m177.4 75.2l-9.1-9.1-20 20"></path>
                                    </g>
                                 </g>
                                 <g>
                                    <path fill="#c3c8d1" d="m267.1 0h202.8v139h-202.8z"></path>
                                    <g>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m352.4 58.4c0-2.7 2.3-5 5-5h22.7c2.8 0 5 2.3 5 5v22.7c0 2.7-2.2 5-5 5h-22.7c-2.7 0-5-2.3-5-5z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m362.4 66.1c-1.5 0-2.7-1.2-2.7-2.7 0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m385.1 75.2l-9.1-9.1-19.9 20"></path>
                                    </g>
                                 </g>
                                 <g>
                                    <path fill="#c3c8d1" d="m474.8 0h202.9v139h-202.9z"></path>
                                    <g>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m559.6 57.8c0-2.8 2.2-5 5-5h22.6c2.8 0 5 2.2 5 5v22.7c0 2.7-2.2 5-5 5h-22.6c-2.8 0-5-2.3-5-5z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m569.6 65.5c-1.5 0-2.8-1.2-2.8-2.7 0-1.5 1.3-2.7 2.8-2.7 1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7z"></path>
                                       <path fill="none" stroke="#e6eaf0" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m592.2 74.6l-9-9.1-20 20"></path>
                                    </g>
                                 </g>
                                 <g>
                                    <g>
                                       <path fill="#e6eaf0" d="m392.8 133.8c0-8.3 6.7-15 15-15h156.2c8.3 0 15 6.7 15 15v154.1c0 8.3-6.7 15-15 15h-156.2c-8.3 0-15-6.7-15-15z"></path>
                                       <g>
                                          <path fill="#c3c8d1" d="m414.3 145.2c0-2.7 2.2-4.9 4.8-4.9h85.8c2.7 0 4.8 2.2 4.8 4.9 0 2.7-2.1 4.8-4.8 4.8h-85.8c-2.6 0-4.8-2.1-4.8-4.8z"></path>
                                          <path fill="#c3c8d1" d="m414.3 164.6c0-3.8 3.1-7 7-7h129.2c3.9 0 7 3.2 7 7v32.4c0 3.9-3.1 7-7 7h-129.2c-3.9 0-7-3.1-7-7z"></path>
                                       </g>
                                       <g>
                                          <path fill="#c3c8d1" d="m413.7 231.4c0-2.6 2.1-4.8 4.8-4.8h65.6c2.7 0 4.9 2.2 4.9 4.8 0 2.7-2.2 4.9-4.9 4.9h-65.6c-2.7 0-4.8-2.2-4.8-4.9z"></path>
                                          <path fill="#c3c8d1" d="m414.3 250.9c0-3.8 3.2-7 7-7h53.8c3.8 0 7 3.2 7 7v2.7c0 3.9-3.2 7-7 7h-53.8c-3.8 0-7-3.1-7-7z"></path>
                                          <path fill="#c3c8d1" d="m414.3 272.4c0-3.8 3.2-7 7-7h61.4c3.8 0 7 3.2 7 7v2.7c0 3.9-3.2 7-7 7h-61.4c-3.8 0-7-3.1-7-7z"></path>
                                          <path fill="#c3c8d1" d="m489.7 250.9c0-3.8 3.1-7 7-7h53.7c3.9 0 7 3.2 7 7v2.7c0 3.9-3.1 7-7 7h-53.7c-3.9 0-7-3.1-7-7z"></path>
                                       </g>
                                    </g>
                                    <g>
                                       <path fill="#e6eaf0" d="m158 133.8c0-8.3 6.7-15 15-15h181.2c8.3 0 15 6.7 15 15v213.2c0 8.3-6.7 15-15 15h-181.2c-8.3 0-15-6.7-15-15z"></path>
                                       <g>
                                          <path fill="#c3c8d1" d="m178 150.3c0-5.5 4.4-10 9.9-10h121c5.5 0 10 4.5 10 10v0.1c0 5.5-4.5 10-10 10h-121c-5.5 0-9.9-4.5-9.9-10z"></path>
                                          <path fill="#c3c8d1" d="m178 171.8c0-2.5 2-4.5 4.5-4.5h61.8c2.5 0 4.5 2 4.5 4.5 0 2.5-2 4.5-4.5 4.5h-61.8c-2.5 0-4.5-2-4.5-4.5z"></path>
                                       </g>
                                       <g>
                                          <path fill="#c3c8d1" d="m178.1 204.3c0-2.7 2.2-4.9 4.9-4.9h64.6c2.7 0 4.9 2.2 4.9 4.9 0 2.7-2.2 4.8-4.9 4.8h-64.6c-2.7 0-4.9-2.1-4.9-4.8z"></path>
                                          <path fill="#c3c8d1" d="m178.1 223.8c0-3.9 3.1-7 7-7h156.3c3.8 0 6.9 3.1 6.9 7v9.6c0 3.9-3.1 7-6.9 7h-156.3c-3.9 0-7-3.1-7-7z"></path>
                                          <path fill="#c3c8d1" d="m178.1 251.6c0-3.9 3.1-7 7-7h134.7c3.9 0 7 3.1 7 7v9.6c0 3.9-3.1 7-7 7h-134.7c-3.9 0-7-3.1-7-7z"></path>
                                       </g>
                                       <g>
                                          <path fill="#c3c8d1" d="m177.4 290.4c0-2.7 2.2-4.8 4.9-4.8h104.9c2.7 0 4.9 2.1 4.9 4.8 0 2.7-2.2 4.9-4.9 4.9h-104.9c-2.7 0-4.9-2.2-4.9-4.9z"></path>
                                          <path fill="#c3c8d1" d="m178.1 309.9c0-3.8 3.2-7 7-7h118.8c3.8 0 6.9 3.2 6.9 7v2.7c0 3.9-3.1 7-6.9 7h-118.8c-3.8 0-7-3.1-7-7z"></path>
                                          <path fill="#c3c8d1" d="m178 331.5c0-3.9 3.2-7 7-7h75.8c3.8 0 7 3.1 7 7v2.7c0 3.8-3.2 6.9-7 6.9h-75.8c-3.8 0-7-3.1-7-6.9z"></path>
                                       </g>
                                    </g>
                                 </g>
                              </svg>
                           </div>
                        </div>
                        <div class="sc-6080f731-3 hexPUj radio-content">
                           <div class="sc-6080f731-4 ihregp">
                              <h3>Amplo</h3>
                              <p>Slide grande mostrando 3 imagens por vez</p>
                           </div>
                        </div>
                     </label>
                  </div>

               </fieldset>
               <fieldset class="sc-262cfb40-0 cjuEqU">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Informações</span></div>
                  <label>
                     <input name="informations_style" type="radio" id="informations-style" class="sc-262cfb40-8 iDmPEq" onClick={()=>{
                       
                       let newData = { ...this.state.data };
                 
                       newData.informations_style = 0;
                       this.setState({ data: newData });

                     }}  checked={this.state.data.informations_style == 0 ? true : false}  value="default" />
                     <div class="sc-262cfb40-1 kPSVld">
                        <div class="sc-262cfb40-2 jhenhz">
                           <div class="sc-262cfb40-4 blqqI icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <circle cx="12" cy="6" r="2"></circle>
                                 <line x1="5" y1="12" x2="19" y2="12"></line>
                                 <circle cx="12" cy="18" r="2"></circle>
                              </svg>
                           </div>
                           <h3 class="sc-262cfb40-3 OJeJK">Dividido</h3>
                           <div class="sc-262cfb40-6 gPuqcB">
                              <div class="sc-262cfb40-7 gLgCDf option"></div>
                           </div>
                        </div>
                        <div class="sc-262cfb40-5 cwUvaj"><span>Informações principais no topo, e prêmio(s), descrição e rifinhas no final da página</span></div>
                     </div>
                  </label>
                  <label>
                     <input name="informations_style" type="radio" id="informations-style" class="sc-262cfb40-8 iDmPEq" onClick={()=>{
                       
                       let newData = { ...this.state.data };
                 
                       newData.informations_style = 1;
                       this.setState({ data: newData });

                     }} checked={this.state.data.informations_style == 1 ? true : false} value="top"/>
                     <div class="sc-262cfb40-1 kPSVld">
                        <div class="sc-262cfb40-2 jhenhz">
                           <div class="sc-262cfb40-4 blqqI icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <line x1="12" y1="19" x2="12" y2="5"></line>
                                 <polyline points="5 12 12 5 19 12"></polyline>
                              </svg>
                           </div>
                           <h3 class="sc-262cfb40-3 OJeJK">Topo</h3>
                           <div class="sc-262cfb40-6 gPuqcB">
                              <div class="sc-262cfb40-7 gLgCDf option"></div>
                           </div>
                        </div>
                        <div class="sc-262cfb40-5 cwUvaj"><span>Todas as informações principais no topo, e as rifinhas no final da página</span></div>
                     </div>
                  </label>
               </fieldset>
               <fieldset class="sc-beb28085-0 jjbYZT">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Tema</span></div>
                  <div class="sc-beb28085-5 drthLI">
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="theme" type="radio" class="sc-beb28085-4 vkkOm" 
                                     onClick={()=>{
                       
                                       let newData = { ...this.state.data };
                                 
                                       newData.theme = 'claro';
                                       this.setState({ data: newData });
                
                                     }} 
                        checked={this.state.data.theme == 'claro' ? true : false} value="light"/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Claro</span>
                     </label>
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="theme" type="radio" class="sc-beb28085-4 vkkOm"
                                 onClick={()=>{
                       
                                    let newData = { ...this.state.data };
                              
                                    newData.theme = 'escuro';
                                    this.setState({ data: newData });
             
                                  }} 
                        checked={this.state.data.theme == 'escuro' ? true : false} value="dark"/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Escuro</span>
                     </label>
                  </div>
               </fieldset>
            
               <fieldset class="sc-beb28085-0 jjbYZT">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Posição dos bilhetes premiados</span>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucky_numbers_position-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-beb28085-5 drthLI">
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="lucky_numbers_position" type="radio" class="sc-beb28085-4 vkkOm"
                              onClick={()=>{
                       
                                 let newData = { ...this.state.data };
                           
                                 newData.lucky_numbers_position = 1;
                                 this.setState({ data: newData });
          
                               }} 
                        checked={this.state.data.lucky_numbers_position == 1 ? true : false} value="top"/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Topo</span>
                     </label>
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="lucky_numbers_position" type="radio" class="sc-beb28085-4 vkkOm" value="bottom" 
                                    onClick={()=>{
                       
                                       let newData = { ...this.state.data };
                                 
                                       newData.lucky_numbers_position = 0;
                                       this.setState({ data: newData });
                
                                     }} 
                        checked={this.state.data.lucky_numbers_position == 0 ? true : false}/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Final</span>
                     </label>
                  </div>
               </fieldset>
               <fieldset class="sc-beb28085-0 hLbCq">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Posição dos maiores compradores</span>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="top_buyers_position-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>
                  
                  <div class="sc-beb28085-5 drthLI">
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="top_buyers_position" type="radio" class="sc-beb28085-4 vkkOm" 
                                    onClick={()=>{
                       
                                       let newData = { ...this.state.data };
                                 
                                       newData.top_buyers_position = 1;
                                       this.setState({ data: newData });
                
                                     }} 
                        checked={this.state.data.top_buyers_position == 1 ? true : false}/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Topo</span>
                     </label>
                     <label class="sc-beb28085-3 BwOjI">
                        <input name="top_buyers_position" type="radio" class="sc-beb28085-4 vkkOm" 
                                    onClick={()=>{
                       
                                       let newData = { ...this.state.data };
                                 
                                       newData.top_buyers_position = 0;
                                       this.setState({ data: newData });
                
                                     }} 
                        checked={this.state.data.top_buyers_position == 0 ? true : false}/>
                        <div size="26" class="sc-beb28085-2 fbiliO"></div>
                        <span class="sc-beb28085-1 kQlWFo">Final</span>
                     </label>
                  </div>
               </fieldset>
            </div>
         </div>
         <div class="sc-bb1591e2-11 hzcFfQ"><button type="submit" class="sc-b1836745-0 gCHqgz" aria-label="Salvar alterações" onClick={(e)=>{
          e.target.classList.add("load")


          fetch(`${apiUrl}/account/personalizations/aparence`, {
            credentials: 'include',
            method:"PUT",
            headers: {
            'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
            'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            },
            
            body: JSON.stringify({
            slide_style:this.state.data.slide_style,
            lucky_numbers_position:this.state.data.lucky_numbers_position,
            theme:this.state.data.theme,
            informations_style:this.state.data.informations_style,
            top_buyers_position:this.state.data.top_buyers_position
            })
            })
            .then(response =>{
if(response.status == 200){
   e.target.classList.remove("load")
   toast.success("Aparência atualizada com sucesso!", {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
}
            })



         }}>Salvar alterações</button></div>

   </div>



         
         </>)
      case 'c':
         return(<>
<div class="sc-bb1591e2-7 khSMF">
   <h1 class="sc-bb1591e2-8 hEqGkD">Redes sociais</h1>
   <div class="sc-6ec49348-9 crtFOn">
      <p>Ao adicionar uma rede social, os botões para compartilhar a campanha serão substituídos por suas redes sociais.</p>
   </div>
   <div class="sc-bb1591e2-3 iUBqHp">
      <div class="sc-bb1591e2-4 bpBBvu">
        
        {this.state.data.socials.instagram != null ? <>
         <div class="sc-6ec49348-2 hQRHj">
   <div class="sc-6ec49348-4 gbmvEo">
      <div class="sc-6ec49348-5 hRGfs">
         <div>
            <div class="sc-6ec49348-6 jsIPip">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
               </svg>
            </div>
         </div>
         <div>
            <h3>Instagram</h3>
            <p>@{this.state.data.socials.instagram}</p>
            <div class="sc-6ec49348-7 cxMtoM">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="20 6 9 17 4 12"></polyline>
               </svg>
               <span>Adicionado</span>
            </div>
         </div>
      </div>
      <div class="sc-6ec49348-8 ledXBs">
         <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
     
<Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Usuário do Instagram</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              instagram:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.instagram = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.instagram
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>Instagram</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             instagram:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.instagram = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>


         </div>
      </div>
   </div>
</div>

        </> : <>
        <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                     <div class="sc-6ec49348-6 jsIPip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                           <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                           <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                        </svg>
                     </div>
                  </div>
                  <div>
                     <h3>Instagram</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs"><button type="button" class="sc-b1836745-0 jicnTG" onClick={()=>{
                  Modal.create({titulo:"Adicionar rede social",html:<>
             
   <fieldset class="sc-41e32b1a-1 flXygX">
      <div class="sc-2d577abe-0 jYSPyC"><span>Usuário do Instagram</span></div>
      <div class="sc-41e32b1a-2 hZfkxe">
         <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
         <input  placeholder="exemplo" onChange={(e)=>{
            this.setState({instagram:e.target.value})
         }} class="sc-41e32b1a-0 gDQfeo"/>
      </div>
   </fieldset>
   <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
     
     if(this.state.instagram == undefined){
      toast.error("Preencha o campo de nome de usuário!", {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
      return false;
     }

     if(this.state.instagram.trim().length < 3){
      toast.error("Insira no mínimo 3 caracteres!", {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
      return false;
     }
     const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
if(usernameRegex.test(this.state.instagram) == false){
   toast.error("Preencha o campo corretamente!", {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}
     
     e.target.classList.add("load")

     fetch(`${apiUrl}/account/personalizations/socials`, {
      credentials: 'include',
      method:"POST",
      headers: {
      'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
      'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      },
      
      body: JSON.stringify({
      socials:{
         instagram:this.state.instagram
      }
      })
      })
      .then(response =>{

         if(response.status == 200){
            const newData = { ...this.state.data };
            newData.socials.instagram = this.state.instagram;
            this.setState({ data: newData });
            toast.success("Rede social adicionada com sucesso!", {
               position: "top-center",
               autoClose: 3000, // Fecha automaticamente após 3 segundos
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
             Modal.close()
         }


      })






   }}>Adicionar</button></div>


                  </>})
               }}><span>Adicionar</span></button></div>
            </div>
         </div>
        </>}
       
        {this.state.data.socials.facebook != null ? <> 
         <div class="sc-6ec49348-2 hQRHj">      
  <div class="sc-6ec49348-4 gbmvEo">
   <div class="sc-6ec49348-5 hRGfs">
      <div>
         <div class="sc-6ec49348-6 lmpUnM">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
         </div>
      </div>
      <div>
         <h3>Facebook</h3>
         <p>/{this.state.data.socials.facebook}</p>
         <div class="sc-6ec49348-7 cxMtoM">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span>Adicionado</span>
         </div>
      </div>
   </div>
   <div class="sc-6ec49348-8 ledXBs">
      <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
<Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Página do facebook</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              facebook:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.facebook = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.facebook
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>Facebook</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             facebook:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.facebook = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>
      </div>
   </div>
</div>
</div>
        
        </> : <>
        <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                     <div class="sc-6ec49348-6 lmpUnM">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                        </svg>
                     </div>
                  </div>
                  <div>
                     <h3>Facebook</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs">
               <button type="button" class="sc-b1836745-0 jicnTG" onClick={()=>{
                    Modal.create({titulo:"Adicionar rede social",html:<>
             
                    <fieldset class="sc-41e32b1a-1 flXygX">
                       <div class="sc-2d577abe-0 jYSPyC"><span>Página do facebook</span></div>
                       <div class="sc-41e32b1a-2 hZfkxe">
                          <div class="sc-41e32b1a-3 djbJqI"><span>/</span></div>
                          <input  placeholder="exemplo" onChange={(e)=>{
                             this.setState({instagram:e.target.value})
                          }} class="sc-41e32b1a-0 gDQfeo"/>
                       </div>
                    </fieldset>
                    <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
                      
                      if(this.state.instagram == undefined){
                       toast.error("Preencha o campo de nome de usuário!", {
                          position: "top-center",
                          autoClose: 3000, // Fecha automaticamente após 3 segundos
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                       return false;
                      }
                 
                      if(this.state.instagram.trim().length < 3){
                       toast.error("Insira no mínimo 3 caracteres!", {
                          position: "top-center",
                          autoClose: 3000, // Fecha automaticamente após 3 segundos
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                       return false;
                      }
                      const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
                 if(usernameRegex.test(this.state.instagram) == false){
                    toast.error("Preencha o campo corretamente!", {
                       position: "top-center",
                       autoClose: 3000, // Fecha automaticamente após 3 segundos
                       hideProgressBar: false,
                       closeOnClick: true,
                       pauseOnHover: true,
                       draggable: true,
                       progress: undefined,
                     });
                    return false;
                 }
                      
                      e.target.classList.add("load")
                 
                      fetch(`${apiUrl}/account/personalizations/socials`, {
                       credentials: 'include',
                       method:"POST",
                       headers: {
                       'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                       'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                       },
                       
                       body: JSON.stringify({
                       socials:{
                          facebook:this.state.instagram
                       }
                       })
                       })
                       .then(response =>{
                 
                          if(response.status == 200){
                             const newData = { ...this.state.data };
                             newData.socials.facebook = this.state.instagram;
                             this.setState({ data: newData });
                             toast.success("Rede social adicionada com sucesso!", {
                                position: "top-center",
                                autoClose: 3000, // Fecha automaticamente após 3 segundos
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              Modal.close()
                          }
                 
                 
                       })
                 
                 
                 
                 
                 
                 
                    }}>Adicionar</button></div>
                 
                 
                                   </>})
               }}><span>Adicionar</span></button></div>
            </div>
         </div>
        </>}
        {this.state.data.socials.ytb != null ? <> 
         <div class="sc-6ec49348-2 hQRHj">       
<div class="sc-6ec49348-4 gbmvEo">
   <div class="sc-6ec49348-5 hRGfs">
      <div>
      <div class="sc-6ec49348-6 lg-yt">
                     <svg height="800px" width="800px" version="1.1" id="Layer_1" 
	 viewBox="0 0 461.001 461.001" >
<g>
	<path  d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
</g>
</svg>
                     </div>
      </div>
      <div>
         <h3>YouTube</h3>
         <p>@{this.state.data.socials.ytb}</p>
         <div class="sc-6ec49348-7 cxMtoM">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span>Adicionado</span>
         </div>
      </div>
   </div>
   <div class="sc-6ec49348-8 ledXBs">
      <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
      <Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Canal do YouTube</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }<Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Página do facebook</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              facebook:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.facebook = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.facebook
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>Facebook</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             facebook:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.facebook = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              ytb:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.ytb = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.ytb
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>YouTube</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             ytb:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.ytb = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>
      </div>
   </div>
</div>
</div>
        
        </> : <>
        <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                     <div class="sc-6ec49348-6 lg-yt">
                     <svg height="800px" width="800px" version="1.1" id="Layer_1" 
	 viewBox="0 0 461.001 461.001" >
<g>
	<path  d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
</g>
</svg>
                     </div>
                  </div>
                  <div>
                     <h3>YouTube</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs">
            <button type="button" class="sc-b1836745-0 jicnTG" onClick={()=>{
                     Modal.create({titulo:"Adicionar rede social",html:<>
             
                     <fieldset class="sc-41e32b1a-1 flXygX">
                        <div class="sc-2d577abe-0 jYSPyC"><span>Canal do youtube</span></div>
                        <div class="sc-41e32b1a-2 hZfkxe">
                           <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
                           <input  placeholder="exemplo" onChange={(e)=>{
                              this.setState({instagram:e.target.value})
                           }} class="sc-41e32b1a-0 gDQfeo"/>
                        </div>
                     </fieldset>
                     <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
                       
                       if(this.state.instagram == undefined){
                        toast.error("Preencha o campo de nome de usuário!", {
                           position: "top-center",
                           autoClose: 3000, // Fecha automaticamente após 3 segundos
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                         });
                        return false;
                       }
                  
                       if(this.state.instagram.trim().length < 3){
                        toast.error("Insira no mínimo 3 caracteres!", {
                           position: "top-center",
                           autoClose: 3000, // Fecha automaticamente após 3 segundos
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                         });
                        return false;
                       }
                       const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
                  if(usernameRegex.test(this.state.instagram) == false){
                     toast.error("Preencha o campo corretamente!", {
                        position: "top-center",
                        autoClose: 3000, // Fecha automaticamente após 3 segundos
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                     return false;
                  }
                       
                       e.target.classList.add("load")
                  
                       fetch(`${apiUrl}/account/personalizations/socials`, {
                        credentials: 'include',
                        method:"POST",
                        headers: {
                        'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                        'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                        },
                        
                        body: JSON.stringify({
                        socials:{
                           ytb:this.state.instagram
                        }
                        })
                        })
                        .then(response =>{
                  
                           if(response.status == 200){
                              const newData = { ...this.state.data };
                              newData.socials.ytb = this.state.instagram;
                              this.setState({ data: newData });
                              toast.success("Rede social adicionada com sucesso!", {
                                 position: "top-center",
                                 autoClose: 3000, // Fecha automaticamente após 3 segundos
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                               });
                               Modal.close()
                           }
                  
                  
                        })
                  
                  
                  
                  
                  
                  
                     }}>Adicionar</button></div>
                  
                  
                                    </>})
            }}><span>Adicionar</span></button></div>
            </div>
         </div>
        </> }
        


        {this.state.data.socials.tiktok != null ? <> 
        
         <div class="sc-6ec49348-2 hQRHj">       
<div class="sc-6ec49348-4 gbmvEo">
   <div class="sc-6ec49348-5 hRGfs">
      <div>
      <div className="sc-6ec49348-6 irbHWh">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" style={{ zIndex: 2 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#24f6f0" style={{ position: 'absolute', top: '9px', left: '9px', zIndex: 1 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fe2d52" style={{ position: 'absolute', bottom: '9px', right: '9px', zIndex: 0 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
        </div>
      </div>
      <div>
         <h3>TikTok</h3>
         <p>@{this.state.data.socials.tiktok}</p>
         <div class="sc-6ec49348-7 cxMtoM">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span>Adicionado</span>
         </div>
      </div>
   </div>
   <div class="sc-6ec49348-8 ledXBs">
      <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
      <Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Canal do YouTube</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }<Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 hXgqpa">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
               </svg>
            </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 cxnDGU" >
   <div class="sc-cf9fb95-1 iVXJmG"></div>
   <button type="button" onClick={()=>{
        Modal.create({titulo:"Editar",html:<>
             
        <fieldset class="sc-41e32b1a-1 flXygX">
           <div class="sc-2d577abe-0 jYSPyC"><span>Página do facebook</span></div>
           <div class="sc-41e32b1a-2 hZfkxe">
              <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
              <input  placeholder="exemplo" ref={this.iptS} onChange={(e)=>{
              this.iptS.current.value = e.target.value;
              }} class="sc-41e32b1a-0 gDQfeo"/>
           </div>
        </fieldset>
        <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
          
          if(this.iptS.current.value == undefined){
           toast.error("Preencha o campo de nome de usuário!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
     
          if(this.iptS.current.value.trim().length < 3){
           toast.error("Insira no mínimo 3 caracteres!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           return false;
          }
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              facebook:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.facebook = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.facebook
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>Facebook</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             facebook:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.facebook = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>
          const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
     if(usernameRegex.test(this.iptS.current.value) == false){
        toast.error("Preencha o campo corretamente!", {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
        return false;
     }
          
          e.target.classList.add("load")
     
          fetch(`${apiUrl}/account/personalizations/socials`, {
           credentials: 'include',
           method:"POST",
           headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
           body: JSON.stringify({
           socials:{
              tiktok:this.iptS.current.value
           }
           })
           })
           .then(response =>{
     
              if(response.status == 200){
                 const newData = { ...this.state.data };
                 newData.socials.tiktok = this.iptS.current.value;
                 this.setState({ data: newData });
                 toast.success("Rede social atualizada com sucesso!", {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  Modal.close()
              }
     
     
           })
     
     
     
     
     
     
        }}>Atualizar</button></div>
     
     
         </>})
          this.iptS.current.value = this.state.data.socials.tiktok
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>

   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
       Modal.create({titulo:"Remover rede social",html:<>
            <p>Você tem certeza que deseja remover o <b>YouTube</b>?</p>
      
       <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 hcAgQt" onClick={(e)=>{
         
        
         
         e.target.classList.add("load")
    
         fetch(`${apiUrl}/account/personalizations/socials`, {
          credentials: 'include',
          method:"POST",
          headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
          },
          
          body: JSON.stringify({
          socials:{
             tiktok:null
          }
          })
          })
          .then(response =>{
    
             if(response.status == 200){
                const newData = { ...this.state.data };
                newData.socials.tiktok = null;
                this.setState({ data: newData });
                toast.success("Rede social removida com sucesso!", {
                   position: "top-center",
                   autoClose: 3000, // Fecha automaticamente após 3 segundos
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                 });
                 Modal.close()
             }
    
    
          })
    
    
    
    
    
    
       }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> Remover</button></div>
    
    
        </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>
</Item>
</Dropdown>
      </div>
   </div>
</div>
</div>
        
        </> : <> <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                  <div className="sc-6ec49348-6 irbHWh">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" style={{ zIndex: 2 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#24f6f0" style={{ position: 'absolute', top: '9px', left: '9px', zIndex: 1 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fe2d52" style={{ position: 'absolute', bottom: '9px', right: '9px', zIndex: 0 }}>
                <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path>
            </svg>
        </div>
                  </div>
                  <div>
                     <h3>TikTok</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs">
               <button type="button" class="sc-b1836745-0 jicnTG" onClick={()=>{
                           Modal.create({titulo:"Adicionar rede social",html:<>
             
                           <fieldset class="sc-41e32b1a-1 flXygX">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Página do facebook</span></div>
                              <div class="sc-41e32b1a-2 hZfkxe">
                                 <div class="sc-41e32b1a-3 djbJqI"><span>@</span></div>
                                 <input  placeholder="exemplo" onChange={(e)=>{
                                    this.setState({instagram:e.target.value})
                                 }} class="sc-41e32b1a-0 gDQfeo"/>
                              </div>
                           </fieldset>
                           <div class="sc-6ec49348-0 dcSUNY"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={(e)=>{
                             
                             if(this.state.instagram == undefined){
                              toast.error("Preencha o campo de nome de usuário!", {
                                 position: "top-center",
                                 autoClose: 3000, // Fecha automaticamente após 3 segundos
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                               });
                              return false;
                             }
                        
                             if(this.state.instagram.trim().length < 3){
                              toast.error("Insira no mínimo 3 caracteres!", {
                                 position: "top-center",
                                 autoClose: 3000, // Fecha automaticamente após 3 segundos
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                               });
                              return false;
                             }
                             const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
                        if(usernameRegex.test(this.state.instagram) == false){
                           toast.error("Preencha o campo corretamente!", {
                              position: "top-center",
                              autoClose: 3000, // Fecha automaticamente após 3 segundos
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                           return false;
                        }
                             
                             e.target.classList.add("load")
                        
                             fetch(`${apiUrl}/account/personalizations/socials`, {
                              credentials: 'include',
                              method:"POST",
                              headers: {
                              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                              },
                              
                              body: JSON.stringify({
                              socials:{
                                 tiktok:this.state.instagram
                              }
                              })
                              })
                              .then(response =>{
                        
                                 if(response.status == 200){
                                    const newData = { ...this.state.data };
                                    newData.socials.tiktok = this.state.instagram;
                                    this.setState({ data: newData });
                                    toast.success("Rede social adicionada com sucesso!", {
                                       position: "top-center",
                                       autoClose: 3000, // Fecha automaticamente após 3 segundos
                                       hideProgressBar: false,
                                       closeOnClick: true,
                                       pauseOnHover: true,
                                       draggable: true,
                                       progress: undefined,
                                     });
                                     Modal.close()
                                 }
                        
                        
                              })
                        
                        
                        
                        
                        
                        
                           }}>Adicionar</button></div>
                        
                        
                                          </>})
               }}><span>Adicionar</span></button></div>
            </div>
         </div></> }


        
      </div>
   </div>
</div>
<hr/>
<div class="sc-bb1591e2-7 khSMF">
   <h1 class="sc-bb1591e2-8 hEqGkD">Grupos</h1>
   <div class="sc-bb1591e2-3 iUBqHp">
      <div class="sc-bb1591e2-4 bpBBvu">
         <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                     <div class="sc-6ec49348-6 cvOGyf">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 24 24">
                           <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
                        </svg>
                     </div>
                  </div>
                  <div>
                     <h3>Grupo do WhatsApp</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs"><button type="button" class="sc-b1836745-0 jicnTG"><span>Adicionar</span></button></div>
            </div>
         </div>
         <div class="sc-6ec49348-2 hQRHj">
            <div class="sc-6ec49348-4 gbmvEo">
               <div class="sc-6ec49348-5 KEAHt">
                  <div>
                     <div class="sc-6ec49348-6 eWWLKG">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 24 24"><path d="m5.9 13.5l-5.4-1.7c0 0-0.7-0.3-0.5-0.9 0.1-0.1 0.2-0.2 0.4-0.4 1.2-0.8 22.3-8.4 22.3-8.4 0 0 0.6-0.2 0.9-0.1q0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0.1 0 0.1 0.1 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 0 0 0.1 0 0 0 0.1c0 0.1 0 0.3 0 0.5-0.2 2-4 17.5-4 17.5 0 0-0.2 0.9-1 0.9q-0.2 0-0.3 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2c-1.7-1.4-7.2-5.2-8.5-6q0 0 0 0 0 0 0-0.1 0 0 0 0-0.1 0-0.1 0c0-0.1 0.1-0.2 0.1-0.2 0 0 9.7-8.7 10-9.6 0 0-0.1-0.1-0.2 0-0.6 0.2-11.8 7.2-13 8q-0.1 0-0.1 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0z"></path></svg>
                     </div>
                  </div>
                  <div>
                     <h3>Grupo do telegram</h3>
                  </div>
               </div>
               <div class="sc-6ec49348-8 ledXBs"><button type="button" class="sc-b1836745-0 jicnTG"><span>Adicionar</span></button></div>
            </div>
         </div>
      </div>
   </div>
</div>

         </>)
   }
}

  componentDidMount(){
document.title = "Personalizações - EuRifei"   
fetch(`${apiUrl}/account/personalizations`, {
    method: 'GET',
    credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
    headers: {
      'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
      'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
    }
  })
  .then(response => {
    
    response.json().then(data=>{
       this.setState({Loader:false,data})
       this.iptF.current.value = data.fantasy_name
    })
   
  })
  }
   render() {
   

    if(this.state.Loader){
        return(<>
       <div class="sc-179422ed-0 keEfHG">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
               <rect x="0" y="0" width="100" height="100" fill="none"></rect>
               <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
               <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                  <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                  <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
               </circle>
            </svg>
         </div>
        </>)
      }



    return (
 <>
 <input accept="image/*,.jpeg,.jpg,.png" multiple="" ref={this.faviUpl} type="file" onChange={(e)=>{
   
   const file = e.target.files[0];
   
   // Verifica se o arquivo é uma imagem e tem no máximo 3MB
   if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size <= 3000000) {
       const formData = new FormData();
       formData.append('file', file);
       fetch(`${apiUrl}/account/personalizations/favicon`, {
           method: 'POST',
           body: formData,
           credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
           headers: {
             'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           }
         })
         .then(response => {
         response.json().then(r=>{
           let newData = { ...this.state.data };
           newData.favicon = r.image;
           toast.success("Logotipo salvo com sucesso!", {
               position: "top-center",
               autoClose: 3000, // Fecha automaticamente após 3 segundos
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
           this.setState({ data: newData }); 
         })
         })
         .catch(error => {
           // Tratar o erro
         });
   } else {
     
     toast.error("O arquivo deve ser uma imagem JPG, JPEG ou PNG e ter no máximo 3MB.", {
       position: "top-center",
       autoClose: 3000, // Fecha automaticamente após 3 segundos
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
   }
   
      }} style={{display:'none'}} tabindex="-1"/> 
      
 <input accept="image/*,.jpeg,.jpg,.png" multiple="" type="file" style={{display:'none'}} ref={this.iptUpl} onChange={(e)=>{



const file = e.target.files[0];

// Verifica se o arquivo é uma imagem e tem no máximo 3MB
if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size <= 3000000) {
    const formData = new FormData();
    formData.append('file', file);
    fetch(`${apiUrl}/account/personalizations/logo`, {
        method: 'POST',
        body: formData,
        credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
        headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
        }
      })
      .then(response => {
      response.json().then(r=>{
        let newData = { ...this.state.data };
        newData.logo = r.image;
        toast.success("Logotipo salvo com sucesso!", {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        this.setState({ data: newData }); 
      })
      })
      .catch(error => {
        // Tratar o erro
      });
} else {
  
  toast.error("O arquivo deve ser uma imagem JPG, JPEG ou PNG e ter no máximo 3MB.", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}


                  }} tabindex="-1"/>
                  
 
 <div class="sc-bb1591e2-0 gGjXOO">
   <div class="sc-bb1591e2-1 hazcTU">
      <div class="sc-bb1591e2-2 eKGyOA">
         <div class="sc-84a07fe4-0 jZNhWw">
            <a type="button" class={this.state.page == "a" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"a"})
         }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="2" y1="12" x2="22" y2="12"></line>
                  <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
               </svg>
               <span>Site</span>
            </a>
          
            <a type="button" class={this.state.page == "b" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"b"})
         }}>
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.81 3.94012C20.27 7.78012 16.41 13.0001 13.18 15.5901L11.21 17.1701C10.96 17.3501 10.71 17.5101 10.43 17.6201C10.43 17.4401 10.42 17.2401 10.39 17.0501C10.28 16.2101 9.9 15.4301 9.23 14.7601C8.55 14.0801 7.72 13.6801 6.87 13.5701C6.67 13.5601 6.47 13.5401 6.27 13.5601C6.38 13.2501 6.55 12.9601 6.76 12.7201L8.32 10.7501C10.9 7.52012 16.14 3.64012 19.97 2.11012C20.56 1.89012 21.13 2.05012 21.49 2.42012C21.87 2.79012 22.05 3.36012 21.81 3.94012Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M10.43 17.6201C10.43 18.7201 10.01 19.77 9.22 20.57C8.61 21.18 7.78 21.6001 6.79 21.7301L4.33 22.0001C2.99 22.1501 1.84 21.01 2 19.65L2.27 17.1901C2.51 15.0001 4.34 13.6001 6.28 13.5601C6.48 13.5501 6.69 13.56 6.88 13.57C7.73 13.68 8.56 14.0701 9.24 14.7601C9.91 15.4301 10.29 16.21 10.4 17.05C10.41 17.24 10.43 17.4301 10.43 17.6201Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M14.24 14.47C14.24 11.86 12.12 9.73999 9.50999 9.73999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
               </svg>
               <span>Aparência</span>
            </a>
           
            <a type="button" class={this.state.page == "c" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"c"})
         }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
               <span>Redes sociais e grupos</span>
            </a>


          
           
         </div>
         <div class="sc-bb1591e2-6 fbTodG">

        {this._LoadPage(this.state.page)}

         </div>
      </div>
   </div>
</div>


<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default Personalise;