import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class ConfirmEmail extends React.Component {
  componentDidMount(){
document.title = "Verifique o seu e-mail! - EuRifei"   
  }
   render() {
   
    return (
 <>
 

 <main class="sc-dd1467c3-0 eiMjbr">
   <div class="sc-dd1467c3-1 fYKpJl">
      <div class="sc-dd1467c3-2 kxeFDb">
         <div class="sc-dd1467c3-3 iqDjUF">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
               <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
            </svg>
         </div>
         <h1 class="sc-dd1467c3-4 fgqyyM">Verifique seu e-mail</h1>
         <p class="sc-dd1467c3-5 dQHKei">Finalize seu cadastro através do link que enviamos no seu e-mail.</p>
         <p class="sc-dd1467c3-5 dQHKei">Caso não encontre o e-mail na sua caixa de entrada, verifique a caixa de spam ou lixeira.</p>
         <div class="sc-dd1467c3-7 wPZWq">
            <p>Caso não tenha recebido o e-mail, clique no botão "Reenviar e-mail" para enviarmos novamente.</p>
         </div>
         <div class="sc-dd1467c3-8 iCFybz">
            <button type="button" aria-label="Reenviar e-mail" class="sc-b1836745-3 gepaxS">
               <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <polyline points="1 20 1 14 7 14"></polyline>
                  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
               </svg>
               Reenviar e-mail
            </button>
         </div>
      </div>
   </div>
</main>


 </>
    );
  }
}

export default ConfirmEmail;