import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../../../Comps/Modal';
import { NumericFormat } from 'react-number-format';
import { apiUrl } from '../../../config';
import { Dropdown, Drop, Item } from '../../../Comps/Dropdown';
class PmrR extends React.Component {
    constructor(props) {
    super(props);
       
    this.state = {
    tPmr:"",
    Loader:true,
    data:[]
    }

    this.tPremio = React.createRef();
    this.dPremio = React.createRef();
   
}


 TimeAgo = (date) => {
   
   var currentDate = Math.abs((new Date().getTime() / 1000).toFixed(0));
   var futureDate = Math.abs((new Date(date).getTime() / 1000).toFixed(0));
   var diff = currentDate - futureDate;
   
   var days = Math.floor(diff / 86400); var hours = Math.floor(diff / 3600) % 24;
   var minutes = Math.floor(diff / 60);
   var weeks   = Math.floor(diff / 604800);
   var months  = Math.floor(diff / 2600640);
   var years   = Math.floor(diff / 31207680);
   var seconds = diff;
   
   if (seconds <= 60) {
     return `${seconds} segundos atrás`;
   }
   //Minutes
   else if (minutes <= 60) {
     if (minutes == 1) {
         return "1 minuto";
     } else {
         return `${minutes} minutos`
     }
   }
   //Hours
   else if (hours <= 24) {
     if (hours == 1) {
         return "1 hora";
     } else {
         return `${hours} horas`;
     }
   }
   //Days
   else if (days <= 7) {
     if (days == 1) {
         return "ontem";
     } else {
         return `${days} dias`;
     }
   }
   //Weeks
   else if (weeks <= 4.3) {
     if (weeks == 1) {
         return "1 semana";
     } else {
         return `${weeks} semanas`;
     }
   }
   //Months
   else if (months <= 12) {
     if (months == 1) {
         return "1 mês";
     } else {
         return `${months} mêses`;
     }
   }
   //Years
   else {
     if (years == 1) {
         return "1 ano";
     } else {
         return `${years}  anos`;
     }
   }
   
   
   
   
   }
  componentDidMount(){
document.title = "Carregando..." 
var url = window.location.href;
const regex = /\/raffles\/([^/]+)\//;
const match = url.match(regex);
const code = match ? match[1] : null;
fetch(''+apiUrl+'/raffles/'+code, {
   method: 'GET',
   credentials: 'include', 
   headers: {
         'Content-Type': 'application/json' ,
         'Authorization': 'Bearer '+Cookies.get('auth_token')
   }
 })
.then(response => {

document.title = "Prêmios - EuRifei"

response.json().then(data=>{
   this.setState({Loader:false,data})
})

})
  }
   render() {
   

      if(this.state.Loader){
         return (
             <>
             
            
             <div class="sc-179422ed-0 keEfHG">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                  <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                  <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                  <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                     <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                     <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                  </circle>
               </svg>
            </div>
            
            
             </>
                );
        }


    return (
 <>
 
 <main class="sc-53c286d8-0 hVjYGT">
   <div class="sc-53c286d8-1 kwcquX">
      <div class="sc-84a07fe4-0 jZNhWw">
      
         <a type="button" className={this.state.active == "b" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"}>
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
            <span> Prêmios</span>
         </a>
      </div>
     
      <div class="sc-53c286d8-2 mRYWg">
   <div class="sc-9a4fa90-4 hvnQzk">
      <h1 class="sc-9a4fa90-5 dtJcxf">Convites pendentes</h1>
      <div class="sc-67028026-0 gBAuXk">
         <div class="sc-67028026-1 PUPzy">
            <div class="sc-67028026-2 dfUXrn">
               <input name="search" type="search" autocomplete="off" placeholder="Pesquisar por usuário, comissão ou data" class="sc-67028026-3 bFMuLh" value=""/>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
               </svg>
            </div>
            <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
               <button type="button" class="sc-cf9fb95-0 iIgArl" onClick={()=>{
                Modal.create({titulo:`Adicionar ${this.state.data.awards.length + 1}º prêmio`,html:<>
                <fieldset class="sc-56eec735-1 bSrfIZ">
   <div class="sc-2d577abe-0 jYSPyC"><span>Prêmio</span></div>
   <div class="sc-56eec735-2 bBSNUk"><input   maxlength="120" ref={this.tPremio}   class="sc-56eec735-0 jDba-dm2" /></div>
</fieldset>
<fieldset class="sc-930d7620-1 eqwmiR">
   <div class="sc-2d577abe-0 jYSPyC"><span>Descrição<small> • Opcional</small></span></div>
   <div class="sc-930d7620-2 bwkiZc"><textarea ref={this.dPremio} maxlength="240" class="sc-930d7620-0 jlwqCW"></textarea></div>
</fieldset>

                </>,btn:<>
                <div class="sc-ba975a8b-22 WQtHM">
   <button type="submit" class="sc-b1836745-0 gKYPUX"  aria-label="Adicionar" onClick={(e)=>{
    if(this.tPremio.current.value.trim().length == 0){
      toast.error('Informe o titulo do prêmio!', {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
e.target.classList.add("load")

    fetch(`${apiUrl}/raffles/awards/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get('auth_token')
      },
      body: JSON.stringify({
        award:this.tPremio.current.value,
        desc:this.dPremio.current.value,
        raffle_id:this.state.data.id
      })
    }).then(response => {
      e.target.classList.remove("load")
if(response.status == 200){

response.json().then(data=>{
  let dataAtual = new Date();
  const novoItem = { 
    id: data.id,
    desc:this.dPremio.current.value,
    title:this.tPremio.current.value,
    created_at:dataAtual.setSeconds(dataAtual.getSeconds() - 1)
}; 
var novaLista = [...this.state.data.awards, novoItem];
this.setState({
  data: {
      ...this.state.data,
      awards: novaLista
  }
});
Modal.close()

toast.success("Prêmio adicionado com!", {
  position: "top-center",
  autoClose: 3000, // Fecha automaticamente após 3 segundos
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});



})


 
}
    

    })





   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="12" y1="5" x2="12" y2="19"></line>
         <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
      Adicionar
   </button>
</div>

                </>})
               }}>
                 Adicionar prêmio
               </button>
           
            </div>
            
         </div>
      </div>
   </div>
   <div class="sc-67028026-11 dmSDBm">
     {this.state.data.awards.length > 0 ? <>
      <div class="sc-56b9959f-0 jImkcc">
         <div class="sc-56b9959f-1 dhBAhx">
            <table class="sc-56b9959f-2 kncOte">
               <thead class="sc-56b9959f-3 bSehiw">
                  <tr>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf">
                          <input type='checkbox'/>
                        </div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Colocação</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Prêmio</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Descrição</span></div>
                     </th>
                     <th colspan="1" class="">
                        <div class="sc-56b9959f-5 cWVjpf clickable"><span>Data</span></div>
                     </th>
                  </tr>
               </thead>
               <tbody class="sc-56b9959f-4 kKLelq">
                 
                 {this.state.data.awards.map((item,index)=><>
                  <tr>
                    
                     <td data-label="">
                     <input type='checkbox'/>
                     </td>
                     <td data-label="Usuário">{index + 1}º</td>
                     <td data-label="Comissão">{item.title}</td>
                     <td data-label="Data">{item.desc == "" ? "-" : item.desc }</td>
                     <td>há {this.TimeAgo(item.created_at)}</td>
                     <th class="fixed last" data-label="">
                        <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                           <Dropdown>
                              <Drop>
                              <button type="button" class="sc-cf9fb95-0 cKVYkL">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <circle cx="12" cy="12" r="1"></circle>
                                 <circle cx="12" cy="5" r="1"></circle>
                                 <circle cx="12" cy="19" r="1"></circle>
                              </svg>
                           </button>
                              </Drop>
                              <Item>
  
                              <div aria-hidden="false" role="menu" class="sc-e82feeba-0 sOJTp">
   <div class="sc-cf9fb95-1 gigqVx"></div>
   <button type="button" onClick={()=>{
    Modal.create({titulo:"Editar prêmio",html:<>
<fieldset class="sc-56eec735-1 bSrfIZ">
   <div class="sc-2d577abe-0 jYSPyC"><span>Prêmio</span></div>
   <div class="sc-56eec735-2 bBSNUk">
  <input name="award" id="award" maxlength="120" ref={this.tPremio} class="sc-56eec735-0 jDba-dm2"/></div>
</fieldset>

<fieldset class="sc-930d7620-1 eqwmiR">
   <div class="sc-2d577abe-0 jYSPyC"><span>Descrição<small> • Opcional</small></span></div>
   <div class="sc-930d7620-2 bwkiZc"><textarea ref={this.dPremio} maxlength="240" class="sc-930d7620-0 jlwqCW"></textarea></div>
</fieldset>
   
    </>,btn:<>
    <div class="sc-955b9eec-6 iHWLZo2">
    <button type="submit" class="sc-cf9fb95-0 iIgArl" onClick={(e)=>{


if(this.tPremio.current.value.trim().length == 0){
  toast.error('Informe o titulo do prêmio!', {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return false;
}

e.target.classList.add("load")

fetch(`${apiUrl}/raffles/awards/${item.id}/${this.state.data.code}`, {
  method: 'PUT',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get('auth_token')
  },
  body: JSON.stringify({
    award:this.tPremio.current.value,
    desc:this.dPremio.current.value,
  })
}).then(response => {
  e.target.classList.add("remove")
if(response.status == 200){


  const updatedAwards = this.state.data.awards.map(award =>
    award.id === item.id ? { ...award, title: this.tPremio.current.value,desc:this.dPremio.current.value } : award
  );

  const updatedItem = { ...this.state.data, awards: updatedAwards };
  this.setState({ data: updatedItem });
  toast.success("Prêmio editado com sucesso!", {
    position: "top-center",
    autoClose: 3000, // Fecha automaticamente após 3 segundos
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  Modal.close()
}
})



    }}>Salvar alterações</button>
    </div>
    </>})
    this.tPremio.current.value  = item.title;
    this.dPremio.current.value  = item.desc;
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Editar</span>
   </button>
   <hr/>
   <button type="button" data-danger="true" onClick={()=>{
    Modal.create({titulo:"Remover prêmio",html:<>
    <p>Você tem certeza que deseja excluir este prêmio?</p>
    </>,btn:<>
    <div class="sc-955b9eec-6 iHWLZo2">
    <button type="button" aria-label="Remover" class="sc-b1836745-0 bJxjoS" onClick={(e)=>{
     e.target.classList.add("load")

     fetch(''+apiUrl+'/raffles/awards/'+item.id+'/'+this.state.data.code, {
      method: 'DELETE',
      credentials: 'include', 
      headers: {
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+Cookies.get('auth_token')
      }
    })
   .then(response => {
    e.target.classList.remove("load")
    if(response.status == 200){


     var  novaLista = this.state.data.awards.filter(e => e.id !== item.id);
     this.setState({
      data: {
          ...this.state.data,
          awards: novaLista
      }
    });

    toast.success("prêmio removido com sucesso!", {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
      Modal.close()
    }
   })




    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>Remover</button></div>
    </>})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <polyline points="3 6 5 6 21 6"></polyline>
         <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
      <span>Remover</span>
   </button>
</div>


                              </Item>
                           </Dropdown>
                          
                     


                          
                        </div>

                     </th>
                  </tr>
                 </>)}
                  
               </tbody>
            </table>
         </div>
      </div>
     </> : <>
     <div class="sc-56b9959f-7 cgvjTZ"><div class="sc-56b9959f-8 jrjgKP"><h2>:(</h2><span>Você ainda não adicionou nenhum prêmio!</span></div></div>
     </>}
     

   </div>
   <div class="sc-67028026-12 eOxNfk"></div>
</div>
   

   </div>
</main>

{this.state.show == true && <>
 <div id="modal-root">
   
      <div  class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({show:false})
      }}/>
    <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Adicionar afiliado</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC">
                        <span>Endereço de e-mail do afiliado</span>
                        <div style={{height:'16px'}}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="email-help-tt">
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                              <line x1="12" y1="17" x2="12.01" y2="17"></line>
                           </svg>
                        </div>
                     </div>
                     <div class="sc-56eec735-2 bBSNUk">
                     <input name="email" id="email" type="email" autocomplete="off" placeholder="Digite o e-mail" class="sc-56eec735-0 jDba-dm" onChange={(e)=>{
                        this.setState({email:e.target.value})
                     }} value={this.state.email}/>
                     </div>
                  </fieldset>
                  <fieldset class="sc-262cfb40-0 sUMyx">
                     <div class="sc-2d577abe-0 jYSPyC">
                        <span>Comissão</span>
                        <div style={{height:'16px'}}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="commission_type-help-tt">
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                              <line x1="12" y1="17" x2="12.01" y2="17"></line>
                           </svg>
                        </div>
                     </div>
                     <label>
                        <input name="commission_type" type="radio" onClick={()=>{
                           this.setState({tp:"a",enb:"a"})
}} class="sc-262cfb40-8 iDmPEq" value="currency" checked={this.state.enb == "a" ? true : false }/>   
                        <div class="sc-262cfb40-1 kPSVld">
                           <div class="sc-262cfb40-2 jhenhz">
                              <div class="sc-262cfb40-4 blqqI icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <line x1="19" y1="5" x2="5" y2="19"></line>
                                    <circle cx="6.5" cy="6.5" r="2.5"></circle>
                                    <circle cx="17.5" cy="17.5" r="2.5"></circle>
                                 </svg>
                              </div>
                              <h3 class="sc-262cfb40-3 OJeJK">Porcentagem</h3>
                              <div class="sc-262cfb40-6 gPuqcB">
                                 <div class="sc-262cfb40-7 gLgCDf option"></div>
                              </div>
                           </div>
                           <div class="sc-262cfb40-5 cwUvaj"><span>Porcentagem em cima do total da venda</span></div>
                        </div>
                     </label>
                     <label>
                        <input name="commission_type" type="radio"  onClick={()=>{
                           this.setState({tp:"b",enb:"b"})
                        }} class="sc-262cfb40-8 iDmPEq"  checked={this.state.enb == "b" ? true : false } value="currency"/>
                        <div class="sc-262cfb40-1 kPSVld">
                           <div class="sc-262cfb40-2 jhenhz">
                              <div class="sc-262cfb40-4 blqqI icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                 </svg>
                              </div>
                              <h3 class="sc-262cfb40-3 OJeJK">Dinheiro fixo</h3>
                              <div class="sc-262cfb40-6 gPuqcB">
                                 <div class="sc-262cfb40-7 gLgCDf option"></div>
                              </div>
                           </div>
                           <div class="sc-262cfb40-5 cwUvaj"><span>Dinheiro fixo por venda, independente da quantidade</span></div>
                        </div>
                     </label>
                  </fieldset>


{this.state.enb == "a" ? <>
<div class="sc-3f3c9d1b-1 fISuhk">
   <fieldset class="sc-c66b824c-0 hkCOsw">
      <div data-min="0%" data-max="100%" class="sc-c66b824c-1 cqSdLd">
       
         <div class="sc-c66b824c-2 fFAypK"></div>
         <input name="commission_value" type="range" step="1" min="0" max="100" onChange={(e)=>{
       this.setState({rage:e.target.value})
         }} style={{
      background: `linear-gradient(to right, rgb(251, 140, 0) ${this.state.rage}%, rgb(251, 140, 0) ${this.state.rage}%, rgb(245, 246, 248) ${this.state.rage}%, rgb(245, 246, 248) 100%)`
    }} class="sc-c66b824c-5 bQsEHB" value={this.state.rage} />
    <span className='vrage'>{this.state.rage}%</span>
      </div>
   </fieldset>
</div>

</> : <>
<div className='sc-3f3c9d1b-1 fISuir'>
<fieldset class="sc-6ca2aeef-1 glvjDU">
   <div class="sc-2d577abe-0 jYSPyC"><span>Valor</span></div>
   <div class="sc-6ca2aeef-3 cgNEER">
   <input name="commission_value" id="commission_value" class="sc-6ca2aeef-0 ittpNn" onChange={(e)=>{
       var val  = e.target.value;
       const onlyDigits = val
       .split("")
       .filter(s => /\d/.test(s))
       .join("")
       .padStart(3, "0")
       this.setState({currency:onlyDigits})
     const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
var vn = this.maskCurrency(digitsFloat);
this.setState({vn})
   }} value={this.state.vn}/>
   </div>
</fieldset>

</div>


</>}

<div class="sc-3f3c9d1b-2 fyXsDq"><button type="submit" class="sc-b1836745-0 dHtLaV" onClick={()=>{
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(re.test(this.state.email) == false){
      toast.error('E-mail inválido!', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
      return false
    }

    fetch(`${apiUrl}/affiliates/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get('auth_token')
      },
      body: JSON.stringify({
      commission_type:this.state.enb == "a" ? "percentage" : "currency",
      commission_value:this.state.enb == "a" ? this.state.rage : parseFloat(this.state.currency) / 100,
      email:this.state.email
      })
    }).then(response => {

      if(response.status == 404){
         response.json().then(data=>{
            toast.error(data.message, {
               position: "top-center",
               autoClose: 3000, // Fecha automaticamente após 3 segundos
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             }); 
         })
      }

      if(response.status == "200"){
         response.json().then(data=>{
            this.setState({show:false,email:""})
            const novoItem = { 
                id: data.user.id,
                name:data.user.name,
                commission_type:data.user.commission_type,
                commission_value:data.user.commission_value,
                created_at:data.user.created_at
            }; // Novo item a ser adicionado
            const novaLista = [...this.state.data, novoItem]; // Clonar a lista e adicionar o novo item
            this.setState({ data: novaLista }); 
               toast.success("Convite enviado com sucesso!", {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
         })
          
      }

      if(response.status == 409){
         response.json().then(data=>{
            toast.error(data.message, {
               position: "top-center",
               autoClose: 3000, // Fecha automaticamente após 3 segundos
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             }); 
         })
      }

   })






}}>Enviar convite</button></div>
               </div>
               
            </div>
            
         </div>
   
</div>

</>}

<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default PmrR;