import React, { Component } from 'react';
import { Route, Routes, Router,useNavigate} from "react-router-dom"
import SingIn from '../SingIn';
import Raffles from '../Raffles';
import RCreate from '../RCreate';
import SlcRifa from '../SlcRifa';
import Page404 from '../Page404';
import Header from '../../Comps/Header';
import EditR from '../SlcRifa/EditR';
import ImgR from '../SlcRifa/ImgR';
import FdpR from '../SlcRifa/FdpR';
import PmrR from '../SlcRifa/PmrR';
import StrR from '../SlcRifa/StrR';
import DstR from '../SlcRifa/DsrT';
import Manage from '../Afiliados/Manage';
import Afiliados from '../Afiliados';
import Affiliate from '../Afiliados/Affiliate';
import Config from '../Config';
import Winner from '../Winner';
import Personalise from '../Personalise';
import Orders from '../Orders';
import ROrder from '../Orders/ROrder';
import Rifinhas from '../Rifinhas';
import CriarRifinha from '../CriarRifinha';
import SlcRifinha from '../SlcRifinha';
import UserVinc from '../Config/UserVinc';
import Dispositivos from '../Config/Dispositivos';
import Compras from '../Config/Compras';
import Preferences from '../Config/Preferences';
import EdtRifinha from '../SlcRifinha/EdtRifinha';
import PmrRifinha from '../SlcRifa/PmrRifinha';
import StrRifinha from '../SlcRifa/StrRifinha';
import WinneRifinha from '../WinneRifinha';
import ROrderEx from '../Orders/ROrderEx';
import ROptions from '../PRifa/Options';
import Description from '../PRifa/Description';
import Rcustomization from '../PRifa/Customization';



export default function LogadoPage() {
    const navigateTo = useNavigate();
    return (
        <>
       <Header navigate={navigateTo}/>
        <Routes>
        <Route  path="/"  element={<SingIn navigate={navigateTo}/>} />
        <Route  path="/raffles"  element={<Raffles/>} />

         <Route  path="/raffles/:id/edit"  element={<EditR navigate={navigateTo}/>} />
         <Route  path="/rifinhas/:id/edit"  element={<EdtRifinha navigate={navigateTo}/>} />


         <Route  path="/raffles/:id/orders"  element={<ROrder navigate={navigateTo}/>} />
         <Route  path="/raffles/:id/expired-orders"  element={<ROrderEx navigate={navigateTo}/>} />
        <Route  path="/raffles/:id/images"  element={<ImgR navigate={navigateTo}/>} />
        <Route  path="/raffles/:id/payments"  element={<FdpR navigate={navigateTo}/>} />

        <Route  path="/raffles/:id/awards"  element={<PmrR navigate={navigateTo}/>} />
        <Route  path="/rifinhas/:id/awards"  element={<PmrRifinha navigate={navigateTo}/>} />

        <Route  path="/raffles/:id/discounts"  element={<DstR navigate={navigateTo}/>} />
        <Route  path="/raffles/:id/finish"  element={<StrR navigate={navigateTo}/>} />
        <Route  path="/rifinhas/:id/finish"  element={<StrRifinha navigate={navigateTo}/>} />
        <Route  path="/raffles/:id/winner"  element={<Winner navigate={navigateTo}/>} />
        <Route  path="/rifinhas/:id/winner"  element={<WinneRifinha navigate={navigateTo}/>} />
        <Route  path="/raffles/*"  element={<SlcRifa navigate={navigateTo}/>} />
        <Route  path="/affiliates/"  element={<Afiliados navigate={navigateTo} />} />
        <Route  path="/affiliates/manage"  element={<Manage navigate={navigateTo} />} />
        <Route  path="/affiliates/affiliate"  element={<Affiliate navigate={navigateTo} />} />
        <Route  path="/account"  element={<Config navigate={navigateTo} />} />
        <Route  path="/account/linked-users"  element={<UserVinc navigate={navigateTo} />} />
        <Route  path="/account/devices"  element={<Dispositivos navigate={navigateTo} />} />
        <Route  path="/account/purchases"  element={<Compras navigate={navigateTo} />} />
        <Route  path="/account/preferences"  element={<Preferences navigate={navigateTo} />} />


        <Route  path="/personalize*"  element={<Personalise navigate={navigateTo}/>} />
        <Route  path="/orders*"  element={<Orders navigate={navigateTo}/>} />
        <Route  path="/rifinhas/"  element={<Rifinhas navigate={navigateTo}/>} />
        <Route  path="/rifinhas/*"  element={<SlcRifinha navigate={navigateTo}/>} />
    

      
        <Route  path="/raffles/:slug/description"  element={<Description navigate={navigateTo}/>} />
        

        <Route path="*" element={<Page404/>} />
        </Routes>
        </>
    );
  
}

