import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import homem from "./home-support-image.webp"
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Section7 extends React.Component {
  render() {
    return (
 <>
 
 <section class="sc-2c403d08-16 fLOYIJ">
   <div class="sc-2c403d08-8 klLsrI">
      <div class="sc-2c403d08-20 kogWnT">
         <div class="sc-2c403d08-21 chvgfa image">
            <span className='est1w'>
               <span className='estw'>
            <img className='estw' alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271254%27%20height=%27836%27/%3e"/></span>
            <img alt="Homem usando um headset sorri com a mão no queixo olhando para o computador." src={homem} decoding="async" data-nimg="intrinsic"  className='est4w'/>
                      </span>
         </div>
         <div class="sc-2c403d08-21 chvgfa text">
            <h2 class="sc-2c403d08-17 jdVlnJ">Suporte</h2>
            <p class="sc-2c403d08-18 eRmqZW">Nós temos uma central de ajuda preparada para você tirar todas as suas dúvidas.Se você não encontrar a solução para a sua dúvida, é só entrar em contato conosco que iremos lhe ajudar o mais rápido possível!</p>
            <Link to="/ajuda" class="sc-2c403d08-19 iLOllg">
        
               Ir para a central de ajuda
               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link ">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" x2="21" y1="14" y2="3"></line>
               </svg>
            </Link>
         </div>
      </div>
   </div>
</section>




 </>
    );
  }
}

export default Section7;