import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
class Section3 extends React.Component {
  render() {
    return (
 <>
 
 <section class="sc-2c403d08-16 ibmVzO">
   <div class="sc-2c403d08-8 klLsrI">
      <div class="sc-2c403d08-9 YDbbQ">
         <div class="sc-2c403d08-10 hPDazC">
            <div class="sc-2c403d08-11 hWCRqo">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                  <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                  <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                  <circle cx="50" cy="50" r="40" stroke="currentColor" fill="none" stroke-width="6" stroke-linecap="round">
                     <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                     <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                  </circle>
               </svg>
            </div>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/yJQUqQz31ik?si=lfpunwOl8QcbJ5f-&amp;controls=0&amp;showinfo=0&amp;rel=0" title="Vídeo animado de como funciona a plataforma" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
         </div>
      </div>
   </div>
</section>



 </>
    );
  }
}

export default Section3;