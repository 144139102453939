import React, { Component } from 'react';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleItemClick = (event) => {
    if (event.target.getAttribute('CloseMe') === 'true') {
      this.setState({ isOpen: false });
    }
  };
  render() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return (
      <div className="dropdown" ref={this.dropdownRef}>
        {React.Children.map(children, (child) => {

          if (child.type === Drop) {
            return React.cloneElement(child, { onClick: this.handleToggle });
          }

          if (child.type === Item && isOpen) {
        
            return React.cloneElement(child,{ onClick: this.handleItemClick });
          }
          return null;
        })}
      </div>
    );
  }
}

class Drop extends Component {
  render() {
    const { children, onClick } = this.props;
    return <div className="drop" onClick={onClick}>{children}</div>;
  }
}

class Item extends Component {
  render() {

    const { children,onClick } = this.props;
    return <div className="item" onClick={onClick}>{children}</div>;
  }
}

export { Dropdown, Drop, Item };