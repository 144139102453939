import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./cpas.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
class Compras extends React.Component {
   constructor(props) {
      super(props);
         
      this.state = {
      tPmr:"",
      Loader:true,
      data:[]
      }
   }
   formatarData(data) {
      const partes = data.split(' ')[0].split('-');
      const mes = new Date(`${partes[0]}-${partes[1]}-${partes[2]}`).toLocaleString('pt-BR', { month: 'short' });
      return `${partes[2]} de ${mes} de ${partes[0]}`;
    }
  componentDidMount(){
document.title = "Compras - EuRifei"   
fetch(`${apiUrl}/purchases`, {
   method: 'GET',
   credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
   headers: {
     'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
     'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
   }
 })
 .then(response => {
response.json().then(data=>{
   this.setState({data,Loader:false})
})
 })
  }
   render() {
      if(this.state.Loader){
         return(<>
        <div class="sc-179422ed-0 keEfHG">
             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                   <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                   <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                </circle>
             </svg>
          </div>
         </>)
       }
    return (
 <>
 
 <div class="sc-f7781e17-0 ebFOLR">
   <div class="sc-f7781e17-1 ivElUD">
      <div class="sc-f7781e17-2 gYYPbP">
        
         <div class="sc-84a07fe4-0 jZNhWw">
            <Link to="/account" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-database ">
                  <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                  <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                  <path d="M3 12A9 3 0 0 0 21 12"></path>
               </svg>
               <span>Meus dados</span>
            </Link>
            <Link to="/account/linked-users" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill-rule="evenodd">
                  <path d="m18 19c0-1.6-0.6-3.1-1.8-4.2-1.1-1.2-2.6-1.8-4.2-1.8-1.6 0-3.1 0.6-4.2 1.8-1.2 1.1-1.8 2.6-1.8 4.2"></path>
                  <path d="m12 13c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4 0 2.2-1.8 4-4 4z"></path>
                  <path d="m22.9 16.5q0-0.8-0.3-1.5-0.3-0.7-0.8-1.2-0.5-0.6-1.3-0.9-0.7-0.3-1.4-0.3c0.7 0 1.3-0.2 1.8-0.7 0.5-0.5 0.7-1.1 0.7-1.8 0-0.7-0.2-1.4-0.7-1.8-0.5-0.5-1.1-0.8-1.8-0.8"></path>
                  <path d="m1.1 16.5q0-0.8 0.3-1.5 0.3-0.7 0.8-1.2 0.5-0.6 1.3-0.9 0.7-0.3 1.4-0.3c-0.7 0-1.3-0.2-1.8-0.7-0.5-0.5-0.7-1.1-0.7-1.8 0-0.7 0.2-1.4 0.7-1.8 0.5-0.5 1.1-0.8 1.8-0.8"></path>
               </svg>
               <span>Usuários vinculados</span>
            </Link>
            <Link to="/account/devices" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor-smartphone ">
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"></path>
                  <path d="M10 19v-3.96 3.15"></path>
                  <path d="M7 19h5"></path>
                  <rect width="6" height="10" x="16" y="12" rx="2"></rect>
               </svg>
               <span>Dispositivos</span>
            </Link>
            <Link to="/account/purchases" class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
               </svg>
               <span>Compras</span>
            </Link>
            <Link to="/account/preferences" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench ">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
               </svg>
               <span>Preferências</span>
            </Link>
         </div>
         
         <div class="sc-f7781e17-6 bwWzPR">
   <div class="sc-f7781e17-7 jtgWWa">
      <div class="sc-f7781e17-8 jkIyEd not-row">
         <h1 class="sc-f7781e17-9 elsxTF">Compras</h1>
      </div>
      {this.state.data.length > 0 ? <>
    {this.state.data.map((item,index)=><>
      <div class="sc-6efce03c-0 iVcSqF">
         <div class="sc-6efce03c-1 fdQurb row">
            <div class="sc-6efce03c-2 eUmhBC">
               <div>
                  <div class="sc-6efce03c-3 cLLPO pending">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                     </svg>
                  </div>
               </div>
               <div class="sc-6efce03c-4 dAoLqo">
                  <h4>Ativação de campanha</h4>
                  <span>/{item.slug}</span>
               </div>
            </div>
         </div>
         <hr/>
         <div class="sc-6efce03c-1 fdQurb">
            <div class="sc-6efce03c-5 bDFafK">
               <span>Data:</span>
               <p>{this.formatarData(item.created_at)}</p>
            </div>
            <div class="sc-6efce03c-5 bDFafK">
               <span>Total:</span>
               <p>{new Number(item.subtotal_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
         </div>
      </div>
    </>)}
      </> : <>
      <div class="sc-cbe1e9c6-7 gAkDyX">
      <h2>:(</h2><span>Você ainda não possui nenhuma compra!</span>
      </div>
      </>}
     
     
   </div>
</div>


      </div>
   </div>
</div>



 </>
    );
  }
}

export default Compras;

