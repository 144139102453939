import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Section1 extends React.Component {
  render() {
    return (
 <>
 

 <section class="sc-2c403d08-1 jgBKKC">
   <div class="sc-2c403d08-2 flgAwv">
      <h1 class="sc-2c403d08-3 eZUjNd">Crie sua campanha em questão de minutos!</h1>
      <p class="sc-2c403d08-4 ieJbaO">Plataforma completa para você criar suas campanhas e alcançar um público maior através do Rifei.</p>
      <Link to="/sign-up" rel="noopener noreferrer" aria-label="Criar minha campanha" class="sc-2c403d08-5 uGKWH"><span>Criar minha campanha</span></Link>
   </div>
</section>


 </>
    );
  }
}

export default Section1;