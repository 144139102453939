import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Page404 extends React.Component {
  componentDidMount(){
document.title = "Página não encontrada! - EuRifei"   
  }
   render() {
   
    return (
 <>
 
 <main class="sc-de8cbe2d-0 iypOTp">
   <div class="sc-de8cbe2d-1 hCroVH">
      <div class="sc-de8cbe2d-3 kXRTMm">
         <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" viewBox="0 0 1024 1024">
            <defs>
               <linearGradient id="_element_656" gradientUnits="userSpaceOnUse" x1="512" y1="1024.079" x2="512" y2="-0.079">
                  <stop offset="0" stop-color="#fb8b00"></stop>
                  <stop offset="1" stop-color="#ffc47b"></stop>
               </linearGradient>
               <linearGradient id="_element_464" gradientUnits="userSpaceOnUse" x1="109.066" y1="348.433" x2="109.066" y2="146.828">
                  <stop offset="0" stop-color="#0070fb"></stop>
                  <stop offset="1" stop-color="#7acbff"></stop>
               </linearGradient>
            </defs>
            <path fill="url(#_element_656)" d="M379.48 17.45C652.62 -55.74 933.37 106.35 1006.55 379.48C1079.74 652.62 917.65 933.37 644.52 1006.55C371.38 1079.74 90.63 917.65 17.45 644.52C-55.74 371.38 106.35 90.63 379.48 17.45Z"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M244.46 599.22C244.46 599.22 235.86 545.62 295.2 529.72C353.01 514.23 371.96 565.05 371.96 565.05"></path>
            <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M659.81 487.92C659.81 487.92 651.21 434.32 710.55 418.42C768.36 402.93 787.31 453.76 787.31 453.76"></path>
            <path fill="#000000" d="M557.55 682C614.17 666.83 649.47 705.85 644.68 713.53C641.24 719.05 610.88 700.84 565.83 712.91C524.32 724.04 505.89 756.33 498.83 752.61C490.38 748.16 500.71 697.23 557.55 682Z"></path>
            <path fill="url(#_element_464)" d="M99.54 156.59C158.71 190.76 249.14 310.84 126.3 343.75C2.57 376.91 22.48 225.67 56.42 166.88C70.38 142.7 73.95 141.82 99.54 156.59Z"></path>
         </svg>
      </div>
      <div class="sc-de8cbe2d-2 bAAQGt">
         <h1 class="sc-de8cbe2d-4 hZNJuz">Esta página não existe</h1>
         <p class="sc-de8cbe2d-5 kPXVax">Desculpe, a página que você está procurando não existe ou foi movida.</p>
      </div>
      <div class="sc-de8cbe2d-6 BJfFs">
    <Link to="/" class="sc-688eee80-0 ivGZii">Retornar ao início</Link></div>
   </div>
</main>


 </>
    );
  }
}

export default Page404;