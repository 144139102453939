import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class Menu extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
         menu:false
      }
   }
  render() {
    return (
 <>
 
 <header className={this.state.menu == true ? "sc-e6341458-6 goCdep active" : "sc-e6341458-6 goCdep"}>
   <nav aria-label="Menu principal" class="sc-e6341458-7 exeFlu">
      <div class="sc-e6341458-1 bYjJsm header-mobile-menu">
         <ul class="header-navigation">
            <li class="header-navigation-item primary">
               <Link to="/sign-in" aria-label="Login">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-in ">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                        <polyline points="10 17 15 12 10 7"></polyline>
                        <line x1="15" x2="3" y1="12" y2="12"></line>
                     </svg>
                  </div>
                  Login
               </Link>
            </li>
            <li class="header-navigation-item primary">
               <Link to="/sign-up" aria-label="Criar conta">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-plus ">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <line x1="19" x2="19" y1="8" y2="14"></line>
                        <line x1="22" x2="16" y1="11" y2="11"></line>
                     </svg>
                  </div>
                  Criar conta
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Preços" to="/precos">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
                        <line x1="12" x2="12" y1="2" y2="22"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                     </svg>
                  </div>
                  Preços
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Sobre nós" to="/sobre-nos">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <path d="M12 17h.01"></path>
                     </svg>
                  </div>
                  Sobre nós
               </Link>
            </li>
            <li class="header-navigation-item">
               <Link aria-label="Central de ajuda" to="/ajuda">
                  <div class="header-navigation-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-life-buoy ">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="m4.93 4.93 4.24 4.24"></path>
                        <path d="m14.83 9.17 4.24-4.24"></path>
                        <path d="m14.83 14.83 4.24 4.24"></path>
                        <path d="m9.17 14.83-4.24 4.24"></path>
                        <circle cx="12" cy="12" r="4"></circle>
                     </svg>
                  </div>
                  Central de ajuda
               </Link>
            </li>
         </ul>
      </div>
      <div class="sc-e6341458-0 lnAbnn header-container">
         <div class="sc-e6341458-11 hUolII">
            <a aria-label="Página inicial do Rifei" to="/" class="sc-e6341458-8 gxWuHA">
               <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="#fb8c00" viewBox="0 0 100 45">
                  <path fill="#ffffff" d="m6.5 19.1l83.8-13.9 3.3 19.9-83.8 13.9z"></path>
                  <path d="M0,17.391L100,0V5.171a3.321,3.321,0,0,0-3.179,2.919A3.231,3.231,0,0,0,100,11.469v5.255a3.107,3.107,0,0,0,0,6.214v4.8L0,45V17.391ZM30.43,33.83l4.742-.822-3.24-6.024a5.217,5.217,0,0,0,2.183-2.44,5.543,5.543,0,0,0,.311-3.174,4.727,4.727,0,0,0-.536-1.516,3.183,3.183,0,0,0-1.059-1.112,4.125,4.125,0,0,0-1.635-.58,7.069,7.069,0,0,0-2.244.076l-7.22,1.252L22.1,35.274l4.486-.778-0.154-5.889,1.26-.218Zm-4.058-8.495-0.1-3.135,1.645-.285a2.378,2.378,0,0,1,1.432.065,1.1,1.1,0,0,1,.559.842,1.782,1.782,0,0,1-.057.971,1.568,1.568,0,0,1-.486.667,2.55,2.55,0,0,1-.784.417,6.136,6.136,0,0,1-.927.236Zm15.782-6.687,1.624-.281-0.043-2.691L35.98,17.019l0.064,2.688,1.6-.278,0.239,10.409-1.623.281L36.347,32.8l7.733-1.34-0.064-2.688-1.6.278Zm13.5,1.569-5.276.915-0.088-2.943,6.067-1.052L56.273,13.5,45.7,15.334l0.367,15.785,4.529-.785-0.138-5.676,5.276-.915ZM67.892,17.49l-5.362.929-0.068-2.342,6.045-1.048-0.058-3.639L57.875,13.224l0.367,15.785,10.83-1.877-0.084-3.656-6.3,1.092-0.07-2.6,5.362-.929Zm8.46-4.77,1.623-.281L77.933,9.747l-7.754,1.344,0.064,2.688,1.6-.278L72.084,23.91l-1.623.281,0.085,2.684,7.733-1.34-0.064-2.688-1.6.278Z"></path>
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#fb8c00" viewBox="0 0 24 24">
                  <path fill="#ffffff" d="m3.3 5.8l14.3-1.5 1.4 14.3-14.2 1.4z"></path>
                  <path d="M24 0v3.71l-.22.01c-.96.05-1.78.73-2.03 1.67-.07.23-.09.67-.05.9.07.48.28.89.61 1.23.4.42.95.66 1.52.68l.17.01v3.78l-.05.01c-.15.03-.48.14-.59.2-.57.29-1 .82-1.17 1.43-.05.19-.06.24-.06.58 0 .35.01.39.06.58.06.24.24.59.37.77.33.43.82.75 1.33.85l.11.02v3.44l-11.97 2.05C5.45 23.05.05 23.97.04 23.98 0 23.98 0 23.5 0 14.06 0 8.6.01 4.14.02 4.14c0 0 5.4-.93 11.99-2.07C18.6.93 23.99 0 24 0zM12.48 6.4c-.49.04-.66.06-4.44.71l-1.46.25v5.63c0 4.49.01 5.62.03 5.62.01-.01.71-.13 1.55-.27l1.53-.26v-2.13c0-2 0-2.12.04-2.13.1-.03.96-.16 1.13-.17.71-.05 1.13.15 1.55.76.08.1.48.79.91 1.53.42.74.78 1.35.79 1.36.01.02 3.28-.53 3.3-.55 0 0-.47-.84-1.55-2.69-.51-.88-.86-1.31-1.3-1.6-.26-.17-.65-.31-.88-.31-.06 0-.03-.02.17-.12 1.11-.59 1.75-1.37 1.97-2.42.05-.23.05-.29.05-.74 0-.53-.02-.71-.14-1.06-.29-.86-.99-1.31-2.18-1.41-.27-.02-.74-.02-1.07 0z"></path>
                  <path d="M11.27 8.68c-.07.01-.46.07-.85.14-.39.07-.71.12-.72.12-.01 0-.01.65-.01 1.44v1.43l.06-.01c.03-.01.34-.06.71-.12.95-.17 1.21-.24 1.55-.42.4-.22.64-.53.73-.98.06-.29.05-.75-.03-.98-.11-.33-.28-.5-.61-.59-.14-.04-.62-.06-.83-.03z"></path>
               </svg>
            </a>
            <button type="button" aria-label="Abrir menu" onClick={()=>{
               this.setState({menu:!this.state.menu})
            }} aria-expanded="false" aria-haspopup="true" class="sc-e6341458-3 jWdlOA">
               <div class="sc-e6341458-4 cOGBuu">
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                  <div class="sc-e6341458-5 hFrINQ header-menu-line"></div>
                 
               </div>
            </button>
         </div>
         <div class="sc-e6341458-9 iga-dTb">
         <Link class="sc-e6341458-10 hOJEzN" aria-label="Preços" to="/precos">Preços</Link>
         <Link class="sc-e6341458-10 hOJEzN" aria-label="Sobre nós" to="/sobre-nos">Sobre nós</Link>
         <Link aria-label="Ajuda" to="/ajuda" class="sc-e6341458-10 hOJEzN">Ajuda</Link>
         </div>
         <div class="sc-e6341458-11 hUolII">
         <Link to="/sign-in" class="sc-688eee80-2 eJNlyb header-sign-in" aria-label="Login">Login</Link>
         <Link to="/sign-up" class="sc-688eee80-0 dDccFD header-sign-up" aria-label="Criar conta">Criar conta</Link></div>
      </div>
   </nav>
</header>


<div class="sc-e6341458-2 gSnkdf header-backdrop" onClick={()=>{
   this.setState({menu:false})
}}></div>
 </>
 
    );
  }
}

export default Menu;