import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./usvc.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class UserVinc extends React.Component {
  componentDidMount(){
document.title = "Usuários vinculados - EuRifei"   
  }
   render() {
   
    return (
 <>
 
 <div class="sc-f7781e17-0 ebFOLR">
   <div class="sc-f7781e17-1 ivElUD">
      <div class="sc-f7781e17-2 gYYPbP">
         <div class="sc-84a07fe4-0 jZNhWw">
            <Link to="/account" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-database ">
                  <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                  <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                  <path d="M3 12A9 3 0 0 0 21 12"></path>
               </svg>
               <span>Meus dados</span>
            </Link>
            <div class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill-rule="evenodd">
                  <path d="m18 19c0-1.6-0.6-3.1-1.8-4.2-1.1-1.2-2.6-1.8-4.2-1.8-1.6 0-3.1 0.6-4.2 1.8-1.2 1.1-1.8 2.6-1.8 4.2"></path>
                  <path d="m12 13c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4 0 2.2-1.8 4-4 4z"></path>
                  <path d="m22.9 16.5q0-0.8-0.3-1.5-0.3-0.7-0.8-1.2-0.5-0.6-1.3-0.9-0.7-0.3-1.4-0.3c0.7 0 1.3-0.2 1.8-0.7 0.5-0.5 0.7-1.1 0.7-1.8 0-0.7-0.2-1.4-0.7-1.8-0.5-0.5-1.1-0.8-1.8-0.8"></path>
                  <path d="m1.1 16.5q0-0.8 0.3-1.5 0.3-0.7 0.8-1.2 0.5-0.6 1.3-0.9 0.7-0.3 1.4-0.3c-0.7 0-1.3-0.2-1.8-0.7-0.5-0.5-0.7-1.1-0.7-1.8 0-0.7 0.2-1.4 0.7-1.8 0.5-0.5 1.1-0.8 1.8-0.8"></path>
               </svg>
               <span>Usuários vinculados</span>
            </div>
            <Link to="/account/devices" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor-smartphone ">
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"></path>
                  <path d="M10 19v-3.96 3.15"></path>
                  <path d="M7 19h5"></path>
                  <rect width="6" height="10" x="16" y="12" rx="2"></rect>
               </svg>
               <span>Dispositivos</span>
            </Link>
            <Link to="/account/purchases" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
               </svg>
               <span>Compras</span>
            </Link>
            <Link to="/account/preferences" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench ">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
               </svg>
               <span>Preferências</span>
            </Link>
         </div>
         <div class="sc-f7781e17-6 bwWzPR">
            <div class="sc-cbe1e9c6-1 imkLEh">
               <div class="sc-f7781e17-8 jkIyEd not-row">
                  <h1 class="sc-f7781e17-9 elsxTF">Usuários vinculados a sua conta</h1>
                  <p class="sc-cbe1e9c6-0 jKTjlK">Usuários que você vinculou em sua conta.</p>
               </div>
            </div>
            <div class="sc-cbe1e9c6-3 jqeThs">
               <div class="sc-1de34f21-0 hCzXlu">
                  <div class="sc-1de34f21-1 jDMYBf">
                     <div class="sc-1de34f21-2 cULWUu">
                        <input name="search" type="search" autocomplete="off" placeholder="Pesquisar..." class="sc-1de34f21-3 eDjLBQ" value=""/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <circle cx="11" cy="11" r="8"></circle>
                           <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                     </div>
                     <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                        <button type="button" class="sc-cf9fb95-0 iIgArl">
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path>
                           </svg>
                        </button>
                     </div>
                  </div>
                  <div class="sc-cbe1e9c6-2 RWqbL">
                     <button type="button" aria-label="Adicionar usuário" class="sc-b1836745-0 efnqmZ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus ">
                           <path d="M5 12h14"></path>
                           <path d="M12 5v14"></path>
                        </svg>
                        Adicionar usuário
                     </button>
                  </div>
               </div>
            </div>
            <div class="sc-cbe1e9c6-4 eHMxKd">
               <div class="sc-cbe1e9c6-6 hTPSsP">
                  <div class="sc-cbe1e9c6-7 gAkDyX">
                     <h2>:(</h2>
                     <span>Você não adicionou nenhum usuário</span>
                  </div>
               </div>
            </div>
            <div class="sc-cbe1e9c6-5 ijWyAA"></div>
            <div class="sc-cbe1e9c6-1 imkLEh">
               <div class="sc-f7781e17-8 jkIyEd not-row">
                  <h1 class="sc-f7781e17-9 elsxTF">Usuários vinculados a você</h1>
                  <p class="sc-cbe1e9c6-0 jKTjlK">Usuários que vincularam você em suas contas.</p>
               </div>
            </div>
            <div class="sc-cbe1e9c6-4 eHMxKd">
               <div class="sc-a998c891-0 jxPPwJ">
                  <div class="sc-a998c891-1 CMHzE">
                     <table class="sc-a998c891-2 fIduKX">
                        <thead class="sc-a998c891-3 bfHPfZ">
                           <tr>
                              <th colspan="1" class="">
                                 <div class="sc-a998c891-5 cQhwLC clickable"><span>Usuário</span></div>
                              </th>
                              <th colspan="1" class="">
                                 <div class="sc-a998c891-5 cQhwLC clickable"><span>Nível de acesso</span></div>
                              </th>
                              <th colspan="1" class="">
                                 <div class="sc-a998c891-5 cQhwLC clickable"><span>Adicionado em</span></div>
                              </th>
                              <th colspan="1" class="fixed last">
                                 <div class="sc-a998c891-5 cQhwLC"><span></span></div>
                              </th>
                           </tr>
                        </thead>
                        <tbody class="sc-a998c891-4 iodjbP">
                           <tr>
                              <td data-label="Usuário">tiegomatias@gmail.com</td>
                              <td data-label="Nível de acesso">Somente leitura</td>
                              <td data-label="Adicionado em">17 de abr. de 2024</td>
                              <th class="fixed last" data-label="">
                                 <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                                    <button type="button" class="sc-cf9fb95-0 cKVYkL">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="12" cy="5" r="1"></circle>
                                          <circle cx="12" cy="19" r="1"></circle>
                                       </svg>
                                    </button>
                                 </div>
                              </th>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>



 </>
    );
  }
}

export default UserVinc;