import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import "./srt.css"
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
class StrR extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      tp:"",
      Loader:true,
      valores:[]
    }
  }

  handleChange = (index, valor) => {
    this.setState(prevState => {
      const novosValores = [...prevState.valores];
      novosValores[index] = valor;
      // Remove o valor do estado se o novo valor for uma string vazia
      if (valor === '') {
        novosValores.splice(index, 1);
      }
      return { valores: novosValores };
    });
  };
  componentDidMount(){
    document.title  = "Carregando..."
var url = window.location.href;
const regex = /\/raffles\/([^/]+)\//;
const match = url.match(regex);
const code = match ? match[1] : null;
    fetch(''+apiUrl+'/raffles/'+code, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
         this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Sortear campanha | EuRifei`;
            
            if(result.status == "finish"){
              this.props.navigate(`/raffles/${result.slug}`)
            }else{
              this.setState({data:result,Loader:false})
            }
           
         


            })
            .catch(error => {
          console.log(error)   
          });

  }

_RenderB = (arr) =>{
return(<>
{arr.map((item,index)=><>
  <fieldset class="sc-56eec735-1 bSrfIZ">
            <div class="sc-2d577abe-0 jYSPyC"><span>🥇 Bilhete do {index + 1}º ganhador(a)</span></div>
            <div class="sc-56eec735-2 hPKeLg">
            <input  type="number" placeholder="000" maxlength="3" class="sc-56eec735-0 jDba-dm" key={item.id}
            value={item.value}
            onChange={e => this.handleChange(index, e.target.value)}/></div>
         </fieldset>
</>)}
</>)
}



   render() {
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <main class="sc-ef8bb925-0 XNzrJ">
   
   <div class="sc-ef8bb925-1 bInjkA">
      <span class="sc-ef8bb925-11 kCnksb">Campanha a ser finalizada:</span>
      <div class="sc-ef8bb925-2 fwhntf">
         <div class="sc-ef8bb925-3 ekMjZe">
            <div class="sc-ef8bb925-4 gJzOxu">
              {this.state.data.images.length > 0 ? <>
                <img src={`${apiUrl}/static/${this.state.data.images[0].name}`} alt="aaaaaaaa" width="50" height="50"/>
            
              </> : <>
              <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=60&amp;h=60&amp;fit=crop&amp;auto=format,compress" alt="aaaaaaaa" width="50" height="50"/>
            
              </>}
           
            </div>
            <div class="sc-ef8bb925-5 gpTPMR">
               <h4>{this.state.data.title}</h4>
               <span>Criada há 3 dias</span>
            </div>
         </div>
      </div>
      <fieldset class="sc-262cfb40-0 sUMyx">
         <div class="sc-2d577abe-0 jYSPyC"><span>Por onde você realizará o sorteio?</span></div>
         <label>
            <input type="radio" name="method" id="method" class="sc-262cfb40-8 iDmPEq" onClick={()=>{
             this.setState({tp:'ltr'})
            }} />
            <div class="sc-262cfb40-1 kPSVld">
               <div class="sc-262cfb40-2 jhenhz">
                  <div class="sc-262cfb40-4 blqqI icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 32 32">
                        <path d="M 16.417969 16.355469 L 24.136719 16.371094 C 26.160156 16.371094 27.242188 16.332031 28.757813 17.917969 C 31.777344 21.074219 29.605469 25.800781 24.253906 24.625 C 25.40625 29.894531 19.6875 31.832031 17.28125 28.203125 C 16.339844 26.785156 16.460938 25.414063 16.417969 23.789063 Z M 16.417969 16.355469 "></path>
                        <path d="M 15.394531 16.355469 L 7.675781 16.371094 C 5.648438 16.371094 4.570313 16.332031 3.054688 17.917969 C 0.03125 21.074219 2.207031 25.800781 7.554688 24.625 C 6.402344 29.894531 12.121094 31.832031 14.53125 28.203125 C 15.472656 26.785156 15.351563 25.414063 15.390625 23.789063 Z M 15.394531 16.355469 "></path>
                        <path d="M 15.394531 15.421875 L 7.675781 15.40625 C 5.648438 15.40625 4.570313 15.445313 3.054688 13.859375 C 0.03125 10.703125 2.207031 5.976563 7.554688 7.152344 C 6.402344 1.882813 12.121094 -0.0546875 14.53125 3.574219 C 15.472656 4.992188 15.351563 6.363281 15.390625 7.988281 Z M 15.394531 15.421875 "></path>
                        <path d="M 16.417969 15.421875 L 24.136719 15.40625 C 26.160156 15.40625 27.242188 15.445313 28.757813 13.859375 C 31.777344 10.703125 29.605469 5.976563 24.257813 7.152344 C 25.40625 1.882813 19.6875 -0.0546875 17.28125 3.574219 C 16.339844 4.992188 16.464844 6.363281 16.421875 7.988281 Z M 16.417969 15.421875 "></path>
                     </svg>
                  </div>
                  <h3 class="sc-262cfb40-3 OJeJK">Loteria Federal</h3>
                  <div class="sc-262cfb40-6 gPuqcB">
                     <div class="sc-262cfb40-7 gLgCDf option"></div>
                  </div>
               </div>
               <div class="sc-262cfb40-5 cwUvaj"><span>Sorteio realizado com base na última extração da Loteria Federal.</span><a href="https://rifei.com.br/ajuda/sorteio/loteria-federal" target="_blank" rel="noopener noreferrer">Saiba mais</a></div>
            </div>
         </label>
         <label>
            <input type="radio" name="method" id="method" class="sc-262cfb40-8 iDmPEq" onClick={()=>{
             this.setState({tp:'otr'})
            }}/>
            <div class="sc-262cfb40-1 kPSVld">
               <div class="sc-262cfb40-2 jhenhz">
                  <div class="sc-262cfb40-4 blqqI icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                     </svg>
                  </div>
                  <h3 class="sc-262cfb40-3 OJeJK">Outro</h3>
                  <div class="sc-262cfb40-6 gPuqcB">
                     <div class="sc-262cfb40-7 gLgCDf option"></div>
                  </div>
               </div>
               <div class="sc-262cfb40-5 cwUvaj"><span>Sorteio realizado em um site de sua escolha, onde você irá inserir o resultado.</span></div>
            </div>
         </label>
      </fieldset>
   </div>


{this.state.tp == "ltr" && <div class="awards">

<div class="sc-ef8bb925-1 bInjkA">
   <form action="#">
      <div class="sc-ef8bb925-6 iNySED"><span>Para realizar o sorteio pela Loteria Federal, você deve acessar o site das extrações clicando <a href="https://loterias.caixa.gov.br/Paginas/Federal.aspx" target="_blank" rel="noreferrer noopener">aqui</a> e inserir o(s) resultado(s) no(s) campo(s) abaixo.</span><span>Caso tenha dúvidas de como realizar o sorteio, acesse o nosso artigo de <a href="https://rifei.com.br/ajuda/sorteio/loteria-federal" target="_blank" rel="noreferrer noopener">como sortear pela Loteria Federal</a>.</span></div>
      <div class="sc-ef8bb925-7 XUzBQ">
    {this._RenderB(this.state.data.awards)}
      </div>
      
    
      

      <div class="sc-ef8bb925-13 cfJjJv"></div>
   </form>
</div>


</div>}

{this.state.tp == "otr" && <div  class="awards">

<div class="sc-ef8bb925-1 bInjkA">
   <form action="#">
      <div class="sc-ef8bb925-7 XUzBQ">
   {this._RenderB(this.state.data.awards)}
      </div>
      
      <div class="sc-ef8bb925-13 cfJjJv"></div>
   </form>
</div>
</div>}


<div class="sc-ef8bb925-1 bInjkA">
      <div class="sc-ef8bb925-12 hNGehF">
         <a href="/raffles/aaaaaaaa" class="sc-ef8bb925-14 eIDWww">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
         </a>
         <button type="button" disabled={this.state.valores.length > 0 ? this.state.data.awards.length == this.state.valores.length ? false : true : true} class="sc-b1836745-0 gCHqgz2" onClick={()=>{
          if(this.state.data.awards.length != this.state.valores.length){
            toast.error("Você deve infromar o número dos bilhetes!", {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return false;
          }



          fetch(`${apiUrl}/raffles/${this.state.data.id}/finish`, {
            credentials: 'include',
           method:"POST",
           headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           },
           
            body: JSON.stringify({
            numbers:this.state.valores
            })
          })
          .then(response =>{
if(response.status == 422){
  response.json().then(data=>{
    toast.error(data.errors[0].message, {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  })
}
if(response.status == 404){
   response.json().then(data=>{
     toast.error(data.errors[0].message, {
       position: "top-center",
       autoClose: 3000, // Fecha automaticamente após 3 segundos
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
   })
}
this.props.navigate(`${window.location.pathname.split("/finish")[0]}/winner`)
          })

         }}>Continuar</button>
      </div>
      <div class="sc-ef8bb925-13 cfJjJv"></div>
   </div>

   

</main>

<ToastContainer/>
 </>
    );
  }
}

export default StrR;