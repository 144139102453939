import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import { Dropdown,Drop,Item } from '../../../Comps/Dropdown';
class EdtRifinha extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    dadosFormulario:[],
    dadosOriginais: {},
    inputsAlterados: []
    }
  }

  handleChange = (event, inputName) => {
   const { dadosFormulario } = this.state;
   dadosFormulario[inputName] = event;
   this.setState({ dadosFormulario });
   if (!this.state.inputsAlterados.includes(inputName)) {
     this.setState(prevState => ({
       inputsAlterados: [...prevState.inputsAlterados, inputName]
     }));
   }
 };

 handleSubmit = (event) => {
  
   const { dadosFormulario, dadosOriginais, inputsAlterados } = this.state;
   const dadosAlterados = {};
   inputsAlterados.forEach(inputName => {
     if (dadosFormulario[inputName] !== dadosOriginais[inputName]) {
       dadosAlterados[inputName] = dadosFormulario[inputName];
     }
   });
  
   if(Object.keys(dadosAlterados).length > 0){
      this.setState({init:true})
      fetch(`${apiUrl}/rifinhas/${this.state.dadosFormulario.id}`, {
  method: 'PUT',
  credentials: 'include',
  headers: {
   'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
   'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
 },
  body: JSON.stringify(dadosAlterados)
})
.then(response => {
  if (!response.ok) {
    throw new Error('Erro ao atualizar dados');
  }
  return response.json();
})
.then(data => {
   this.setState({init:false})
  if(data.status == "ok"){
   toast.success('Alterações salvas com sucesso!', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    this.setState({inputsAlterados:[]})
  }
})
.catch(error => {
  console.error('Erro ao atualizar dados:', error);
});
     
   }else{
      toast.error('Não foram feitas alterações nos dados do formulário.', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   }
 };

 
  componentDidMount(){
document.title = "Editar campanha - EuRifei" 

var url = window.location.href;
const regex = /\/rifinhas\/(.*?)\//;
const match = url.match(regex);

document.title  = "Carregando..."
    fetch(''+apiUrl+'/rifinhas/'+match[1], {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
         this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Editar campanha | EuRifei`;
            this.setState({Loader:false,dadosFormulario:result,})
            
            })
            .catch(error => {
          console.log(error)   
          });




  }
   render() {
      const { dadosFormulario } = this.state;
  
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <div class="sc-f73caa79-1 cJfoas">
 <div class="sc-f73caa79-2 dOTmxc">
 <div class="sc-84a07fe4-0 jZNhWw">
   <a type="button" class="sc-84a07fe4-1 xZztE active">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Informações básicas</span>
   </a>
   <a type="button" class="sc-84a07fe4-1 xZztE">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="4" y1="21" x2="4" y2="14"></line>
         <line x1="4" y1="10" x2="4" y2="3"></line>
         <line x1="12" y1="21" x2="12" y2="12"></line>
         <line x1="12" y1="8" x2="12" y2="3"></line>
         <line x1="20" y1="21" x2="20" y2="16"></line>
         <line x1="20" y1="12" x2="20" y2="3"></line>
         <line x1="1" y1="14" x2="7" y2="14"></line>
         <line x1="9" y1="8" x2="15" y2="8"></line>
         <line x1="17" y1="16" x2="23" y2="16"></line>
      </svg>
      <span>Opções</span>
   </a>
   <a type="button" class="sc-84a07fe4-1 xZztE">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
      <span>Descrição</span>
   </a>
   <a type="button" class="sc-84a07fe4-1 xZztE">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
         <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
         <path d="M2 2l7.586 7.586"></path>
         <circle cx="11" cy="11" r="2"></circle>
      </svg>
      <span>Personalizações</span>
   </a>
</div>



<div class="sc-f73caa79-3 AMTpX">
   <div class="sc-f73caa79-5 jKFAiY">
      <h1 class="sc-f73caa79-4 bUDWoL">Informações básicas</h1>
     
         <div class="sc-f73caa79-6 kHJboQ">
            <div class="sc-f73caa79-7 byNUyF">
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Título</span></div>
                  <div class="sc-56eec735-2 hPKeLg">
                  <input name="title" id="title" class="sc-56eec735-0 jDba-dm2" 
                   value={dadosFormulario.title}
                   onChange={(e) => this.handleChange(e.target.value, 'title')}
                  />
                  </div>
               </fieldset>
               <fieldset class="sc-41e32b1a-1 flXygX">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Link</span>
                     <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="slug-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-41e32b1a-2 hZfkxe">
                     <div disabled="" class="sc-41e32b1a-3 hbnhBQ"><span>/</span></div>
                     <input name="slug" id="slug" disabled="true" class="sc-41e32b1a-0 gDQfeo" value={this.state.dadosFormulario.slug}/>
                  </div>
               </fieldset>
               
               <div class="sc-f73caa79-8 kMUHJV">
                  <fieldset class="sc-56eec735-1 hDxYJw">
                     <div class="sc-2d577abe-0 jYSPyC">
                        <span>Bilhetes</span>
                        <div >
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="numbers-help-tt">
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                              <line x1="12" y1="17" x2="12.01" y2="17"></line>
                           </svg>
                        </div>
                     </div>
                     <div class="sc-56eec735-2 bBSNUk">
                     <input name="numbers" id="numbers" type="tel" class="sc-56eec735-0 jDba-dm2" 
                                        value={dadosFormulario.numbers}
              onChange={(e) => {
                if(e.target.value < 100){
                    this.handleChange(e.target.value, 'numbers')
                }
                
              }
             
                                       

                                            
                                        }
                     />
                     </div>
                  </fieldset>
                  <fieldset class="sc-6ca2aeef-1 eMoWel">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Valor por bilhete</span></div>
                     <div class="sc-6ca2aeef-3 cgNEER">
                     <input name="price" id="price" type="text" class="sc-6ca2aeef-0 ittpNn" 
                      value={dadosFormulario.price}
                      onChange={(e) => this.handleChange(e.target.value, 'price')}

                     />
                     </div>
                  </fieldset>
               </div>
               <fieldset class="sc-ea2f37b4-0 qjAQi">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Telefone público para contato</span>
                     <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="phone-help-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                           <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                     </div>
                  </div>

                    
                     <div class="sc-ea2f37b4-4">
                     <input name="title" id="title" class="sc-56eec735-0 jDba-dm2" 
                       value={dadosFormulario.phone}
                       onChange={(e) => this.handleChange(e.target.value, 'phone')}
                     ></input>
                     </div>
               
               </fieldset>
            </div>
         </div>
         <div class="sc-f73caa79-9 dzqiqZ">
         <button type="submit" className={this.state.init == true ? "sc-b1836745-0 gCHqgz load"  : "sc-b1836745-0 gCHqgz"} onClick={()=>{
           this.handleSubmit()
         }}>Salvar alterações</button></div>

   </div>
</div>



 </div>
 </div>
<ToastContainer/>
 </>
    );
  }
}

export default EdtRifinha;