import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import "./w.css"
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';

class Winner extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      tp:"",
      Loader:true,
      valores:[]
    }
  }

  handleChange = (index, valor) => {
    this.setState(prevState => {
      const novosValores = [...prevState.valores];
      novosValores[index] = valor;
      // Remove o valor do estado se o novo valor for uma string vazia
      if (valor === '') {
        novosValores.splice(index, 1);
      }
      return { valores: novosValores };
    });
  };
  TimeAgo = (date) => {
    var currentDate = Math.floor(Date.now() / 1000);
    var futureDate = Math.floor(new Date(date).getTime() / 1000);
    var diff = currentDate - futureDate;

    if (diff <= 60) {
        return `${diff} segundos atrás`;
    } else if (diff <= 3600) {
        return `${Math.floor(diff / 60)} minutos atrás`;
    } else if (diff <= 86400) {
        return `${Math.floor(diff / 3600)} horas atrás`;
    } else if (diff <= 604800) {
        return `${Math.floor(diff / 86400)} dias atrás`;
    } else if (diff <= 2419200) {
        return `${Math.floor(diff / 604800)} semanas atrás`;
    } else if (diff <= 29030400) {
        return `${Math.floor(diff / 2419200)} meses atrás`;
    } else {
        return `${Math.floor(diff / 29030400)} anos atrás`;
    }
}

  componentDidMount(){
document.title  = "Carregando..."
var url = window.location.href;
const regex = /\/raffles\/([^/]+)\//;
const match = url.match(regex);
const code = match ? match[1] : null;
    fetch(''+apiUrl+'/raffles/check-winner/'+code, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

        if (response.status == 422) {
   this.props.navigate("/raffles")
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Campanha sorteada | EuRifei`;
            
           
              this.setState({data:result,Loader:false})
            
           
         


            })
            .catch(error => {
          console.log(error)   
          });

  }

_RenderB = (arr) =>{
return(<>
{arr.map((item,index)=><>
  <fieldset class="sc-56eec735-1 bSrfIZ">
            <div class="sc-2d577abe-0 jYSPyC"><span>🥇 Bilhete do {index + 1}º ganhador(a)</span></div>
            <div class="sc-56eec735-2 hPKeLg">
            <input  type="number" placeholder="000" maxlength="3" class="sc-56eec735-0 jDba-dm" key={item.id}
            value={item.value}
            onChange={e => this.handleChange(index, e.target.value)}/></div>
         </fieldset>
</>)}
</>)
}



   render() {
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <main class="sc-ef8bb925-0 XNzrJ">
   
   <div class="sc-ef8bb925-1 bInjkA">
      <span class="sc-ef8bb925-11 kCnksb">Campanha finalizada:</span>
      <div class="sc-ef8bb925-2 fwhntf">
         <div class="sc-ef8bb925-3 ekMjZe">
            <div class="sc-ef8bb925-4 gJzOxu">
              {this.state.data.images.length > 0 ? <>
                <img src={`${apiUrl}/static/${this.state.data.images[0].name}`} alt="aaaaaaaa" width="50" height="50"/>
            
              </> : <>
              <img src="https://rifei.imgix.net/storage/images/no-image.jpg?w=60&amp;h=60&amp;fit=crop&amp;auto=format,compress" alt="aaaaaaaa" width="50" height="50"/>
            
              </>}
           
            </div>
            <div class="sc-ef8bb925-5 gpTPMR">
               <h4>{this.state.data.title}</h4>
               <span>Criada há {this.TimeAgo(this.state.data.created_at)}</span>
            </div>
         </div>
      </div>
      <fieldset class="sc-262cfb40-0 sUMyx">
         <div class="sc-2d577abe-0 jYSPyC"><span>Vencedores:</span></div>
       
         
{this.state.data.winners.map((item,index)=><>


    <div class="sc-262cfb40-1 kPSVld2" style={{marginTop:'20px'}}>
               <div class="sc-262cfb40-2 jhenhz">
                  <div class="sc-262cfb40-4 blqqI icon">
                   {item.name.substring(0, 1).toUpperCase()}
                  </div>
                  <h3 class="sc-262cfb40-3 OJeJK">{item.name}</h3>
                  <div class="sc-262cfb40-6 gPuqcB">
                  </div>
               </div>
               <div class="sc-262cfb40-5 cwUvaj">
             <span>prêmio: </span>
             <a href="#">{item.premio}</a>
             </div>
            <div class="sc-262cfb40-5 cwUvaj">
             <span>Números: </span>
             <a href="#">{item.numbers}</a>
             </div>

             <div class="sc-262cfb40-5 cwUvaj">
             <span>Telefone: </span>
             <a href={`https://web.whatsapp.com/send/?phone=${item.phone}`} target="_blank">{item.phone}</a>
             </div>

             <div class="sc-262cfb40-5 cwUvaj">
             <span>E-mail: </span>
             <a href="#">{item.email}</a>
             </div>
            </div>

</>)}



         

      
      </fieldset>
   </div>








   

</main>

<ToastContainer/>
 </>
    );
  }
}

export default Winner;