import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faSleigh,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Modal from '../../Comps/Modal';
import { apiUrl } from '../../config';
import logo from "../../../src/images/logo.png"
class RdfSen extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
      vsen:false,
      senha:'',
      csenha:'',
      Loader:true
      };
   
    }

    generate_token(length){
      //edit the token allowed characters
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      var b = [];  
      for (var i=0; i<length; i++) {
          var j = (Math.random() * (a.length-1)).toFixed(0);
          b[i] = a[j];
      }
      return b.join("");
  }


   
    componentDidMount(){
      document.title = "Insiras uma nova senha | EuRifei"
      var token  = window.location.pathname.split("/")[2];
      
      fetch(`${apiUrl}/auth/verify-token`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        token
        })
      })
      .then(resposne=>{
    
    if(resposne.status == 404){
    resposne.json().then(data=>{
        this.props.navigate('/')
    })
    }


    if(resposne.status == 422){
        resposne.json().then(data=>{
            toast.success(data.errors[0].message, {
                position: "top-center",
                autoClose: 3000, // Fecha automaticamente após 3 segundos
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        })
        }



    if(resposne.status == 200){
        this.setState({Loader:false})
    }
    
    
    
      })


   }
   
 

  render() {
  
 

    if(this.state.Loader){
        return(<>
       <div class="sc-179422ed-0 keEfHG">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
               <rect x="0" y="0" width="100" height="100" fill="none"></rect>
               <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
               <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                  <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                  <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
               </circle>
            </svg>
         </div>
        </>)
      }

    return (
 <>



<main class="sc-cd0bc32a-0 hZvQEN">
   <div class="sc-cd0bc32a-1 esSVdS">
      <div class="sc-cd0bc32a-2 fWhuSh">
         <div class="sc-cd0bc32a-3 cTeDsw">
            <Link to="/" rel="noopener noreferrer" aria-label="Rifei" class="sc-cd0bc32a-4 jgVpAp">
               <img src={logo} style={{width:'100px'}}/>
            </Link>
            <h1 class="sc-cd0bc32a-5 iRBoGL">Redefinir senha</h1>
            <p class="sc-cd0bc32a-6 hqBEoH">Insira sua nova senha abaixo.</p>
         </div>
         <div class="sc-cd0bc32a-7 bYEoBo">
            <div class="sc-cd0bc32a-8 hHefzS">
       
   <fieldset class="sc-b1c12742-1 evcjtm">
      <div class="sc-2d577abe-0 jYSPyC"><span>Senha</span></div>
      <div class="sc-b1c12742-2 SVOwP">
         <input name="password" type={this.state.vsen == true ?  'text' : 'password'} onChange={(e)=>{
            this.setState({senha:e.target.value})
            if(e.target.value.length < 6 ){
                this.setState({checkSen:"A senha deve ter pelo menos 6 caracteres!"})
            }else{
                this.setState({checkSen:""})
            }
         }} onBlur={()=>{
            if(this.state.senha < 6 ){
                this.setState({checkSen:"A senha deve ter pelo menos 6 caracteres!"})
            }else{
                this.setState({checkSen:""})
            }
         }} placeholder="Digite sua nova senha" autocomplete="new-password" class="sc-b1c12742-0 dqCkxp" value={this.state.senha}/>
         <button type="button" aria-label="Mostrar senha" class="sc-b1c12742-3 fetzFm show-password" tabindex="-1"  onClick={()=>{
                this.setState({vsen:!this.state.vsen})
            }}>
           {this.state.vsen == true ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
               <circle cx="12" cy="12" r="3"></circle>
            </svg>
           </> : <>
           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
            </svg>
           </>}
            


           

         </button>
      </div>
      {this.state.checkSen != undefined && <>
        <span class="sc-b1c12742-4 dmnLE">{this.state.checkSen}</span>
      </>}
   </fieldset>
   <fieldset class="sc-b1c12742-1 evcjtm">
      <div class="sc-2d577abe-0 jYSPyC"><span>Repita a senha</span></div>
      <div class="sc-b1c12742-2 SVOwP">
         <input name="repassword" type={this.state.vsen == true ?  'text' : 'password'} onChange={(e)=>{
            this.setState({csenha:e.target.value})
            if(this.state.senha != e.target.value){
                this.setState({checkCsenha:"As senhas não coincidem!"})
            }else{
                this.setState({checkCsenha:""}) 
            }
         }}  placeholder="Repita sua nova senha" autocomplete="new-password" class="sc-b1c12742-0 dqCkxp" value={this.state.csenha}/>
        
         <button type="button" aria-label="Mostrar senha" class="sc-b1c12742-3 fetzFm show-password" tabindex="-1"  onClick={()=>{
                this.setState({vsen:!this.state.vsen})
            }}>
           {this.state.vsen == true ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
               <circle cx="12" cy="12" r="3"></circle>
            </svg>
           </> : <>
           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
            </svg>
           </>}
            


           

         </button>

      </div>
      {this.state.checkCsenha != undefined && <>
        <span class="sc-b1c12742-4 dmnLE">{this.state.checkCsenha}</span>
      </>}
   </fieldset>
   
     
   <button type="submit" class="sc-b1836745-0 jomePN submit" aria-label="Alterar"  onClick={()=>{
fetch(`${apiUrl}/forgot-password/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      // Se necessário, adicione headers de autenticação ou outros headers necessários
    },
    body: JSON.stringify({
        senha:this.state.senha,
        csenha:this.state.csenha,
        token:  window.location.pathname.split("/")[2]
    }),
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Erro ao enviar requisição');
    }
    return response.json();
  })
  .then(data => {
    console.log('Requisição PUT enviada com sucesso', data);
  })
  .catch(error => {
    console.error('Erro ao enviar requisição PUT:', error);
  });
  
    
   }} disabled={(this.state.checkSen != "" || this.state.checkCsenha != "") == true ? true : false}>Alterar</button>


            </div>
         </div>
      </div>
   </div>
   </main>




<div id="modal-root"></div>
<ToastContainer/>




 </>
    );
  }
}

export default RdfSen;