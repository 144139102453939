import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fotocapa from "./foto.webp"
import "./style.css"
import { 
   faMagnifyingGlass,
   faSleigh,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../images/logo.png"
import { apiUrl } from '../../config';
class SingIn extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
        email: '',
        isValidEmail:null,
        ClassEmail:'',
        msgEmail:"",

        senha:'',
        ShowPass:false,
        ClassPass:"",
        isValidPass:null,
        msgPass:"",
        uri:"",
        isAuthenticated:false

      };
   
    }

    LerCookie(nome) {
      // Separe todos os cookies em uma matriz
      var cookies = document.cookie.split(';');
      
      // Percorra a matriz de cookies
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        
        // Remova espaços em branco no início e no final do cookie
        while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
        }
        
        // Se o cookie começar com o nome desejado, retorne o valor do cookie
        if (cookie.indexOf(nome + '=') == 0) {
          return cookie.substring(nome.length + 1, cookie.length);
        }
      }
      
      // Se o cookie não for encontrado, retorne null
      return null;
    }
   
    componentDidMount(){
      document.title = "Entrar"

      if (this.LerCookie("auth_token") != null) {
         this.setState({ isAuthenticated: true });
        
         fetch(`${apiUrl}/account`, {
           method: 'GET',
           credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
           headers: {
             'Authorization': 'Bearer '+this.LerCookie("auth_token"), // Se necessário, adicione um cabeçalho de autorização
             'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
           }
         })
         .then(response => {
        
           if (response.status == 200) {
           
         window.location = "/raffles"
         this.setState({isAuthenticated:true})
           }else{
            document.cookie = "auth_token" + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            this.setState({isAuthenticated:false})
           }
         

        
        
         })
         
       }





      if (process.env.NODE_ENV === 'development') {
         this.setState({
         
           
            uri:"http://api.rifa.com/"
         })
      }else{
    this.setState({
    uri:"https://rifastiego.com.br/"
    })
      }







   }


   setCookie(name, value, days) {
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + value + expires + '; path=/';
    }
   
    
   handleLogin = () => {

  
     

      this.setState({Loader:true})
    
    fetch(`${apiUrl}/auth/login/`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
      email:this.state.email,
      password:this.state.senha
      })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({Loader:false})

      if(data.errors != undefined){
         toast.error(data.errors[0].message, {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }else{
        
          
      this.setCookie('auth_token',data.token, 30);
      this.props.navigate("/raffles")
      }
      
    })
    
    
    }


  render() {
  
   if(this.state.isValidEmail == true){
   var ClassEmail = "hPKeLg"
   }else if(this.state.isValidEmail == false){
      var ClassEmail = "gqprkY"
   }else if(this.state.isValidEmail == null){
      var ClassEmail = "bBSNUk"
   }



   if(this.state.isValidPass == true){
      var ClassPass = "sc-b1c12742-2 fAsIi"
      }else if(this.state.isValidPass == false){
         var ClassPass = "sc-b1c12742-2 irSrJX"
      }else if(this.state.isValidPass == null){
         var ClassPass = "sc-b1c12742-2 SVOwP"
      }


      const { isAuthenticated } = this.state;

      if (isAuthenticated) {
        return (
          <>
           <div class="sc-179422ed-0 keEfHG">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                 <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                 <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                 <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                    <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                    <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                 </circle>
              </svg>
           </div>
          </>
        );
      }




    return (
 <>



<main class="">
   <div class="sc-eaa465f5-1 lGBBi">
      <div class="sc-eaa465f5-2 fMjRrF">
         <div class="sc-eaa465f5-3 eHLOIS">
            <Link to="/" rel="noopener noreferrer"  class="sc-eaa465f5-4 bjsQhU">
              
              <img src={logo}/>

            </Link>
          
            <h1 class="sc-eaa465f5-5 kiKpGI">Bem-vindo de volta!</h1>
            <p class="sc-eaa465f5-6 fjPoaU">Insira suas informações abaixo para entrar na sua conta</p>
          
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Endereço de e-mail {JSON.stringify(this.isValid)} </span></div>
                  <div  className={ClassEmail}
                    >
                  <input placeholder="Digite seu e-mail" 
                  onBlur={()=>{
                     var email = this.state.email;
                     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                     this.setState({ isValidEmail: emailRegex.test(email) });
                     if(email.trim().length == 0){
                        this.setState({msgEmail:"Este é um campo obrigatório!"})
                     }else{
                        this.setState({msgEmail:""})
                     }
        
                  }}
                  onChange={(e)=>{
                     var email = e.target.value;
                     if(email.trim().length == 0){
                        this.setState({msgEmail:"Este é um campo obrigatório!"})
                     }else{
                        this.setState({msgEmail:""})
                     }
                     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                     this.setState({email,isValidEmail:emailRegex.test(email)})
                     if(emailRegex.test(email) == false){
                        this.setState({msgEmail:"Insira um endereço de e-mail válido!"})
                     }else{
                        this.setState({msgEmail:""})
                     }

                  }}
                  type="email" autocomplete="email" class="sc-56eec735-0 jDba-dm2" value={this.state.email}/>
                  </div>
                  {this.state.isValidEmail == false && <>
                    {this.state.msgEmail.length > 0 && <>
                     <span class="sc-babfcf74-0 kVdljX">{this.state.msgEmail}</span>
             
                    </>}
                     
                  </>}
                    </fieldset>
               <fieldset class="sc-b1c12742-1 evcjtm">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Senha</span></div>
                  <div className={ClassPass}>
                     <input  type={this.state.ShowPass == true ? "text" : "password"}
                       onChange={(e)=>{
                       var senha  = e.target.value;
                        if(senha.length < 6){
                           this.setState({isValidPass:false,msgPass:"A senha deve ter pelo menos 6 caracteres!"})
                       
                        }else{
                           this.setState({isValidPass:true,msgPass:""})
                       
                        }
                        this.setState({senha})
                        }}
                     onBlur={()=>{
                        var senha  = this.state.senha;
                        if(senha.trim().length == 0){
                           this.setState({isValidPass:false,msgPass:"Este é um campo obrigatório!"})
                           }else{
                              this.setState({isValidPass:true,msgPass:""})
                              if(senha.length < 6){
                                 this.setState({isValidPass:false,msgPass:"A senha deve ter pelo menos 6 caracteres!"})
                             
                              }else{
                                 this.setState({isValidPass:true,msgPass:""})
                             
                              }
                           }
                     }}
                     value={this.state.senha}
                     placeholder="Digite sua senha" autocomplete="password" class="sc-b1c12742-0 jDba-dm2" />
                     <button type="button" aria-label="Mostrar senha" onClick={()=>{
                        this.setState({ShowPass:!this.state.ShowPass})
                     }} class="sc-b1c12742-3 fetzFm show-password" tabindex="-1">
                        {this.state.ShowPass == true ? <>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                        </>:
                        <>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                           <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                        </>}
                      

                     </button>
                        </div>
                        {this.state.isValidPass == false && <>
                        {this.state.msgPass != "" && <>
                        <span class="sc-b1c12742-4 dmnLE">{this.state.msgPass}</span>
                        </>}
                        </>}
                 
               
               </fieldset>
               <Link to="/forgot-password" rel="noopener noreferrer" aria-label="Esqueceu sua senha?" class="sc-eaa465f5-8 eVwElK">Esqueceu sua senha?</Link>
               <div class="sc-eaa465f5-7 gtqrwy">
                  <div>
                     <div>
                      
                   
                     </div>
                  </div>
               </div>
           
               <button class={this.state.Loader == true ? "sc-b1836745-0 jomePN submit load": "sc-b1836745-0 jomePN submit"} onClick={()=>{
                  if(this.state.Loader == true){
                     return false;
                  }
                  this.handleLogin();
               }} aria-label="Entrar" disabled={
                  (this.state.isValidEmail == true && this.state.isValidPass == true) == false ? true : false
               }>Entrar</button>
          
            <div class="sc-eaa465f5-9 bbqOwu"><span>ou</span></div>
            <div class="sc-eaa465f5-10 irmRgX"><span>Ainda não tem uma conta?&nbsp;<Link to="/sign-up">Registre-se</Link></span></div>
         </div>
      </div>
         <div class="sc-eaa465f5-13 iQUznz">
         <div class="sc-eaa465f5-14 dWpFxt"><span className='est2l'><span className='est3l'>
        <img className='est4l' alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271280%27%20height=%27784%27/%3e"/></span>
        <img class="est5l" alt="Painel administrativo aberto em um navegador" src={fotocapa}  /></span></div>
         <h2>Painel rápido e prático</h2>
         <p>Acompanhe suas campanhas de perto com relatórios detalhados</p>
      </div>
   </div>
   </main>

   <ToastContainer
position="top-center"
autoClose={5000}

theme="light"

/>


 </>
    );
  }
}

export default SingIn;