import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fotocapa from "../Inicio/Section7/home-support-image.webp"
import "./style.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../../src/images/logo.png"
import fotocapas from "../SingIn/foto.webp"
import Modal from '../../Comps/Modal';
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
class SingUp extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
      nome:"",
      checkNome:null,

      email:"",
      checkEmail:null,

      senha:"",
      checkSenha:null,

      csenha:"",
      checkCsenha:null,
      cap:"aaa"
      }
      this.inputRef = React.createRef();
      this.imgRef = React.createRef();
      this.btnRef = React.createRef();
   }
   componentDidMount(){
      document.title = "Criar conta"
   }
troca= () => {
   console.log('troca')
   this.setState({cap:"bbb"})
}
   generate_token(length){
      //edit the token allowed characters
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      var b = [];  
      for (var i=0; i<length; i++) {
          var j = (Math.random() * (a.length-1)).toFixed(0);
          b[i] = a[j];
      }
      return b.join("");
  }


HandleNome = (txt) =>{
   const regex = /^[a-zA-Z]+ [a-zA-Z]+$/;

   if(txt.trim().length == 0){
   this.setState({checkNome:"Este é um campo obrigatório!"})
   }else if(txt.trim().indexOf(' ') === -1){
     
   this.setState({checkNome:"Insira seu nome completo!"})
   }else{
      this.setState({checkNome:""})
   }

}


HandleEmail = (txt) =>{
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

   if(txt.trim().length == 0){
   this.setState({checkEmail:"Este é um campo obrigatório!"})
   }else if(emailRegex.test(txt) == false){
     
   this.setState({checkEmail:"Insira um endereço de e-mail válido!"})
   }else{
   this.setState({checkEmail:""})
   }

}




HandleSenha = (txt) => {

if(this.state.csenha != ""){
   if(this.state.csenha == txt){
      this.setState({checkCsenha:""})
   }else{
      this.setState({checkCsenha:"As senhas não coincidem!"})
   }
}



   if(txt.trim().length == 0){
      this.setState({checkSenha:"Este é um campo obrigatório!"})
   }else if(txt.length < 6){
      this.setState({checkSenha:"A senha deve ter pelo menos 6 caracteres!"})
   }else{
      this.setState({checkSenha:""})
   }
}




HandleCsenha = (txt) => {
 
   if(txt.trim().length == 0){
      this.setState({checkCsenha:"Este é um campo obrigatório!"})
   }else if(this.state.senha != txt){
  
         this.setState({checkCsenha:"As senhas não coincidem!"})
      

   }
   
   else{
      this.setState({checkCsenha:""})
   }
}


  render() {
    return (
 <>


<main class="sc-e837f753-0 gICQsM">
   <div class="sc-e837f753-1 lcHQLx">
      <div class="sc-e837f753-2 jmzJji">
         <div class="sc-e837f753-3 bHmcuR">
            <Link to="/" rel="noopener noreferrer" class="sc-e837f753-4 iRTTID">
             <img src={logo} style={{width:'100px'}}/>
            </Link>
            <h1 class="sc-e837f753-5 lgGuoL">Criar conta</h1>
            <p class="sc-e837f753-6 iuSrpf">Crie a sua campanha, configure para ficar do seu jeito, e receba você mesmo o dinheiro!</p>
            
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Nome completo</span></div>
                  <div className={
          this.state.checkNome == null ? "" : this.state.checkNome != "" ? "gqprkY" : "hPKeLg"
                  }><input 
                  onChange={(e)=>{
                     this.setState({nome:e.target.value})
                     this.HandleNome(e.target.value)
                  }}
                  onBlur={(e)=>{
                    
                     this.HandleNome(this.state.nome)
                     
                  }}
                  placeholder="Digite seu nome e sobrenome" autocomplete="name" class="sc-56eec735-0 jDba-dm2" value={this.state.nome}/></div>
               
               {this.state.checkNome != ""  && <><span class="sc-babfcf74-0 kVdljX">{this.state.checkNome}</span>
              </>}
                   </fieldset>
               <fieldset class="sc-56eec735-1 bSrfIZ">
                  <div class="sc-2d577abe-0 jYSPyC"><span>Endereço de e-mail</span></div>
                 <div className={
          this.state.checkEmail == null ? "" : this.state.checkEmail != "" ? "gqprkY" : "hPKeLg"
                  }><input 
                   onChange={(e)=>{
                     this.setState({email:e.target.value})
                     this.HandleEmail(this.state.email)
                     
                  }}
                  onBlur={(e)=>{
                  
                     this.HandleEmail(this.state.email)
                     
                  }}
                  placeholder="Digite seu e-mail" type="email" autocomplete="email" class="sc-56eec735-0 jDba-dm2" value={this.state.email}/></div>
             
             {this.state.checkEmail != ""  && <><span class="sc-babfcf74-0 kVdljX">{this.state.checkEmail}</span>
              </>}
               </fieldset>
               <div class="area-senha">
                  <fieldset class="sc-56eec735-1 hDxYJw">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Senha</span></div>
                     <div className={
          this.state.checkSenha == null ? "" : this.state.checkSenha != "" ? "gqprkY" : "hPKeLg"
                  }><input 
                    onChange={(e)=>{
                     this.setState({senha:e.target.value})
                     this.HandleSenha(e.target.value)
               
                  }}
                  onBlur={()=>{
                     this.HandleSenha(this.state.senha)
                  }}
                     placeholder="Digite sua senha" type="password" autocomplete="new-password" class="sc-56eec735-0 jDba-dm2" value={this.state.senha}/></div>
                            {this.state.checkSenha != ""  && <><span class="sc-babfcf74-0 kVdljX">{this.state.checkSenha}</span>
       </>}
                      
                       </fieldset>
                  <fieldset class="sc-56eec735-1 hDxYJw">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Repita a senha</span></div>
                     <div className={
          this.state.checkCsenha == null ? "" : this.state.checkCsenha != "" ? "gqprkY" : "hPKeLg"
                  }><input 
                     onChange={(e)=>{
                     
                        this.HandleCsenha(e.target.value)
                        this.setState({csenha:e.target.value})
                        
                       
                     }}
                     onBlur={()=>{
                        this.HandleCsenha(this.state.csenha)
                     }}
                     placeholder="Repita sua senha" type="password" autocomplete="new-password" class="sc-56eec735-0 jDba-dm2" value={this.state.csenha}/></div>
{this.state.checkcSenha != ""  && <><span class="sc-babfcf74-0 kVdljX">{this.state.checkCsenha}</span>
       </>}
                  </fieldset>
               </div>
               
               <div class="sc-e837f753-12 ixaMFC"><span>Ao clicar no botão abaixo "Criar conta", você concorda com nossos&nbsp;
                <Link to="/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer">termos de uso</Link>&nbsp;e a nossa&nbsp;<Link to="/ajuda/outros/politica-de-privacidade" rel="noopener noreferrer">política de privacidade</Link>&nbsp;e confirma ter mais de 18 anos.</span></div>
               
               <button type="submit" class="sc-b1836745-0 jomePN submit" aria-label="Criar conta" onClick={()=>{
                    Modal.create({titulo:"Confirme a captcha",html:<div class="area-cpt">
                
                 <div class="cpt">
                 

                  <img ref={this.imgRef} alt="Carregando..." src={`${apiUrl}/captcha?tkn=${this.generate_token(4)}`} />


 <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{
 this.imgRef.current.src = `${apiUrl}/captcha?tkn=${this.generate_token(4)}`
 this.inputRef.current.value = ""
 }}>
    <path d="M11 2L13 3.99545L12.9408 4.05474M13 18.0001L11 19.9108L11.0297 19.9417M12.9408 4.05474L11 6M12.9408 4.05474C12.6323 4.01859 12.3183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.5264 5.17107 16.7793 7 18.2454M17 5.75463C18.8289 7.22075 20 9.47362 20 12C20 16.4183 16.4183 20 12 20C11.6716 20 11.3477 19.9802 11.0297 19.9417M13 22.0001L11.0297 19.9417" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                 
                 </div>
                 <div class="ipt">

                 <input placeholder="Captcha:" ref={this.inputRef} autocomplete="name" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
                  this.setState({cpt:e.target.value})
                 }}></input>


                 </div>
                
                <div className='bto'>
                <button className='btn-su' ref={this.btnRef} onClick={()=>{
                 
                  if(this.inputRef.current.value.trim().length == 0){
                     toast.error("O campo de CAPTCHA é obrigatório!", {
                        position: "top-center",
                        autoClose: 3000, // Fecha automaticamente após 3 segundos
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                     return false;
                  }
                  this.btnRef.current.disabled = true;
                  this.btnRef.current.classList.add('load')
                  fetch(`${apiUrl}/auth/sign-up/`, {
                     credentials: 'include',
                     method: 'POST',
                     headers: {
                       'Content-Type': 'application/json'
                     },
                     body: JSON.stringify({
                     name:this.state.nome,
                     email:this.state.email,
                     password:this.state.senha,
                     recaptcha_token:this.inputRef.current.value
                     })
                   })
                   .then(resposne=>{
                     this.btnRef.current.disabled = false;
                     this.btnRef.current.classList.remove('load')
if(resposne.status == 200){

   resposne.json().then(data=>{
      this.props.navigate("/sign-up/confirmation?id="+data.id)
   })

}

if(resposne.status == 400){
   resposne.json().then(data=>{
     
      toast.error(data.errors[0].message, {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   })
  
}

if(resposne.status == 422){
   resposne.json().then(data=>{
     
      toast.error(data.errors[0].message, {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   })
  
}

                   })
                   
                   


                  
                }}>Enviar</button>
                </div>

                    </div>})
               }} disabled={(this.state.checkNome != "" || this.state.checkEmail != "" || this.state.checkSenha != "" || this.state.checkCsenha != "") == true}>Criar conta</button>

            <div class="sc-e837f753-8 eQzWHU"><span>ou</span></div>
            <div class="sc-e837f753-9 bNyeWU"><span>Já tem uma conta?&nbsp;<Link aria-label="Login" to="/">Login</Link></span></div>
         </div>
      </div>
        <div class="sc-e837f753-15 efLyti">
         <div class="sc-e837f753-16 jmVqiI"><span className='est2'><span className='est3'>
        <img className='est4' alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271280%27%20height=%27784%27/%3e"/></span>
        <img alt="Painel administrativo aberto em um navegador"  className='est5' src={fotocapas} decoding="async" data-nimg="intrinsic"/></span></div>
         <h2>Painel rápido e prático</h2>
         <p>Acompanhe suas campanhas de perto com relatórios detalhados</p>
      </div>
   </div>
   </main>


<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default SingUp;