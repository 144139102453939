import React from "react"
import { Route, Routes, Router,useNavigate} from "react-router-dom"
import Cookies from 'js-cookie';
import Inicio from "./paginas/Inicio";
import "../src/estilo.css"
import Ajuda from "./paginas/Ajuda";
import Precos from "./paginas/Precos";
import Page404 from "./paginas/Page404";
import SobreNos from "./paginas/SobreNos";
import SingIn from "./paginas/SingIn";
import SingUp from "./paginas/SingUp";
import RecuperarSenha from "./paginas/RecuperarSenha";
import Raffles from "./paginas/Raffles";
import Header from "./Comps/Header";
import RCreate from "./paginas/RCreate";
import SlcRifa from "./paginas/SlcRifa";
import LogadoPage from "./paginas/LogadoPage";
import CheckActive from "./paginas/CheckActive";
import ConfirmEmail from "./paginas/ConfirmEmail";
import VeryMail from "./paginas/VeryMail";
import RdfSen from "./paginas/RdfSen";
import Finish from "./paginas/Finish";
import CheckPay from "./paginas/CheckPay";
import Affiliates from "./paginas/Afiliados";
import AltMail from "./paginas/AltMail";
import Personalise from "./paginas/Personalise";
import CriarRifinha from "./paginas/CriarRifinha";

function App() {
 
  const navigateTo = useNavigate();

  return (<>
 

{Cookies.get("auth_token")!= undefined ? <>
<Routes>
<Route  path="/*"  element={<LogadoPage />} />
<Route  path="/raffles/create"  element={<RCreate navigate={navigateTo} />} />
<Route  path="/checkout/active/*"  element={<CheckActive navigate={navigateTo} />} />
<Route  path="/checkout/active/pay/*"  element={<CheckPay navigate={navigateTo} />} />
<Route  path="/verification/change-email/*"  element={<AltMail navigate={navigateTo}/>} />
<Route  path="/rifinhas/create"  element={<CriarRifinha navigate={navigateTo}/>} />
</Routes>
</> : <>
<Routes>
<Route  path="/"  element={<SingIn navigate={navigateTo}/>} />
<Route  path="/sign-up"  element={<SingUp navigate={navigateTo}/>} />
<Route  path="/sign-up/confirmation/"  element={<ConfirmEmail navigate/>} />
<Route  path="/forgot-password"  element={<RecuperarSenha/>} />
<Route  path="/forgot-password/*" navigate={navigateTo} element={<RdfSen navigate={navigateTo}/>} />
<Route  path="/verify"  element={<VeryMail navigate={navigateTo}/>} />
<Route  path="/verification/change-email/*"  element={<AltMail navigate={navigateTo}/>} />
<Route  path="/:name/checkout/:id"  element={<Finish navigate={navigateTo}/>} />
<Route path="*" element={<Page404/>} />
</Routes>
</>}













  </>
    
  );
}

export default App;
