import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class AltMail extends React.Component {
 
    setCookie(name, value, days) {
        var expires = '';
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }
 
 
 
 
    componentDidMount(){
document.title = "Alterando email - EuRifei"  
var url = window.location.href;
const lastSlashIndex = url.lastIndexOf('/');
const token = url.substring(lastSlashIndex + 1);


fetch(`${apiUrl}/users/email/verify`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
    token
    })
  })
  .then(resposne=>{

if(resposne.status == 404){
resposne.json().then(data=>{
    toast.error(data.message, {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.props.navigate('/')
})
}

if(resposne.status == 200){
    resposne.json().then(data=>{
        toast.success("Email alterado com sucesso!", {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
         
          this.props.navigate('/')
    })
    }

    if(resposne.status == 422){
      resposne.json().then(data=>{
          toast.error(data.errors[0].message, {
              position: "top-center",
              autoClose: 3000, // Fecha automaticamente após 3 segundos
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.props.navigate('/')
      })
      }


  })



  }
   render() {
   
    return (
 <>
 
 <div class="sc-179422ed-0 keEfHG ckmail">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
          <span>Alterando E-mail</span>
       </div>

<ToastContainer/>
 </>
    );
  }
}

export default AltMail;