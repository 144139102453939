import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./style.css"
import { 
   faMagnifyingGlass,
   faSleigh,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Modal from '../../Comps/Modal';
import { apiUrl } from '../../config';
import logo from "../../../src/images/logo.png"
class RecuperarSenha extends React.Component {
   constructor(props) {
      super(props);
     
      this.state = {
      email:"",
      checkEmail:null,
      page:"a"
      };
      this.inputRef = React.createRef();
      this.imgRef = React.createRef();
      this.btnRef = React.createRef();
    }

    generate_token(length){
      //edit the token allowed characters
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      var b = [];  
      for (var i=0; i<length; i++) {
          var j = (Math.random() * (a.length-1)).toFixed(0);
          b[i] = a[j];
      }
      return b.join("");
  }

   
    componentDidMount(){
      document.title = "Recuperar senha | EuRifei"
   }
   
   HandleEmail = (txt) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
    if(txt.trim().length == 0){
    this.setState({checkEmail:"Este é um campo obrigatório!"})
    }else if(emailRegex.test(txt) == false){
      
    this.setState({checkEmail:"Insira um endereço de e-mail válido!"})
    }else{
       this.setState({checkEmail:""})
    }
 
 }
 



  render() {
  
 



    return (
 <>


{this.state.page == "a" ? <>
<main class="sc-a5e663d9-0 gNvUTI">
   <div class="sc-a5e663d9-1 klswjU">
      <div class="sc-a5e663d9-2 lcRcfA">
         <div class="sc-a5e663d9-3 hVLzbJ">
            <Link to="/" rel="noopener noreferrer" aria-label="Rifei" class="sc-a5e663d9-4 fmcixI">
             <img src={logo} style={{width:'100px'}}/>
            </Link>
            <h1 class="sc-a5e663d9-5 jLHUvO">Recuperar senha</h1>
            <p class="sc-a5e663d9-6 kimgqW">Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.</p>
         </div>
         <div class="sc-a5e663d9-7 bDpPkl">
            <div class="sc-a5e663d9-8 jkCqXf">
           
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Endereço de e-mail</span></div>
                     <div className={
          this.state.checkEmail == null ? "" : this.state.checkEmail != "" ? "gqprkY" : "hPKeLg"
                  }>
                    <input 
                     onChange={(e)=>{
                        
                        this.setState({email:e.target.value})
                        this.HandleEmail(this.state.email)
                        
                     }}
                     onBlur={(e)=>{
                     
                        this.HandleEmail(this.state.email)
                        
                     }}
                    placeholder="Digite seu e-mail" type="email" class="sc-56eec735-0 jDba-dm2" value={this.state.email}/></div>
                  {this.state.checkEmail != ""  && <><span class="sc-babfcf74-0 kVdljX">{this.state.checkEmail}</span>
              </>}
                  </fieldset>
                  <div class="sc-a5e663d9-9 krKZaa">
                    
                  </div>
                  <button type="submit" class="sc-b1836745-0 jomePN submit" onClick={()=>{

Modal.create({titulo:"Confirme a captcha",html:<div class="area-cpt">
                
<div class="cpt">


 <img ref={this.imgRef} alt="Carregando..." src={`${apiUrl}/captcha?tkn=${this.generate_token(4)}`} />


<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>{
this.imgRef.current.src = `${apiUrl}/captcha?tkn=${this.generate_token(4)}`
this.inputRef.current.value = ""
}}>
<path d="M11 2L13 3.99545L12.9408 4.05474M13 18.0001L11 19.9108L11.0297 19.9417M12.9408 4.05474L11 6M12.9408 4.05474C12.6323 4.01859 12.3183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.5264 5.17107 16.7793 7 18.2454M17 5.75463C18.8289 7.22075 20 9.47362 20 12C20 16.4183 16.4183 20 12 20C11.6716 20 11.3477 19.9802 11.0297 19.9417M13 22.0001L11.0297 19.9417" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>
<div class="ipt">

<input placeholder="Captcha:" ref={this.inputRef} autocomplete="name" class="sc-56eec735-0 jDba-dm2" onChange={(e)=>{
 this.setState({cpt:e.target.value})
}}></input>


</div>

<div className='bto'>
<button className='btn-su' ref={this.btnRef} onClick={()=>{

 if(this.inputRef.current.value.trim().length == 0){
    toast.error("O campo de CAPTCHA é obrigatório!", {
       position: "top-center",
       autoClose: 3000, // Fecha automaticamente após 3 segundos
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
    return false;
 }

 this.btnRef.current.disabled = true;
 this.btnRef.current.classList.add('load')
 fetch(`${apiUrl}/auth/forgot-password`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
    email:this.state.email,
    recaptcha_token:this.inputRef.current.value
    })
  })
  .then(resposne=>{
    this.btnRef.current.disabled = false;
    this.btnRef.current.classList.remove('load')


if(resposne.status == 404){
resposne.json().then(data=>{
toast.error(data.errors[0].message, {
position: "top-center",
autoClose: 3000, // Fecha automaticamente após 3 segundos
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});
})
Modal.close()
}

if(resposne.status == 200){
   this.setState({page:"b"})
  Modal.close()
}

if(resposne.status == 422){
   resposne.json().then(data=>{
       toast.error(data.errors[0].message, {
           position: "top-center",
           autoClose: 3000, // Fecha automaticamente após 3 segundos
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
   })
   }


  })
  
  


 
}}>Enviar</button>
</div>

   </div>})

                  }} disabled={
                    this.state.checkEmail == null ? "false" : this.state.checkEmail == "" ? false: true
                  }>Enviar link</button>
            
            </div>
         </div>
         <div class="sc-a5e663d9-10 fupdIY"><span>ou</span></div>
         <div class="sc-a5e663d9-11 iJwfNx">
            <Link to="/" rel="noopener noreferrer" aria-label="Voltar ao login" class="sc-b1836745-3 kkeGrI">
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" viewBox="0 0 34 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="29" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
               </svg>
               Voltar ao login
            </Link>
         </div>
      </div>
   </div>
  
   
</main>
</> : <>
<main class="sc-a5e663d9-0 kYUUWo">
   <div class="sc-a5e663d9-14 iMZqah">
      <div class="sc-a5e663d9-15 jgzmve">
         <div class="sc-a5e663d9-16 kKkvNC">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
               <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
            </svg>
         </div>
         <h2 class="sc-a5e663d9-17 ieOlhJ">Cheque seu e-mail</h2>
         <p>Caso o e-mail informado esteja vinculado a uma conta já existente, enviaremos um e-mail de recuperação de senha dentro de alguns minutos.</p>
         <p>Caso ainda não tenha recebido o e-mail, confira sua pasta de spam/lixo eletrônico.</p>
         <div class="sc-a5e663d9-18 jgawRr">
            <Link to="/" rel="noopener noreferrer" aria-label="Voltar ao login" class="sc-b1836745-0 fRDvzQ">
               <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 34 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="29" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
               </svg>
               Voltar ao login
            </Link>
         </div>
      </div>
   </div>
</main>

</>}




<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default RecuperarSenha;