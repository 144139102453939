import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import "./d.css"
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Dropdown,Drop,Item } from '../../../Comps/Dropdown';
class Description extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    dadosFormulario:[],
    dadosOriginais: {},
    inputsAlterados: [],
    active:"c",
    enable:"a",
    html:''
    }
  }



 
 
  componentDidMount(){


var url = window.location.href;
const regex = /\/raffles\/(.*?)\//;
const match = url.match(regex);

document.title  = "Carregando..."
    fetch(''+apiUrl+'/raffles/'+match[1], {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
        // this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Descrição da campanha | EuRifei`;
            this.setState({Loader:false,dadosFormulario:result,})
            
            })
            .catch(error => {
          console.log(error)   
          });




  }
   
  
  
  
  render() {
      const { dadosFormulario } = this.state;
  
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <div class="sc-f73caa79-1 cJfoas">
 <div class="sc-f73caa79-2 dOTmxc">
 <div class="sc-84a07fe4-0 jZNhWw">
   <Link to={document.location.pathname.replace("description","edit")} type="button" className={this.state.active == "a" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Informações básicas</span>
   </Link>
 
   <a type="button" className={this.state.active == "c" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
      <span>Descrição</span>
   </a>


</div>



<div class="sc-f73caa79-3 AMTpX">
<div class="sc-f73caa79-5 jKFAiY">
<h1 class="sc-47174a9-0 coSScx">Descrição</h1>

<ReactQuill
value={this.state.dadosFormulario.description.content}
      onChange={(e)=>{
        
if(this.state.dadosFormulario.description.length){

   var novaLista = [...this.state.dadosFormulario.description, {
      content:e
   }];
   this.setState({
     data: {
         ...this.state.dadosFormulario,
         description: novaLista
     }
   });
}else{

   let newData = { ...this.state.dadosFormulario };
   newData.description.content = e;
   this.setState({ dadosFormulario: newData });


}




      }}
      placeholder="Escreva algo..."
      theme="snow"
    />
<button type="submit" class="sc-b1836745-0 sv-desc" onClick={(e)=>{
   e.target.classList.add("load")
   console.log(this.state)
   fetch(`${apiUrl}/raffles/${this.state.dadosFormulario.id}/description`, {
      credentials: 'include',
      method:"POST",
      headers: {
      'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
      'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
      },
      
      body: JSON.stringify({
      html:this.state.dadosFormulario.description.content
      })
      })
      .then(response =>{
         e.target.classList.remove("load")
         toast.success("Alterações salvas com sucesso!", {
            position: "top-center",
            autoClose: 3000, // Fecha automaticamente após 3 segundos
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });     
      })
}}>Salvar alterações</button>
</div>


</div>



 </div>
 </div>
<ToastContainer/>
 </>
    );
  }
}

export default Description;