import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import "./o.css"
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown,Drop,Item } from '../../../Comps/Dropdown';
class ROptions extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    dadosFormulario:[],
    dadosOriginais: {},
    inputsAlterados: [],
    active:"b",
    enable:"a",
    vtpp:'',
    draw_date:undefined
    }
  }




  formatarT(minutos) {
  
   if (minutos < 60) {
       return [minutos,'Minutos'];
   } else if (minutos < 1440) {
       const horas = Math.floor(minutos / 60);
       return [horas,'Horas']
   } else {
       const dias = Math.floor(minutos / 1440);
       return [dias,'Dias'];
   }
}
 
Cformat = (d) => {
  
   const data = new Date(d);
   console.log(d)
   const dia = data.getDate();
   const mes = data.getMonth() + 1; // Os meses são indexados a partir de zero, então adicionamos 1
   const ano = data.getFullYear();

   return `${dia}/${mes}/${ano}`;

}

  componentDidMount(){


var url = window.location.href;
const regex = /\/raffles\/(.*?)\//;
const match = url.match(regex);

document.title  = "Carregando..."
    fetch(''+apiUrl+'/raffles/'+match[1], {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
         this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Opções de campanha | EuRifei`;

            if(result.draw_date == null){
               this.setState({draw_date:false})
            }else{
               this.setState({draw_date:true})
            }

            this.setState({Loader:false,dadosFormulario:result,vtpp:this.formatarT(result.time_to_expire_orders)[0]})
            
            })
            .catch(error => {
          console.log(error)   
          });




  }
   
  
  
  
  render() {
      const { dadosFormulario } = this.state;
  
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <div class="sc-f73caa79-1 cJfoas">
 <div class="sc-f73caa79-2 dOTmxc">
 <div class="sc-84a07fe4-0 jZNhWw">
   <Link to={document.location.pathname.replace("options","edit")} type="button" className={this.state.active == "a" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Informações básicas</span>
   </Link>



   <Link  type="button" class="sc-84a07fe4-1 xZztE" className={this.state.active == "b" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="4" y1="21" x2="4" y2="14"></line>
         <line x1="4" y1="10" x2="4" y2="3"></line>
         <line x1="12" y1="21" x2="12" y2="12"></line>
         <line x1="12" y1="8" x2="12" y2="3"></line>
         <line x1="20" y1="21" x2="20" y2="16"></line>
         <line x1="20" y1="12" x2="20" y2="3"></line>
         <line x1="1" y1="14" x2="7" y2="14"></line>
         <line x1="9" y1="8" x2="15" y2="8"></line>
         <line x1="17" y1="16" x2="23" y2="16"></line>
      </svg>
      <span>Opções</span>
   </Link>
   <Link to={document.location.pathname.replace("options","description")} type="button" className={this.state.active == "c" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
      <span>Descrição</span>
   </Link>
   <Link to={document.location.pathname.replace("options","customization")} type="button" class="sc-84a07fe4-1 xZztE" className={this.state.active == "d" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
      this.setState({active:"d"})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
         <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
         <path d="M2 2l7.586 7.586"></path>
         <circle cx="11" cy="11" r="2"></circle>
      </svg>
      <span>Personalizações</span>
   </Link>
</div>



<div class="sc-f73caa79-3 AMTpX">


<div class="sc-f73caa79-5 jKFAiY">
   <h1 class="sc-f73caa79-4 bUDWoL">Opções</h1>
   <div class="sc-b009d0df-0 dkptfc">
      <button type="button" className={this.state.enable == "a" ? "sc-b009d0df-1 iBHwYL active" : "sc-b009d0df-1 iBHwYL"} onClick={()=>{
      this.setState({enable:"a"}) }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
         </svg>
         <span>Principais</span>
      </button>
      <button type="button" className={this.state.enable == "b" ? "sc-b009d0df-1 iBHwYL active" : "sc-b009d0df-1 iBHwYL"} onClick={()=>{
      this.setState({enable:"b"}) }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M5 3a2 2 0 0 0-2 2"></path>
            <path d="M19 3a2 2 0 0 1 2 2"></path>
            <path d="M21 19a2 2 0 0 1-2 2"></path>
            <path d="M5 21a2 2 0 0 1-2-2"></path>
            <path d="M9 3h1"></path>
            <path d="M9 21h1"></path>
            <path d="M14 3h1"></path>
            <path d="M14 21h1"></path>
            <path d="M3 9v1"></path>
            <path d="M21 9v1"></path>
            <path d="M3 14v1"></path>
            <path d="M21 14v1"></path>
         </svg>
         <span>Modelo</span>
      </button>
    
   </div>
 {this.state.enable == "a" && <>

      <div class="sc-f73caa79-6 kHJboQ">
         <div class="sc-f73caa79-7 byNUyF">
            <fieldset class="sc-b009d0df-4 ggWyIk">
               <fieldset class="sc-58cc593b-0 fXBYeI">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Tempo para um pedido expirar</span>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="time_to_expire_orders-help-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                           <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                     </div>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="time_to_expire_orders-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-58cc593b-1 gpsAPQ">
                     <input name="time_to_expire_orders_input" maxlength="2" class="sc-58cc593b-2 mReub" onChange={(e)=>{
var value = (e.target.value);
var tp = (this.formatarT(this.state.dadosFormulario.time_to_expire_orders)[1])


if(tp == "Minutos"){
if(value > 10 || value < 59){
   this.setState({vtpp:value})
}
}else if(tp == "Horas"){
   if(value > 1 || value < 59){
      this.setState({vtpp:value})
   }
}else if(tp == "Dias"){
   if(value > 1 || value < 30){
      this.setState({vtpp:value})
   }
}



                     }} value={this.state.vtpp}/>
                     <div class="sc-cf9fb95-2 dvyOPd dropdown-wrapper">
                       <Dropdown>
<Drop>
<button type="button" class="sc-cf9fb95-0 dnHdYp">
                           <div class="sc-58cc593b-3 gJBNxd">
                              <span>{this.formatarT(this.state.dadosFormulario.time_to_expire_orders)[1]}</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                           </div>
                        </button>
</Drop>
<Item>
<div aria-hidden="false" role="menu"  class="sc-2cbef6dd-0 bVVCWH">
   <div class="sc-cb7ae748-1 iDhnkN"></div>
   <button type="button" class="text" onClick={()=>{


let newData = { ...this.state.dadosFormulario };
newData.time_to_expire_orders = 10;                    
this.setState({ dadosFormulario: newData });



   }}><span>Minutos</span></button>
   <button type="button" class="text" onClick={()=>{


let newData = { ...this.state.dadosFormulario };
newData.time_to_expire_orders = 60;                    
this.setState({ dadosFormulario: newData });



   }}><span>Horas</span></button>
   <button type="button" class="text" onClick={()=>{


let newData = { ...this.state.dadosFormulario };
newData.time_to_expire_orders = 1440;                    
this.setState({ dadosFormulario: newData });



   }}><span>Dias</span></button>
</div>

</Item>
                       </Dropdown>
                     

                     </div>
                  </div>
               </fieldset>
            </fieldset>
          
          
          

            <fieldset class="sc-b009d0df-4 ggWyIk">
               <fieldset class="sc-eb9b4b7f-2 gfZJgt">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Mostrar maiores compradores<small> • Opcional</small></span>
                     <div style={{height:16}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="show_top_buyers-help-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                           <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-eb9b4b7f-3 fcffpD">
         <input name="show_top_buyers" type="checkbox" id="show_top_buyers" class="sc-eb9b4b7f-0 dIfVcB" onClick={()=>{
            let newData = { ...this.state.dadosFormulario };
            newData.maior_cp = !this.state.dadosFormulario.maior_cp;
            this.setState({ dadosFormulario: newData });
         }} checked={this.state.dadosFormulario.maior_cp == 0 ? false : true}/>
         <label for="show_top_buyers" class="sc-eb9b4b7f-1 jeCmla"></label></div>
               </fieldset>
            </fieldset>
         
         </div>
      </div>
      
     
      <div class="sc-b009d0df-6 fHCvNh">
         <button type="submit"  class="sc-b1836745-0 gCHqgz" onClick={(e)=>{
e.target.classList.add('load')
fetch(`${apiUrl}/account/personalizations/rifa/`, {
   credentials: 'include',
   method:"PUT",
   headers: {
   'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
   'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
   },
   
   body: JSON.stringify({
  raffle_id:this.state.dadosFormulario.id,
  maior_cp:this.state.dadosFormulario.maior_cp == true ? 1: 0,
  time_to_expire_orders:new Number(this.state.vtpp)
   })
   })
   .then(response =>{


   })






         }}>Salvar alterações</button>
         <div class="sc-b009d0df-5 hnyCkv"></div>
      </div>
   
 </>}

 {this.state.enable == "b" && <>
 
   <fieldset class="sc-2ea4ede2-0 ldURjY">
      <div class="sc-2ea4ede2-1 kmbHkU">
         <label>
            <input name="show_numbers" type="radio" id="show_numbers" class="sc-2ea4ede2-8 cmWiqT"
            onClick={(e)=>{
               let newData = { ...this.state.dadosFormulario };
               newData.show_numbers = 0;
               this.setState({ dadosFormulario: newData });
            }}
            checked={this.state.dadosFormulario.show_numbers == 0 ? true : false}/>
            <div class="sc-2ea4ede2-4 cnwpCR">
               <div class="sc-2ea4ede2-2 dfxaGn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="176" height="158" viewBox="0 0 176 158">
                     <path fill="#e3e6eb" d="M27,14H77.856a8,8,0,0,1,8,8V40.061a8,8,0,0,1-8,8H27a8,8,0,0,1-8-8V22A8,8,0,0,1,27,14Z"></path>
                     <path fill="#e3e6eb" d="M27,51.939H77.856a8,8,0,0,1,8,8V78a8,8,0,0,1-8,8H27a8,8,0,0,1-8-8V59.939A8,8,0,0,1,27,51.939Z"></path>
                     <path fill="#e3e6eb" d="M98.144,51.939H149a8,8,0,0,1,8,8V78a8,8,0,0,1-8,8H98.144a8,8,0,0,1-8-8V59.939A8,8,0,0,1,98.144,51.939Z"></path>
                     <path fill="#e3e6eb" d="M98.144,14H149a8,8,0,0,1,8,8V40.061a8,8,0,0,1-8,8H98.144a8,8,0,0,1-8-8V22A8,8,0,0,1,98.144,14Z"></path>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(50.05 37.056) scale(0.812 0.809)">
                        <tspan x="0">+1</tspan>
                     </text>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(50.862 75.079) scale(0.812 0.809)">
                        <tspan x="0">+25</tspan>
                     </text>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(120.674 37.056) scale(0.812 0.809)">
                        <tspan x="0">+5</tspan>
                     </text>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(120.674 75.079) scale(0.812 0.809)">
                        <tspan x="0">+50</tspan>
                     </text>
                     <rect fill="#fb8c00" x="52" y="125" width="73" height="19" rx="9.5" ry="9.5"></rect>
                     <rect fill="#e3e6eb" x="61" y="96" width="55" height="15" rx="6" ry="6"></rect>
                     <rect fill="#e3e6eb" x="121" y="96" width="15" height="15" rx="7.5" ry="7.5"></rect>
                     <rect fill="#e3e6eb" x="41" y="96" width="15" height="15" rx="7.5" ry="7.5"></rect>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(88 106.711) scale(0.549 0.436)">
                        <tspan x="0">1</tspan>
                     </text>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(128.5 106.711) scale(0.471 0.436)">
                        <tspan x="0">+</tspan>
                     </text>
                     <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(48.5 106.711) scale(0.41 0.436)">
                        <tspan x="0">-</tspan>
                     </text>
                  </svg>
               </div>
               <div class="sc-2ea4ede2-7 cZsqeQ">
                  <div class="sc-2ea4ede2-5 tChdo">
                     <h3 class="sc-2ea4ede2-6 fctANy">Aleatório</h3>
                  </div>
                  <span>O usuário recebe bilhetes aleatórios</span>
               </div>
            </div>
         </label>
         <label>
            <input name="show_numbers" type="radio" id="show_numbers" class="sc-2ea4ede2-8 cmWiqT"
             onClick={(e)=>{
               let newData = { ...this.state.dadosFormulario };
               newData.show_numbers = 1;
               this.setState({ dadosFormulario: newData });
            }}
            checked={this.state.dadosFormulario.show_numbers == 1 ? true : false}/>
            <div class="sc-2ea4ede2-4 cnwpCR">
               <div class="sc-2ea4ede2-2 dfxaGn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="176" height="158" viewBox="0 0 176 158">
                     <g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M22,7H53.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H22a8,8,0,0,1-8-8V15A8,8,0,0,1,22,7Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(37.239 23.245) scale(0.571 0.57)">
                              <tspan x="0">000</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M73,7h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H73a8,8,0,0,1-8-8V15A8,8,0,0,1,73,7Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(88.572 23.245) scale(0.571 0.57)">
                              <tspan x="0">001</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M124,7h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H124a8,8,0,0,1-8-8V15A8,8,0,0,1,124,7Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(139.239 23.245) scale(0.571 0.57)">
                              <tspan x="0">002</tspan>
                           </text>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M22,35H53.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H22a8,8,0,0,1-8-8V43A8,8,0,0,1,22,35Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(37.239 51.245) scale(0.571 0.57)">
                              <tspan x="0">003</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M73,35h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H73a8,8,0,0,1-8-8V43A8,8,0,0,1,73,35Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(88.572 51.245) scale(0.571 0.57)">
                              <tspan x="0">004</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M124,35h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H124a8,8,0,0,1-8-8V43A8,8,0,0,1,124,35Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(139.239 51.245) scale(0.571 0.57)">
                              <tspan x="0">005</tspan>
                           </text>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M22,63H53.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H22a8,8,0,0,1-8-8V71A8,8,0,0,1,22,63Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(37.239 79.245) scale(0.571 0.57)">
                              <tspan x="0">006</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M73,63h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H73a8,8,0,0,1-8-8V71A8,8,0,0,1,73,63Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(88.572 79.245) scale(0.571 0.57)">
                              <tspan x="0">007</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M124,63h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H124a8,8,0,0,1-8-8V71A8,8,0,0,1,124,63Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(139.239 79.245) scale(0.571 0.57)">
                              <tspan x="0">008</tspan>
                           </text>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M22,91H53.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H22a8,8,0,0,1-8-8V99A8,8,0,0,1,22,91Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(37.239 107.245) scale(0.571 0.57)">
                              <tspan x="0">009</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M73,91h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H73a8,8,0,0,1-8-8V99A8,8,0,0,1,73,91Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(88.572 107.245) scale(0.571 0.57)">
                              <tspan x="0">010</tspan>
                           </text>
                        </g>
                        <g>
                           <path fill="#e3e6eb" fill-rule="evenodd" d="M124,91h31.009a8,8,0,0,1,8,8v8a8,8,0,0,1-8,8H124a8,8,0,0,1-8-8V99A8,8,0,0,1,124,91Z"></path>
                           <text font-size="20" fill="#212226" text-anchor="middle" font-family="Poppins" font-weight="500" transform="translate(139.239 107.245) scale(0.571 0.57)">
                              <tspan x="0">011</tspan>
                           </text>
                        </g>
                     </g>
                     <rect fill="#fb8c00" x="52" y="132" width="73" height="19" rx="9.5" ry="9.5"></rect>
                  </svg>
               </div>
               <div class="sc-2ea4ede2-7 cZsqeQ">
                  <div class="sc-2ea4ede2-5 tChdo">
                     <h3 class="sc-2ea4ede2-6 fctANy">Mostrar os bilhetes</h3>
                  </div>
                  <span>O usuário seleciona os bilhetes que desejar</span>
                  
               </div>
            </div>
         </label>
      </div>
   </fieldset>

   <div class="sc-b009d0df-6 fHCvNh">
      <button type="submit" disabled="" class="sc-b1836745-0 gCHqgz" onClick={(e)=>{
        e.target.classList.add("load")
        fetch(`${apiUrl}/account/personalizations/alterar-modelo/`, {
            credentials: 'include',
            method:"PUT",
            headers: {
            'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
            'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            },
            
            body: JSON.stringify({
           raffle_id:this.state.dadosFormulario.id,
           show_numbers:this.state.dadosFormulario.show_numbers
            })
            })
            .then(response =>{
               e.target.classList.remove("load")
               if(response.status == 200){
                  toast.success("Alterações salvas com sucesso!", {
                     position: "top-center",
                     autoClose: 3000, // Fecha automaticamente após 3 segundos
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                   });  
               }
            })
      }}>Salvar alterações</button>
      <div class="sc-b009d0df-5 hnyCkv"></div>
   </div>


 </>}

 {this.state.enable == "c" && <>
 
 <form action="#">
   <div class="sc-f73caa79-6 kHJboQ">
      <div class="sc-f73caa79-7 byNUyF">
         <fieldset class="sc-b009d0df-4 ggWyIk">
            <fieldset class="sc-eb9b4b7f-2 gfZJgt">
               <div class="sc-2d577abe-0 jYSPyC">
                  <span>Solicitar e-mail<small> • Opcional</small></span>
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="require_email-help-tt">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                     </svg>
                  </div>
               </div>
               <div class="sc-eb9b4b7f-3 fcffpD"><input name="require_email" type="checkbox" id="require_email" class="sc-eb9b4b7f-0 dIfVcB" value="false"/><label for="require_email" class="sc-eb9b4b7f-1 jeCmla"></label></div>
            </fieldset>
            <div class="sc-b009d0df-2 cFWiZa"><span>Ao utilizar o Mercado Pago como forma de pagamento não é possível desabilitar o e-mail obrigatório.</span></div>
         </fieldset>
         <fieldset class="sc-b009d0df-4 ggWyIk">
            <fieldset class="sc-eb9b4b7f-2 gfZJgt">
               <div class="sc-2d577abe-0 jYSPyC">
                  <span>Solicitar CPF/CNPJ<small> • Opcional</small></span>
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="require_document-help-tt">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                     </svg>
                  </div>
               </div>
               <div class="sc-eb9b4b7f-3 fcffpD"><input name="require_document" type="checkbox" id="require_document" class="sc-eb9b4b7f-0 dIfVcB" value="false"/><label for="require_document" class="sc-eb9b4b7f-1 jeCmla"></label></div>
            </fieldset>
         </fieldset>
         <fieldset class="sc-b009d0df-4 ggWyIk">
            <fieldset class="sc-eb9b4b7f-2 gfZJgt">
               <div class="sc-2d577abe-0 jYSPyC">
                  <span>Solicitar data de nascimento<small> • Opcional</small></span>
                  <div >
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="require_date_of_birth-help-tt">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                     </svg>
                  </div>
               </div>
               <div class="sc-eb9b4b7f-3 fcffpD"><input name="require_date_of_birth" type="checkbox" id="require_date_of_birth" class="sc-eb9b4b7f-0 dIfVcB" value="false"/><label for="require_date_of_birth" class="sc-eb9b4b7f-1 jeCmla"></label></div>
            </fieldset>
         </fieldset>
      </div>
   </div>
   <div class="sc-b009d0df-6 fHCvNh">
      <button type="submit" disabled="" class="sc-b1836745-0 gCHqgz">Salvar alterações</button>
      <div class="sc-b009d0df-5 hnyCkv"></div>
   </div>
</form>

 
 </>}
</div>



</div>



 </div>
 </div>
<ToastContainer/>
 </>
    );
  }
}

export default ROptions;