import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./dps.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
class Dispositivos extends React.Component {
   constructor(props) {
      super(props);
     
this.state = {
Loader:true
}

  }
  TimeAgo(date) {
   
   var currentDate = Math.abs((new Date().getTime() / 1000).toFixed(0));
   var futureDate = Math.abs((new Date(date).getTime() / 1000).toFixed(0));
   var diff = currentDate - futureDate;
   
   var days = Math.floor(diff / 86400); var hours = Math.floor(diff / 3600) % 24;
   var minutes = Math.floor(diff / 60);
   var weeks   = Math.floor(diff / 604800);
   var months  = Math.floor(diff / 2600640);
   var years   = Math.floor(diff / 31207680);
   var seconds = diff;
   
   if (seconds <= 60) {
     return `${seconds} segundos atrás`;
   }
   //Minutes
   else if (minutes <= 60) {
     if (minutes == 1) {
         return "1 minuto";
     } else {
         return `${minutes} minutos`
     }
   }
   //Hours
   else if (hours <= 24) {
     if (hours == 1) {
         return "1 hora";
     } else {
         return `${hours} horas`;
     }
   }
   //Days
   else if (days <= 7) {
     if (days == 1) {
         return "ontem";
     } else {
         return `${days} dias`;
     }
   }
   //Weeks
   else if (weeks <= 4.3) {
     if (weeks == 1) {
         return "1 semana";
     } else {
         return `${weeks} semanas`;
     }
   }
   //Months
   else if (months <= 12) {
     if (months == 1) {
         return "1 mês";
     } else {
         return `${months} mêses`;
     }
   }
   //Years
   else {
     if (years == 1) {
         return "1 ano";
     } else {
         return `${years}  anos`;
     }
   }
   
   
   
   
   }

  componentDidMount(){
document.title = "Dispositivos - EuRifei"   
fetch(`${apiUrl}/account/sessions`, {
   method: 'GET',
   credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
   headers: {
     'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
     'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
   }
 })
 .then(response => {
response.json().then(data=>{
   this.setState({data,Loader:false})
})
 }) 
  }
   render() {
      if(this.state.Loader){
         return(<>
        <div class="sc-179422ed-0 keEfHG">
             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                   <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                   <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                </circle>
             </svg>
          </div>
         </>)
       }
    return (
 <>
 
 <div class="sc-f7781e17-0 ebFOLR">
   <div class="sc-f7781e17-1 ivElUD">
      <div class="sc-f7781e17-2 gYYPbP">
        
         <div class="sc-84a07fe4-0 jZNhWw">
            <Link to="/account" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-database ">
                  <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                  <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                  <path d="M3 12A9 3 0 0 0 21 12"></path>
               </svg>
               <span>Meus dados</span>
            </Link>
            <Link to="/account/linked-users" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill-rule="evenodd">
                  <path d="m18 19c0-1.6-0.6-3.1-1.8-4.2-1.1-1.2-2.6-1.8-4.2-1.8-1.6 0-3.1 0.6-4.2 1.8-1.2 1.1-1.8 2.6-1.8 4.2"></path>
                  <path d="m12 13c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4 0 2.2-1.8 4-4 4z"></path>
                  <path d="m22.9 16.5q0-0.8-0.3-1.5-0.3-0.7-0.8-1.2-0.5-0.6-1.3-0.9-0.7-0.3-1.4-0.3c0.7 0 1.3-0.2 1.8-0.7 0.5-0.5 0.7-1.1 0.7-1.8 0-0.7-0.2-1.4-0.7-1.8-0.5-0.5-1.1-0.8-1.8-0.8"></path>
                  <path d="m1.1 16.5q0-0.8 0.3-1.5 0.3-0.7 0.8-1.2 0.5-0.6 1.3-0.9 0.7-0.3 1.4-0.3c-0.7 0-1.3-0.2-1.8-0.7-0.5-0.5-0.7-1.1-0.7-1.8 0-0.7 0.2-1.4 0.7-1.8 0.5-0.5 1.1-0.8 1.8-0.8"></path>
               </svg>
               <span>Usuários vinculados</span>
            </Link>
            <Link to="/account/devices" class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor-smartphone ">
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"></path>
                  <path d="M10 19v-3.96 3.15"></path>
                  <path d="M7 19h5"></path>
                  <rect width="6" height="10" x="16" y="12" rx="2"></rect>
               </svg>
               <span>Dispositivos</span>
            </Link>
            <Link to="/account/purchases" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
               </svg>
               <span>Compras</span>
            </Link>
            <Link to="/account/preferences" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench ">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
               </svg>
               <span>Preferências</span>
            </Link>
         </div>
         
         <div class="sc-f7781e17-6 bwWzPR">
   <div class="sc-1e31ac01-1 bFeclQ">
      <div class="sc-f7781e17-8 jkIyEd not-row">
         <h1 class="sc-f7781e17-9 elsxTF">Dispositivos</h1>
         <p class="sc-1e31ac01-0 dZAAqz mt">Aqui estão todos os dispositivos que estão atualmente logados com sua conta. Você pode sair de cada um individualmente ou de todos os outros dispositivos.</p>
         <p class="sc-1e31ac01-0 dZAAqz">Se você ver uma entrada que não reconhece, saia desse dispositivo e altere a senha da sua conta imediatamente.</p>
      </div>
      <small class="sc-1e31ac01-2 chacFn">Dispositivo atual</small>
      <div class="sc-1e31ac01-3 etEMtM">
         <div class="sc-1e31ac01-4 jhRpfw">
            <div class="sc-1e31ac01-5 kwOPIl">
            {this.state.data.currentSession.device == "mobile" ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-smartphone "><rect width="14" height="20" x="5" y="2" rx="2" ry="2"></rect><path d="M12 18h.01"></path></svg>
            </> : <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor ">
                  <rect width="20" height="14" x="2" y="3" rx="2"></rect>
                  <line x1="8" x2="16" y1="21" y2="21"></line>
                  <line x1="12" x2="12" y1="17" y2="21"></line>
               </svg>
            </>}
              
            </div>
            <div class="sc-1e31ac01-6 ga-DevN">
               <div class="sc-1e31ac01-7 cZITYS"><span>{this.state.data.currentSession.user_agent}</span></div>
               <div class="sc-1e31ac01-8 bLcoqu"><span>Conectado há {this.TimeAgo(this.state.data.currentSession.created_at)} </span></div>
            </div>
         </div>
      </div>
      <small class="sc-1e31ac01-2 chacFn">Outros dispositivos</small>
      <div class="sc-1e31ac01-3 etEMtM">
        {this.state.data.sessions.length > 0 ? <>
        {this.state.data.sessions.map((item,index)=><>
         <div class="sc-1e31ac01-4 jhRpfw">
            <div class="sc-1e31ac01-5 kwOPIl">
            {item.device == "mobile" ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-smartphone "><rect width="14" height="20" x="5" y="2" rx="2" ry="2"></rect><path d="M12 18h.01"></path></svg>
            </> : <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor ">
                  <rect width="20" height="14" x="2" y="3" rx="2"></rect>
                  <line x1="8" x2="16" y1="21" y2="21"></line>
                  <line x1="12" x2="12" y1="17" y2="21"></line>
               </svg>
            </>}
              
            </div>
            <div class="sc-1e31ac01-6 ga-DevN">
               <div class="sc-1e31ac01-7 cZITYS"><span>{item.user_agent}</span></div>
               <div class="sc-1e31ac01-8 bLcoqu"><span>Conectado há {this.TimeAgo(item.created_at)} </span></div>
            </div>
            <button type="button" aria-label="Desconectar" class="sc-1e31ac01-9 cznJWX"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x "><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg></button>
         </div>
        </>)}
        </> : <>
        <div class="sc-1e31ac01-4 jhRpfw">
            <div class="sc-1e31ac01-5 kwOPIl">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-box-select ">
                  <path d="M5 3a2 2 0 0 0-2 2"></path>
                  <path d="M19 3a2 2 0 0 1 2 2"></path>
                  <path d="M21 19a2 2 0 0 1-2 2"></path>
                  <path d="M5 21a2 2 0 0 1-2-2"></path>
                  <path d="M9 3h1"></path>
                  <path d="M9 21h1"></path>
                  <path d="M14 3h1"></path>
                  <path d="M14 21h1"></path>
                  <path d="M3 9v1"></path>
                  <path d="M21 9v1"></path>
                  <path d="M3 14v1"></path>
                  <path d="M21 14v1"></path>
               </svg>
            </div>
            <div  class="sc-1e31ac01-8 bLcoqu"><span>Nenhum outro dispositivo conectado</span></div>
         </div>
        </>}
         

      </div>
   </div>
   <hr style={{marginTop:32}} />
   <div class="sc-1e31ac01-1 bFeclQ">
      <small class="sc-1e31ac01-2 chacFn">Desconectar de todos os dispositivos</small>
      <p class="sc-1e31ac01-0 dZAAqz">Isso desconectará você de todos os dispositivos, exceto do que você está usando agora.</p>
      <button type="button" aria-label="Desconectar de todos os dispositivos" style={{marginTop:24}} class="sc-b1836745-0 dKFVNT">Desconectar de todos os dispositivos</button>
   </div>
</div>

      </div>
   </div>
</div>



 </>
    );
  }
}

export default Dispositivos

;