import React from 'react';
import "./afiliado.css"
import { Link } from 'react-router-dom';
class Afiliados extends React.Component {
    constructor(props) {
    super(props);
       
    this.state = {
   
    }
}


   render() {
   

return(<>
<div class="sc-34a8e9fc-0 erUtcS">
   <div class="sc-34a8e9fc-1 kssrjF">
      <div class="sc-34a8e9fc-2 fSquRF">
         <h1>Afiliados</h1>
         <span>Selecione uma opção abaixo</span>
      </div>
      <div class="sc-34a8e9fc-3 hCzFkd">
         <div class="sc-34a8e9fc-4 ctOqvM">
            <div class="sc-34a8e9fc-5 kpkmxc">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                  <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
               </svg>
            </div>
            <div class="sc-34a8e9fc-6 kHHrgW">Sou criador</div>
            <span>Adicione e gerencie afiliados para a sua campanha</span>
            <Link to="/affiliates/manage" class="sc-34a8e9fc-7 dxsKfQ">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
               </svg>
               Gerenciar
            </Link>
         </div>
         <div class="sc-34a8e9fc-4 ctOqvM">
            <div class="sc-34a8e9fc-5 mTdQp">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
               </svg>
            </div>
            <div class="sc-34a8e9fc-6 hlWMSD">Sou afiliado</div>
            <span>Gerencie seu link de afiliado, veja suas comissões e mais</span>
            <Link to="/affiliates/affiliate" class="sc-34a8e9fc-7 dxsKfQ">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="12" y1="20" x2="12" y2="10"></line>
                  <line x1="18" y1="20" x2="18" y2="4"></line>
                  <line x1="6" y1="20" x2="6" y2="16"></line>
               </svg>
               Acessar
            </Link>
         </div>
      </div>
   </div>
</div>

</>)

  }
}

export default Afiliados;