import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
class Footer extends React.Component {
  render() {
    return (
 <>
 
 <footer class="sc-64a453c2-0 fumfAL">
   <div class="sc-64a453c2-1 gUsOcN">
      <div class="sc-64a453c2-2 ytogt">
         <div class="sc-64a453c2-3 bZFNeE">
            <div>
               <a aria-label="Rifei" href="/?utm_source=footer&amp;utm_medium=logo&amp;utm_campaign=site">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="#000000" viewBox="0 0 100 45">
                     <path fill="#ffffff" d="m6.5 19.1l83.8-13.9 3.3 19.9-83.8 13.9z"></path>
                     <path d="M0,17.391L100,0V5.171a3.321,3.321,0,0,0-3.179,2.919A3.231,3.231,0,0,0,100,11.469v5.255a3.107,3.107,0,0,0,0,6.214v4.8L0,45V17.391ZM30.43,33.83l4.742-.822-3.24-6.024a5.217,5.217,0,0,0,2.183-2.44,5.543,5.543,0,0,0,.311-3.174,4.727,4.727,0,0,0-.536-1.516,3.183,3.183,0,0,0-1.059-1.112,4.125,4.125,0,0,0-1.635-.58,7.069,7.069,0,0,0-2.244.076l-7.22,1.252L22.1,35.274l4.486-.778-0.154-5.889,1.26-.218Zm-4.058-8.495-0.1-3.135,1.645-.285a2.378,2.378,0,0,1,1.432.065,1.1,1.1,0,0,1,.559.842,1.782,1.782,0,0,1-.057.971,1.568,1.568,0,0,1-.486.667,2.55,2.55,0,0,1-.784.417,6.136,6.136,0,0,1-.927.236Zm15.782-6.687,1.624-.281-0.043-2.691L35.98,17.019l0.064,2.688,1.6-.278,0.239,10.409-1.623.281L36.347,32.8l7.733-1.34-0.064-2.688-1.6.278Zm13.5,1.569-5.276.915-0.088-2.943,6.067-1.052L56.273,13.5,45.7,15.334l0.367,15.785,4.529-.785-0.138-5.676,5.276-.915ZM67.892,17.49l-5.362.929-0.068-2.342,6.045-1.048-0.058-3.639L57.875,13.224l0.367,15.785,10.83-1.877-0.084-3.656-6.3,1.092-0.07-2.6,5.362-.929Zm8.46-4.77,1.623-.281L77.933,9.747l-7.754,1.344,0.064,2.688,1.6-.278L72.084,23.91l-1.623.281,0.085,2.684,7.733-1.34-0.064-2.688-1.6.278Z"></path>
                  </svg>
               </a>
               <p>Plataforma para você criar e gerenciar suas campanhas com segurança e praticidade</p>
            </div>
            <div class="sc-64a453c2-4 djplug">
               <a href="https://instagram.com/siterifei" target="_blank" rel="noopener noreferrer" aria-label="Instagram" class="sc-64a453c2-5 bZYRJN secondary-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram ">
                     <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                     <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                     <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
                  </svg>
               </a>
               <a href="https://facebook.com/siterifei" target="_blank" rel="noopener noreferrer" aria-label="Facebook" class="sc-64a453c2-5 bZYRJN secondary-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook ">
                     <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                  </svg>
               </a>
            </div>
         </div>
         <div class="sc-64a453c2-7 dmIaEy">
            <div>
               <h3>Páginas</h3>
               <a aria-label="Início" href="/">Início</a><a aria-label="Preço" href="/precos">Preço</a><a aria-label="Sobre nós" href="/sobre-nos">Sobre nós</a><a aria-label="Termos de uso" href="/ajuda/outros/termos-de-uso">Termos de uso</a><a aria-label="Política de Privacidade" href="/ajuda/outros/politica-de-privacidade">Política de Privacidade</a>
            </div>
            <div>
               <h3>Ajuda</h3>
               <a aria-label="Central de ajuda" href="/ajuda">Central de ajuda</a><a aria-label="Dúvidas frequentes" href="/#faq">Dúvidas frequentes</a>
            </div>
            <div>
               <h3>Contato</h3>
               <a href="https://wa.me/554892100283" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">WhatsApp</a><a href="mailto:contato@rifei.com.br" target="_blank" rel="noopener noreferrer" aria-label="E-mail">contato@rifei.com.br</a>
            </div>
         </div>
      </div>
      <div class="sc-64a453c2-2 ytogt last-footer-content">
         <div>
            <span>
               Copyright © 2021 - 2024 Rifei
            </span>
            <span>Todos os direitos reservados</span>
         </div>
         <div>
            <button type="button" aria-label="Voltar ao topo da página" class="sc-64a453c2-6 cPlCeG secondary-button">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up ">
                  <path d="m5 12 7-7 7 7"></path>
                  <path d="M12 19V5"></path>
               </svg>
               Voltar ao topo
            </button>
         </div>
      </div>
   </div>
</footer>




 </>
    );
  }
}

export default Footer;