import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./pref.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
class Preferences extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
ck1:true,
ck2:true,
Loader:true
        }
    }


    

   converterParaBooleano(objeto) {
      const novoObjeto = {};
      for (let chave in objeto) {
        novoObjeto[chave] = objeto[chave] === 1;
      }
      return novoObjeto;
    }



  componentDidMount(){
document.title = "Preferências - EuRifei"
fetch(`${apiUrl}/account/settings`, {
   method: 'GET',
   credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
   headers: {
     'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
     'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
   }
 })
 .then(response => {
response.json().then(data=>{
   this.setState({data,Loader:false})
})
 })   
  }
   render() {
      if(this.state.Loader){
         return(<>
        <div class="sc-179422ed-0 keEfHG">
             <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
                <rect x="0" y="0" width="100" height="100" fill="none"></rect>
                <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
                <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                   <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                   <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
                </circle>
             </svg>
          </div>
         </>)
       }
    return (
 <>
 
 <div class="sc-f7781e17-0 ebFOLR">
   <div class="sc-f7781e17-1 ivElUD">
      <div class="sc-f7781e17-2 gYYPbP">
        
         <div class="sc-84a07fe4-0 jZNhWw">
            <Link to="/account" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-database ">
                  <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                  <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                  <path d="M3 12A9 3 0 0 0 21 12"></path>
               </svg>
               <span>Meus dados</span>
            </Link>
            <Link to="/account/linked-users" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill-rule="evenodd">
                  <path d="m18 19c0-1.6-0.6-3.1-1.8-4.2-1.1-1.2-2.6-1.8-4.2-1.8-1.6 0-3.1 0.6-4.2 1.8-1.2 1.1-1.8 2.6-1.8 4.2"></path>
                  <path d="m12 13c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4 0 2.2-1.8 4-4 4z"></path>
                  <path d="m22.9 16.5q0-0.8-0.3-1.5-0.3-0.7-0.8-1.2-0.5-0.6-1.3-0.9-0.7-0.3-1.4-0.3c0.7 0 1.3-0.2 1.8-0.7 0.5-0.5 0.7-1.1 0.7-1.8 0-0.7-0.2-1.4-0.7-1.8-0.5-0.5-1.1-0.8-1.8-0.8"></path>
                  <path d="m1.1 16.5q0-0.8 0.3-1.5 0.3-0.7 0.8-1.2 0.5-0.6 1.3-0.9 0.7-0.3 1.4-0.3c-0.7 0-1.3-0.2-1.8-0.7-0.5-0.5-0.7-1.1-0.7-1.8 0-0.7 0.2-1.4 0.7-1.8 0.5-0.5 1.1-0.8 1.8-0.8"></path>
               </svg>
               <span>Usuários vinculados</span>
            </Link>
            <Link to="/account/devices" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor-smartphone ">
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"></path>
                  <path d="M10 19v-3.96 3.15"></path>
                  <path d="M7 19h5"></path>
                  <rect width="6" height="10" x="16" y="12" rx="2"></rect>
               </svg>
               <span>Dispositivos</span>
            </Link>
            <Link to="/account/purchases" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
               </svg>
               <span>Compras</span>
            </Link>
            <Link to="/account/preferences" class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench ">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
               </svg>
               <span>Preferências</span>
            </Link>
         </div>

         <div class="sc-f7781e17-6 bwWzPR">
   <div class="sc-f7781e17-7 jtgWWa">
      <div class="sc-f7781e17-8 jkIyEd not-row">
         <h1 class="sc-f7781e17-9 elsxTF">Preferências</h1>
      </div>
      
         <button type="button" className={!this.state.ck1 ? "sc-b9946f39-0 kzGWdA" : "sc-b9946f39-0 jGLsDr"} onClick={()=>{
            this.setState({ck1:!this.state.ck1})
         }}>
            <div>
               <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                     <path d="M18 20a6 6 0 0 0-12 0"></path>
                     <circle cx="12" cy="10" r="4"></circle>
                     <circle cx="12" cy="12" r="10"></circle>
                  </svg>
               </div>
               <span>Conta</span>
            </div>
            <div class="chevron">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                  <path d="m6 9 6 6 6-6"></path>
               </svg>
            </div>
         </button>
         {this.state.ck1 && <>
            <div class="sc-b9946f39-1 jiHENQ">
            <fieldset class="sc-eb9b4b7f-2 cCoDyY">
               <div class="sc-2d577abe-0 jYSPyC">
                  <span>Receber convites de afiliado</span>
                  <div style={{height:16}}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="affiliate_invites-help-tt">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                     </svg>
                  </div>
               </div>
               <div class="sc-eb9b4b7f-3 fcffpD">
               <input name="affiliate_invites" type="checkbox" id="affiliate_invites" class="sc-eb9b4b7f-0 dIfVcB" onClick={()=>{
                 let newData = { ...this.state.data };
                 newData.affiliate_invites = !this.state.data.affiliate_invites;
                 this.setState({ data: newData });
                 }}  checked={this.state.data.affiliate_invites}/>
               <label for="affiliate_invites" class="sc-eb9b4b7f-1 jeCmla"></label></div>
            </fieldset>
            <fieldset class="sc-eb9b4b7f-2 gfZJgt" style={{marginTop:32}}>
            
            <div class="sc-2d577abe-0 jYSPyC">
                <span>Receber convites para se vincular à um usuário</span>
            </div>

               <div class="sc-eb9b4b7f-3 fcffpD">
                
                <input name="linked_user_invites" type="checkbox" id="linked_user_invites" class="sc-eb9b4b7f-0 dIfVcB" onClick={()=>{
                 let newData = { ...this.state.data };
                 newData.linked_user_invites = !this.state.data.linked_user_invites;
                 this.setState({ data: newData });
                 }}  checked={this.state.data.linked_user_invites}/>
               
                <label for="linked_user_invites" class="sc-eb9b4b7f-1 jeCmla"></label>
                
                </div>
            </fieldset>
         </div>
         </>}
        

         <button type="button" id="notifications" className={!this.state.ck2 ? "sc-b9946f39-0 kzGWdA" : "sc-b9946f39-0 jGLsDr"} onClick={()=>{
            this.setState({ck2:!this.state.ck2})
         }}>
            <div>
               <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bell icon">
                     <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
                     <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
                  </svg>
               </div>
               <span>Notificações</span>
            </div>
            <div class="chevron">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                  <path d="m6 9 6 6 6-6"></path>
               </svg>
            </div>
         </button>
         {this.state.ck2 && <>
            <div class="sc-b9946f39-1 jiHENQ">

            <fieldset class="sc-eb9b4b7f-2 cCoDyY">
               <div class="sc-2d577abe-0 jYSPyC"><span>Novos pedidos reservados</span></div>
               <div class="sc-eb9b4b7f-3 fcffpD">
               <input name="browser_new_reserves" type="checkbox" id="browser_new_reserves" class="sc-eb9b4b7f-0 dIfVcB" onClick={()=>{
                 let newData = { ...this.state.data };
                 newData.browser_new_reserves = !this.state.data.browser_new_reserves;
                 this.setState({ data: newData });
                 }}  checked={this.state.data.browser_new_reserves} />
               <label for="browser_new_reserves" class="sc-eb9b4b7f-1 jeCmla"></label>
               </div>
            </fieldset>

            <fieldset class="sc-eb9b4b7f-2 gfZJgt" style={{marginTop:32}}>
               <div class="sc-2d577abe-0 jYSPyC">
                  <span>Novos pedidos pagos</span>
                  <div style={{height:'16px'}}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="browser_new_payed-alert-tt">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                     </svg>
                  </div>
               </div>
               <div class="sc-eb9b4b7f-3 fcffpD">
               <input name="browser_new_payed" type="checkbox" id="browser_new_payed" class="sc-eb9b4b7f-0 dIfVcB" onClick={()=>{
                 let newData = { ...this.state.data };
                 
                 newData.browser_new_payed = !this.state.data.browser_new_payed;
                 this.setState({ data: newData });
                 }}  checked={this.state.data.browser_new_payed}/><label for="browser_new_payed" class="sc-eb9b4b7f-1 jeCmla"></label></div>
            </fieldset>
         </div>
         </>}
         <div class="sc-f7781e17-12 emavYa"><button type="submit" disabled="" class="sc-b1836745-0 gCHqgz" onClick={(e)=>{


e.target.classList.add('load')
fetch(`${apiUrl}/account/settings`, {
   method: 'PUT',
   credentials: 'include',
   headers: {
    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
  },
   body: JSON.stringify({
      sets:{
         "browser_new_reserves": this.state.data.browser_new_reserves == true ? 1 : 0,
         "browser_new_payed": this.state.data.browser_new_payed  == true ? 1 : 0,
         "affiliate_invites": this.state.data.affiliate_invites  == true ? 1 : 0,
         "linked_user_invites": this.state.data.linked_user_invites  == true ? 1 : 0
       }
   })
 })
 .then(response => {
   e.target.classList.remove('load')
   if(response.status == 200){
      toast.success('Alterações salvas com sucesso!', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   }
 })

         }}>Salvar alterações</button></div>
     
   </div>
 
</div>


      </div>
   </div>
</div>


<ToastContainer/>
 </>
    );
  }
}

export default Preferences;

