import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./slcr.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
import Cookies from 'js-cookie';
import { Dropdown,Drop,Item } from '../../Comps/Dropdown';
class SlcRifinha extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
        Loader:true,
        data:[],
        page:'a'
        
        }
    }
    _Resumo(){
      var html = `<button type="button" aria-label="Resumo de vendas" aria-expanded="false" class="sc-b9946f39-0 kzGWdA">
      <div>
         <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
               <line x1="12" y1="20" x2="12" y2="10"></line>
               <line x1="18" y1="20" x2="18" y2="4"></line>
               <line x1="6" y1="20" x2="6" y2="16"></line>
            </svg>
         </div>
         <span>Resumo de vendas</span>
      </div>
      <div class="chevron">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
         </svg>
      </div>
   </button>`
   return(html)
    }
    _ToBuyes = (id) =>{

      if(this.state.LoadTb == undefined){
        this._LoadTp('?startDate=all&endDate=today&allowTime=false')
      }
   
      if(this.state.LoadTb == undefined){
   return<>
      <span class="react-loading-skeleton" style={{
     width: '100%',
     height: '60px',
     '--base-color': '#C0C4CF',
     '--highlight-color': '#EBEDF4',
   }}>‌</span>
     <span class="react-loading-skeleton" style={{
      marginTop:'10px',
     width: '100%',
     height: '60px',
     '--base-color': '#C0C4CF',
     '--highlight-color': '#EBEDF4',
   }}>‌</span>
   
   <span class="react-loading-skeleton" style={{
      marginTop:'10px',
     width: '100%',
     height: '60px',
     '--base-color': '#C0C4CF',
     '--highlight-color': '#EBEDF4',
   }}>‌</span>
   </>
      }
   
   
   return (<>
   
   {this.state.dataTp.length > 0 ? <>
      {this.state.dataTp.map((item,index)=><>
   <div class="sc-58116526-1 fuASIX">
               <div class="sc-58116526-6 fEihHu">
                  <div>
                     <div class="sc-58116526-7 iVwBLN position-1">
                        <h2>{index+1}º</h2>
                     </div>
                  </div>
                  <div class="sc-58116526-8 jyXfvY">
                     <h4 class="sc-58116526-9 bYCMZz">{item.buyer_name}</h4>
                     <div class="sc-58116526-2 cyuDyL">
                        <h4 class="sc-58116526-3 jAVtFe">{item.buyer_email}</h4>
                        <span class="point" aria-hidden="true"> · </span>
                        <h4 class="sc-58116526-3 jAVtFe">{item.buyer_phone}</h4>
                     </div>
                     <div class="sc-58116526-2 cyuDyL">
                        <h4 class="sc-58116526-4 gBxekq">Pedidos: <b>{item.total_orders}</b></h4>
                        <span class="point" aria-hidden="true"> · </span>
                        <h4 class="sc-58116526-4 gBxekq">Bilhetes: <b>{item.total_numbers}</b></h4>
                        <span class="point" aria-hidden="true"> · </span>
                        <h4 class="sc-58116526-4 gBxekq">Total: <b>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></h4>
                     </div>
                  </div>
               </div>
   </div>
   </>)}
   </> : <>
   <div class="sc-58116526-11 jYHNoH"><span class="sc-58116526-12 kCjyNR">:(</span><p>Você ainda não possui nenhum pedido pago!</p></div>
   </>} 
   
   
   </>)
   
   
   }


    _LoadTp = (query) =>{
  
      fetch(`${apiUrl}/rifinhas/${this.state.data.id}/analytics/top-buyers${query}`, {
         method: 'GET',
         credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
         headers: {
           'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
           'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
         }
       })
       .then(response => {
         
         response.json().then(data=>{
            this.setState({LoadTb:true,dataTp:data,query})
         })
        
       })
   }



    _ShowDt(){

      if(this.state.LoadDt == undefined){
         fetch(`${apiUrl}/rifinhas/${this.state.data.id}/analytics/sales-by-payment-method`, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {
            
            response.json().then(dataDt=>{
               this.setState({LoadDt:false,dataDt})
            })
           
          })
      }
   
     
   
   
   
      return(<>
      
   
   {this.state.LoadDt == undefined ? <>
      <span class="react-loading-skeleton" style={{
     width: '116px',
     height: '16px',
     '--base-color': '#C0C4CF',
     '--highlight-color': '#EBEDF4',
   }}>‌</span>
      <span class="react-loading-skeleton" style={{
     top:'10px',
     width: '100%',
     height: '60px',
     '--base-color': '#C0C4CF',
     '--highlight-color': '#EBEDF4',
   }}>‌</span>
   </> : <>
   
   
   <div class="sc-5bb5aa0a-1 cFyaly">
      <div class="sc-5bb5aa0a-2 heOrIZ">
         <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#78b6a8" viewBox="0 0 24 24">
               <path d="M18.69 18.34C18.46 18.34 18.23 18.32 18.01 18.28C17.78 18.23 17.56 18.17 17.35 18.08C17.13 17.99 16.93 17.88 16.74 17.75C16.55 17.63 16.37 17.48 16.2 17.32L12.61 13.72C12.54 13.66 12.47 13.61 12.39 13.58C12.31 13.55 12.22 13.53 12.14 13.53C12.05 13.53 11.96 13.55 11.88 13.58C11.8 13.61 11.73 13.66 11.66 13.72L8.05 17.33C7.89 17.49 7.71 17.64 7.52 17.77C7.33 17.89 7.13 18 6.91 18.09C6.7 18.18 6.48 18.25 6.25 18.29C6.03 18.34 5.8 18.36 5.56 18.36L4.86 18.36L9.42 22.91C9.76 23.25 10.16 23.52 10.6 23.7C11.04 23.88 11.51 23.98 11.99 23.98C12.47 23.98 12.94 23.88 13.39 23.7C13.83 23.52 14.23 23.25 14.57 22.91L19.13 18.35L18.69 18.34Z"></path>
               <path d="M5.57 5.63C5.8 5.63 6.03 5.65 6.25 5.69C6.48 5.74 6.7 5.8 6.91 5.89C7.13 5.98 7.33 6.09 7.52 6.22C7.71 6.35 7.89 6.49 8.06 6.66L11.66 10.27C11.73 10.33 11.8 10.38 11.88 10.41C11.96 10.44 12.05 10.46 12.14 10.46C12.22 10.46 12.31 10.44 12.39 10.41C12.47 10.38 12.55 10.33 12.61 10.27L16.2 6.67C16.37 6.51 16.54 6.36 16.74 6.23C16.93 6.1 17.13 5.99 17.35 5.91C17.56 5.82 17.78 5.75 18.01 5.71C18.23 5.66 18.46 5.64 18.69 5.64L19.13 5.64L14.56 1.07C14.22 0.74 13.82 0.47 13.38 0.28C12.94 0.1 12.46 0.01 11.99 0.01C11.51 0.01 11.03 0.1 10.59 0.28C10.15 0.47 9.75 0.74 9.41 1.07L4.86 5.63L5.57 5.63Z"></path>
               <path d="M22.9 9.42L20.14 6.66C20.13 6.66 20.11 6.67 20.1 6.68C20.08 6.68 20.06 6.68 20.05 6.69C20.03 6.69 20.01 6.69 20 6.7C19.98 6.7 19.96 6.7 19.95 6.7L18.69 6.7C18.53 6.7 18.37 6.71 18.21 6.75C18.06 6.78 17.9 6.82 17.75 6.89C17.6 6.95 17.46 7.02 17.33 7.11C17.19 7.2 17.07 7.31 16.95 7.42L13.36 11.01C13.2 11.17 13.01 11.3 12.8 11.39C12.59 11.47 12.36 11.52 12.14 11.52C11.91 11.52 11.69 11.47 11.48 11.39C11.27 11.3 11.08 11.17 10.92 11.01L7.31 7.41C7.19 7.29 7.07 7.19 6.93 7.1C6.8 7.01 6.66 6.93 6.51 6.87C6.36 6.81 6.2 6.76 6.05 6.73C5.89 6.7 5.73 6.68 5.57 6.68L4.03 6.68C4.01 6.68 3.99 6.68 3.98 6.68C3.96 6.68 3.95 6.68 3.93 6.67C3.92 6.67 3.9 6.67 3.88 6.66C3.87 6.66 3.85 6.65 3.84 6.65L1.06 9.42C0.73 9.76 0.46 10.16 0.28 10.6C0.09 11.04 0 11.51 0 11.99C0 12.47 0.09 12.94 0.28 13.39C0.46 13.83 0.73 14.23 1.06 14.57L3.83 17.34C3.85 17.33 3.86 17.33 3.88 17.32C3.89 17.32 3.91 17.31 3.92 17.31C3.94 17.31 3.96 17.31 3.97 17.3C3.99 17.3 4 17.3 4.02 17.3L5.57 17.3C5.73 17.3 5.89 17.28 6.05 17.25C6.2 17.22 6.36 17.17 6.51 17.11C6.66 17.05 6.8 16.97 6.93 16.88C7.07 16.8 7.19 16.69 7.31 16.58L10.92 12.97C11.57 12.32 12.7 12.32 13.36 12.97L16.95 16.57C17.07 16.68 17.19 16.78 17.33 16.87C17.46 16.96 17.6 17.04 17.75 17.1C17.9 17.16 18.06 17.21 18.21 17.24C18.37 17.27 18.53 17.29 18.69 17.29L19.95 17.29C19.96 17.29 19.98 17.29 20 17.29C20.01 17.29 20.03 17.29 20.05 17.3C20.06 17.3 20.08 17.3 20.1 17.31C20.11 17.31 20.13 17.32 20.14 17.33L22.9 14.57C23.24 14.23 23.51 13.83 23.69 13.39C23.88 12.94 23.97 12.47 23.97 11.99C23.97 11.51 23.88 11.04 23.69 10.6C23.51 10.16 23.24 9.76 22.9 9.42"></path>
            </svg>
         </div>
         <h4 class="sc-5bb5aa0a-3 dEPGxy">Pix (Mercado Pago)</h4>
      </div>
      <div class="sc-5bb5aa0a-4 cfwJHe">
         <h4 class="sc-5bb5aa0a-5 iMsfis">Pedidos: <b>{this.state.dataDt[0].orders_count}</b></h4>
         <span class="point" aria-hidden="true"> · </span>
         <h4 class="sc-5bb5aa0a-5 iMsfis">Bilhetes vendidos: <b>{this.state.dataDt[0].numbers_count}</b></h4>
         <span class="point" aria-hidden="true"> · </span>
         <h4 class="sc-5bb5aa0a-5 iMsfis">Valor bruto arrecadado: <b>{this.state.dataDt[0].total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></h4>
      </div>
   </div>
   
   
   
   
   </>}
   
   
   
      </>)
   }
   
    _Detais = (data) =>{
   
      return(<>
      <div style={{marginTop:'45px'}} class="sc-26994133-3 bbWzbp">
      <div class="sc-26994133-4 bEqoKR">
         <h1 class="sc-26994133-5 csFrDE">Detalhes de vendas</h1>
      </div>
      <button type="button" aria-label="Vendas por forma de pagamento" className={this.state.click1 ? "sc-b9946f39-0 jGLsDr" : "sc-b9946f39-0 kzGWdA"} onClick={()=>{
         this.setState({click1:!this.state.click1})
      }}>
     
         <div>
            <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                  <line x1="1" y1="10" x2="23" y2="10"></line>
               </svg>
            </div>
            <span>Vendas por forma de pagamento</span>
         </div>
         <div class="chevron">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
         </div>
      </button>
      {this.state.click1 && <>
         <div class="sc-b9946f39-1 jiHENQ">
        {data != "pending" ? <>
      {this._ShowDt()}
        </> : <>
        <div class="sc-5bb5aa0a-6 gSEASE">
            
         <h2>:(</h2><span>Você ainda não possui nenhuma venda!</span>
         
         </div>
        </>}
         
   
         </div>
      </>}
     
   </div>
      </>)
   }
_LoadPage = (page) =>{
   switch(page){
      case  'a':
         return(<>
          <div style={{paddingTop:60}} class="sc-26994133-3 bbWzbp">
            <div class="sc-26994133-4 bEqoKR">
               <h1 class="sc-26994133-5 csFrDE">Detalhes</h1>
               <div class="bts">
   <a class="cpt">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
         <polyline points="15 3 21 3 21 9"></polyline>
         <line x1="10" y1="14" x2="21" y2="3"></line>
      </svg>
   </a>
   <button>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <circle cx="18" cy="5" r="3"></circle>
         <circle cx="6" cy="12" r="3"></circle>
         <circle cx="18" cy="19" r="3"></circle>
         <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
         <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
      </svg>
   </button>
   <div class="dropdown">
      <div class="drop">
      <Dropdown>
<Drop>
<button>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
</button>
</Drop>
<Item>
<div aria-hidden="false" role="menu" class="sc-e82feeba-0 ethkPc">
<div class="sc-cf9fb95-1 iVXJmG2"></div>
<Link to={"/rifinhas/"+this.state.data.slug+"/edit"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
  </svg>
  <span>Editar</span>
</Link>


<Link to={"/rifinhas/"+this.state.data.slug+"/awards"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <circle cx="12" cy="8" r="7"></circle>
     <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
  </svg>
  <span>Prêmio(s)</span>
</Link>


<Link to={"/rifinhas/"+this.state.data.slug+"/finish"}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
     <line x1="4" y1="22" x2="4" y2="15"></line>
  </svg>
  <span>Sortear</span>
</Link>

<button type="button" data-danger="true" aria-label="Remover">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
     <polyline points="3 6 5 6 21 6"></polyline>
     <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
  </svg>
  <span>Remover</span>
</button>
</div>

</Item>
</Dropdown>
      </div>
   </div>
</div>

            </div>
            <div  class="sc-681b51f-12 kKxxON">
               <div class="sc-681b51f-0 fjzQkG">
                  <div class="sc-681b51f-13 dFvRBx">
                     <div class="sc-681b51f-15 dRLDJR">
                        <div class="sc-681b51f-16 kanbMU">
                           <small>Título:</small>
                           <p class="title">{this.state.data.title}</p>
                        </div>
                        <div class="sc-681b51f-16 kanbMU">
                           <small>Status:</small>
                           <div class="sc-681b51f-17 kAXjZU">
                              <button type="button" disabled="" aria-label="Ativo" class="sc-681b51f-4 chgUIY">
                                 <div class="sc-681b51f-5 fFBZPT"></div>
                                 <span>Ativo</span>
                                 <div class="sc-681b51f-6 lfWCDX">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down ">
                                       <path d="m6 9 6 6 6-6"></path>
                                    </svg>
                                 </div>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <hr/>
               <div class="sc-681b51f-0 fjzQkG">
                  <div class="sc-681b51f-18 knCEUx">
                     <div class="sc-681b51f-16 kanbMU">
                        <small>Bilhetes:</small>
                        <p class="title">{this.state.data.numbers}</p>
                     </div>
                     <div class="sc-681b51f-16 kanbMU">
                        <small>Bilhetes disponíveis:</small>
                        <p class="title">{parseInt(this.state.data.numbers.replace(".", "")) - this.state.data.details.show.apv}</p>
                     </div>
                     <div class="sc-681b51f-16 kanbMU">
                        <small>Bilhetes pagos:</small>
                        <p class="title">{this.state.data.details.show.apv}</p>
                     </div>
                     <div class="sc-681b51f-16 kanbMU">
                        <small>Bilhetes reservados:</small>
                        <p class="title">{this.state.data.details.show.pcc}</p>
                     </div>
                  </div>
                  <div className="sc-681b51f-16 kanbMU">
            <small>Progresso:</small>
            <div>
              <div className="sc-681b51f-19 hotkjq">
              <div class="sc-681b51f-20 JwFJD approved" style={{width:(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100)+'%'}}></div>
              </div>
              <div className="sc-681b51f-21 dCWZQc secondary" style={{ left: `calc(${(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100)}% - 16px)` }}>{(this.state.data.details.show.apv / parseInt(this.state.data.numbers.replace(".", "")) * 100).toFixed(2)}%</div>
            </div>
          </div>
               </div>
            </div>
         </div>

         <div class="sc-26994133-3 bbWzbp"></div>
         <hr style={{marginTop:32}}/>
         {this._Detais(this.state.data.status)}
         
         </>)
      case 'b':
         return(<>

            <div style={{padding:'40px',marginBottom:'-60px'}} >
               <div class="sc-26994133-4 bEqoKR">
                  <h1 class="sc-26994133-5 csFrDE">Maiores compradores</h1>
               </div>
            </div>
            
            <div style={{padding:'10px'}}>
               <button type="button"  className={this.state.click2 ? "sc-b9946f39-0 jGLsDr" : "sc-b9946f39-0 kzGWdA"} onClick={()=>{
         
              this.setState({click2:!this.state.click2})
            }}>
           
                  <div>
                     
                     <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                           <line x1="4" y1="21" x2="4" y2="14"></line>
                           <line x1="4" y1="10" x2="4" y2="3"></line>
                           <line x1="12" y1="21" x2="12" y2="12"></line>
                           <line x1="12" y1="8" x2="12" y2="3"></line>
                           <line x1="20" y1="21" x2="20" y2="16"></line>
                           <line x1="20" y1="12" x2="20" y2="3"></line>
                           <line x1="1" y1="14" x2="7" y2="14"></line>
                           <line x1="9" y1="8" x2="15" y2="8"></line>
                           <line x1="17" y1="16" x2="23" y2="16"></line>
                        </svg>
                     </div>
                     <span>Opções</span>
                  </div>
                  <div class="chevron">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <polyline points="6 9 12 15 18 9"></polyline>
                     </svg>
                  </div>
               </button>
               {this.state.click2 && <>
               <div class="sc-b9946f39-1 jiHENQ">
            <div class="sc-58116526-14 igCBpO">
               <p class="text">Data dos dados</p>
               <div class="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
                 <Dropdown>
                  <Drop>
                  <button type="button" class="sc-cf9fb95-0 fzuDCk">
                     <div class="sc-5b5576f7-0 cBxLNV">
                        <span>Todo o periodo</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                     </div>
                  </button>
                  </Drop>
                  <Item>
               <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML">
            <div class="sc-cf9fb95-1 iVXJmG"></div>
            <button class="" onClick={()=>{this._LoadTp('?startDate=today&endDate=today&allowTime=false')}}><span>Hoje</span></button>
            <button class="" onClick={()=>{this._LoadTp('?startDate=yesterday&endDate=yesterday&allowTime=false')}}><span>Ontem</span></button>
            <button class="" onClick={()=>{this._LoadTp('?startDate=7days&endDate=today&allowTime=false')}}><span>Últimos 7 dias</span></button>
            <button class="" onClick={()=>{this._LoadTp('?startDate=28days&endDate=today&allowTime=false')}}><span>Últimos 28 dias</span></button>
         
         
         </div>
         
                  </Item>
                 </Dropdown>
                  
               </div>
            </div>
         
            <div class="sc-58116526-14 igCBpO">
               <p class="text">Ordenar por</p>
               <div class="sc-cf9fb95-2 ibVeNp dropdown-wrapper">
                  <Dropdown>
                     <Drop>
                     <button type="button" class="sc-cf9fb95-0 fzuDCk">
                     <div class="sc-5b5576f7-0 cBxLNV">
                        <span>Maior valor gasto</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                           <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                     </div>
                  </button>
                     </Drop>
                     <Item>
                     <div aria-hidden="false" role="menu" class="sc-e82feeba-0 ekMnML">
            <div class="sc-cf9fb95-1 iVXJmG"></div>
            <button type="button" class="" aria-label="Mais bilhetes comprados" onClick={()=>{
               this._LoadTp(`${this.state.query}&orderBy=numbers`)
            }}>
            <span>Mais bilhetes comprados</span>
            </button>
            <button type="button" class="selected" disabled="" aria-label="Maior valor gasto" onClick={()=>{
                 this._LoadTp(`${this.state.query}&orderBy=price`)
            }}>
               <span>Maior valor gasto</span></button>
         </div>
         
                     </Item>
                  </Dropdown>
                  
               </div>
            </div>
         </div>
         
            </>}
            </div>
           <hr/>
            <div class="sc-26994133-3 bbWzbp">
               <div class="sc-58116526-0 OfdMt">
                 
         
         {this._ToBuyes(this.state.data.id)}
         
         
                 
                  
               </div>
            </div>
         
         
               </>)
      case 'c':
         return(<>

   <div style={{paddingTop:40}} class="sc-26994133-3 bbWzbp">
      <div class="sc-26994133-4 bEqoKR">
         <h1 class="sc-26994133-5 csFrDE">Vendas dos afiliados</h1>
      </div>
   </div>
   <div class="sc-26994133-3 bbWzbp">
   <div class="sc-58116526-11 jYHNoH">
         <span class="sc-58116526-12 kCjyNR">:(</span>
         <p>Você ainda não possui nenhum afiliado cadastrado!</p>
      </div>
   </div>


         </>)
   }
}

  componentDidMount(){
document.title = "Carregando..."   
fetch(''+apiUrl+'/rifinhas/'+window.location.pathname.split("/rifinhas/")[1]+'?details=true', {
    method: 'GET',
    credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
    headers: {
      'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
      'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
    }
  })
  .then(response => {
if(response.status == 200){
   response.json().then(data=>{
    document.title = `${data.title} | EuRifei`
    this.setState({Loader:false,data})
   })
}
  })
  }
   render() {
    if(this.state.Loader){
        return(<>
       <div class="sc-179422ed-0 keEfHG">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
               <rect x="0" y="0" width="100" height="100" fill="none"></rect>
               <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
               <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                  <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                  <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
               </circle>
            </svg>
         </div>
        </>)
      }
    return (
 <>
 
 <main class="sc-26994133-0 cbBLwb">
   <div class="sc-26994133-1 ebNXoB">
      <div class="sc-84a07fe4-0 jZNhWw">
         <a type="button" class={this.state.page == "a" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"a"})
         }} aria-label="Detalhes">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gauge ">
               <path d="m12 14 4-4"></path>
               <path d="M3.34 19a10 10 0 1 1 17.32 0"></path>
            </svg>
            <span>Detalhes</span>
         </a>
         <a type="button"  class={this.state.page == "b" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"b"})
         }}aria-label="Maiores compradores">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-line-chart ">
               <path d="M3 3v18h18"></path>
               <path d="m19 9-5 5-4-4-3 3"></path>
            </svg>
            <span>Maiores compradores</span>
         </a>
         <a type="button"  class={this.state.page == "c" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
            this.setState({page:"c"})
         }} aria-label="Vendas dos afiliados">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users ">
               <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
               <circle cx="9" cy="7" r="4"></circle>
               <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
               <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            <span>Vendas dos afiliados</span>
         </a>
      </div>
      <div class="sc-26994133-2 hCoRZw">
       
        {this._LoadPage(this.state.page)}

      </div>
      
   </div>
</main>



 </>
    );
  }
}

export default SlcRifinha;