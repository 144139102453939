import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./config.css"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../../config';
import Modal from '../../Comps/Modal';
class Config extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
         Loader:true,
         data:[],
         address:[],
         showPass:false,
         sen:'',
         csen:''
        }
    }

    estados = {
      'AC': 'Acre',
      'AL': 'Alagoas',
      'AP': 'Amapá',
      'AM': 'Amazonas',
      'BA': 'Bahia',
      'CE': 'Ceará',
      'DF': 'Distrito Federal',
      'ES': 'Espírito Santo',
      'GO': 'Goiás',
      'MA': 'Maranhão',
      'MT': 'Mato Grosso',
      'MS': 'Mato Grosso do Sul',
      'MG': 'Minas Gerais',
      'PA': 'Pará',
      'PB': 'Paraíba',
      'PR': 'Paraná',
      'PE': 'Pernambuco',
      'PI': 'Piauí',
      'RJ': 'Rio de Janeiro',
      'RN': 'Rio Grande do Norte',
      'RS': 'Rio Grande do Sul',
      'RO': 'Rondônia',
      'RR': 'Roraima',
      'SC': 'Santa Catarina',
      'SP': 'São Paulo',
      'SE': 'Sergipe',
      'TO': 'Tocantins'
  };

    handleChange = (event, inputName) => {
        const { data } = this.state;
        data[inputName] = event;
        this.setState({ data });
        if (!this.state.data.includes(inputName)) {
          this.setState(prevState => ({
            data: [...prevState.data, inputName]
          }));
        }
      };
     


  componentDidMount(){
   document.title = "Configurações - EuRifei" 
    fetch(`${apiUrl}/account`, {
        method: 'GET',
        credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
        headers: {
          'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
          'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
        }
      })
      .then(response => {
response.json().then(data=>{
    this.setState({data,Loader:false})


  


})
      })
  }
   render() {
    if(this.state.Loader){
        return(<>
       <div class="sc-179422ed-0 keEfHG">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
               <rect x="0" y="0" width="100" height="100" fill="none"></rect>
               <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
               <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                  <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                  <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
               </circle>
            </svg>
         </div>
        </>)
      }
    return (
 <>
 
 
 <div class="sc-f7781e17-0 ebFOLR">
   <div class="sc-f7781e17-1 ivElUD">
      <div class="sc-f7781e17-2 gYYPbP">
         <div class="sc-84a07fe4-0 jZNhWw">
            <div class="sc-84a07fe4-1 xZztE active">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-database ">
                  <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                  <path d="M3 5V19A9 3 0 0 0 21 19V5"></path>
                  <path d="M3 12A9 3 0 0 0 21 12"></path>
               </svg>
               <span>Meus dados</span>
            </div>
            <Link to="/account/linked-users" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill-rule="evenodd">
                  <path d="m18 19c0-1.6-0.6-3.1-1.8-4.2-1.1-1.2-2.6-1.8-4.2-1.8-1.6 0-3.1 0.6-4.2 1.8-1.2 1.1-1.8 2.6-1.8 4.2"></path>
                  <path d="m12 13c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4 0 2.2-1.8 4-4 4z"></path>
                  <path d="m22.9 16.5q0-0.8-0.3-1.5-0.3-0.7-0.8-1.2-0.5-0.6-1.3-0.9-0.7-0.3-1.4-0.3c0.7 0 1.3-0.2 1.8-0.7 0.5-0.5 0.7-1.1 0.7-1.8 0-0.7-0.2-1.4-0.7-1.8-0.5-0.5-1.1-0.8-1.8-0.8"></path>
                  <path d="m1.1 16.5q0-0.8 0.3-1.5 0.3-0.7 0.8-1.2 0.5-0.6 1.3-0.9 0.7-0.3 1.4-0.3c-0.7 0-1.3-0.2-1.8-0.7-0.5-0.5-0.7-1.1-0.7-1.8 0-0.7 0.2-1.4 0.7-1.8 0.5-0.5 1.1-0.8 1.8-0.8"></path>
               </svg>
               <span>Usuários vinculados</span>
            </Link>
            <Link to="/account/devices" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-monitor-smartphone ">
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"></path>
                  <path d="M10 19v-3.96 3.15"></path>
                  <path d="M7 19h5"></path>
                  <rect width="6" height="10" x="16" y="12" rx="2"></rect>
               </svg>
               <span>Dispositivos</span>
            </Link>
            <Link to="/account/purchases" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart ">
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
               </svg>
               <span>Compras</span>
            </Link>
            <Link to="/account/preferences" class="sc-84a07fe4-1 xZztE">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench ">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
               </svg>
               <span>Preferências</span>
            </Link>
         </div>
         <div class="sc-f7781e17-6 bwWzPR">
            <div class="sc-f7781e17-7 jtgWWa">
               <div class="sc-f7781e17-8 jkIyEd">
                  <h1 class="sc-f7781e17-9 elsxTF">Meus dados</h1>
               </div>
               <div class="sc-f7781e17-3 fTkMWE">
                  <div class="sc-f7781e17-4 eUCtDl">
                     <div class="sc-f7781e17-11 clMCtJ">
                        <div class="sc-f48cf0c1-0 cXBjxh">
                           <div class="sc-2d577abe-0 jYSPyC"><span>Nome completo</span></div>
                           <div class="sc-f48cf0c1-2 gIYykr">
                              <div class="sc-f48cf0c1-5 nzVXl"><span>{this.state.data.name}</span></div>
                              <div class="sc-f48cf0c1-3 erRwyQ"></div>
                              <button type="button" class="sc-f48cf0c1-4 jLevxR" onClick={()=>{
                            this.setState({altnome:true})
                            }}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                 </svg>
                                 <span>Editar</span>
                              </button>
                           </div>
                        </div>
                        <div style={{marginBottom:"32px"}}>
                           <div class="sc-2d577abe-0 jYSPyC"><span>Endereço de e-mail</span></div>
                           <div class="sc-f48cf0c1-2 gIYykr">
                              <div class="sc-f48cf0c1-5 nzVXl"><span>{this.state.data.email}</span></div>
                              <div class="sc-f48cf0c1-3 erRwyQ"></div>
                              <button type="button" class="sc-f48cf0c1-4 jLevxR" onClick={()=>{
                                this.setState({altEmail:true})
                              }}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                 </svg>
                                 <span>Editar</span>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div class="sc-f48cf0c1-0 cXBjxh">
                        <div class="sc-2d577abe-0 jYSPyC">
                           <span>Nome de usuário</span>
                           <div style={{height:"16px"}}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="undefined-help-tt">
                                 <circle cx="12" cy="12" r="10"></circle>
                                 <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                 <line x1="12" y1="17" x2="12.01" y2="17"></line>
                              </svg>
                           </div>
                        </div>
                        <div class="sc-f48cf0c1-2 gIYykr">
                           <div class="sc-f48cf0c1-5 nzVXl"><span>{this.state.data.username}</span></div>
                           <div class="sc-f48cf0c1-3 erRwyQ"></div>
                           <button type="button" aria-label="Editar nome de usuário" class="sc-f48cf0c1-4 jLevxR" onClick={()=>{
                              this.setState({altUserName:true})
                           }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                              <span>Editar</span>
                           </button>
                        </div>
                     </div>
                     {this.state.data.document != null && <>
                        <div class="sc-f48cf0c1-1 hXZarP">
                        <div class="sc-f48cf0c1-0 cXBjxh no-mb">
                           <div class="sc-2d577abe-0 jYSPyC"><span>CPF/CNPJ</span></div>
                           <div class="sc-f48cf0c1-5 nzVXl disabled"><span>076.329.790-93</span></div>
                        </div>
                        <div class="sc-f48cf0c1-0 cXBjxh no-mb">
                           <div class="sc-2d577abe-0 jYSPyC"><span>Data de nascimento</span></div>
                           <div class="sc-f48cf0c1-5 nzVXl disabled"><span>01/01/1979</span></div>
                        </div>
                     </div>
                     </>}
                    
                  

                     <div class="sc-f48cf0c1-0 cXBjxh">
                        <div class="sc-2d577abe-0 jYSPyC"><span>Telefone</span></div>
                        <div class="sc-f48cf0c1-2 gIYykr">
                           <div class="sc-f48cf0c1-5 nzVXl">{this.state.data.phone == null ? "Não informado" : this.state.data.phone}</div>
                           <div class="sc-f48cf0c1-3 erRwyQ"></div>
                           <button type="button" aria-label="Editar telefone" class="sc-f48cf0c1-4 jLevxR" onClick={()=>{
                              this.setState({altPhone:true})
                           }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                              <span>Editar</span>
                           </button>
                        </div>
                     </div>
                     <div class="sc-f48cf0c1-0 cXBjxh">
                        <div class="sc-2d577abe-0 jYSPyC"><span>Senha</span></div>
                        <div class="sc-f48cf0c1-2 gIYykr">
                           <div class="sc-f48cf0c1-5 nzVXl"><span>*********</span></div>
                           <div class="sc-f48cf0c1-3 erRwyQ"></div>
                           <button type="button" aria-label="Editar senha" class="sc-f48cf0c1-4 jLevxR" onClick={()=>{
                              this.setState({altSen:true})
                           }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                 <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                              <span>Editar</span>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <hr/>
            <div class="sc-f7781e17-7 jtgWWa">
               <div class="sc-f7781e17-8 jkIyEd">
                  <h1 class="sc-f7781e17-9 elsxTF">Endereço</h1>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle address-help">
                     <circle cx="12" cy="12" r="10"></circle>
                     <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                     <path d="M12 17h.01"></path>
                  </svg>
               </div>
          
                  <div class="sc-f7781e17-3 fTkMWE">
                     <div class="sc-f7781e17-4 eUCtDl">
                        <div class="sc-f7781e17-11 clMCtJ">
                           <fieldset class="sc-56eec735-1 bSrfIZ">
                              <div class="sc-2d577abe-0 jYSPyC"><span>CEP</span></div>
                              <div class="sc-56eec735-2 bBSNUk">
                              <InputMask  mask="99.999-999" name="zip_code" class="sc-56eec735-0 jDba-dm" onChange={(e)=>{
               
               let newData = { ...this.state.data };
               const inputVal = e.target.value;
               newData.address.cep = e.target.value;
               this.setState({ data: newData });

               var regexCEP = /^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/;
               if(regexCEP.test(e.target.value)){

                fetch(`https://viacep.com.br/ws/${e.target.value.replace(/\D/g, '')}/json/`, {
                  method: 'GET',
                  headers: {
                  'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
                  }
                })
                .then(response => {
                 
response.json().then(data=>{
   let newData = { ...this.state.data };
   const inputVal = e.target.value;
   newData.address.address = data.logradouro;
   newData.address.bairro = data.bairro;
   newData.address.localidade = data.localidade;
   newData.address.state = data.uf;
   console.log(newData)
   this.setState({ address: newData });
})
                




                })

               }

                              }} value={this.state.data.address.cep == undefined ? "" : this.state.data.address.cep}/></div>
                           </fieldset>
                           <fieldset class="sc-56eec735-1 bSrfIZ">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Endereço</span></div>
                              <div class="sc-56eec735-2 bBSNUk">
                              <input name="address" id="address" class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
                                 let newData = { ...this.state.data };
                                 const inputVal = e.target.value;
                                 newData.address.address = inputVal;
                                
                                 this.setState({ data: newData });
                              }}   value={this.state.data.address.address == undefined ? "" : this.state.data.address.address}/></div>
                           </fieldset>
                        </div>
                        <div class="sc-f7781e17-11 clMCtJ">
                           <fieldset class="sc-56eec735-1 bSrfIZ">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Número</span></div>
                              <div class="sc-56eec735-2 bBSNUk">
                           <input name="number" id="number" class="sc-56eec735-0 jDba-dm" onChange={(e)=>{
let newData = { ...this.state.data };
newData.address.number = e.target.value.replace(/\D/g, '');
this.setState({ data: newData });

                           }} value={this.state.data.address.number == undefined ? "" : this.state.data.address.number}/></div>
                           </fieldset>
                           <fieldset class="sc-56eec735-1 bSrfIZ">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Bairro</span></div>
                              <div class="sc-56eec735-2 bBSNUk">
                              <input name="neighborhood" id="neighborhood" class="sc-56eec735-0 jDba-dm"
                              onChange={(e)=>{
                                 let newData = { ...this.state.data };
                                 newData.address.bairro = e.target.value;
                                 this.setState({ data: newData });
                              }}
                              value={this.state.data.address.bairro == undefined ? "" : this.state.data.address.bairro}/></div>
                           </fieldset>
                           <fieldset class="sc-56eec735-1 bSrfIZ">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Complemento<small> • Opcional</small></span></div>
                              <div class="sc-56eec735-2 bBSNUk"><input name="complement" id="complement" 
                               onChange={(e)=>{
                                 let newData = { ...this.state.data };
                                 newData.address.adicional = e.target.value;
                                 this.setState({ data: newData });
                              }}
                              class="sc-56eec735-0 jDba-dm"  value={this.state.data.address.adicional == undefined ? "" : this.state.data.address.adicional}/></div>
                           </fieldset>
                        </div>
                        <div class="sc-f7781e17-11 clMCtJ desktop-mb">
                           <fieldset class="sc-94a8971d-0 dtRnqe">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Estado</span></div>
                              <div class="sc-94a8971d-1 dapydL">
                                 <button type="button" class="sc-94a8971d-2 Kpdbv" disabled="">
                                    {this.state.data.address.state != undefined && this.estados[this.state.data.address.state]}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                       <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                 </button>
                              </div>
                           </fieldset>
                           <fieldset class="sc-56eec735-1 hDxYJw">
                              <div class="sc-2d577abe-0 jYSPyC"><span>Cidade</span></div>
                              <div class="sc-56eec735-2 bBSNUk"><input name="city" id="city" disabled="" class="sc-56eec735-0 jDba-dm" value={this.state.data.address.localidade == undefined ? "" : this.state.data.address.localidade}/></div>
                           </fieldset>
                        </div>
                     </div>
                  </div>
                  <div class="sc-f7781e17-12 emavYa">
                     {Object.keys(this.state.data.address).length > 1  ? <>
                        <button type="submit" class="sc-b1836745-0 gCHqgz" onClick={(e)=>{
                     
                     
                     var edr = this.state.data.address;

                     var regexCEP = /^[0-9]{2}\.[0-9]{3}\-[0-9]{3}$/;
               
if(edr.cep.trim().length == 0){
                        toast.error('Preencha o campo de cep!', {
                           position: "top-center",
                           autoClose: 3000, // Fecha automaticamente após 3 segundos
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                         });
                        return false;
}
if(regexCEP.test(edr.cep) == false){
   toast.error('Cep inválido!', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.address.trim().length == 0){
   toast.error('Preencha o campo Rua: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.number.trim().length == 0){
   toast.error('Preencha o campo Número: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.bairro.trim().length == 0){
   toast.error('Preencha o campo Bairro: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

e.target.classList.add('load')


var address = this.state.data.address;
fetch(`${apiUrl}/account/address`, {
   method: 'POST',
   credentials: 'include',
   headers: {
    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
  },
   body: JSON.stringify({
      address
   })
 })
 .then(response => {
   
   e.target.classList.remove('load')


if(response.status == 200){
   response.json().then(data=>{
      toast.success('Endereço alterado com sucesso!', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   })
}


 })



                  }}>Salvar alterações</button>
                     </> : <>
                     <button type="submit" class="sc-b1836745-0 gCHqgz" onClick={(e)=>{
                     
                     
                     var edr = this.state.data.address;

                     var regexCEP = /^[0-9]{2}\.[0-9]{3}\-[0-9]{3}$/;
               
if(edr.cep == undefined){
                        toast.error('Preencha o campo de cep!', {
                           position: "top-center",
                           autoClose: 3000, // Fecha automaticamente após 3 segundos
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                         });
                        return false;
}
if(regexCEP.test(edr.cep) == false){
   toast.error('Cep inválido!', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.address == undefined){
   toast.error('Preencha o campo Rua: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.number == undefined){
   toast.error('Preencha o campo Número: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

if(edr.bairro == undefined){
   toast.error('Preencha o campo Bairro: ', {
      position: "top-center",
      autoClose: 3000, // Fecha automaticamente após 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   return false;
}

e.target.classList.add('load')

var address = this.state.data.address;
fetch(`${apiUrl}/account/address`, {
   method: 'POST',
   credentials: 'include',
   headers: {
    'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
    'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
  },
   body: JSON.stringify({
      address
   })
 })
 .then(response => {
   
   e.target.classList.remove('load')


if(response.status == 200){
   response.json().then(data=>{
      toast.success('Endereço adicionado com sucesso!', {
         position: "top-center",
         autoClose: 3000, // Fecha automaticamente após 3 segundos
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
   })
}


 })



                  }}>Adicionar</button>
                     
                     </>}
                 
                  
                  </div>
             
            </div>
            <hr/>
            <div class="sc-f7781e17-7 jtgWWa">
               <div class="sc-f7781e17-8 jkIyEd">
                  <h1 class="sc-f7781e17-9 elsxTF">Excluir conta</h1>
               </div>
               <small class="sc-3e0612ad-0 hFlGVm">O que você tem que saber:</small>
               <div class="sc-f7781e17-5 QkqHJ">
                  <span>Caso você tenha feito alguma compra, a sua conta será removida somente 7 dias após ter solicitado a exclusão da conta por questões de segurança, sendo possível recuperá-la entrando em contato conosco.</span>
                  <div class="line"></div>
                  <span>A sua conta será removida permanentemente, junto com todos os dados relacionados a ela, como campanhas, rifinhas, pedidos e afiliados associados.</span>
               </div>
               <div class="sc-f7781e17-12 emavYa"><button class="sc-b1836745-0 lpsAUo">Excluir</button></div>
            </div>
         </div>
      </div>
   </div>
</div>


{this.state.altnome && <>
    <div id="modal-root">
   
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({altnome:false})
      }}></div>

         <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar nome</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Nome</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input name="name" id="name" class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({iptNome:e.target.value})
        }} value={this.state.iptNome ==undefined ? this.state.data.name : this.state.iptNome}  autoComplete='off'/>
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
         
            var name = this.state.iptNome ==undefined ? this.state.data.name : this.state.iptNome;
            const parts = name.split(' ');
            if((parts.length >= 2 && parts.every(part => part.trim() !== '')) == false){
                toast.error('Informe o nome e seobrenome!', {
                    position: "top-center",
                    autoClose: 3000, // Fecha automaticamente após 3 segundos
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
            e.target.classList.add("load")
            fetch(`${apiUrl}/users`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({nome:name})
              })
              .then(response => {
               

                let newData = { ...this.state.data };
                newData.name = name;
                this.setState({ data: newData,altnome:false,iptmail:"" });




              })


            }}>Salvar alterações</button>
            </div>
            </div>
         </div>
     
     
     
      </div>
  

</>}



{this.state.altEmail && <>
    <div id="modal-root">
   
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({altEmail:false})
      }}></div>

{this.state.checkPass == undefined ? <>
    <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Autenticação</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Digite sua senha atual para continuar</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input name="name" type='password' class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({iptsen:e.target.value})
        }} value={this.state.iptsen}  autoComplete='off'/>
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
            e.target.classList.add("load")
   
      
            fetch(`${apiUrl}/auth/verify-password`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({
                    password:this.state.iptsen
                })
              })
              .then(response => {
                e.target.classList.remove("load")
             
if(response.status == 404){
    toast.error('Senha incorreta!', {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

if(response.status == 200){
response.json().then(data=>{
    this.setState({tkn:data.tkn,checkPass:'email'})
})
}

           if(response.status == 200){
                
              }

              })
          
         


            }}>Salvar alterações</button>
            </div>
            </div>
         </div>
</> : <>
<div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar email</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Novo endereço de e-mail</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input id="email" type='text' class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({iptmails:e.target.value})
        }} value={this.state.iptmails == undefined ? "" : this.state.iptmails}  autoComplete='off'/>
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
            e.target.classList.add("load")
   
      
            fetch(`${apiUrl}/users/mail`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({
                    mail:this.state.iptmails,
                    token:this.state.tkn
                })
              })
              .then(response => {
                e.target.classList.remove("load")
             
if(response.status == 404){
    toast.error('Senha incorreta!', {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

if(response.status == 422){
   response.json().then(data=>{
    toast.error(data.errors[0].message, {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
   })
  
}




if(response.status == 200){
response.json().then(data=>{
    this.setState({tkn:data.tkn,checkPass:'email'})
})
}

           if(response.status == 200){
               this.setState({altEmail:false,checkPass:false}) 
               Modal.create({titulo:"Cheque seu novo e-mail",html:<>
               <p class="sc-172df605-4 kVQFcq">Finalize a alteração através do link que enviamos no novo e-mail que você inseriu.</p>
               <p class="sc-172df605-4 kVQFcq">Caso não encontre o e-mail na sua caixa de entrada, verifique a caixa de spam ou lixeira.</p>
               </>})
            }

              })
          
         


            }}>Alterar</button>
            </div>
            </div>
         </div>
</>}
        
     
     
     
      </div>
  

</>}


{this.state.altUserName && <>
    <div id="modal-root">
   
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({altUserName:false})
      }}></div>

         <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar nome de usuário</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Novo nome de usuário</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input  class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({iptUserName:e.target.value})
        }} value={this.state.iptUserName ==undefined ? this.state.data.username : this.state.iptUserName }  autoComplete='off'/>
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
           
            const regex = /^[a-zA-Z0-9_-]{3,16}$/;

            if(regex.test(this.state.iptUserName) == false){
               toast.error('O username deve conter entre 3 e 16 caracteres, contendo apenas letras, números, underscore (_) e hifens (-)', {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
               return false
            }
            e.target.classList.add("load")
            fetch(`${apiUrl}/users`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({username:this.state.iptUserName})
              })
              .then(response => {
               



               
                let newData = { ...this.state.data };
                newData.username = this.state.iptUserName;
                this.setState({ data: newData,altUserName:false });

                toast.success('Nome de usuário alterado com sucesso!', {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
          


              })


            }}>Salvar alterações</button>
            </div>
            </div>
         </div>
     
     
     
      </div>
  

</>}

{this.state.altPhone && <>
    <div id="modal-root">
   
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({altPhone:false})
      }}></div>

         <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar nome de usuário</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Novo nome de usuário</span></div>
                     <div class="sc-56eec735-2 bBSNUk">

                     <InputMask mask={this.state.mask} class="sc-56eec735-0 jDba-dm" onChange={(e)=>{
                 var phone = e.target.value;
                 if (/^\d{2} 9\d/.test(phone)) {
                    this.setState({mask:'99 99999-9999'})
                  } else {
                    this.setState({mask:'99 9999-9999'})
                  }
                
                
                  this.setState({iptPhone:phone})

              }}  value={this.state.iptPhone ==undefined ? this.state.data.phone : this.state.iptPhone } placeholder="Telefone" />




     
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
            const regex = /^(\d{2} )?(\d{4,5}-\d{4})$/;
          
            if(regex.test(this.state.iptPhone) == false){
               toast.error('Número de telefone inválido!.', {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
               return false;
            }
           
            e.target.classList.add("load")
            fetch(`${apiUrl}/users`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({phone:this.state.iptPhone})
              })
              .then(response => {
               



               
                let newData = { ...this.state.data };
                newData.phone = this.state.iptPhone;
                this.setState({ data: newData,altPhone:false });

                toast.success('Número de telefone alterado com sucesso!', {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
          


              })


            }}>Salvar alterações</button>
            </div>
            </div>
         </div>
     
     
     
      </div>
  

</>}




{this.state.altSen && <>
    <div id="modal-root">
   
      <div class="sc-912e3955-1 guWPuq" onClick={()=>{
        this.setState({altSen:false,checkSen:undefined})
      }}></div>

{this.state.checkSen == undefined ? <>
    <div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Autenticação</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Digite sua senha atual para continuar</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input name="name" type='password' class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({iptsen:e.target.value})
        }} value={this.state.iptsen}  autoComplete='off'/>
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
            e.target.classList.add("load")
   
      
            fetch(`${apiUrl}/auth/verify-password`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({
                    password:this.state.iptsen
                })
              })
              .then(response => {
                e.target.classList.remove("load")
             
if(response.status == 404){
    toast.error('Senha incorreta!', {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

if(response.status == 200){
response.json().then(data=>{
    this.setState({tkn:data.tkn,checkSen:'pass'})
})
}

           if(response.status == 200){
                
              }

              })
          
         


            }}>Salvar alterações</button>
            </div>
            </div>
         </div>
</> : <>
<div class="sc-912e3955-0 fyGHVYa">
            <div role="dialog" aria-modal="true" aria-labelledby="Ver meus bilhetes" class="sc-b992e925-1 kVYZTt">
               <div class="sc-b992e925-4 eCuIZO">
                  <h1 class="sc-b992e925-2 iEZsiF">Alterar senha</h1>
                  <button type="button" class="sc-b992e925-3 hoeLrA">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x ">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                     </svg>
                  </button>
               </div>
               <div class="sc-b992e925-5 gmRYUp">
               <div class="sc-6fa7939-0 eDhqYU"><p>Ao alterar a sua senha, todos os dispositivos (menos o atual) conectados a sua conta serão desconectados.</p></div>
                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Nova senha</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input id="email" type={this.state.showPass == true ? "text" : "password"} class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({sen:e.target.value})
        }} value={this.state.sen}  autoComplete='off'/>
        <button type="button" aria-label="Mostrar senha" class="sc-b1c12742-3 fetzFm show-password" tabindex="-1" onClick={()=>{
         this.setState({showPass:!this.state.showPass})
        }}>
         
         {this.state.showPass == false ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
        
         </> : <>
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
         </>}
          </button>
        
        </div>
                  </fieldset>

                  <fieldset class="sc-56eec735-1 bSrfIZ">
                     <div class="sc-2d577abe-0 jYSPyC"><span>Confrime a sua senha</span></div>
                     <div class="sc-56eec735-2 bBSNUk">
        <input id="email" type={this.state.showPass == true ? "text" : "password"} class="sc-56eec735-0 jDba-dm"  onChange={(e)=>{
            this.setState({csen:e.target.value})
        }} value={this.state.csen}  autoComplete='off'/>
        <button type="button" aria-label="Mostrar senha" class="sc-b1c12742-3 fetzFm show-password" tabindex="-1" onClick={()=>{
         this.setState({showPass:!this.state.showPass})
        }}>
         
         {this.state.showPass == false ? <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
        
         </> : <>
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
         </>}
          </button>
        
        </div>
                  </fieldset>
               </div>
               <div class="sc-f7781e17-13 hAhHdT">
            <button type="submit" class="sc-b1836745-0 fzfuwn"  onClick={(e)=>{
     
   
            if(this.state.sen.trim().length == 0){
               toast.error('Preencha o campo de senha!', {
                  position: "top-center",
                  autoClose: 3000, // Fecha automaticamente após 3 segundos
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
               return false;
            }
            e.target.classList.add("load")
            fetch(`${apiUrl}/users/password`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                 'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
                 'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
               },
                body: JSON.stringify({
                    sen:this.state.sen,
                    csen:this.state.csen,
                    token:this.state.tkn
                })
              })
              .then(response => {
                e.target.classList.remove("load")
             
if(response.status == 404){
    toast.error('Senha incorreta!', {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}

if(response.status == 422){
   response.json().then(data=>{
    toast.error(data.errors[0].message, {
        position: "top-center",
        autoClose: 3000, // Fecha automaticamente após 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
   })
  
}





           if(response.status == 200){
               this.setState({altSen:false,checkSen:false}) 
               Modal.create({titulo:"Sua senha foi alterada com sucesso",html:<>
               <p class="sc-172df605-4 kVQFcq">Irenos recarregar a página</p>
              </>})
            setInterval(() => {
               window.location = ''
            },3000);
            }

              })
          
         


            }}>Alterar</button>
            </div>
            </div>
         </div>
</>}
        
     
     
     
      </div>
  

</>}




<div id="modal-root"></div>
<ToastContainer/>
 </>
    );
  }
}

export default Config;