import React, { Component } from 'react';

class Mods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

  }

  
  render() {
   

    return (
     <>aaaa</>
    );
  }
}

class Drop extends Component {
  render() {
    const { children, onClick } = this.props;
    return <div className="drop" onClick={onClick}>{children}</div>;
  }
}

class Item extends Component {
  render() {

    const { children,onClick } = this.props;
    return <div className="item" onClick={onClick}>{children}</div>;
  }
}


class AreaMods extends Component {
    render() {
      return (
        <div id="area-modal">
        
        </div>
      );
    }
  }

  class Modal extends Component {
    render() {
     return(<>
     
     </>)
    }
  }


export  { Modal, AreaMods } 