import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
 import "./c.css"
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Dropdown,Drop,Item } from '../../../Comps/Dropdown';
class Rcustomization extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    Loader:true,
    dadosFormulario:[],
    dadosOriginais: {},
    inputsAlterados: [],
    active:"d",
    enable:"a"
    }
  }



 
 
  componentDidMount(){


var url = window.location.href;
const regex = /\/raffles\/(.*?)\//;
const match = url.match(regex);

document.title  = "Carregando..."
    fetch(''+apiUrl+'/raffles/'+match[1], {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+Cookies.get("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {

            if (response.status == 404) {
        // this.props.navigate("/raffles")
            return false;
        }
          
 
            return response.json();
         
          }).then(result => {
            document.title = `Personalização da campanha | EuRifei`;
            this.setState({Loader:false,dadosFormulario:result,})
            
            })
            .catch(error => {
          console.log(error)   
          });




  }
   
  
  
  
  render() {
      const { dadosFormulario } = this.state;
  
    if(this.state.Loader){
      return(<>
     <div class="sc-179422ed-0 keEfHG">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
             <rect x="0" y="0" width="100" height="100" fill="none"></rect>
             <circle cx="50" cy="50" r="40" stroke="rgba(0,0,0,0.1)" fill="none" stroke-width="10" stroke-linecap="round"></circle>
             <circle cx="50" cy="50" r="40" stroke="#fb8c00" fill="none" stroke-width="6" stroke-linecap="round">
                <animate attributeName="stroke-dashoffset" dur="1s" repeatCount="indefinite" from="0" to="502"></animate>
                <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>
             </circle>
          </svg>
       </div>
      </>)
    }
    return (
 <>
 
 <div class="sc-f73caa79-1 cJfoas">
 <div class="sc-f73caa79-2 dOTmxc">
 <div class="sc-84a07fe4-0 jZNhWw">
   <Link to={document.location.pathname.replace("customization","edit")} type="button" className={this.state.active == "a" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
      </svg>
      <span>Informações básicas</span>
   </Link>
   <Link to={document.location.pathname.replace("customization","options")} type="button" class="sc-84a07fe4-1 xZztE" className={this.state.active == "b" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <line x1="4" y1="21" x2="4" y2="14"></line>
         <line x1="4" y1="10" x2="4" y2="3"></line>
         <line x1="12" y1="21" x2="12" y2="12"></line>
         <line x1="12" y1="8" x2="12" y2="3"></line>
         <line x1="20" y1="21" x2="20" y2="16"></line>
         <line x1="20" y1="12" x2="20" y2="3"></line>
         <line x1="1" y1="14" x2="7" y2="14"></line>
         <line x1="9" y1="8" x2="15" y2="8"></line>
         <line x1="17" y1="16" x2="23" y2="16"></line>
      </svg>
      <span>Opções</span>
   </Link>
   <Link  to={document.location.pathname.replace("customization","description")} type="button" className={this.state.active == "c" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
     
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
      <span>Descrição</span>
   </Link>
   <Link type="button" class="sc-84a07fe4-1 xZztE" className={this.state.active == "d" ? "sc-84a07fe4-1 xZztE active" : "sc-84a07fe4-1 xZztE"} onClick={()=>{
      this.setState({active:"d"})
   }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
         <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
         <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
         <path d="M2 2l7.586 7.586"></path>
         <circle cx="11" cy="11" r="2"></circle>
      </svg>
      <span>Personalizações</span>
   </Link>
</div>



<div class="sc-f73caa79-3 AMTpX">


<div class="sc-f73caa79-5 jKFAiY">
   <h1 class="sc-f73caa79-4 bUDWoL">Personalizações</h1>
   <div class="sc-b009d0df-0 dkptfc">
      <button type="button" className={this.state.enable == "a" ? "sc-b009d0df-1 iBHwYL active" : "sc-b009d0df-1 iBHwYL"} onClick={()=>{
      this.setState({enable:"a"}) }}>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
         </svg>
         <span>Principais</span>
      </button>
 
  
   </div>
 {this.state.enable == "a" && <>
 <form action="#">
      <div class="sc-f73caa79-6 kHJboQ">
         <div class="sc-f73caa79-7 byNUyF">
            <fieldset class="sc-44d7b48d-0 jhRUKY">
               <fieldset class="sc-eb9b4b7f-2 gfZJgt">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Mostrar porcentagem de vendas<small> • Opcional</small></span>
                     <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="show_progress_bar-help-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                           <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-eb9b4b7f-3 fcffpD"><input name="show_progress_bar" type="checkbox" id="show_progress_bar" class="sc-eb9b4b7f-0 dIfVcB" value="false"/><label for="show_progress_bar" class="sc-eb9b4b7f-1 jeCmla"></label></div>
               </fieldset>
            </fieldset>
            <fieldset class="sc-44d7b48d-0 jhRUKY">
               <fieldset class="sc-eb9b4b7f-2 gfZJgt">
                  <div class="sc-2d577abe-0 jYSPyC">
                     <span>Mostrar botões para compartilhar<small> • Opcional</small></span>
                     <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="show_share-help-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                           <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                     </div>
                     <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="show_share-alert-tt">
                           <circle cx="12" cy="12" r="10"></circle>
                           <line x1="12" y1="8" x2="12" y2="12"></line>
                           <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                     </div>
                  </div>
                  <div class="sc-eb9b4b7f-3 fcffpD"><input name="show_share" type="checkbox" id="show_share" class="sc-eb9b4b7f-0 dIfVcB" value="true" checked=""/><label for="show_share" class="sc-eb9b4b7f-1 jeCmla"></label></div>
               </fieldset>
            </fieldset>
           
         </div>
      </div>
      <div class="sc-f73caa79-9 dzqiqZ"><button type="submit" class="sc-b1836745-0 gCHqgz" disabled="">Salvar alterações</button></div>
   </form>
 </>}
 {this.state.enable == "b" && <>
 <div class="sc-f73caa79-6 kHJboQ">
   <div class="sc-f73caa79-7 byNUyF">
      <div  class="sc-2d577abe-0 jYSPyC"><span>Quantidade de bilhetes para selecionar<small> • Opcional</small></span></div>
      <div class="sc-44d7b48d-3 gAAvJy">
         <button type="button" id="select-numbers-1" class="sc-44d7b48d-4 ljHhxq">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+1</h4>
         </button>
         <button type="button" id="select-numbers-2" class="sc-44d7b48d-4 ljHhxq">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+5</h4>
         </button>
         <button type="button" id="select-numbers-3" class="sc-44d7b48d-4 ljHhxq">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+25</h4>
         </button>
         <button type="button" id="select-numbers-4" class="sc-44d7b48d-4 ljHhxq">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+50</h4>
         </button>
         <button type="button" id="select-numbers-5" class="sc-44d7b48d-4 ljHhxq most-popular">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+100</h4>
         </button>
         <button type="button" id="select-numbers-6" class="sc-44d7b48d-4 ljHhxq">
            <span>Selecionar</span>
            <h4 class="sc-44d7b48d-5 gxWaOQ">+500</h4>
         </button>
      </div>
      <div class="sc-44d7b48d-7 jKVPrj">
         <span>Clique em um dos números acima para editar a quantidade de bilhetes que o participante poderá escolher.</span>
         <div>
            <div id="select-numbers-quantity" class="sc-44d7b48d-8 gogaJb"><span>6/6</span></div>
         </div>
      </div>
   </div>
</div>

 </>}
</div>



</div>



 </div>
 </div>
<ToastContainer/>
 </>
    );
  }
}

export default Rcustomization;