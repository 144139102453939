import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../paginas/Raffles/style.css"
import Cookies from 'js-cookie';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import logo from "../../images/logo.png"
import {apiUrl} from "../../config"
import { 
   faMagnifyingGlass,
   faUser
 } from '@fortawesome/free-solid-svg-icons'
import { Link,NavLink } from 'react-router-dom';
class Header extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
MenOpc:false,
MenNtf:false,
MenEtc:false,
Loader:true,
Data:[],
MenActive:"a",
ntf:[]
        }
    this.dropdownRef = React.createRef();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.dropdownRef2 = React.createRef();
    this.toggleDropdown2 = this.toggleDropdown2.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this)




    this.dropdownRef3 = React.createRef();
    this.toggleDropdown3 = this.toggleDropdown3.bind(this);
    this.handleClickOutside3= this.handleClickOutside3.bind(this)
    }

    LerCookie(nome) {
        // Separe todos os cookies em uma matriz
        var cookies = document.cookie.split(';');
        
        // Percorra a matriz de cookies
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          
          // Remova espaços em branco no início e no final do cookie
          while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
          }
          
          // Se o cookie começar com o nome desejado, retorne o valor do cookie
          if (cookie.indexOf(nome + '=') == 0) {
            return cookie.substring(nome.length + 1, cookie.length);
          }
        }
        
        // Se o cookie não for encontrado, retorne null
        return null;
      }



      getClassNames = ({ isActive }) => {
         return isActive ? "sc-fee5f128-11 iKmgdt active" :
          "sc-fee5f128-11 iKmgdt "
         };
       


    componentDidMount() {
     
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside2);
        document.addEventListener('mousedown', this.handleClickOutside3);
   
        fetch(`${apiUrl}/account`, {
            method: 'GET',
            credentials: 'include', // Isso permite que os cookies sejam enviados com a solicitação
            headers: {
              'Authorization': 'Bearer '+this.LerCookie("auth_token"), // Se necessário, adicione um cabeçalho de autorização
              'Content-Type': 'application/json' // Adicione o tipo de conteúdo, se necessário
            }
          })
          .then(response => {


            if (response.status == 401) {
       
        document.cookie = "auth_token" + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location = window.location.origin;
         
      return;
            }
          
 
     return response.json();
         
          }).then(result => {



            this.setState({Data:result,Loader:false})
         


          })
          .catch(error => {
        console.log(error)   
        });


    }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutside2);
        document.removeEventListener('mousedown', this.handleClickOutside3);
      }
    
      handleClickOutside(event) {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
          this.setState({ MenOpc: false });
        }
      }

      handleClickOutside2(event) {
         if (this.dropdownRef2.current && !this.dropdownRef2.current.contains(event.target)) {
           this.setState({ MenNtf: false });
         }
       }
    

       handleClickOutside3(event) {
         if (this.dropdownRef3.current && !this.dropdownRef3.current.contains(event.target)) {
           this.setState({ MenEtc: false });
         }
       }
    



      toggleDropdown() {
        this.setState(prevState => ({
          MenOpc: !prevState.isOpen
        }));
      }

      toggleDropdown2() {
         this.setState(prevState => ({
           MenNtf: !prevState.isOpen
         }));
       }

       toggleDropdown3() {
         this.setState(prevState => ({
           MenEtc: !prevState.isOpen
         }));
       }
 
 


  render() {


if(this.state.Loader){
    return(<>
 return (
    <header className="sc-fee5f128-0 frPoWM">
      <div className="sc-fee5f128-2 WGXws">
        <ul className="sc-fee5f128-12 ddxdzM">
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ width: '116px', height: '36px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
        </ul>
        <div className="sc-fee5f128-10 fpbcwK">
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ margin: '8px', borderRadius: '12px', width: '65px', height: '58px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br/>
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ margin: '8px', borderRadius: '12px', width: '65px', height: '58px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ margin: '8px', borderRadius: '12px', width: '65px', height: '58px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ margin: '8px', borderRadius: '12px', width: '65px', height: '58px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ margin: '8px', borderRadius: '12px', width: '65px', height: '58px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
        </div>
        <ul className="sc-fee5f128-12 ddxdzM">
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ borderRadius: '12px', width: '45px', height: '45px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ borderRadius: '12px', width: '45px', height: '45px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
          <span aria-live="polite" aria-busy="true">
            <span className="react-loading-skeleton" style={{ borderRadius: '12px', width: '45px', height: '45px', '--base-color': '#C0C4CF', '--highlight-color': '#EBEDF4' }}>‌</span>
            <br />
          </span>
        </ul>
      </div>
    </header>
    </>)
}

    return (
 <>
 
 <header class="sc-fee5f128-0 frPoWM">
   <div class="sc-fee5f128-2 WGXws">
      <div class="sc-fee5f128-7 dlcUEZ">
        
         <Link to="/raffles" class="sc-fee5f128-9 ivoZEH" >
            <img src={logo} class="mylogo" />
         </Link>


         



      </div>
      <div class="sc-fee5f128-10 fpbcwK">
         
        <NavLink exact className={this.getClassNames} to="/raffles">
         Rifas
        </NavLink>

        <NavLink exact className={this.getClassNames} to="/rifinhas">
         Rifinhas
        </NavLink>


        <NavLink exact className={this.getClassNames} to="/orders">
         Pedidos
        </NavLink>


        <NavLink exact className={this.getClassNames} to="/affiliates">
        Afiliados
        </NavLink>



        <NavLink exact className={this.getClassNames} to="/personalize">
        Personalizar
        </NavLink>
         
         
         
      </div>
      <ul class="sc-fee5f128-12 ddxdzM">
         <li class="sc-cf9fb95-2 ibVeNp dropdown-wrapper" ref={this.dropdownRef3} >
            <button type="button" class="sc-cf9fb95-0 jEcjzF" onClick={()=>{
               this.setState({MenEtc:!this.state.MenEtc})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-help-circle ">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                  <path d="M12 17h.01"></path>
               </svg>
            </button>
          {
            this.state.MenEtc && <>
            <div aria-hidden="false" role="menu" class="sc-e82feeba-0 dhya-DG">
   <div class="sc-cf9fb95-1 gigqVx"></div>
   <a href="https://eurifei.com/ajuda" target="_blank" rel="noopener noreferrer" aria-label="Central de ajuda" class="sc-fee5f128-18 inVboz">
      <div class="sc-fee5f128-19 bORGyA icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-life-buoy ">
            <circle cx="12" cy="12" r="10"></circle>
            <path d="m4.93 4.93 4.24 4.24"></path>
            <path d="m14.83 9.17 4.24-4.24"></path>
            <path d="m14.83 14.83 4.24 4.24"></path>
            <path d="m9.17 14.83-4.24 4.24"></path>
            <circle cx="12" cy="12" r="4"></circle>
         </svg>
      </div>
      <span>Central de ajuda</span>
   </a>
   <a href="https://www.youtube.com/eurifei/" target="_blank" rel="noopener noreferrer" aria-label="Vídeos tutoriais" class="sc-fee5f128-18 inVboz">
      <div class="sc-fee5f128-19 bORGyA icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"></path>
            <path d="m10 15 5-3-5-3z"></path>
         </svg>
      </div>
      <span>Vídeos tutoriais</span>
   </a>
   <hr/>
   <a href="https://wa.me/eurifei/" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp" class="sc-fee5f128-18 inVboz">
      <div class="sc-fee5f128-19 bORGyA icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24" >
            <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"></path>
         </svg>
      </div>
      <span>WhatsApp</span>
   </a>
</div>

            </>
          }
         </li>
         <li class="sc-cf9fb95-2 ibVeNp dropdown-wrapper" ref={this.dropdownRef2}>
            <button type="button" data-notifications="0" class="sc-cf9fb95-0 jEcjzF" onClick={()=>{
                this.setState({MenNtf:!this.state.MenNtf})
            }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bell ">
                  <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
                  <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
               </svg>
            </button>
            {
                this.state.MenNtf && <>
                <div aria-hidden="false" role="menu" class="sc-e82feeba-0 eEkxdi">
   <div class="sc-cf9fb95-1 gigqVx"></div>
   <div class="sc-fee5f128-14 fCGBX">
      <div class="sc-fee5f128-15 fnLxda">
         <h2>Notificações</h2>
         <Link title="Configurações" aria-label="Configurações" to="/account/preferences#notifications" class="sc-fee5f128-17 hFspiN">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings ">
               <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
               <circle cx="12" cy="12" r="3"></circle>
            </svg>
         </Link>
      </div>
   </div>
   <hr/>
  {this.state.ntf.length > 0 ? <>
  {this.state.ntf.map((item,index)=><>
   <a class="sc-b22d7a16-0 gTQxSt clickable" href="#" aria-label="Abrir notificação">
   <div class="sc-b22d7a16-1 jyRlru align-center">
      <div class="sc-b22d7a16-2 gCZaLj">
         <div class="sc-b22d7a16-3 iFqwKY"><img src="#" alt="Imagem da notificação" width="55" height="55"/></div>
         <div  class="sc-b22d7a16-4 dSxiQZ">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign ">
               <line x1="12" x2="12" y1="2" y2="22"></line>
               <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
            </svg>
         </div>
      </div>
      <div>
         <div class="sc-b22d7a16-5 gaIGKJ"><span><strong>:user</strong> comprou :quanty bilhetes  via :method.</span></div>
         <div class="sc-b22d7a16-6 dpNeUa">
            <span>Há :time dia:s</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link ">
               <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
               <polyline points="15 3 21 3 21 9"></polyline>
               <line x1="10" x2="21" y1="14" y2="3"></line>
            </svg>
         </div>
      </div>
   </div>
</a>

  </>)}
  </> : <>
  <div class="sc-fee5f128-13 drYJoW">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 1024 1024">
         <defs>
            <linearGradient id="_element_656" gradientUnits="userSpaceOnUse" x1="512" y1="1024.079" x2="512" y2="-0.079">
               <stop offset="0" stop-color="#fb8b00"></stop>
               <stop offset="1" stop-color="#ffc47b"></stop>
            </linearGradient>
            <linearGradient id="_element_464" gradientUnits="userSpaceOnUse" x1="109.066" y1="348.433" x2="109.066" y2="146.828">
               <stop offset="0" stop-color="#0070fb"></stop>
               <stop offset="1" stop-color="#7acbff"></stop>
            </linearGradient>
         </defs>
         <path fill="url(#_element_656)" d="M379.48 17.45C652.62 -55.74 933.37 106.35 1006.55 379.48C1079.74 652.62 917.65 933.37 644.52 1006.55C371.38 1079.74 90.63 917.65 17.45 644.52C-55.74 371.38 106.35 90.63 379.48 17.45Z"></path>
         <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M244.46 599.22C244.46 599.22 235.86 545.62 295.2 529.72C353.01 514.23 371.96 565.05 371.96 565.05"></path>
         <path fill="none" stroke="#000000" stroke-linecap="round" stroke-width="57" d="M659.81 487.92C659.81 487.92 651.21 434.32 710.55 418.42C768.36 402.93 787.31 453.76 787.31 453.76"></path>
         <path fill="#000000" d="M557.55 682C614.17 666.83 649.47 705.85 644.68 713.53C641.24 719.05 610.88 700.84 565.83 712.91C524.32 724.04 505.89 756.33 498.83 752.61C490.38 748.16 500.71 697.23 557.55 682Z"></path>
         <path fill="url(#_element_464)" d="M99.54 156.59C158.71 190.76 249.14 310.84 126.3 343.75C2.57 376.91 22.48 225.67 56.42 166.88C70.38 142.7 73.95 141.82 99.54 156.59Z"></path>
      </svg>
      <p style={{
         fontSize:14,
         marginTop:15
      }}>Parece que ainda não há nada aqui</p>
   </div>
  </>}
</div>

                </>
            }
         </li>
         <li class="sc-cf9fb95-2 ibVeNp dropdown-wrapper" ref={this.dropdownRef}>
            <button type="button" onClick={()=>{
            this.setState({MenOpc:!this.state.MenOpc})
         }} class="sc-cf9fb95-0 jEcjzF">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round ">
                  <circle cx="12" cy="8" r="5"></circle>
                  <path d="M20 21a8 8 0 0 0-16 0"></path>
               </svg>
            </button>
            {this.state.MenOpc && <>
                <div ref={this.elementRef} aria-hidden="false" role="menu" class="sc-e82feeba-0 fZGPcO">
   <div class="sc-cf9fb95-1 gigqVx"></div>
   <div class="sc-fee5f128-16 hhzGHt">
      <h4>{this.state.Data.name}</h4>
   </div>
   <hr/>
   <Link to="/account" class="sc-fee5f128-18 inVboz">
      <div class="sc-fee5f128-19 bORGyA icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings ">
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
            <circle cx="12" cy="12" r="3"></circle>
         </svg>
      </div>
      <span>Configurações</span>
   </Link>
   <button type="button" aria-label="Sair" class="sc-fee5f128-18 inVboz" onClick={()=>{
      Cookies.remove("auth_token");
      this.props.navigate("/")
   }}>
      <div class="sc-fee5f128-19 bORGyA icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out ">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" x2="9" y1="12" y2="12"></line>
         </svg>
      </div>
      <span>Sair</span>
   </button>
   <hr/>
   <ul class="sc-fee5f128-20 hsufhm">
      <li><a href="https://eurifei.com/ajuda/outros/termos-de-uso" target="_blank" rel="noopener noreferrer" aria-label="Termos">Termos</a><span aria-hidden="true"> · </span></li>
      <li><a href="https://eurifei.com/ajuda/outros/politica-de-privacidade" target="_blank" rel="noopener noreferrer" aria-label="Privacidade">Privacidade</a><span aria-hidden="true"> · </span></li>
      <li>EuRifei © 2024</li>
   </ul>
</div>

            </>}
         </li>
      </ul>
   </div>
</header>
<div class="sc-fee5f128-3 jtNgYo">
   <Link 
   onClick={()=>{
      this.setState({MenActive:"a"})
   }}
    className={this.state.MenActive == "a" ? "sc-fee5f128-4 eaXZST active" : "sc-fee5f128-4 eaXZST"}
 
    aria-label="Campanhas" to="/raffles">
      <div class="sc-fee5f128-5 jpBKJm icon">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 10.75C22.41 10.75 22.75 10.41 22.75 10V9C22.75 4.59 21.41 3.25 17 3.25H10.75V5.5C10.75 5.91 10.41 6.25 10 6.25C9.59 6.25 9.25 5.91 9.25 5.5V3.25H7C2.59 3.25 1.25 4.59 1.25 9V9.5C1.25 9.91 1.59 10.25 2 10.25C2.96 10.25 3.75 11.04 3.75 12C3.75 12.96 2.96 13.75 2 13.75C1.59 13.75 1.25 14.09 1.25 14.5V15C1.25 19.41 2.59 20.75 7 20.75H9.25V18.5C9.25 18.09 9.59 17.75 10 17.75C10.41 17.75 10.75 18.09 10.75 18.5V20.75H17C21.41 20.75 22.75 19.41 22.75 15C22.75 14.59 22.41 14.25 22 14.25C21.04 14.25 20.25 13.46 20.25 12.5C20.25 11.54 21.04 10.75 22 10.75ZM10.75 14.17C10.75 14.58 10.41 14.92 10 14.92C9.59 14.92 9.25 14.58 9.25 14.17V9.83C9.25 9.42 9.59 9.08 10 9.08C10.41 9.08 10.75 9.42 10.75 9.83V14.17Z"></path>
         </svg>
      </div>
      <span class="sc-fee5f128-6 jHJNax">Campanhas</span>
   </Link>
   <Link 
   onClick={()=>{
      this.setState({MenActive:"b"})
   }}
    className={this.state.MenActive == "b" ? "sc-fee5f128-4 eaXZST active" : "sc-fee5f128-4 eaXZST"}
   aria-label="Rifinhas" to="/rifinhas">
      <div class="sc-fee5f128-5 jpBKJm icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" stroke-width="1.5">
            <path d="M14.5 10.6499H9.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M16.8199 2H7.17995C5.04995 2 3.31995 3.74 3.31995 5.86V19.95C3.31995 21.75 4.60995 22.51 6.18995 21.64L11.0699 18.93C11.5899 18.64 12.4299 18.64 12.9399 18.93L17.8199 21.64C19.3999 22.52 20.6899 21.76 20.6899 19.95V5.86C20.6799 3.74 18.9499 2 16.8199 2Z" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div>
      <span class="sc-fee5f128-6 jHJNax">Rifinhas</span>
   </Link>
   <Link onClick={()=>{
      this.setState({MenActive:"c"})
   }}
    className={this.state.MenActive == "c" ? "sc-fee5f128-4 eaXZST active" : "sc-fee5f128-4 eaXZST"} aria-label="Pedidos" to="/orders">
      <div class="sc-fee5f128-5 jpBKJm icon">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5">
            <path d="M22 12V17C22 20 20 22 17 22H7C4 22 2 20 2 17V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M17.7514 7.05C17.5114 7.01 17.2614 7.00001 17.0014 7.00001H7.00141C6.72141 7.00001 6.45141 7.02001 6.19141 7.06001C6.33141 6.78001 6.53141 6.52001 6.77141 6.28001L10.0214 3.02C11.3914 1.66 13.6114 1.66 14.9814 3.02L16.7314 4.79002C17.3714 5.42002 17.7114 6.22 17.7514 7.05Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div>
      <span class="sc-fee5f128-6 jHJNax">Pedidos</span>
   </Link>
   <Link 
   onClick={()=>{
      this.setState({MenActive:"d"})
   }}
    className={this.state.MenActive == "d" ? "sc-fee5f128-4 eaXZST active" : "sc-fee5f128-4 eaXZST"}
   aria-label="Afiliados" to="/affiliates">
      <div class="sc-fee5f128-5 jpBKJm icon">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5">
            <path d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div>
      <span class="sc-fee5f128-6 jHJNax">Afiliados</span>
   </Link>
   <Link onClick={()=>{
      this.setState({MenActive:"e"})
   }}
    className={this.state.MenActive == "e" ? "sc-fee5f128-4 eaXZST active" : "sc-fee5f128-4 eaXZST"} aria-label="Personalizações" to="/personalize">
      <div class="sc-fee5f128-5 jpBKJm icon">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5">
            <path d="M9.5 19.5V18H4.5C3.95 18 3.45 17.78 3.09 17.41C2.72 17.05 2.5 16.55 2.5 16C2.5 14.97 3.3 14.11 4.31 14.01C4.37 14 4.43 14 4.5 14H19.5C19.57 14 19.63 14 19.69 14.01C20.17 14.05 20.59 14.26 20.91 14.59C21.32 14.99 21.54 15.56 21.49 16.18C21.4 17.23 20.45 18 19.39 18H14.5V19.5C14.5 20.88 13.38 22 12 22C10.62 22 9.5 20.88 9.5 19.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M20.17 5.3L19.69 14.01C19.63 14 19.57 14 19.5 14H4.50001C4.43001 14 4.37001 14 4.31001 14.01L3.83001 5.3C3.65001 3.53 5.04001 2 6.81001 2H17.19C18.96 2 20.35 3.53 20.17 5.3Z" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M7.98999 2V7" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M12 2V4" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div>
      <span class="sc-fee5f128-6 jHJNax">Personalizações</span>
   </Link>
</div>

{
   (this.state.MenOpc == true || this.state.MenNtf == true || this.state.MenEtc == true) && <>
   <div class="sc-fee5f128-23 bHa-dVb"></div>
   </>
}
 </>
    );
  }
}

export default Header;